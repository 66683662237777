import { Component, Input } from '@angular/core';
import { NgIf, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'atlas-service-availability',
  standalone: true,
  imports: [NgOptimizedImage, NgIf],
  templateUrl: './service-availability.component.html',
  styleUrl: './service-availability.component.scss',
})
export class ServiceAvailabilityComponent {
  @Input() isAvailable!: boolean;
  @Input() serviceName!: string;
}
