import { Injectable, Type } from '@angular/core';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { PartnerVehicleFormComponent } from '@app/administrator/partners/partner-vehicle-form/partner-vehicle-form.component';
import { DialogService } from '@ui/dialog/dialog.service';
import {IPartnerVehicleDto, IPartnerVehiclesItem} from "@shared/model/atlas.api";

@Injectable({
  providedIn: 'root',
})
export class VehicleDialogService {
  private _dialogConfig: DialogConfig = new DialogConfig(DialogSize.LARGE);

  constructor(private _dialogService: DialogService) {}

  show(partnerId: string | undefined, vehicleId: string | undefined, fromInfoPage: boolean) {
    this._dialogConfig.header = 'Izmena vozila';
    this._dialogConfig.customSubmitButton = {
      label: 'Izmeni vozilo',
      icon: '',
    };
    this._dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this._dialogConfig.maximisable = false;
    this._dialogConfig.closable = true;

    this._dialogConfig.data = {
      fromInfoPage: fromInfoPage,
      partnerId: partnerId,
      vehicleId: vehicleId,
    };

    return this._openDialog(PartnerVehicleFormComponent);
  }

  private _openDialog(form: Type<any>) {
    return this._dialogService.open(form, this._dialogConfig);
  }
}

export interface DialogResponse {
  vehicleForm: IPartnerVehicleDto,
  vehicleItem: IPartnerVehiclesItem
}
