<atlas-page
  pageTitle="Administracija website kupovina"
  pageDescription="Prikaz liste kupovina preko website"
>
    <div class="flex flex-column md:flex-row gap-3">
        <atlas-page-info-card
                [cardTitle]="'Suma paketa'"
                cardIcon="pi-money-bill"
                [cardNumber]="packageSum | currencyNumber"
                class="flex-1"></atlas-page-info-card>
    </div>

  <div class="flex flex-column gap-3 table-filter mt-3">
    <div class="flex align-items-center gap-3">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Sve </span>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="Neplaćene">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Neplaćene </span>
              <p-badge [value]="totalNonPaid" />
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
      <div class="search-bar">
        <atlas-inline-wrapper [showLabel]="false" formCtrlId="search">
          <div class="flex align-items-end">
            <atlas-dropdown
              [hideErrors]="true"
              [options]="searchOptions"
              [formCtrl]="searchOptionCtrl"
              ctrlStyleClass="input-next-to w-50rem"
            ></atlas-dropdown>
            <atlas-input-text
              [hideErrors]="true"
              formCtrlClass="dropdown-next-to w-10rem lg:w-20rem"
              ctrlPlaceholder="Pretraži"
              [formCtrl]="searchCtrl"
              (onEnterClicked)="load()"
            ></atlas-input-text>
          </div>
        </atlas-inline-wrapper>
      </div>
    </div>
  </div>
  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
