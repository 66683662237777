<div class="flex flex-column gap-2">
  <span class="p-input-icon-left google-autocomplete">
    <i class="pi pi-map-marker"></i>
    <input
      #placeStartRef
      id="start-id"
      placeholder="Mesto kvara"
      [ngClass]="
        formService.getForm.controls.from.controls.place.invalid &&
        (formService.getForm.controls.from.controls.place.dirty ||
          formService.getForm.controls.from.controls.place.touched)
          ? 'p-inputtext p-component invalid'
          : 'p-inputtext p-component'
      "
    />
  </span>
  <atlas-error-required
    [formCtrl]="formService.getForm.controls.from.controls.place"
  ></atlas-error-required>
</div>

<div class="flex flex-column gap-2">
  <span class="p-input-icon-left google-autocomplete mt-3">
    <i class="pi pi-map-marker"></i>
    <input
      #placeEndRef
      id="end-id"
      placeholder="Odredište"
      autocomplete="off"
      [ngClass]="
        formService.getForm.controls.to.controls.place.invalid &&
        (formService.getForm.controls.to.controls.place.dirty ||
          formService.getForm.controls.to.controls.place.touched)
          ? 'p-inputtext p-component invalid'
          : 'p-inputtext p-component'
      "
    />
    <!--  <atlas-error-required [formCtrl]="placeEndCtrl"></atlas-error-required>-->
  </span>
  <atlas-error-required
    [formCtrl]="formService.getForm.controls.to.controls.place"
  ></atlas-error-required>
</div>

<ng-container *ngIf="vehicleDestinationAddress" class="py-2">
  <div>
    <span class="distance-text pr-0">Adresa šlepanja za vozilo: </span
    ><span class="font-bold">{{ vehicleDestinationAddress }}</span>
  </div>
</ng-container>

<ng-container *ngIf="clientDestinationAddress" class="py-2">
  <div>
    <span class="distance-text pr-0">Adresa šlepanja za moj paket: </span
    ><span class="font-bold">{{ clientDestinationAddress }}</span>
  </div>
</ng-container>

<p-divider></p-divider>

<div class="text-sm">
  <img
    [ngSrc]="'assets/images/location_distance.svg'"
    width="13.33"
    height="13.33"
    alt="distance-image"
  />
  <span class="distance-text">Udaljenost: </span
  ><span class="font-bold">{{ distanceText }}</span>
</div>

<div class="mt-3 w-full max-w-30rem">
  <google-map #gmap [options]="mapOptions">
    @if (service.directionsResults$ | async; as directionsResults) {
      <map-directions-renderer
        [directions]="directionsResults"
        [options]="directionOptions"
      />
    }
  </google-map>
</div>
