import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {ControlsOf} from '@shared/forms/forms-common';
import {
  IAddPartnerDriverCommand,
  ICreatePartnerCommand,
  IPhoneItem,
  PartnerType,
  PartnerVehicleDto,
  PhoneItem,
} from '@shared/model/atlas.api';
import {BehaviorSubject} from 'rxjs';
import { strictEmailValidator } from './emailValidator';
import { Guid } from 'guid-typescript';
import { ContactFormService } from './contact-form.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerFormService {
  constructor(
    private _fb: FormBuilder, 
    private phonesService: ContactFormService) {}

  partnerForm: CreatePartnerForm;
  vehicleForm: CreatePartnerVehicleForm;
  dirverForm: CreateDriverForm;

  get getVehicleForm(): CreatePartnerVehicleForm {
    if (!this.vehicleForm) {
      this.initPartnerVehicleForm();
    }
    return this.vehicleForm;
  }

  get getPartnerForm(): CreatePartnerForm {
    if (!this.partnerForm) {
      this.initPartnerForm();
    }
    return this.partnerForm;
  }

  get getDriverFrom(): CreateDriverForm {
    if (!this.dirverForm) {
      this.initDriverForm();
    }
    return this.dirverForm;
  }

  isClientBoxValid() {
    if (!this.partnerForm) {
      return false;
    }
    return (
      this.partnerForm.controls.name.valid &&
      this.partnerForm.controls.alias.valid &&
      this.partnerForm.controls.address.valid &&
      this.partnerForm.controls.taxId.valid &&
      this.partnerForm.controls.nationalNumber.valid &&
      this.partnerForm.controls.contactEmail.valid &&
      this.partnerForm.controls.contactPerson.valid &&
      this.partnerForm.controls.contactNumber.valid &&
      this.partnerForm.controls.activityTypeIds.valid &&
      this.partnerForm.controls.partnerType.valid
    );
  }

  isVehicleBoxValid() {
    if (!this.partnerForm) {
      return false;
    }
    return this.partnerForm.controls.vehicles.valid;
  }

  initPartnerForm(model?: ICreatePartnerCommand) {
    if (!this.partnerForm) {
      this.partnerForm = this._fb.group<ControlsOf<ICreatePartnerCommand>>({
        partnerId: this._fb.control(null),
        name: this._fb.control(model?.name, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        address: this._fb.control(model?.address, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        alias: this._fb.control(model?.alias, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required],
        }),
        taxId: this._fb.control(model?.taxId, {
          nonNullable: true,
          validators: [Validators.maxLength(9), Validators.required],
        }),
        nationalNumber: this._fb.control(model?.nationalNumber, {
          nonNullable: true,
          validators: [Validators.maxLength(13), Validators.required],
        }),
        bankAccountNum: this._fb.control(model?.bankAccountNum, {
          nonNullable: true,
          validators: [Validators.maxLength(18)],
        }),
        contactPerson: this._fb.control(model?.contactPerson, {
          nonNullable: true,
          validators: [Validators.maxLength(32), Validators.required],
        }),
        contactEmail: this._fb.control(model?.contactEmail, {
          nonNullable: true,
          validators: [Validators.maxLength(64), Validators.required, Validators.email, strictEmailValidator()],
        }),
        contactNumber: this._fb.control(model?.contactNumber, {
          nonNullable: true,
          validators: [Validators.maxLength(32), Validators.required],
        }),
        partnerType: this._fb.control(model?.partnerType ?? PartnerType.EMPTY, {
          validators: [Validators.pattern(`^(${PartnerType.AUTH_SERVICE}|${PartnerType.BOSCH_CAR}|${PartnerType.REGULAR})+$`)],
        }),
        activityTypeIds: this._fb.array<FormControl<string>>([], {
          validators: [Validators.required]
        }),
        vehicles: this._fb.array<FormGroup<ControlsOf<PartnerVehicleDto>>>([]),
      });
    }
    return this.partnerForm;
  }

  initPartnerVehicleForm(model?: PartnerVehicleDto) {
    return this._fb.group<ControlsOf<PartnerVehicleDto>>({
      id: this._fb.control(model?.id, {
        nonNullable: false,
      }),
      typeId: this._fb.control(model?.typeId, {
        nonNullable: false,
        validators: [Validators.maxLength(60), Validators.required],
      }),
      brand: this._fb.control(model?.brand, {
        nonNullable: true,
        validators: [Validators.maxLength(32), Validators.required],
      }),
      modelId: this._fb.control(model?.modelId, {
        nonNullable: true,
      }),
      model: this._fb.control(model?.model, {
        nonNullable: true,
        validators: [Validators.maxLength(32), Validators.required],
      }),
      licencePlate: this._fb.control(model?.licencePlate, {
        nonNullable: true,
        validators: [Validators.maxLength(8), Validators.required],
      }),
      maxWeight: this._fb.control(model?.maxWeight, {
        nonNullable: true,
        validators: [Validators.max(99999), Validators.required],
      }),
      seatsNumber: this._fb.control(model?.seatsNumber, {
        nonNullable: true,
        validators: [Validators.max(40), Validators.required],
      }),
      pricePerKm: this._fb.control(model?.pricePerKm, {
        nonNullable: true,
        validators: [Validators.max(99999), Validators.required],
      }),
      longitude: this._fb.control(model?.longitude, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      latitude: this._fb.control(model?.latitude, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      place: this._fb.control(model?.place, {
        nonNullable: true,
        validators: [Validators.maxLength(256), Validators.required],
      }),
      init: undefined,
      toJSON: undefined,
    });
  }

  initDriverForm(model?: IAddPartnerDriverCommand) {
    this.dirverForm = this._fb.group<ControlsOf<IAddPartnerDriverCommand>>({
      name: this._fb.control(model?.name, {
        nonNullable: false,
        validators: [Validators.maxLength(64), Validators.required],
      }),
      lastName: this._fb.control(model?.lastName, {
        nonNullable: false,
        validators: [Validators.maxLength(64), Validators.required],
      }),
      phones: this._fb.array<FormGroup<ControlsOf<PhoneItem>>>([]),
      email: this._fb.control(model?.email, {
        nonNullable: false,
        validators: [
          Validators.maxLength(64),
          Validators.required,
          Validators.email,
          strictEmailValidator()
        ],
      }),
      sendInvite: this._fb.control(false, {
        nonNullable: false,
        validators: [Validators.required],
      }),
    });
    return this.dirverForm;
  }

  selectedPartnerId$ = new BehaviorSubject<string>(undefined);

  set setSelectedPartnerId(value: string) {
    this.selectedPartnerId$.next(value);
  }

  get getSelectedPartnerId() {
    return this.selectedPartnerId$.asObservable();
  }

  reset() {
    this.partnerForm = undefined;
    this.vehicleForm = undefined;
  }
}

export declare type CreatePartnerForm = FormGroup<
  ControlsOf<ICreatePartnerCommand>
>;

export declare type CreatePartnerVehicleForm = FormGroup<
  ControlsOf<PartnerVehicleDto>
>;

export declare type CreateDriverForm = FormGroup<
  ControlsOf<IAddPartnerDriverCommand>
>;
