import { NgFor } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AddZoneForm,
  AdminService,
} from '@app/administrator/services/admin-service';
import { PageComponent } from '@shared/components/page/page.component';
import {
  CreateParkingZoneCommand,
  ParkingZoneItem,
  ParkingsClient,
  ZoneColor,
  ZoneType,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { DropdownColorComponent } from '@ui/dropdown-color/dropdown-color/dropdown-color.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperZoneComponent } from '@ui/inline-wrapper-zone/inline-wrapper-zone/inline-wrapper-zone.component';
import { InputDescriptionComponent } from '@ui/input-description/input-description/input-description.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-add-zone',
  standalone: true,
  imports: [
    PageComponent,
    ScrollPanelModule,
    InlineWrapperZoneComponent,
    InputTextComponent,
    FormsModule,
    NgFor,
    ReactiveFormsModule,
    RadioButtonModule,
    DropdownComponent,
    DropdownColorComponent,
    InputDescriptionComponent,
  ],
  templateUrl: './add-zone.component.html',
  styleUrl: './add-zone.component.scss',
})
export class AddZoneComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  form: AddZoneForm;
  zones: ParkingZoneItem[] = [];
  colors: any[];
  zoneTypes: any[];
  @Input() ctrlInfo: string = '100 karaktera';

  selectedCityId: string | null = null;
  colorPlaceHolder: string;

  constructor(
    private _dialogRef: DialogRef,
    private _adminService: AdminService,
    private _alertService: AlertService,
    private _parkingClient: ParkingsClient,
  ) {
    this.form = this._adminService.getAddZoneForm();
    this._initzoneTypes();
    this._initColors();
  }

  ngOnInit(): void {
    this._initzoneTypes();
    this._initColors();
    this._adminService.cityId$.subscribe((cityId) => {
      this.selectedCityId = cityId;
    });
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  public _handleSave() {
    if (!this.form?.valid) {
      this.form.markAllAsTouched();
      this._alertService.addWarnMsg('Potrebno je uneti sva polja!');
      return;
    }
    const zoneName = this.form.value.name;
    const zoneDesc = this.form.value.description;
    const zoneNumber = this.form.value.phoneNumber;
    const zoneType = this.form.value.type;
    const zoneColor = this.form.value.color;
    this._subs.add(
      this._parkingClient
        .createParkingZone(
          new CreateParkingZoneCommand({
            name: zoneName,
            cityId: this.selectedCityId,
            description: zoneDesc,
            phoneNumber: zoneNumber,
            color: zoneColor,
            zoneType: zoneType,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.exit();
        }),
    );
  }
  handleRoleChange(event: any) {
    this.form.controls.color.patchValue(event.value.id);
    this.colorPlaceHolder = event.value.zoneColor;
  }

  private _initzoneTypes() {
    this.zoneTypes = [
      { name: 'Po satu', id: ZoneType.PO_SATU },
      { name: 'Dnevna', id: ZoneType.DNEVNA },
    ];
  }

  private _initColors() {
    this.colors = [
      { zoneColor: 'Plava', id: ZoneColor.PLAVA },
      { zoneColor: 'Zelena', id: ZoneColor.ZELENA },
      { zoneColor: 'Zuta', id: ZoneColor.ZUTA },
      { zoneColor: 'Ljubicasta', id: ZoneColor.LJUBICASTA },
      { zoneColor: 'Siva', id: ZoneColor.SIVA },
      { zoneColor: 'Crvena', id: ZoneColor.CRVENA },
      { zoneColor: 'Zlatna', id: ZoneColor.ZLATNA },
      { zoneColor: 'Bela', id: ZoneColor.BELA },
    ];
  }

  exit() {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this.form?.reset();
    this._subs.unsubscribe();
  }
}
