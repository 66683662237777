import { Component, OnDestroy, OnInit } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PanelModule } from 'primeng/panel';
import { Subscription } from 'rxjs';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { LocationMapComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/location-map/location-map.component';
import { NumOfPassengersDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/num-of-passengers-dropdown/num-of-passengers-dropdown.component';
import {
  AssistanceService,
  ClientDetails,
} from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';

@Component({
  selector: 'atlas-assistance-details',
  standalone: true,
  imports: [
    DividerModule,
    InlineWrapperComponent,
    LocationMapComponent,
    NumOfPassengersDropdownComponent,
    FieldsetModule,
    AccordionModule,
    AvatarModule,
    ScrollPanelModule,
    PanelModule,
    ButtonComponent,
    JsonPipe,
    NgIf,
    NgClass,
  ],
  templateUrl: './assistance-details.component.html',
  styleUrl: './assistance-details.component.scss',
})
export class AssistanceDetailsComponent implements OnInit, OnDestroy {
  clientDetails: ClientDetails;
  private _subs: Subscription;

  constructor(
    public formService: AssistanceFormService,
    public service: AssistanceService,
  ) {
    this._subs = new Subscription();
    this.clientDetails = service.getClientDetails;
  }

  ngOnInit() {
    // this._subs.add(
    //   this.formService.getForm.controls.partnerVehicleId.valueChanges.subscribe(
    //     (partnerVehicleId) => {
    //       if (partnerVehicleId) {
    //         this.service.getSelectedPartner;
    //       } else {
    //         this.service.setSelectedPartner = undefined;
    //       }
    //     },
    //   ),
    // );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  handleCancelPartner() {
    //this.formService.setPartnerVehicle = undefined;
    this.service.resetPartner();
  }

  protected readonly ButtonStyle = ButtonStyle;
}
