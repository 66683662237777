import { NgOptimizedImage, NgIf, CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PackageService } from '@app/administrator/services/package-service';
import {
  DeletePromoCodeCommand,
  GetPromoCodePreviewResponse,
  IGetPromoCodePreviewResponse,
  PackagesClient,
  PromoCodesClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { ConfirmationService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';
import { firstValueFrom, Subscription } from 'rxjs';
import { PromoCodeService } from '@app/administrator/promo-codes/promo-code-service';

@Component({
  selector: 'atlas-promo-code-preview-old',
  standalone: true,
  imports: [
    NgOptimizedImage,
    InputSwitchComponent,
    InputSwitchModule,
    TabViewModule,
    NgIf,
    CommonModule,
    DividerModule,
    FormsModule,
  ],
  templateUrl: './promo-code-preview.component.html',
  styleUrl: './promo-code-preview.component.scss',
})
export class PromoCodePreviewComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  currentCodeId: string;
  previewData: GetPromoCodePreviewResponse;

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private _alertService: AlertService,
    private _formService: PromoCodeService,
    private _promoCodeClient: PromoCodesClient,
    private _locationService: LocationService,
  ) {
    this._subs = new Subscription();
    this._subs.add(
      this._formService.getSelectedPromoCodeId.subscribe((res) => {
        this.currentCodeId = res;
      }),
    );
    this._getPreviewData().then((res) => {
      this.previewData = res.result.value;
      console.log(this.previewData);
    });
  }

  ngOnInit() {
    this._subs.add(
      this._formService.getSelectedPromoCodeId.subscribe((res) => {
        this.currentCodeId = res;
      }),
    );
    this._getPreviewData().then();
  }

  private async _getPreviewData() {
    return await firstValueFrom(
      this._promoCodeClient.getPromoCodePreview(this.currentCodeId),
    );
  }

  editPromoCode() {
    this._formService.setSelectedPromoCodeId = this.currentCodeId.toString();
    this._locationService.routeToEditPromoCode().then();
    this.dialogRef.close();
  }

  deletePromoCode(promoCode: any) {
    this._subs.add(
      this._promoCodeClient
        .deletePromoCode(
          new DeletePromoCodeCommand({
            id: promoCode.id,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.dialogRef.close(true);
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
