import { finalize, mergeMap, take, throwError } from 'rxjs';
import { inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { AuthService } from '@shared/services/auth.service';
import { RefreshTokenCommand, TokensClient } from '@shared/model/atlas.api';
import { RefreshService } from '@shared/services/refresh.service';

export const refreshTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const tokensClient = inject(TokensClient);
  const service = inject(RefreshService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        error instanceof HttpErrorResponse &&
        !req.url.includes('tokens') &&
        error.status === HttpStatusCode.Unauthorized
      ) {
        if (service.currentlyRefreshing) {
          return service.getTokenRefreshed.pipe(
            filter((token) => token != undefined),
            take(1),
            mergeMap((tokens) => {
              return next(
                req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${tokens}`),
                }),
              );
            }),
          );
        } else {
          // we must clean up before every first refresh..in case there ara some other requests,
          // waiting for new refreshed token
          service.startRefreshing();

          return tokensClient
            .refresh(
              new RefreshTokenCommand({
                refreshToken: authService.getRefreshToken, //service.getRefreshedTokens().refreshToken,
                token: authService.getToken, //service.getRefreshedTokens().token,
              }),
            )
            .pipe(
              finalize(() => {
                service.currentlyRefreshing = false;
                service.setTokenRefreshed = authService.getToken;
              }),
              mergeMap((refreshResult) => {
                authService.setTokens(
                  refreshResult.result.token,
                  refreshResult.result.refreshToken,
                );
                return next(
                  req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      `Bearer ${refreshResult.result.token}`,
                    ),
                  }),
                );
              }),
              catchError((error) => {
                console.log('error prilikom refresha');
                console.log(error);
                if (error.status == '403' || error.status == '401') {
                  authService.logout();
                }
                return throwError(() => error);
              }),
            );
        }
      }
      return throwError(() => error);
    }),
  );
};
