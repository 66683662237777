import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as signalR from '@microsoft/signalr';
import {
  IRequestAssistanceChanged,
  NotificationMessageType,
} from '@shared/model/atlas.api';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private _hubConnection: signalR.HubConnection;

  constructor(private alertService: AlertService) {}

  public startConnection = (token: string) => {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiBaseUrl}/notifications?access_token=${token}`)
      .build();

    this._hubConnection
      .start()
      .then(() => {
        this._addNotificationListener();
      })
      .catch(
        (err: string) => `Client got error while starting connection: ${err}`,
      );
  };

  public closeConnection() {
    if (this._hubConnection) {
      this._hubConnection.stop().then();
      this._hubConnection = undefined;
    }
  }

  private _addNotificationListener = () => {
    this._hubConnection.on(
      'NOTIFICATION_FROM_SERVER',
      (type: NotificationMessageType, data: any) => {
        switch (type) {
          case NotificationMessageType.REQUEST_COUNT_CHANGED:
            const notificationData: IRequestAssistanceChanged =
              data as IRequestAssistanceChanged;
            console.log(notificationData);
            this.alertService.notifyRequestCountChanged();
            break;
          default:
            return;
        }
      },
    );
  };
}
