import { JsonPipe, NgIf, NgClass, NgTemplateOutlet, NgForOf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PackageDetailSidebarComponent } from '@app/cc-agent/clients/package-detail-sidebar/package-detail-sidebar.component';
import { ClientPackageSidebarComponent } from '@app/sos-agent/packages/client-package-sidebar/client-package-sidebar.component';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { PageComponent } from '@shared/components/page/page.component';
import { ClientClient, ClientWithPackagesItem, ClientWithPackagesTableResponse, GetClientWithPackagesTableQuery, IPaginationResponseOfClientWithPackagesTableResponse } from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import { ButtonComponent } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { Guid } from 'guid-typescript';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule, TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-client-with-packages-table',
  standalone: true,
  imports: [PageComponent,
    TableComponent,
    TableModule,
    ButtonComponent,
    ScrollPanelModule,
    JsonPipe,
    NgIf,
    NgClass,
    SkeletonModule,
    NgTemplateOutlet,
    ButtonModule,
    NgForOf,
    BadgeModule,
    ButtonModule,
    InlineWrapperComponent,
    DropdownComponent,
    InputAddonComponent,
    InputTextComponent,
    PackageDetailSidebarComponent,
  ],
  templateUrl: './client-with-packages-table.component.html',
  styleUrl: './client-with-packages-table.component.scss'
})
export class ClientWithPackagesTableComponent implements OnInit, OnDestroy {

  paginatedData: IPaginationResponseOfClientWithPackagesTableResponse;
  private _subs: Subscription = new Subscription();
  protected tableConfig: TableConfig;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  expandedRows: { [key: string]: boolean } = {};

  constructor(
    private client: ClientClient,
    private _locationService: LocationService,
    private service: ClientPackageTableService
  ) {
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT_NAME,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.CLIENT_NAME,
        value: SearchOptions.CLIENT_NAME,
      },
      {
        label: SearchOptions.CLIENT_PHONE,
        value: SearchOptions.CLIENT_PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
      {
        label: SearchOptions.IS_ACTIVE,
        value: SearchOptions.IS_ACTIVE,
      },
    ];
  }
  ngOnInit(): void {
    this.load()
  }

  handleCreateClient() {
    this._locationService.routeToCreateClientForm();
  }

  onRowExpand(event: TableRowExpandEvent) {
    this.expandedRows[event.data.id] = true;
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    this.expandedRows[event.data.id] = false;
  }

  toggleRow(client: any) {
    if (this.expandedRows[client.id]) {
      delete this.expandedRows[client.id];
    } else {
      console.log(client)
      this.expandedRows[client.id] = true;
    }
  }

  openOptionsDialog() {
    // alert('Test')

    this.service.showPreviewSidebar('test')

  }

  loadLazy(event: LazyLoadEvent) {
    if (event.first === 0) {
      this.paginatedData.currentPage = 0;
    } else {
      this.paginatedData.currentPage = event.first! / event.rows! + 1;
    }
    this.paginatedData.pageSize = event.rows!;
    this.load();
  }

  getPackageSeverity(status: string): string {
    switch (status?.toLowerCase()) {
      case 'istekao':
        return 'danger';
      case 'aktivan':
        return 'success';
      default:
        return 'info';
    }
  }


  async load() {
    this._loadTable().then((res) => {
      this.paginatedData = res.result;
    });
  }

  private async _loadTable() {
    return await firstValueFrom(
      this.client.getClientWithPackagesTable(
        new GetClientWithPackagesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: 5,
          clientName: this.searchOptionCtrl.value === SearchOptions.CLIENT_NAME
            ? this.searchCtrl.value
            : undefined,
          clientPhone: this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
            ? this.searchCtrl.value
            : undefined,
          licencePlate: this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
            ? this.searchCtrl.value
            : undefined,
          isActive: this.searchOptionCtrl.value === SearchOptions.IS_ACTIVE
            ? this.searchCtrl.value
            : undefined,
        }),
      ),

    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }


}

enum SearchOptions {
  CLIENT_NAME = 'Klijent',
  CLIENT_PHONE = 'Telefon',
  LICENCE_PLATE = 'Tablice',
  IS_ACTIVE = 'Aktivnost',
}