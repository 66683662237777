<atlas-page
  pageTitle="Ugovori"
  btnIcon="pi pi-plus"
  pageDescription="Prikaz liste svih ugovora i klijenata"
>
  <!-- Overview of active packages and clients -->
  <div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card
      [cardTitle]="'Aktivnih ugovora'"
      cardIcon="pi-book"
      [cardNumber]="countPackage"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Klijenti'"
      [cardNumber]="countClients"
      cardIcon="pi-users"
      class="flex-1"
    ></atlas-page-info-card>
  </div>

  <div class="text-2xl font-semibold mt-5">Lista ugovora</div>
  <div class="text-base text-gray-600 mt-2">
    Lista svih, aktivnih i neaktivnih ugovora
  </div>

  <div class="flex flex-column mt-5 gap-3 table-filter">
    <div class="flex align-items-center gap-3">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel header="Svi ugovori"></p-tabPanel>
        <p-tabPanel header="Aktivni"></p-tabPanel>
        <p-tabPanel header="Neaktivni"></p-tabPanel>
      </p-tabView>
      <div class="search-bar">
        <atlas-inline-wrapper [showLabel]="false" formCtrlId="search">
          <div class="flex align-items-end">
            <atlas-dropdown
              [hideErrors]="true"
              [options]="searchOptions"
              [formCtrl]="searchOptionCtrl"
              ctrlStyleClass="input-next-to w-50rem"
            ></atlas-dropdown>
            <atlas-input-text
              [hideErrors]="true"
              formCtrlClass="dropdown-next-to w-10rem lg:w-20rem"
              ctrlPlaceholder="Pretraži"
              [formCtrl]="searchCtrl"
              (onEnterClicked)="load()"
            ></atlas-input-text>
          </div>
        </atlas-inline-wrapper>
      </div>
    </div>
  </div>

  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>

<atlas-client-package-sidebar></atlas-client-package-sidebar>