import { Injectable } from '@angular/core';
import { LocationService } from './location.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RefreshService } from '@shared/services/refresh.service';
import { SignalrService } from '@shared/services/signalr.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _tokenName = 'atlas_token';
  private readonly _refreshTokenName = 'atlas_refreshToken';

  constructor(
    private _jwtHelper: JwtHelperService,
    private _locationService: LocationService,
    private _refreshService: RefreshService,
    private signalRService: SignalrService,
  ) {}

  get getUserClaims(): string[] {
    return this.getDecodedToken?.Permission;
  }

  get getDecodedToken(): any {
    if (!this.getToken) {
      return null;
    }
    return this._jwtHelper.decodeToken(this.getToken);
  }

  get getToken(): string | null {
    return localStorage.getItem(this._tokenName);
  }

  get getRefreshToken(): string | null {
    return localStorage.getItem(this._refreshTokenName);
  }

  public isUserAuthenticated = (): boolean => {
    if (!this.getToken) {
      return false;
    }

    return !this.isTokenExpired;
  };

  get isTokenExpired(): boolean {
    if (!this.getToken) {
      return true;
    }
    return this._jwtHelper.isTokenExpired(this.getToken);
  }

  public logout() {
    this.removeTokens();
    this._refreshService.reset();
    this._locationService.routeToLogin();
  }

  setTokens(token: string, refreshToken: string) {
    localStorage.setItem(this._tokenName, token);
    localStorage.setItem(this._refreshTokenName, refreshToken);
  }

  removeTokens() {
    localStorage.removeItem(this._tokenName);
    localStorage.removeItem(this._refreshTokenName);
    this.signalRService.closeConnection();
  }

  /**
   * start signalR connection
   */
  public startSignalR() {
    this.signalRService.closeConnection();
    this.signalRService.startConnection(this.getToken);
  }

  // This method can be called a couple of different ways
  // *hasClaim="'claimType'"  // Assumes claimValue is true
  // *hasClaim="'claimType:value'" // Compares claimValue to value
  // *hasClaim="['claimType1','claimType2:value','claimType3']"
  hasClaim(claimValue: string | string[]): boolean {
    if (!this.isUserAuthenticated()) {
      return false;
    }

    let userHasClaim = false;

    // See if an array of values was passed in.
    if (typeof claimValue === 'string') {
      userHasClaim = this.isClaimValid(claimValue);
    } else {
      const claims: string[] = claimValue;
      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          userHasClaim = this.isClaimValid(claims[index]);
          // If one is successful, then let them in
          if (userHasClaim) {
            break;
          }
        }
      }
    }

    return userHasClaim;
  }

  private isClaimValid(claimValue: string): boolean {
    let ret = false;
    const regEx = new RegExp(`\\b${claimValue}\\b`);

    for (let i = 0; i < this.getUserClaims?.length; ++i) {
      if (regEx.test(this.getUserClaims[i])) {
        ret = true;
        break;
      }
    }
    return ret;
  }
}
