<div class="flex flex-column gap-2 w-full">
  <label [for]="formCtrlId" *ngIf="showLabel">{{ ctrlLabel }}</label>
  <p-dropdown
    (onChange)="handleOnItemChange($event)"
    [disabled]="ctrlDisabled"
    [filterBy]="ctrlFilterBy"
    [filterPlaceholder]="ctrlFilterPlaceholder"
    [filter]="canFilter"
    [formControl]="formCtrl"
    [group]="isGrouped"
    [inputId]="formCtrlId"
    [optionLabel]="optionLabel"
    [options]="options"
    [placeholder]="ctrlPlaceholder"
    [showClear]="canClear"
    [styleClass]="ctrlStyleClass" 
    [tooltip]="ctrlTooltip"
  >
    <ng-template let-outerContext pTemplate="selectedItem">
      <div class="selected-item">
        <span class="color-circle" [style.backgroundColor]="getColor(outerContext.value.id)"></span>
        {{ outerContext.value[optionLabel] }}
      </div>
    </ng-template>
    
    <ng-template let-outerContext pTemplate="item">
      <div class="dropdown-item">
        <span class="color-circle" [style.backgroundColor]="getColor(outerContext.id)"></span>
        {{ outerContext[optionLabel] }}
      </div>
    </ng-template>
  </p-dropdown>

  <atlas-error-required *ngIf="!hideErrors" [formCtrl]="formCtrl"></atlas-error-required>
</div>
