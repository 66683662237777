import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  AssistancesClient,
  IFailureTypeDropdownItem,
} from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';

@Component({
  selector: 'atlas-failure-type-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './failure-type-dropdown.component.html',
  styleUrl: './failure-type-dropdown.component.scss',
})
export class FailureTypeDropdownComponent implements OnInit {
  searchOptions: any[] = [];

  failureTypeCtrl: FormControl<string>;
  vehicles: IFailureTypeDropdownItem[] | undefined;
  clientId: string | undefined = undefined;

  constructor(
    private _client: AssistancesClient,
    private formService: AssistanceFormService,
  ) {
    this.failureTypeCtrl = formService.getForm.controls['failureType'];
  }

  ngOnInit() {
    this._load();
  }

  private _load() {
    this._getFailures().then((res) => {
      this.vehicles = [...res.result.responseList];
      this.searchOptions = this.vehicles!.map((failureType) => ({
        label: failureType.label,
        value: failureType.id,
      }));
    });
  }

  private async _getFailures() {
    return await firstValueFrom(this._client.getFailureTypeDropdown());
  }
}
