<atlas-dropdown
  [options]="searchOptions"
  [formCtrl]="selectedVehicleCtrl"
  ctrlStyleClass="w-full"
  ctrlPlaceholder="Izaberi vozilo"
>
  <ng-template #selectedItem let-obj>
    <div class="p-text-left item-label">{{ obj.label }} <span class="item-sublabel">{{obj.sublabel}}</span></div>
  </ng-template>

  <ng-template #item let-obj>
    <div class="p-text-left item-label">{{ obj.label }} <span class="item-sublabel">{{obj.sublabel}}</span></div>
  </ng-template>
</atlas-dropdown>