import { NgTemplateOutlet, NgIf } from '@angular/common';
import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZoneColor } from '@shared/model/atlas.api';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'atlas-dropdown-color',
  standalone: true,
  imports: [DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    NgIf,
    ErrorRequiredComponent,],
  templateUrl: './dropdown-color.component.html',
  styleUrl: './dropdown-color.component.scss'
})
export class DropdownColorComponent {
  /** I/O */
  @Input() formCtrl!: FormControl;
  @Input() optionLabel!: string;
  @Input() options: any[] = [];
  @Input() formCtrlId!: string;
  @Input() ctrlTooltip!: string;
  @Input() ctrlPlaceholder!: string;
  @Input() ctrlTooltipPosition!: string; // Dodati u .html
  @Input() canFilter!: boolean;
  @Input() ctrlLabel: string;
  @Input() showLabel: boolean = true;
  @Input() ctrlFilterPlaceholder!: string;
  @Input() ctrlFilterBy!: string;
  @Input() ctrlStyleClass!: string;
  @Input() isGrouped!: boolean;
  @Input() canClear!: boolean;
  @Input() ctrlDisabled!: boolean;
  @Input() hideErrors: boolean = false;

  @Output() optionChanged = new EventEmitter();

  /**
   * Pass a template for the autocomplete in this component
   *
   * @usage
   * Can be passed inside the body of this component as:
   *  <app-my-wrapper ...>
   *      <ng-template ... #item let-obj>...</ng-template>
   *  </app-my-wrapper>
   */
  @ContentChild('item') item!: TemplateRef<ElementRef>;
  @ContentChild('selectedItem') selectedItem!: TemplateRef<ElementRef>;

  constructor() {}

  getColor(colorId: ZoneColor): string {
    const colors = {
      [ZoneColor.PLAVA]: '#a0c4e2',      
      [ZoneColor.ZELENA]: '#80e0a0',     
      [ZoneColor.ZUTA]: '#e0d080',       
      [ZoneColor.LJUBICASTA]: '#c480e0', 
      [ZoneColor.SIVA]: '#e0e0e0',       
      [ZoneColor.CRVENA]: '#e0a0a0',     
      [ZoneColor.ZLATNA]: '#e0c480',     
      [ZoneColor.BELA]: '#f0f0f0'        
    };
    return colors[colorId] || '#e0e0e0'; 
  }

  handleOnItemChange(event: any) {
    this.optionChanged.emit(event);
  }
}

export interface DropdownOptions {
  label: string;
  value: any;
}
