<div class="center-div padding-32">
    <atlas-white-logo></atlas-white-logo>
    <div class="key-logo">
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="5" width="56" height="56" rx="28" fill="#F3D2D2"/>
            <rect x="5" y="5" width="56" height="56" rx="28" stroke="#F9E8E8" stroke-width="10"/>
            <path d="M36.5 29.5H36.5117M36.5 36.5C40.366 36.5 43.5 33.366 43.5 29.5C43.5 25.634 40.366 22.5 36.5 22.5C32.634 22.5 29.5 25.634 29.5 29.5C29.5 29.8193 29.5214 30.1336 29.5628 30.4415C29.6309 30.948 29.6649 31.2013 29.642 31.3615C29.6181 31.5284 29.5877 31.6184 29.5055 31.7655C29.4265 31.9068 29.2873 32.046 29.009 32.3243L23.0467 38.2866C22.845 38.4884 22.7441 38.5893 22.6719 38.707C22.608 38.8114 22.5608 38.9252 22.5322 39.0442C22.5 39.1785 22.5 39.3212 22.5 39.6065V41.6333C22.5 42.2867 22.5 42.6134 22.6272 42.863C22.739 43.0825 22.9175 43.261 23.137 43.3728C23.3866 43.5 23.7133 43.5 24.3667 43.5H26.3935C26.6788 43.5 26.8215 43.5 26.9558 43.4678C27.0748 43.4392 27.1886 43.392 27.293 43.3281C27.4107 43.2559 27.5116 43.155 27.7134 42.9533L33.6757 36.991C33.954 36.7127 34.0932 36.5735 34.2345 36.4945C34.3816 36.4123 34.4716 36.3819 34.6385 36.358C34.7987 36.3351 35.052 36.3691 35.5585 36.4372C35.8664 36.4786 36.1807 36.5 36.5 36.5Z"
                  stroke="#C21A1A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
    <h1 class="text-center">Zaboravljena lozinka?</h1>
    <div class="flex justify-content-center align-items-center">
        <div class="col-6">
            <div class="text-gray-600 text-center">Bez brige! Ukucajte vaš imejl i poslaćemo Vam link za resetovanje lozinke
            </div>
            <div class="mt-7 flex flex-column gap-6">
                <atlas-input-text [formCtrl]="form.controls.email | formControl" ctrlLabel="Imejl" 
                                  ctrlPlaceholder="Upiši imejl"
                                  formCtrlId="imejl"></atlas-input-text>
            </div>
            <div class="flex flex-column mt-5 gap-3">
                <atlas-button (btnClicked)="handleResetPassword()" [btnStyle]="ButtonStyle.BASIC" btnClass="w-full"
                              btnLabel="Resetuj lozinku"></atlas-button>
            </div>
            <div class="mt-4 flex justify-content-center align-items-center">
                <i class="pi pi-arrow-left" (click)="handleBackToLogin()"></i>
                <atlas-button (btnClicked)="handleBackToLogin()" btnClass="p-0"
                              btnLabel="Nazad na logovanje"></atlas-button>
            </div>
        </div>
    </div>
</div>
<div class="text-base mt-2 text-gray-600 text-center footer">&copy; Atlas24h</div>
