import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ButtonComponent } from '../../../ui/button/button.component';
import { FormControlPipe } from '../../../shared/pipes/form-control.pipe';
import { InputPasswordComponent } from '../../../ui/input-password/input-password.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { WhiteLogoComponent } from '../../../ui/white-logo/white-logo.component';

@Component({
  selector: 'atlas-login-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    ButtonComponent,
    FormControlPipe,
    InputPasswordComponent,
    InputTextComponent,
    WhiteLogoComponent,
  ],
  templateUrl: './login-layout.component.html',
  styleUrl: './login-layout.component.scss',
})
export class LoginLayoutComponent {}
