import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssistanceVehicleComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/assistance-vehicle/assistance-vehicle.component';
import { DividerModule } from 'primeng/divider';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { FailureTypeDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/failure-type-dropdown/failure-type-dropdown.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { InputTextareaComponent } from '@ui/input-textarea/input-textarea.component';
import { LocationMapComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/location-map/location-map.component';
import { NumOfPassengersDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/num-of-passengers-dropdown/num-of-passengers-dropdown.component';
import { PackageForAssistanceComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/package-for-assistance/package-for-assistance.component';
import {
  GoogleMap,
  MapAdvancedMarker,
  MapDirectionsRenderer,
  MapMarker,
} from '@angular/google-maps';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { PartnerPreviewFormComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-2/partner-preview-form/partner-preview-form.component';
import { AssistanceDetailsComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-2/assistance-details/assistance-details.component';
import { MapLegendComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-2/map-legend/map-legend.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { AssistancesClient, PartnerType } from '@shared/model/atlas.api';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { Router } from '@angular/router';
import {
  activePartnerSvg,
  authPartnerSvg,
  boschPartenerSvg,
} from '@app/sos-agent/assistances/assistance-form/assistance-form-step-2/map-legend/svg-files';
import {
  ContactPhoneListComponent
} from "@app/sos-agent/assistances/assistance-form/assistance-form-step-1/contact-phone-list/contact-phone-list.component";

@Component({
  selector: 'atlas-assistance-form-step-2',
  standalone: true,
  imports: [
    AssistanceVehicleComponent,
    ClientForAssistanceAutocompleteComponent,
    ContactPhoneListComponent,
    DividerModule,
    DropdownComponent,
    FailureTypeDropdownComponent,
    FormControlPipe,
    InlineWrapperComponent,
    InputTextComponent,
    InputTextareaComponent,
    LocationMapComponent,
    NumOfPassengersDropdownComponent,
    PackageForAssistanceComponent,
    GoogleMap,
    ButtonComponent,
    MapMarker,
    MapDirectionsRenderer,
    AsyncPipe,
    MapAdvancedMarker,
    OverlayPanelModule,
    PartnerPreviewFormComponent,
    AssistanceDetailsComponent,
    JsonPipe,
    MapLegendComponent,
    NgIf,
  ],
  providers: [GoogleMap],
  templateUrl: './assistance-form-step-2.component.html',
  styleUrl: './assistance-form-step-2.component.scss',
})
export class AssistanceFormStep2Component implements OnInit, OnDestroy {
  @ViewChild('gmap') mapElement: GoogleMap;
  @ViewChild('op') op: OverlayPanel;
  private _subs: Subscription;
  previewPartnerIdx: number;
  mapOptions: google.maps.MapOptions;
  directionOptions: google.maps.DirectionsRendererOptions = {
    polylineOptions: {
      strokeColor: '#C21A1A',
      strokeWeight: 4,
    },
    markerOptions: {},
  };

  constructor(
    private _client: AssistancesClient,
    public formService: AssistanceFormService,
    public service: AssistanceService,
    private router: Router,
  ) {
    this._subs = new Subscription();
    this.previewPartnerIdx = -1;
    this.service.currentStep = 1;
    this.mapOptions = {
      mapId: 'DEMO_MAP_ID',
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: true,
      center: {
        // Belgrade
        lat: 44.80401,
        lng: 20.46513,
      },
      zoom: 7,
      styles: [
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    };
  }

  ngOnInit() {
    this._load();
    this._subs.add(
      this.formService.getForm.controls.partnerVehicleId.valueChanges.subscribe(
        (partner) => {
          console.log(partner);
          this.op.hide();
        },
      ),
    );
  }

  private _load() {
    this._getPartners().then((res) => {
      this.service.setPartners = res.result.partnerVehicles;
      this.service.setDirection();
      this._drawMarkers();
      if (this.service.isUpdate) {
        const selectedPartner = this.service.getPartners.find(
          (x) => x.id == this.formService.getForm.value.partnerVehicleId,
        );
        if (selectedPartner) {
          this.service.setSelectedPartner = selectedPartner;
        }
      }
    });
  }

  private async _getPartners() {
    return await firstValueFrom(this._client.getPartnersForAssistance());
  }

  private _drawMarkers() {
    //iterate latLng and add markers
    const parser = new DOMParser();
    const partners = this.service.getPartners;
    for (let i = 0; i < partners.length; ++i) {
      const pinSvg = parser.parseFromString(
        partners[i].type === PartnerType.REGULAR
          ? activePartnerSvg
          : partners[i].type === PartnerType.AUTH_SERVICE
            ? authPartnerSvg
            : boschPartenerSvg,
        'image/svg+xml',
      ).documentElement;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.mapElement.googleMap,
        position: new google.maps.LatLngAltitude({
          lat: partners.at(i).vehicleLocation.latitude,
          lng: partners.at(i).vehicleLocation.longitude,
          altitude: 9,
        }),
        title: partners.at(i).name,
        content: pinSvg,
      });
      marker.textContent = i.toString();

      marker.addListener('click', ({ domEvent, latLng }) => {
        //const { target } = domEvent;
        this.previewPartnerIdx = Number(marker.textContent);
        this.op.show(domEvent);
      });
    }
  }

  protected readonly ButtonStyle = ButtonStyle;
  showLegend: boolean = true;

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
