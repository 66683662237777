import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { CheckIcon } from 'primeng/icons/check';
import { TimesIcon } from 'primeng/icons/times';
import * as i2 from 'primeng/autofocus';
import { AutoFocusModule } from 'primeng/autofocus';
function TriStateCheckbox_ng_container_5_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r4.checkboxTrueIcon);
    i0.ɵɵattribute("data-pc-section", "checkIcon");
  }
}
function TriStateCheckbox_ng_container_5_ng_container_2_CheckIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "CheckIcon", 11);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-checkbox-icon");
    i0.ɵɵattribute("data-pc-section", "checkIcon");
  }
}
function TriStateCheckbox_ng_container_5_ng_container_2_span_2_1_ng_template_0_Template(rf, ctx) {}
function TriStateCheckbox_ng_container_5_ng_container_2_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TriStateCheckbox_ng_container_5_ng_container_2_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TriStateCheckbox_ng_container_5_ng_container_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtemplate(1, TriStateCheckbox_ng_container_5_ng_container_2_span_2_1_Template, 1, 0, null, 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(3);
    i0.ɵɵattribute("data-pc-section", "checkIcon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r7.checkIconTemplate);
  }
}
function TriStateCheckbox_ng_container_5_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TriStateCheckbox_ng_container_5_ng_container_2_CheckIcon_1_Template, 1, 2, "CheckIcon", 9)(2, TriStateCheckbox_ng_container_5_ng_container_2_span_2_Template, 2, 2, "span", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r5.checkIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r5.checkIconTemplate);
  }
}
function TriStateCheckbox_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TriStateCheckbox_ng_container_5_span_1_Template, 1, 2, "span", 7)(2, TriStateCheckbox_ng_container_5_ng_container_2_Template, 3, 2, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.checkboxTrueIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.checkboxTrueIcon);
  }
}
function TriStateCheckbox_ng_container_6_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r10.checkboxFalseIcon);
    i0.ɵɵattribute("data-pc-section", "uncheckIcon");
  }
}
function TriStateCheckbox_ng_container_6_ng_container_2_TimesIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesIcon", 11);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-checkbox-icon");
    i0.ɵɵattribute("data-pc-section", "uncheckIcon");
  }
}
function TriStateCheckbox_ng_container_6_ng_container_2_span_2_1_ng_template_0_Template(rf, ctx) {}
function TriStateCheckbox_ng_container_6_ng_container_2_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TriStateCheckbox_ng_container_6_ng_container_2_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TriStateCheckbox_ng_container_6_ng_container_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtemplate(1, TriStateCheckbox_ng_container_6_ng_container_2_span_2_1_Template, 1, 0, null, 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r13 = i0.ɵɵnextContext(3);
    i0.ɵɵattribute("data-pc-section", "uncheckIcon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r13.uncheckIconTemplate);
  }
}
function TriStateCheckbox_ng_container_6_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TriStateCheckbox_ng_container_6_ng_container_2_TimesIcon_1_Template, 1, 2, "TimesIcon", 9)(2, TriStateCheckbox_ng_container_6_ng_container_2_span_2_Template, 2, 2, "span", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r11.uncheckIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r11.uncheckIconTemplate);
  }
}
function TriStateCheckbox_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TriStateCheckbox_ng_container_6_span_1_Template, 1, 2, "span", 7)(2, TriStateCheckbox_ng_container_6_ng_container_2_Template, 3, 2, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.checkboxFalseIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.checkboxFalseIcon);
  }
}
const _c0 = (a0, a1, a2) => ({
  "p-checkbox-label-active": a0,
  "p-disabled": a1,
  "p-checkbox-label-focus": a2
});
function TriStateCheckbox_label_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "label", 14);
    i0.ɵɵlistener("click", function TriStateCheckbox_label_7_Template_label_click_0_listener($event) {
      i0.ɵɵrestoreView(_r17);
      const ctx_r16 = i0.ɵɵnextContext();
      const _r0 = i0.ɵɵreference(3);
      return i0.ɵɵresetView(ctx_r16.onClick($event, _r0));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(3, _c0, ctx_r3.value != null, ctx_r3.disabled, ctx_r3.focused));
    i0.ɵɵattribute("for", ctx_r3.inputId);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r3.label);
  }
}
const _c1 = (a1, a2) => ({
  "p-checkbox p-component": true,
  "p-checkbox-disabled": a1,
  "p-checkbox-focused": a2
});
const _c2 = (a0, a1, a2) => ({
  "p-highlight": a0,
  "p-disabled": a1,
  "p-focus": a2
});
const TRISTATECHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TriStateCheckbox),
  multi: true
};
/**
 * TriStateCheckbox is used to select either 'true', 'false' or 'null' as the value.
 * @group Components
 */
class TriStateCheckbox {
  cd;
  constructor(cd) {
    this.cd = cd;
  }
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  disabled;
  /**
   * Name of the component.
   * @group Props
   */
  name;
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  ariaLabel;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex;
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  inputId;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Label of the checkbox.
   * @group Props
   */
  label;
  /**
   * When present, it specifies that the component cannot be edited.
   * @group Props
   */
  readonly;
  /**
   * Specifies the icon for checkbox true value.
   * @group Props
   */
  checkboxTrueIcon;
  /**
   * Specifies the icon for checkbox false value.
   * @group Props
   */
  checkboxFalseIcon;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus;
  /**
   * Callback to invoke on value change.
   * @param {TriStateCheckboxChangeEvent} event - Custom change event.
   * @group Emits
   */
  onChange = new EventEmitter();
  templates;
  checkIconTemplate;
  uncheckIconTemplate;
  focused;
  value;
  onModelChange = () => {};
  onModelTouched = () => {};
  onClick(event, input) {
    if (!this.disabled && !this.readonly) {
      this.toggle(event);
      this.focused = true;
      input.focus();
    }
  }
  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.toggle(event);
      event.preventDefault();
    }
  }
  toggle(event) {
    if (this.value == null || this.value == undefined) this.value = true;else if (this.value == true) this.value = false;else if (this.value == false) this.value = null;
    this.onModelChange(this.value);
    this.onChange.emit({
      originalEvent: event,
      value: this.value
    });
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'checkicon':
          this.checkIconTemplate = item.template;
          break;
        case 'uncheckicon':
          this.uncheckIconTemplate = item.template;
          break;
      }
    });
  }
  onFocus() {
    this.focused = true;
  }
  onBlur() {
    this.focused = false;
    this.onModelTouched();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  writeValue(value) {
    this.value = value;
    this.cd.markForCheck();
  }
  setDisabledState(disabled) {
    this.disabled = disabled;
    this.cd.markForCheck();
  }
  static ɵfac = function TriStateCheckbox_Factory(t) {
    return new (t || TriStateCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: TriStateCheckbox,
    selectors: [["p-triStateCheckbox"]],
    contentQueries: function TriStateCheckbox_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      disabled: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "disabled", "disabled", booleanAttribute],
      name: "name",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy",
      tabindex: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "tabindex", "tabindex", numberAttribute],
      inputId: "inputId",
      style: "style",
      styleClass: "styleClass",
      label: "label",
      readonly: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "readonly", "readonly", booleanAttribute],
      checkboxTrueIcon: "checkboxTrueIcon",
      checkboxFalseIcon: "checkboxFalseIcon",
      autofocus: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "autofocus", "autofocus", booleanAttribute]
    },
    outputs: {
      onChange: "onChange"
    },
    features: [i0.ɵɵProvidersFeature([TRISTATECHECKBOX_VALUE_ACCESSOR]), i0.ɵɵInputTransformsFeature],
    decls: 8,
    vars: 27,
    consts: [[3, "ngStyle", "ngClass", "click"], [1, "p-hidden-accessible"], ["type", "checkbox", "inputmode", "none", "pAutoFocus", "", 3, "name", "readonly", "disabled", "autofocus", "keydown", "focus", "blur"], ["input", ""], ["role", "checkbox", 1, "p-checkbox-box", 3, "ngClass"], [4, "ngIf"], ["class", "p-checkbox-label", 3, "ngClass", "click", 4, "ngIf"], ["class", "p-checkbox-icon", 3, "ngClass", 4, "ngIf"], [1, "p-checkbox-icon", 3, "ngClass"], [3, "styleClass", 4, "ngIf"], ["class", "p-checkbox-icon", 4, "ngIf"], [3, "styleClass"], [1, "p-checkbox-icon"], [4, "ngTemplateOutlet"], [1, "p-checkbox-label", 3, "ngClass", "click"]],
    template: function TriStateCheckbox_Template(rf, ctx) {
      if (rf & 1) {
        const _r18 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function TriStateCheckbox_Template_div_click_0_listener($event) {
          i0.ɵɵrestoreView(_r18);
          const _r0 = i0.ɵɵreference(3);
          return i0.ɵɵresetView(ctx.onClick($event, _r0));
        });
        i0.ɵɵelementStart(1, "div", 1)(2, "input", 2, 3);
        i0.ɵɵlistener("keydown", function TriStateCheckbox_Template_input_keydown_2_listener($event) {
          return ctx.onKeyDown($event);
        })("focus", function TriStateCheckbox_Template_input_focus_2_listener() {
          return ctx.onFocus();
        })("blur", function TriStateCheckbox_Template_input_blur_2_listener() {
          return ctx.onBlur();
        });
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵtemplate(5, TriStateCheckbox_ng_container_5_Template, 3, 2, "ng-container", 5)(6, TriStateCheckbox_ng_container_6_Template, 3, 2, "ng-container", 5);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(7, TriStateCheckbox_label_7_Template, 2, 7, "label", 6);
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngStyle", ctx.style)("ngClass", i0.ɵɵpureFunction2(20, _c1, ctx.disabled, ctx.focused));
        i0.ɵɵattribute("data-pc-name", "tristatecheckbox")("data-pc-section", "root");
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("name", ctx.name)("readonly", ctx.readonly)("disabled", ctx.disabled)("autofocus", ctx.autofocus);
        i0.ɵɵattribute("id", ctx.inputId)("tabindex", ctx.tabindex)("aria-labelledby", ctx.ariaLabelledBy)("aria-label", ctx.ariaLabel)("data-pc-section", "hiddenInput");
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(23, _c2, ctx.value != null, ctx.disabled, ctx.focused));
        i0.ɵɵattribute("aria-checked", ctx.value === true);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.value === true);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.value === false);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.label);
      }
    },
    dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.AutoFocus, CheckIcon, TimesIcon],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TriStateCheckbox, [{
    type: Component,
    args: [{
      selector: 'p-triStateCheckbox',
      template: `
        <div
            [ngStyle]="style"
            [ngClass]="{ 'p-checkbox p-component': true, 'p-checkbox-disabled': disabled, 'p-checkbox-focused': focused }"
            [class]="styleClass"
            (click)="onClick($event, input)"
            [attr.data-pc-name]="'tristatecheckbox'"
            [attr.data-pc-section]="'root'"
        >
            <div class="p-hidden-accessible">
                <input
                    #input
                    [attr.id]="inputId"
                    type="checkbox"
                    [name]="name"
                    [attr.tabindex]="tabindex"
                    [readonly]="readonly"
                    [disabled]="disabled"
                    (keydown)="onKeyDown($event)"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    [attr.aria-labelledby]="ariaLabelledBy"
                    [attr.aria-label]="ariaLabel"
                    inputmode="none"
                    [attr.data-pc-section]="'hiddenInput'"
                    pAutoFocus
                    [autofocus]="autofocus"
                />
            </div>
            <div class="p-checkbox-box" role="checkbox" [attr.aria-checked]="value === true" [ngClass]="{ 'p-highlight': value != null, 'p-disabled': disabled, 'p-focus': focused }">
                <ng-container *ngIf="value === true">
                    <span *ngIf="checkboxTrueIcon" [ngClass]="checkboxTrueIcon" class="p-checkbox-icon" [attr.data-pc-section]="'checkIcon'"></span>
                    <ng-container *ngIf="!checkboxTrueIcon">
                        <CheckIcon [styleClass]="'p-checkbox-icon'" *ngIf="!checkIconTemplate" [attr.data-pc-section]="'checkIcon'" />
                        <span *ngIf="checkIconTemplate" class="p-checkbox-icon" [attr.data-pc-section]="'checkIcon'">
                            <ng-template *ngTemplateOutlet="checkIconTemplate"></ng-template>
                        </span>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="value === false">
                    <span *ngIf="checkboxFalseIcon" [ngClass]="checkboxFalseIcon" class="p-checkbox-icon" [attr.data-pc-section]="'uncheckIcon'"></span>
                    <ng-container *ngIf="!checkboxFalseIcon">
                        <TimesIcon [styleClass]="'p-checkbox-icon'" *ngIf="!uncheckIconTemplate" [attr.data-pc-section]="'uncheckIcon'" />
                        <span class="p-checkbox-icon" *ngIf="uncheckIconTemplate" [attr.data-pc-section]="'uncheckIcon'">
                            <ng-template *ngTemplateOutlet="uncheckIconTemplate"></ng-template>
                        </span>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <label class="p-checkbox-label" (click)="onClick($event, input)" [ngClass]="{ 'p-checkbox-label-active': value != null, 'p-disabled': disabled, 'p-checkbox-label-focus': focused }" *ngIf="label" [attr.for]="inputId">{{ label }}</label>
    `,
      providers: [TRISTATECHECKBOX_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      }
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    name: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    tabindex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    inputId: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    readonly: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    checkboxTrueIcon: [{
      type: Input
    }],
    checkboxFalseIcon: [{
      type: Input
    }],
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onChange: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class TriStateCheckboxModule {
  static ɵfac = function TriStateCheckboxModule_Factory(t) {
    return new (t || TriStateCheckboxModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TriStateCheckboxModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule, AutoFocusModule, CheckIcon, TimesIcon, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TriStateCheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedModule, AutoFocusModule, CheckIcon, TimesIcon],
      exports: [TriStateCheckbox, SharedModule],
      declarations: [TriStateCheckbox]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TRISTATECHECKBOX_VALUE_ACCESSOR, TriStateCheckbox, TriStateCheckboxModule };
