import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {ccAgentRoutes} from "@app/cc-agent/cc-agent.routes";

export const AdminRoleStr: string = 'Admin';
export const GuestRoleStr: string = 'Guest';
export const AgentSosRoleStr: string = 'AgentSos';
export const AgentCcRoleStr: string = 'AgentCc';
export const FinanceAdminRoleStr: string = 'FinanceAdmin';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _authService: AuthService) {}

  get getUserData(): {
    id: string;
    email: string;
    fullName: string;
    imageUrl: string;
    roles: string | string[];
    ipAddress: string;
  } {
    const decodedJWT = this._authService.getDecodedToken;
    return {
      id: decodedJWT?.[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
      ],
      email:
        decodedJWT?.[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
        ],
      fullName: decodedJWT?.fullName,
      imageUrl:
        decodedJWT?.image_url?.length > 0 ? decodedJWT?.image_url : null,
      ipAddress: decodedJWT?.ipAddress,
      roles:
        decodedJWT?.[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ],
    };
  }

  get getRole(): string {
    if (this.hasMultipleRoles) {
      return (this.getUserData.roles as string[]).join(', ');
    }
    return this.getUserData.roles as string;
  }

  isAdmin(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(AdminRoleStr);
    }
    return this.getUserData.roles === AdminRoleStr;
  }

  isSosAgent(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(AgentSosRoleStr);
    }
    return this.getUserData.roles === AgentSosRoleStr;
  }

  isFinanceAdmin(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(FinanceAdminRoleStr);
    }
    return this.getUserData.roles === FinanceAdminRoleStr;
  }

  isCcAgent(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(AgentCcRoleStr);
    }
    return this.getUserData.roles === AgentCcRoleStr;
  }

  isGuest(): boolean {
    if (this.hasMultipleRoles) {
      return this.getUserData.roles.includes(GuestRoleStr);
    }
    return this.getUserData.roles === GuestRoleStr;
  }

  get hasMultipleRoles() {
    return this.getUserData.roles instanceof Array;
  }
}
