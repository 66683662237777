import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActionMode,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@ui/table/table-common';
import { firstValueFrom, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { LazyLoadEvent, SelectItem, SharedModule } from 'primeng/api';
import {
  GetOrderTableQuery,
  IOrderTableResponse,
  IPaginationResponseOfOrderTableResponse,
  OrdersClient,
  PaginationResponseOfOrderTableResponse,
  PaymentStatus,
} from '@shared/model/atlas.api';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { AlertService } from '@shared/services/alert.service';
import { DialogService } from '@ui/dialog/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { TableComponent } from '@ui/table/table.component';
import {
  ChangePaymentStatusFormComponent,
  PaymentStatusForm,
} from '@app/finance-administrator/order-purchase-table/change-payment-status-form/change-payment-status-form.component';

@Component({
  selector: 'atlas-order-purchase-table',
  standalone: true,
  imports: [
    BadgeModule,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    PageComponent,
    SharedModule,
    TabViewModule,
    TableComponent,
  ],
  templateUrl: './order-purchase-table.component.html',
  styleUrl: './order-purchase-table.component.scss',
})
export class OrderPurchaseTableComponent
  implements OnInit, OnDestroy, OnTableInit
{
  private _subs: Subscription;
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  paymentStatusSearch: PaymentStatus;
  totalNonPaid: number | undefined;
  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfOrderTableResponse;
  activeIndex: number | undefined;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_TINY);

  constructor(
    private client: OrdersClient,
    private _dialogService: DialogService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.totalNonPaid = 0;
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.paymentStatusSearch = PaymentStatus.EMPTY;
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.LICENCE_PLATE,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
    ];
  }

  ngOnInit() {
    this.countNonPaid();

    this.alertService.requestCountChanged$.subscribe(() => {
      this.countNonPaid();
    });

    this.setTableConfig().then();

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab']?.toLowerCase();
      const tabLabels = ['sve', 'neplacene'];
      if (tab && tabLabels.includes(tab)) {
        this.activeIndex = tabLabels.indexOf(tab);
      } else {
        this.activeIndex = 0;
        this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: { tab: 'sve' },
            queryParamsHandling: 'merge',
          })
          .then();
      }
    });
  }

  countNonPaid() {
    this._subs.add(
      this.client.getAllUnpaid().subscribe((res) => {
        this.totalNonPaid = res.result;
      }),
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 0;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Broj',
          field: 'number',
          type: 'text',
        },
        {
          header: 'Klijent',
          field: 'client',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'clientPhone',
              type: 'text',
            },
          ],
        },
        {
          header: 'Adresa',
          field: 'clientAddress',
          type: 'text',
        },
        {
          header: 'Status',
          field: 'statusStr',
          type: 'text',
        },
        {
          header: 'Status uplate',
          field: 'paymentStatusStr',
          type: 'text',
        },
        {
          header: 'Paketi',
          field: 'packages',
          type: 'text',
        },
        {
          header: 'Iznos',
          field: 'price',
          type: 'currency',
        },
        {
          header: 'Datum',
          field: 'createdOn',
          type: 'text',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Promeni status uplate',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IOrderTableResponse = this._getPaginatedItem(rowIdx);
            return rowData.paymentStatus !== PaymentStatus.WC_COMPLETED;
          },
          icon: 'pi pi-sync',
          callback: (rowIdx: number) => {
            const rowData: IOrderTableResponse = this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Promena statusa uplate';
            this.dialogConfig.headerDescription =
              'Odaberite novi status uplate';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi',
              icon: 'pi pi-check',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              orderId: rowData.id,
              paymentStatus: rowData.paymentStatus,
              paymentStatusStr: rowData.paymentStatusStr,
            };
            this.dialogConfig.closable = true;

            this.openDialog(rowIdx);
          },
        },
      ],
    });
  }

  openDialog(rowIdx: number): void {
    const ref = this._dialogService.open(
      ChangePaymentStatusFormComponent,
      this.dialogConfig,
    );
    this._subs.add(
      ref.onClose.subscribe((statusRes: PaymentStatusForm) => {
        if (statusRes) {
          this.alertService.notifyRequestCountChanged();
          this.load();
        }
      }),
    );
  }

  private _updateItemStatus(rowIdx: number, statusRes: PaymentStatusForm) {
    const pagData = this.paginatedData.data;
    pagData.at(rowIdx).paymentStatus = statusRes.status;
    pagData.at(rowIdx).paymentStatusStr = statusRes.label;

    console.log('pagData', pagData[rowIdx]);

    this.paginatedData = new PaginationResponseOfOrderTableResponse({
      data: pagData,
      ...this.paginatedData,
    });
  }

  handleTabChange(idx: any) {
    let selectedTab: string;

    switch (idx) {
      case 1:
        this.paymentStatusSearch = PaymentStatus.WC_ON_HOLD;
        selectedTab = 'neplacene';
        break;
      default:
        this.paymentStatusSearch = PaymentStatus.EMPTY;
        selectedTab = 'sve';
    }

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { tab: selectedTab },
        queryParamsHandling: 'merge',
      })
      .then();

    this.load();
  }

  load() {
    this._getList().then((res) => {
      this.paginatedData = res.result;
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this.client.getTable(
        new GetOrderTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          paymentStatus: this.paymentStatusSearch,
          licencePlate:
            this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
              ? this.searchCtrl.value
              : undefined,
        }),
      ),
    );
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

enum SearchOptions {
  LICENCE_PLATE = 'Tablice',
}
