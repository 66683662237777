<div
  [ngClass]="
    (formService.getForm.controls.partnerVehicleId.invalid &&
      (formService.getForm.controls.partnerVehicleId.dirty ||
        formService.getForm.controls.partnerVehicleId.touched)) ||
    (formService.getForm.controls.driverId.invalid &&
      (formService.getForm.controls.driverId.dirty ||
        formService.getForm.controls.driverId.touched)) ||
    (formService.getForm.controls.driverPhone.invalid &&
      (formService.getForm.controls.driverPhone.dirty ||
        formService.getForm.controls.driverPhone.touched))
      ? 'flat-card assistance-details invalid-card'
      : 'flat-card assistance-details'
  "
>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="header">Detalji</div>
    <div class="content flex flex-column gap-4">
      <!-- ASSISTANCE -->
      <div>
        <div class="label">Korisnik</div>
        <div class="card flex justify-content-center mt-3">
          <p-accordion class="w-full">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <span class="flex align-items-center gap-3 w-full">
                  <p-avatar icon="pi pi-user" shape="circle"></p-avatar>
                  <div class="flex flex-column gap-2">
                    <span class="main-text">{{ clientDetails.name }}</span>
                    <span class="client-phones">{{
                      clientDetails.phones
                    }}</span>
                  </div>
                </span>
              </ng-template>
              <div class="more-details">
                <div class="flex align-items-top gap-2 location-item">
                  <span class="dot">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8"
                        cy="8"
                        r="6"
                        fill="#C7C7C7"
                        stroke="#FAFAFA"
                        stroke-width="4"
                      />
                    </svg>
                  </span>
                  <div>
                    Lokacija kvara
                    <div class="details-place">{{ clientDetails.from }}</div>
                  </div>
                </div>
                <div
                  class="flex align-items-top gap-2 location-item no-dot-border"
                >
                  <span class="dot">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8"
                        cy="8"
                        r="6"
                        fill="#C7C7C7"
                        stroke="#FAFAFA"
                        stroke-width="4"
                      />
                    </svg>
                  </span>
                  <div>
                    Odredište
                    <div class="details-place">{{ clientDetails.to }}</div>
                  </div>
                </div>
                <p-divider></p-divider>
                <div
                  class="flex flex-column md:flex-row justify-content-between gap-3"
                >
                  <div class="flex flex-column max-w-10rem gap-2">
                    <div>Vozilo</div>
                    <span
                      class="main-text text-overflow-ellipsis white-space-nowrap p-overflow-hidden"
                      >{{ clientDetails.vehicle }}</span
                    >
                  </div>
                  <div class="flex flex-column max-w-10rem gap-2">
                    <div>Paket</div>
                    <span
                      class="main-text text-overflow-ellipsis p-overflow-hidden white-space-nowrap"
                      >{{ clientDetails.clientPackageId }}</span
                    >
                  </div>
                  <div class="flex flex-column max-w-10rem gap-2">
                    <div>Broj putnika</div>
                    <span
                      class="main-text text-overflow-ellipsis p-overflow-hidden white-space-nowrap text-right"
                      >{{ clientDetails.numOfPassengers }}</span
                    >
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>

      <!-- PARTNER -->
      <div class="flex flex-column gap-2">
        <div>
          <div class="label mb-3">Izabrani saradnik</div>
          @if (!service.selectedPartner) {
            <div
              class="flex flex-column gap-2 justify-content-center align-items-center"
            >
              <p-avatar
                icon="pi pi-truck"
                styleClass="mr-2 empty-partner"
                size="large"
              ></p-avatar>
              <div class="main-text">Bez izabranog saradnika</div>
              <div class="partner-text text-center">
                Klikom saradnika na mapi možete videti detalje i izabrati
                saradnika
              </div>
            </div>
          } @else {
            <p-panel>
              <ng-template pTemplate="header">
                <div class="flex flex-column gap-2">
                  <div>{{ service.selectedPartner.partnerTypeStr }}</div>
                  <div class="label">{{ service.selectedPartner.name }}</div>
                  <div class="flex align-items-center gap-2">
                    <p-avatar
                      icon="pi pi-truck"
                      shape="circle"
                    ></p-avatar>
                    <div class="flex flex-column justify-content-between">
                      <div>{{ service.selectedDriver }}</div>
                      <div>{{ formService.getForm.value.driverPhone }}</div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="icons">
                <button class="p-link mr-2" (click)="handleCancelPartner()">
                  <span class="pi pi-times"></span>
                </button>
              </ng-template>
            </p-panel>
          }
        </div>
      </div>
      <small
        *ngIf="
          (formService.getForm.controls.partnerVehicleId.invalid &&
            (formService.getForm.controls.partnerVehicleId.dirty ||
              formService.getForm.controls.partnerVehicleId.touched)) ||
          (formService.getForm.controls.driverId.invalid &&
            (formService.getForm.controls.driverId.dirty ||
              formService.getForm.controls.driverId.touched)) ||
          (formService.getForm.controls.driverPhone.invalid &&
            (formService.getForm.controls.driverPhone.dirty ||
              formService.getForm.controls.driverPhone.touched))
        "
        class="error-info text-red-500 align-self-center"
        >Morate odabrati partnera za asistenciju</small
      >
    </div>
  </p-scrollPanel>
</div>
