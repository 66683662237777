import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { ClientAutocompleteComponent } from '../../client-autocomplete/client-autocomplete/client-autocomplete.component';
import { AssignPackagesToSellerCommand, ClientClient, OrderClient, OrdersClient } from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';

@Component({
  selector: 'atlas-change-seller-dialog',
  standalone: true,
  imports: [ClientAutocompleteComponent],
  templateUrl: './change-seller-dialog.component.html',
  styleUrl: './change-seller-dialog.component.scss'
})
export class ChangeSellerDialogComponent implements OnInit, OnDestroy {
  client: any[];
  agentName: string;
  selectedAgentId: string;
  packageIds: string[] = [];
  agentImageUrl: string;
  private _subs: Subscription = new Subscription();

  constructor(private clientService: ClientPackageTableService,
    private _client: OrdersClient,
    private _alertService: AlertService,
    private _dialogRef: DialogRef

  ) { }



  ngOnInit(): void {
    this._loadSelectedClients();
    this._setAgentInfo();
    this._listenForSubmit();
  }

  private _loadSelectedClients(): void {
    const clients = this.clientService.getSelectedClients();

    clients.forEach(client => {
      const selectedPackages = client.packages.filter(pkg => pkg.selected === true);

      selectedPackages.forEach(pkg => {
        this.packageIds.push(pkg.clientPackageId);
      });
    });
  }

  private _setAgentInfo(): void {
    const clients = this.clientService.getSelectedClients();
    const lastClient = clients[clients.length - 1];

    this.agentName = lastClient.agent || 'Unknown Agent';
    this.agentImageUrl = lastClient.agentImage
      ? lastClient.agentImage
      : '../../../../assets/images/client-icon.svg';
  }

  private _listenForSubmit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  onAgentSelected(agentId: string): void {
    this.selectedAgentId = agentId;
  }

  public _handleSave() {
    let isSellerChanged = false;
    this._subs.add(
      this._client.assignPackagesToSeller(
        new AssignPackagesToSellerCommand
          ({
            clientPackageIds: this.packageIds,
            toAgentId: this.selectedAgentId
          })
      )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          isSellerChanged = true;
          this.exit(isSellerChanged);
        }),
    );
  }

  exit(isSellerChanged: boolean) {
    this._dialogRef.close(isSellerChanged);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
