import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentStatusDropdownComponent } from '@app/features/dropdowns/payment-status-dropdown/payment-status-dropdown.component';
import { FormControl, Validators } from '@angular/forms';
import {
  ChangePaymentStatusCommand, GetOrderTableQuery,
  OrdersClient, PackagesClient,
  PaymentStatus,
} from '@shared/model/atlas.api';
import { EmployeeRoleDropdownComponent } from '@app/administrator/employees/employee-role-dropdown/employee-role-dropdown.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { AlertService } from '@shared/services/alert.service';
import {firstValueFrom, Subscription} from 'rxjs';
import { JsonPipe } from '@angular/common';
import {CalendarComponent} from "@ui/calendar/calendar.component";

@Component({
  selector: 'atlas-change-payment-status-form',
  standalone: true,
    imports: [
        PaymentStatusDropdownComponent,
        EmployeeRoleDropdownComponent,
        FormControlPipe,
        JsonPipe,
        CalendarComponent,
    ],
  templateUrl: './change-payment-status-form.component.html',
  styleUrl: './change-payment-status-form.component.scss',
})
export class ChangePaymentStatusFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  statusCtrl: FormControl<PaymentStatusForm>;
  activationDateCtrl: FormControl<Date>;

  constructor(
    private _dailogConfig: DialogConfig,
    private _dialogRef: DialogRef,
    private _orderClient: OrdersClient,
    private _packagesClient: PackagesClient,
    private _alertService: AlertService,
  ) {
    this.statusCtrl = new FormControl({
      status: this._dailogConfig.data.paymentStatus,
      label: this._dailogConfig.data.paymentStatusStr,
    }, {
      validators: [Validators.min(1)],
    });
    this.activationDateCtrl = new FormControl<Date>(new Date(), {
      validators: [Validators.required],
    });
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );

    this._getActivationDate().then(res => {
      this.activationDateCtrl.patchValue(res.result);
    })
  }

  handleSubmit() {
    if (this.statusCtrl.invalid) {
      this.statusCtrl.markAsTouched();
      return;
    }
    if (this.activationDateCtrl.invalid) {
      this.activationDateCtrl.markAsTouched();
      return;
    }
    this._subs.add(
      this._orderClient
        .changePaymentStatus(
          new ChangePaymentStatusCommand({
            paymentStatus: this.statusCtrl.value.status,
            orderId: this._dailogConfig.data.orderId,
            activationDate: this.activationDateCtrl.value
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close(this.statusCtrl.value);
        }),
    );
  }

  private async _getActivationDate() {
    return await firstValueFrom(
        this._packagesClient.getActivationDate(this._dailogConfig.data.orderId),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

export interface PaymentStatusForm {
  label: string;
  status: PaymentStatus;
}
