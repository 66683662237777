import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '@shared/components/loader/loader.service';
import {
  DriverSidebarInfoResponse,
  DriversClient,
  InviteExistingDriverCommand,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { DividerModule } from 'primeng/divider';
import { Subscription, finalize } from 'rxjs';

@Component({
  selector: 'atlas-partner-driver-info',
  standalone: true,
  imports: [ButtonComponent, DividerModule, NgIf],
  templateUrl: './partner-driver-info.component.html',
  styleUrl: './partner-driver-info.component.scss',
})
export class PartnerDriverInfoComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  driverId: string;
  driverInfo: DriverSidebarInfoResponse;

  constructor(
    private _dialogConfig: DialogConfig,
    private _driverClient: DriversClient,
    private _dialogRef: DialogRef,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.driverId = this._dialogConfig.data.driverId;
    this.loadDriverInfo();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleInviteDriver();
      }),
    );
  }

  loadDriverInfo() {
    this._subs.add(
      this._driverClient.getDriverSidebar(this.driverId).subscribe((res) => {
        this.driverInfo = res.result.value;
      }),
    );
  }

  handleInviteDriver() {
    this._loaderService.setLoadingText = 'Zahtev se šalje vozaču';
    this._loaderService.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';

    this._subs.add(
      this._driverClient
        .inviteExistingDriver(
          new InviteExistingDriverCommand({
            email: this.driverInfo.email,
          }),
        )
        .pipe(
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe((res) => {
          this._dialogRef.close();
          this._loaderService.reset();
        }),
    );
  }

  handleEditDriver() {
    alert('Test123');
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
