import { NgIf, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ErrorEmailComponent } from '@ui/errors/error-email/error-email.component';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { MaxLengthComponent } from '@ui/errors/max-length/max-length.component';
import { AutoFocusModule } from 'primeng/autofocus';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'atlas-input-mail',
  standalone: true,
  imports: [ErrorRequiredComponent,
    ErrorEmailComponent,
    InputTextModule,
    NgIf,
    NgClass,
    ReactiveFormsModule,
    AutoFocusModule,
    MaxLengthComponent,
    TooltipModule,],
  templateUrl: './input-mail.component.html',
  styleUrl: './input-mail.component.scss'
})
export class InputMailComponent {

    /** I/O */
    @Input() formCtrl!: FormControl;
    @Input() ctrlPlaceholder: string = '';
    @Input() ctrlLabel: string = '';
    @Input() formCtrlId: string = 'text-input';
    @Input() formCtrlType: string = 'email';
    @Input() formCtrlClass: string = '';
    @Input() formCtrlIcon?: string;
    @Input() tooltip?: string;
    @Input() formCtrlIconRight?: string;
    @Input() isDisabled: boolean;
    @Input() showLabel: boolean = true;
    @Input() shouldFocus: boolean = false;
    @Output() keyReleased = new EventEmitter<boolean>();
    @Output() inputLeft: EventEmitter<string | number> | null = new EventEmitter<
      string | number
    >();
    @Output() onEnterClicked = new EventEmitter<string>();
    @Input() hideErrors: boolean = false;

    constructor() {
      var test = this.isDisabled;
      this.isDisabled = false;
    }

    handleKeyUp(value: any) {
      this.keyReleased.emit(value);
      if (value.keyCode === 13) {
        this.onEnterClicked.emit();
      }
    }
  
    handleBlur(event: any) {
      if (this.inputLeft) {
        this.inputLeft.emit(event.target.value);
      }
    }

}
