import { Component, Input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import { FormControl } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'atlas-menu-item',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgClass,
    NgOptimizedImage,
    RouterLinkActive,
    RippleModule,
  ],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss',
})
export class MenuItemComponent {
  @Input() itemLabel!: string | null;
  @Input() itemIcon!: string | null;
  @Input() itemRouterLink!: string;
  @Input() selectedItem!: FormControl;

  constructor(private _router: Router) {
    this.itemLabel = null;
    this.itemRouterLink = '/';
    this.itemIcon = null;
  }

  isItemSelected(): boolean {
    return this._router.isActive(this.itemRouterLink, false);
  }

  protected readonly onmouseover = onmouseover;
  isHover: boolean = false;
}
