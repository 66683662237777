<div class="form-stepper">
    <p-steps #steps [model]="items" [readonly]="true"> </p-steps>
    <atlas-button
      btnLabel="Odustani"
      (btnClicked)="handleCancel()"
      btnIcon="pi pi-times"
      [btnStyle]="ButtonStyle.TEXT"
    ></atlas-button>
  </div>

  <div class="form-wrapper flex flex-column w-full justify-content-between">
    <div class="form-content">
      <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 290px)' }">
        <ng-container *ngIf="!showLoader">
          <router-outlet></router-outlet>
        </ng-container>
      </p-scrollPanel>
    </div>
  
    <div
      [ngClass]="
        shouldDisplayBack
          ? 'form-footer flex justify-content-between'
          : 'form-footer flex justify-content-end'
      "
    >
      <atlas-button
        *ngIf="shouldDisplayBack"
        btnLabel="Nazad"
        btnIcon="pi pi-arrow-left"
        (btnClicked)="prevPage()"
        [btnStyle]="ButtonStyle.OUTLINED"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
      ></atlas-button>
      <div>
        <!--      <atlas-button-->
        <!--        btnLabel="Sačuvaj nacrt"-->
        <!--        btnIcon="pi pi-save"-->
        <!--        btnClass="mr-2"-->
        <!--        (btnClicked)="saveDraft()"-->
        <!--        [btnStyle]="ButtonStyle.OUTLINED"-->
        <!--        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"-->
        <!--      ></atlas-button>-->
        <atlas-button
        *ngIf="!shouldDisplayFinish"
          btnLabel="Nastavi"
          btnIcon="pi pi-arrow-right"
          btnIconPosition="right"
          (btnClicked)="nextPage()"
          [btnStyle]="ButtonStyle.BASIC"
          [btnSeverity]="ButtonSeverity.SECONDARY"
          [isBtnDisabled]="false"
        ></atlas-button>
        <atlas-button
        *ngIf="shouldDisplayFinish"
        btnLabel="Potvrdi kupovinu"
        btnIcon="pi pi-box"
        btnIconPosition="left"
        (btnClicked)="nextPage()"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SUCCESS"
        [isBtnDisabled]="false"
      ></atlas-button>
      </div>
    </div>
  </div>
