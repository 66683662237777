import { Routes } from '@angular/router';
import { EmployeeTableComponent } from '@app/administrator/employees/employee-table/employee-table.component';
import { PackageTableComponent } from '@app/administrator/packages/package-table/package-table.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AuthGuard } from '@shared/services/auth.guard';
import { PackageFormComponent } from '@app/administrator/packages/package-form/package-form.component';
import { ParkingTableComponent } from '@app/administrator/parkings/parking-table/parking-table.component';
import { PartnerFormComponent } from '@app/administrator/partners/partner-form/partner-form.component';
import { PartnerTableComponent } from '@app/administrator/partners/partner-table/partner-table.component';
import { PartnerInfoPageComponent } from '@app/administrator/partners/partner-info-page/partner-info-page.component';
import { PromoCodeFormComponent } from '@app/administrator/promo-codes/promo-code-form/promo-code-form.component';
import { PromoCodeTableComponent } from '@app/administrator/promo-codes/promo-code-table/promo-code-table.component';

export const administratorRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'employees',
        pathMatch: 'full',
      },
      {
        path: 'employees',
        data: {
          breadcrumb: 'Zaposleni',
        },
        component: EmployeeTableComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: 'partners',
        children: [
          {
            path: '',
            component: PartnerTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'add-partner',
            component: PartnerFormComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit-partner/:id',
            component: PartnerFormComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'partner-info/:id',
            component: PartnerInfoPageComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'packages',
        children: [
          {
            path: '',
            component: PackageTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create-package',
            component: PackageFormComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit-package/:id',
            component: PackageFormComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'promo-codes',
        children: [
          {
            path: '',
            component: PromoCodeTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create-promo-code',
            component: PromoCodeFormComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit-promo-code',
            component: PromoCodeFormComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'parking',
        data: {
          breadcrumb: 'Parking',
        },
        component: ParkingTableComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: 'settings',
        data: {
          breadcrumb: 'Podešavanja',
        },
        component: AdminSettingsComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      { path: '**', redirectTo: 'employees' },
    ],
  },
  { path: '**', redirectTo: 'employees' },
];
