<p-sidebar [(visible)]="showSidebar" position="right" styleClass="w-27rem">
    <ng-template pTemplate="header">
      <div class="text-2xl font-semibold">Detalji Paketa</div>
    </ng-template>
    <ng-container>
      <div class="flex flex-column gap-3">
        <div class="flex justify-content-between align-items-center">
          <img
            ngSrc="assets/images/suitcase.svg"
            width="48"
            height="48"
            alt="user-image"
          />
          <div class="flex flex-column gap-2">
            <div class="label-sm">Broj ugovora</div>
            <div class="label-sm-medium">#{{ packageData?.orderNum }}</div>
          </div>
        </div>

        <div class="mt-2">
            <span
                class="custom-badge"
                [ngClass]="{
                  'badge-success': packageData?.isActive,
                  'badge-danger': !packageData?.isActive
                }">
                <span class="status-dot"></span>
            {{ packageData?.isActive ? "Aktivan" : "Neaktivan" }}
            </span>
        </div>

        <div class="text-3xl font-semibold mb-4">{{ packageData?.packageName }}</div>
      </div>
  
      <div class="flex flex-column gap-4">
        <div class="flex justify-content-between align-items-center">
          <div class="flex flex-column justify-content-between gap-2">
            <div class="label-sm">Početak ugovora</div>
            <div class="label-sm-medium">{{ packageData?.start }}</div>
          </div>
          <div>
            <i class="pi pi-arrow-right"></i>
          </div>
          <div class="flex flex-column justify-content-between gap-2">
            <div class="label-sm">Kraj ugovora</div>
            <div class="label-sm-medium">{{ packageData?.end }}</div>
          </div>
        </div>
      </div>
  
      <div class="flex flex-column gap-3 package-box mt-3">
        <div class="flex gap-3 align-items-center">
            <div class="border-round-md flex justify-content-center align-items-center card-icon"> 
                <i [ngClass]="'text-2xl m-2 icon pi pi-car'"></i>
            </div>
            <div class="flex flex-column justify-content-between gap-1 ml-5">
              <div class="font-semibold text-base">{{ packageData?.vehicleName }}</div>
              <div class="font-medium text-sm licence-plate">{{ packageData?.licencePlate }}</div>
            </div>
          </div>
 
        <div class="flex flex-column">
         <div class="flex align-items-center justify-content-between mb-3">
            <div class="flex align-items-center">
                <i class="pi pi-user icon pl-2"></i>
                <div class="font-medium text-base user-txt ml-2 ">{{ packageData?.owner }}</div>
            </div>
           <div class="flex align-items-center gap-2 pointer">
            <div (click)="handleOpenProfile()" class="font-semibold text-base profile-txt">
                Vidi profil
            </div>
            <i class="pi pi-angle-right"></i>
           </div>
         </div>
            <div class="flex justify-content-between gap-2">
              <div class="renew-btn">
                <atlas-button
                  btnLabel="Obnovi paket"
                  btnIcon="pi pi-refresh"
                  btnClass="w-full h-full"
                  [btnSeverity]="ButtonSeverity.PRIMARY"
                  [btnStyle]="ButtonStyle.BASIC"
                  (btnClicked)="handleObnoviPaket()">
                </atlas-button>
              </div>
              <div>
                <atlas-button
                  btnIcon="pi pi-ellipsis-v"
                  btnClass="w-full px-2 h-full"
                  [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
                  [btnStyle]="ButtonStyle.BASIC"
                  (btnClicked)="handle()">
                </atlas-button>
              </div>
            </div>
        </div>
       </div>

      <p-divider></p-divider>
      
      <div class="flex flex-column gap-3">
        <div class="text-2xl font-semibold">Detalji</div>
        <div class="flex justify-content-between">
          <div class="label-sm">Prodavac</div>
          <div class="label-sm-medium">{{ packageData?.seller }}</div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Način plaćanja</div>
          <div class="label-sm-medium">{{ packageData?.paymentMethod }}</div>
        </div>
        <div class="flex justify-content-between">
          <div class="label-sm">Cena</div>
          <div class="label-sm-medium">{{ packageData?.price | currency }}</div>
        </div>
      </div>
    </ng-container>
  </p-sidebar>
