<div *ngIf="driverInfo">
  <div class="flex justify-content-between mb-2">
    <div class="driver-name">
      {{ driverInfo.name }} {{ driverInfo.lastName }}
    </div>
    <atlas-button
      class="ml-5"
      btnLabel="Izmeni"
      btnIcon="pi pi-pencil"
      (btnClicked)="handleEditDriver()"
    ></atlas-button>
  </div>
  <div class="flex mb-2">
    <div class="pi pi-phone mr-3 icon"></div>
    <div>{{ driverInfo.phoneNum }}</div>
  </div>
  <div class="flex mb-4">
    <div class="pi pi-envelope mr-3 icon"></div>
    <div>{{ driverInfo.email }}</div>
  </div>

  <p-divider></p-divider>

  <div class="footer-txt mb-3">Pristup</div>

  <div>
    <div class="mb-2">Poslednji poslat</div>
    <div class="date">
      {{ driverInfo.lastInviteSent ?? "Poziv još nije poslat" }}
    </div>
  </div>
</div>
