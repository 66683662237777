import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Guid } from 'guid-typescript';

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any>
    ? T[K] extends (infer U)[]
      ? U extends Record<any, any>
        ? FormArray<FormGroup<ControlsOf<U>>>
        : FormArray<FormControl<U>>
      : FormGroup<ControlsOf<T[K]>>
    : FormControl<T[K]>;
};

/**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach((control) => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

// export function isValidGuid(formControl: FormControl) {
//   return (
//     formControl.valid &&
//     (!formControl.hasValidator(Validators.required) ||
//       formControl.value !== Guid.createEmpty().toString())
//   );
// }

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return control.parent == null
    ? null
    : control.parent!.value.password === control.value
      ? null
      : { PasswordNoMatch: true };
};

export function guidValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    return !value || value === Guid.createEmpty().toString()
      ? { invalidGuid: true }
      : null;
  };
}
