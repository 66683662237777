<div class="box available" *ngIf="isAvailable">
  <img
    ngSrc="assets/images/check.svg"
    width="12"
    height="12"
    alt="check-image"
  />
  {{serviceName}}
</div>

<div class="box unavailable" *ngIf="!isAvailable">
  {{serviceName}}
</div>