import { Component } from '@angular/core';

@Component({
  selector: 'atlas-campaign-table',
  standalone: true,
  imports: [],
  templateUrl: './campaign-table.component.html',
  styleUrl: './campaign-table.component.scss'
})
export class CampaignTableComponent {

}
