import { Routes } from '@angular/router';
import { LoginLayoutComponent } from '@infrastructure/core/layouts/login-layout/login-layout.component';
import { authRoutes } from '@infrastructure/auth/auth.routes';
import { AppLayoutComponent } from '@infrastructure/core/layouts/app-layout/app-layout.component';
import { appLayoutRoutes } from '@infrastructure/core/layouts/app-layout/app-layout.routes';
import { AuthGuard } from '@shared/services/auth.guard';
import { AdminSettingsComponent } from './administrator/admin-settings/admin-settings.component';

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [...appLayoutRoutes,
      { path: 'settings', component: AdminSettingsComponent },
    ],
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'authentication',
        children: [...authRoutes],
      },
      { path: '**', redirectTo: 'authentication' },
    ],
  },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'authentication' },
];
