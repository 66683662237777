<atlas-dropdown
  [options]="searchOptions"
  [ctrlAppendTo]="undefined"
  [formCtrl]="formService.getForm.controls.driverId"
  ctrlStyleClass="w-full"
  ctrlPlaceholder="Izaberi vozilo"
>
  <ng-template #selectedItem let-obj>
    <div class="p-text-left item-label">
      {{ obj.label }} <span class="item-sublabel">{{ obj.name }}</span>
    </div>
  </ng-template>

  <ng-template #item let-obj>
    <div class="p-text-left item-label">
      {{ obj.label }} <span class="item-sublabel">{{ obj.name }}</span>
    </div>
  </ng-template>
</atlas-dropdown>
