import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { firstValueFrom, Subscription } from 'rxjs';
import {
  BrandAutocompleteItem,
  IBrandAutocompleteItem,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { NewVehicleService } from '@app/features/vehicles/services/new-vehicle-service';

@Component({
  selector: 'atlas-brand-autocomplete',
  standalone: true,
  imports: [
    DropdownComponent,
    InputSearchComponent,
    NgIf,
    JsonPipe,
    NgClass,
    ErrorRequiredComponent,
  ],
  templateUrl: './brand-autocomplete.component.html',
  styleUrl: './brand-autocomplete.component.scss',
})
export class BrandAutocompleteComponent
  implements OnInit, OnChanges, OnDestroy
{
  private _subs: Subscription;
  brands: IBrandAutocompleteItem[] = [];
  shouldResetInput: boolean = false;

  @Input() typeId: string | undefined = undefined;
  @Input() isDisabled: boolean = true;
  @Input() brandName: string | undefined;

  @Output() public onBrandChanged = new EventEmitter<IBrandAutocompleteItem>();

  constructor(
    private _client: VehiclesClient,
    public newVehicleService: NewVehicleService,
  ) {
    this._subs = new Subscription();
    this.newVehicleService.setBrandNameCtrl(this.brandName);
    this.newVehicleService.setBrandNameRequired();
  }

  ngOnInit() {
    this._subs.add(
      this.newVehicleService.shouldResetBrandInput$.subscribe((res) => {
        this.shouldResetInput = !this.shouldResetInput;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['typeId']?.currentValue !== changes['typeId']?.previousValue) {
      this.brands = [];
    }
    if (
      changes['brandName']?.currentValue !== changes['brandName']?.previousValue
    ) {
      this.newVehicleService.setBrandNameCtrl(this.brandName);
    }
  }

  private _load(keyword?: string) {
    this._getBrands(keyword).then((res) => {
      this.brands = [...res.result.responseList];
    });
  }

  private async _getBrands(keyword: string) {
    return await firstValueFrom(
      this._client.getBrandAutocomplete(this.typeId, keyword),
    );
  }

  searchBrand(searchValue: string) {
    this._load(searchValue);
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }

    this.newVehicleService.setBrandNameCtrl(item.value.label);
    this.onBrandChanged.emit(new BrandAutocompleteItem(item.value));
  }

  ngOnDestroy() {
    this.newVehicleService.resetCtrl();
    this.shouldResetInput = true;
    this._subs.unsubscribe();
  }
}
