import { CommonModule, NgFor, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';


@Component({
  selector: 'atlas-image-preview',
  standalone: true,
  imports: [NgIf,
    CommonModule,
    NgTemplateOutlet,
    NgForOf,
    NgOptimizedImage,
    NgFor,
    ImageModule,
    DialogModule

  ],
  templateUrl: './image-preview.component.html',
  styleUrl: './image-preview.component.scss'
})
export class ImagePreviewComponent {
  @Input() images: any[] = [];
  @Input() imageClass: string = '';
  @Input() maxImages: number = 5;
  displayPreview: boolean = false;
  selectedImage: any;
  @ViewChild('imagePreviewContainer') imagePreviewContainer!: ElementRef;
  selectedIndex: number = 0;


  openPreview(index: number): void {
    this.selectedIndex = index;
    this.selectedImage = this.images[this.selectedIndex];
    this.displayPreview = true;
    setTimeout(() => {
      this.imagePreviewContainer.nativeElement.focus();
    }, 0);
  }

  closePreview(): void {
    this.displayPreview = false;
}

  nextImage(): void {
    if (this.selectedIndex < this.images.length - 1) {
      this.selectedIndex++;
    } else {
      this.selectedIndex = 0;
    }
    this.selectedImage = this.images[this.selectedIndex];
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'ArrowRight') {
        this.nextImage();
    } else if (event.key === 'ArrowLeft') {
        this.prevImage();
    } else if (event.key === 'Escape') {
        this.closePreview();
    }
}

  prevImage(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    } else {
      this.selectedIndex = this.images.length - 1;
    }
    this.selectedImage = this.images[this.selectedIndex];
  }


  


 

}
