import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  constructor(
    private _authService: AuthService,
    private _locationService: LocationService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Get property name on security object to check
    const claimName: string = next.data['claimName'];
    const childrenClaim = next.firstChild?.data['claimName'];

    if (
      // this._authService.isUserAuthenticated() &&
      (!claimName || this._authService.hasClaim(claimName)) &&
      (!childrenClaim || this._authService.hasClaim(childrenClaim))
    ) {

      if (!this._authService.getToken) {
        this._locationService.routeToLogin(state.url);
        return false;
      }

      return true;
    }

    this._authService.removeTokens();
    this._locationService.routeToLogin(state.url);
    return false;
  }
}

export const AuthGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate(route, state);
};
