import { NgFor, NgIf, NgClass, NgForOf, NgTemplateOutlet, NgOptimizedImage, DecimalPipe } from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormsModule } from '@angular/forms';
import { CreatePartnerVehicleForm } from '@app/administrator/partners/services/partner-form.service';
import { BrandAutocompleteComponent } from '@app/features/vehicles/brand-autocomplete/brand-autocomplete.component';
import { NewVehicleService } from '@app/features/vehicles/services/new-vehicle-service';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { PackagesClient, VehiclesClient, VehicleTypeItem } from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-create-order-form-step-2',
  standalone: true,
  imports: [NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    InputAddonComponent,
    DropdownComponent, IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    NgTemplateOutlet,
    NgOptimizedImage],
  templateUrl: './create-order-form-step-2.component.html',
  styleUrl: './create-order-form-step-2.component.scss'
})
export class CreateOrderFormStep2Component implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  selectedOption: 'Vozilo' | 'Licni' = 'Vozilo';
  vehicleTypes: VehicleTypeItem[];
  secondStepControl = new FormControl('');
  vehicleBrandId: string;
  vehicleForm: CreatePartnerVehicleForm;
  selectedVehicle: string | null;
  selectedVehicleDropdown: any;
  isPackageDropdownOpen: boolean = false;
  selectedVehicleType: string | undefined;
  allPackages: any[] = [];
  selectedPackage: any;

  constructor(
    private _vehiclesClient: VehiclesClient,
    private _fb: FormBuilder,
    private _packageClient: PackagesClient,
    private _newVehicleService: NewVehicleService,
    private decimalPipe: DecimalPipe
  ) {
    this.loadVehicleTypes();
  }

  ngOnInit(): void {
    
  }

  selectVozilo() {
    this.selectedOption = 'Vozilo';
  }

  togglePackageDropdown() {
    this.isPackageDropdownOpen = !this.isPackageDropdownOpen;
  }
  
  selectPackage(pkg: any) {
    this.selectedPackage = pkg; 
    this.isPackageDropdownOpen = false; 
  }


  private loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList;
  
        this.vehicleTypes.forEach((type) => {
          if (type.name === 'Putnička vozila') {
            type.name = 'Putničko';
          }
        });
      })
    );
  }

  clearSelectedPackage(event: MouseEvent): void {
    event.stopPropagation(); 
    this.selectedPackage = null; 
   
  }

  onSelectVehicle(vehicle: any) {
    this.selectedVehicle = vehicle.name;
    this.selectedVehicleType = vehicle.typeId;
    this._loadPackages();
  }

  selectLicni() {
    this.selectedOption = 'Licni';
  }

  private _loadPackages() {
    this._subs.add(
      this._packageClient
        .getAllPackages(this.selectedVehicleType)
        .subscribe((res) => {
          this.allPackages = res.result.responseList || [];
          console.log(this.allPackages)
        })
    );
  }

  handleBrandChange(event: any) {
    this.vehicleBrandId = event.id;
    this.vehicleForm.controls.brand.patchValue(event.label);
    this.vehicleForm.controls.modelId.patchValue(undefined);
    this.vehicleForm.controls.model.patchValue(undefined);
    this._newVehicleService.resetModelCtrlValues();
  }

  handleModelChange(event: any) {
    this.vehicleForm.controls.modelId.patchValue(event.id);
    this.vehicleForm.controls.model.patchValue(event.label);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

}
