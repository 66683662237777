import { DecimalPipe, NgFor, NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PackageCardListComponent } from '@app/cc-agent/package-card-list/package-card-list.component';
import { OrderFormService } from '@app/cc-agent/services/order-form.service';
import { PackageCardListService } from '@app/cc-agent/services/package-card-list.service';
import { ClientClient, ClientPackageCardItem, DeliveryType, PaymentType, RenewPackageCommand, ValueObject } from '@shared/model/atlas.api';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-renew-package-form',
  standalone: true,
  imports: [
    ScrollPanelModule,
    DividerModule,
    NgStyle,
    ButtonComponent,
    PackageCardListComponent,
    DropdownComponent,
    NgFor,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlPipe,
    CurrencyNumberPipe
  ],
  templateUrl: './renew-package-form.component.html',
  styleUrl: './renew-package-form.component.scss'
})
export class RenewPackageFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  renewForm: any = this._orderFormService.initRenewPackageForm();

  packageName: string = null;
  packagePrice: number = null;

  paymentType: any = [
    { label: 'Corvus Pay', value: PaymentType.CORVUS_PAY },
    { label: 'Gotovina', value: PaymentType.CASH },
    { label: 'Uplatnica', value: PaymentType.PAYMENT_SLIP }
  ]
  deliveryType: any = [
    { label: 'eUgovor', value: DeliveryType.E_CONTRACT },
    { label: 'Lično preuzimanje', value: DeliveryType.PICK_UP },
    { label: 'Bex', value: DeliveryType.BEX },
    { label: 'Bex besplatna', value: DeliveryType.BEX_FREE },
    { label: 'Bex bez otkupa', value: DeliveryType.BEX_WITHOUT_DEPOSIT },
    { label: 'Kurir NS', value: DeliveryType.COURIER_NS },
    { label: 'Promo-sezonac', value: DeliveryType.PROMO_SEASONAC }
  ]
  activationType: any = [
    { label: 'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)', value: true },
    { label: 'Standardna aktivacija (7 dana od evidentiranja uplate)', value: false }
  ]

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private _clientClient: ClientClient,
    private _orderFormService: OrderFormService,
    private _packageCardService: PackageCardListService,
    private _locationService: LocationService,
    private _alertService: AlertService,
    public decimalPipe: DecimalPipe,
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._packageCardService.getSelectedPackagePrice().subscribe((x) => {
        this.packagePrice = x
      })
    )
    this._subs.add(
      this._packageCardService.getSelectedPackageName().subscribe((x) => {
        this.packageName = x
      })
    )
  }

  handleCancelForm() {
    this._locationService.routeToClientsCCTable()
  }

  handleSubmitForm() {
    this._prepareForm()

    if (!this.renewForm.valid) {
      return this._alertService.addWarnMsg("Morate popuniti sva polja")
    }

    this._subs.add(
      this._clientClient.renewClientPackage(this.renewForm.value as RenewPackageCommand)
        .subscribe((x) => {
          this._alertService.addSuccessMsg(x.result);
          this._locationService.routeToClientsCCTable()
        })
    )
  }

  private _prepareForm() { 
    var packageItem = this._packageCardService.getPackage();

    this.renewForm.controls.package.controls.packgeId?.patchValue(packageItem.value.packgeId);
    this.renewForm.controls.package.controls.packagePriceId?.patchValue(packageItem.value.packagePriceId);
    this.renewForm.controls.package.controls.packagePrice?.patchValue(packageItem.value.packagePrice);
    this.renewForm.controls.package.controls.chassisNum?.patchValue(packageItem.value.chassisNum);
    this.renewForm.controls.clientPackageId?.patchValue(this._packageCardService.getSelectedClientPackageId());
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe()
  }
}
