import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CalendarModule} from "primeng/calendar";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {AutoFocusModule} from "primeng/autofocus";
import {ErrorEmailComponent} from "@ui/errors/error-email/error-email.component";
import {ErrorRequiredComponent} from "@ui/errors/error-required/error-required.component";
import {InputTextModule} from "primeng/inputtext";
import {MaxLengthComponent} from "@ui/errors/max-length/max-length.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'atlas-calendar',
  standalone: true,
  imports: [
    CalendarModule,
    ReactiveFormsModule,
    AutoFocusModule,
    ErrorEmailComponent,
    ErrorRequiredComponent,
    InputTextModule,
    MaxLengthComponent,
    NgIf
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent {
  /** I/O */
  @Input() formCtrl!: FormControl;
  @Input() ctrlPlaceholder: string = '';
  @Input() ctrlLabel: string = '';
  @Input() formCtrlId: string = 'calendar-input';
  @Input() formCtrlType: string = 'text';
  @Input() formCtrlClass: string = '';
  @Input() formCtrlIcon?: string;
  @Input() tooltip?: string;
  @Input() formCtrlIconRight?: string;
  @Input() isDisabled: boolean;
  @Input() showLabel: boolean = true;
  @Input() shouldFocus: boolean = false;
  @Output() keyReleased = new EventEmitter<boolean>();
  @Output() inputLeft: EventEmitter<string | number> | null = new EventEmitter<
      string | number
  >();
  @Output() onEnterClicked = new EventEmitter<string>();
  @Input() hideErrors: boolean = false;

}
