import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'atlas-error-required',
  standalone: true,
  imports: [NgIf],
  templateUrl: './error-required.component.html',
  styleUrl: './error-required.component.scss',
})
export class ErrorRequiredComponent {
  /** Props */
  errorMsg = 'Obavezno polje';

  /** I/O */
  @Input() formCtrl!: FormControl<any>;
  protected readonly Validators = Validators;
}
