import { NgIf, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ErrorEmailComponent } from '@ui/errors/error-email/error-email.component';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { MaxLengthComponent } from '@ui/errors/max-length/max-length.component';
import { AutoFocusModule } from 'primeng/autofocus';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'atlas-input-filter',
  standalone: true,
  imports: [ErrorRequiredComponent,
    ErrorEmailComponent,
    InputTextModule,
    NgIf,
    NgClass,
    ReactiveFormsModule,
    AutoFocusModule,
    MaxLengthComponent,
    TooltipModule,],
  templateUrl: './input-filter.component.html',
  styleUrl: './input-filter.component.scss'
})
export class InputFilterComponent {

  @Input() formCtrl: FormControl | undefined;
  @Input() formCtrlId: string = '';
  @Input() formCtrlClass: string = '';
  @Input() ctrlPlaceholder: string = '';
  @Input() showLabel: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() hideErrors: boolean = false;

  ngOnInit() {
    if (this.formCtrl) {
      this.formCtrl.valueChanges.subscribe(value => {
        this.filterInput(value);
      });
    }
  }

  filterInput(value: string) {
    // Remove spaces and special characters
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
    if (this.formCtrl) {
      this.formCtrl.setValue(filteredValue, { emitEvent: false });
    }
  }

  handleBlur(event: any) {
    // Handle blur event if necessary
  }
}

