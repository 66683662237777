<div>
  <div>
    <div>
      <!-- Naslov -->
      <div class="text-3xl font-semibold mt-1">{{ pageLabel }}</div>

      <p-scrollPanel
        [style]="{ width: '100%', height: 'calc(112dvh - 290px)' }"
      >
        <!-- Podaci saradnika -->
        <div
          [ngClass]="
            !formService.isClientBoxValid() && (form.dirty || form.touched)
              ? 'flat-card mt-4 invalid-card'
              : 'flat-card mt-4'
          "
        >
          <div class="header">
            Podaci saradnika
            <div class="header-desc font-normal">Podaci vezani za partnera</div>
          </div>

          <div class="content flex flex-column gap-3">
            <!-- Naziv -->
            <atlas-inline-wrapper
              formCtrlId="name"
              ctrlLabel="Naziv firme"
              ctrlDesc="Naziv firme ili saradnika"
            >
              <atlas-input-text
                [formCtrl]="form.controls.name"
                formCtrlId="name"
                formCtrlClass="w-full lg:w-30rem"
                ctrlPlaceholder="Naziv kompanije / Saradnika"
                [showLabel]="false"
              ></atlas-input-text>
            </atlas-inline-wrapper>

            <!-- Alias -->
            <atlas-inline-wrapper
              formCtrlId="alias"
              ctrlLabel="Alias"
              ctrlDesc="Naziv koji će predstavljati saradnika"
            >
              <atlas-input-text
                [formCtrl]="form.controls.alias"
                formCtrlId="alias"
                ctrlPlaceholder="Alias saradnika"
                formCtrlClass="w-full lg:w-30rem"
                [showLabel]="false"
              ></atlas-input-text>
            </atlas-inline-wrapper>

            <!-- Adresa -->
            <atlas-inline-wrapper
              formCtrlId="address"
              ctrlLabel="Adresa"
              ctrlDesc="Adresa firme ili saradnika"
            >
              <atlas-input-text
                [formCtrl]="form.controls.address"
                formCtrlId="address"
                formCtrlClass="w-full lg:w-30rem"
                ctrlPlaceholder="Adresa kompanije / Saradnika"
                [showLabel]="false"
              ></atlas-input-text>
            </atlas-inline-wrapper>

            <!-- PIB -->
            <atlas-inline-wrapper formCtrlId="pib" ctrlLabel="PIB">
              <atlas-input-text
                [formCtrl]="form?.controls?.taxId!"
                formCtrlId="pib"
                ctrlPlaceholder="PIB broj"
                formCtrlClass="w-full lg:w-30rem"
                [showLabel]="false"
              ></atlas-input-text>
            </atlas-inline-wrapper>

            <!-- MATICNI BROJ -->
            <atlas-inline-wrapper ctrlLabel="Matični broj">
              <atlas-input-text
                [formCtrl]="form.controls.nationalNumber"
                ctrlPlaceholder="Matični broj"
                formCtrlClass="w-full lg:w-30rem"
                [showLabel]="false"
              ></atlas-input-text>
            </atlas-inline-wrapper>

            <!-- ZIRO RACUN -->
            <atlas-inline-wrapper ctrlLabel="Žiro račun">
              <atlas-input-text
                [formCtrl]="form.controls.bankAccountNum"
                ctrlPlaceholder="Žiro račun"
                formCtrlClass="w-full lg:w-30rem"
                [showLabel]="false"
              ></atlas-input-text>
            </atlas-inline-wrapper>

            <!-- KONTAKT -->
            <atlas-inline-wrapper
              ctrlLabel="Kontakt osoba"
              ctrlDesc="Osoba odgovorna za kontaktiranje u vezi usluge"
            >
              <div>
                <div>
                  <atlas-input-text
                    [formCtrl]="form.controls.contactPerson"
                    ctrlPlaceholder="Ime i prezime"
                    [showLabel]="false"
                    formCtrlClass="w-full lg:w-30rem"
                  ></atlas-input-text>
                </div>

                <div class="Mail-Num">
                  <atlas-input-mail
                    [formCtrl]="form.controls.contactEmail"
                    ctrlPlaceholder="Imejl"
                    [showLabel]="false"
                    formCtrlClass="w-full lg:w-14rem"
                  ></atlas-input-mail>
                  <atlas-input-phone
                    [formCtrl]="form.controls.contactNumber"
                    ctrlPlaceholder="Broj telefona"
                    [showLabel]="false"
                    formCtrlClass="w-full lg:w-14rem"
                  ></atlas-input-phone>
                </div>
              </div>
            </atlas-inline-wrapper>

            <!-- VRSTA PARTNERA -->
            <atlas-inline-wrapper
              ctrlLabel="Servis"
              ctrlDesc="Vrsta servisa saradnika"
            >
              <div>
                <div class="mb-4" *ngFor="let type of partnerTypes">
                  <p-radioButton
                    [formControl]="form.controls.partnerType | formControl"
                    [value]="type.id"
                  ></p-radioButton>
                  <label class="ml-3" [for]="type.name">{{ type.name }}</label>
                </div>
                <small
                  *ngIf="
                    form.controls.partnerType.errors?.['pattern'] &&
                    (form.controls.partnerType.dirty ||
                      form.controls.partnerType.touched)
                  "
                  class="error-info text-red-500"
                  >Obavezno polje</small
                >
              </div>
            </atlas-inline-wrapper>

            <p-divider></p-divider>

            <!-- USLUGE PARTNERA -->
            <atlas-inline-wrapper
              ctrlLabel="Usluge"
              ctrlDesc="Usluge koje saradnik može da obavlja"
            >
              <div *ngIf="selectedActivityTypes">
                <div class="mb-4" *ngFor="let activity of activityTypes">
                  <p-checkbox
                    [(ngModel)]="selectedActivityTypes"
                    [label]="activity.name"
                    [value]="activity.id"
                    (onChange)="handleCheckboxAdd($event)"
                  ></p-checkbox>
                </div>
                <atlas-error-required
                  [formCtrl]="form.controls.activityTypeIds | formControl"
                ></atlas-error-required>
              </div>
            </atlas-inline-wrapper>
          </div>
        </div>

        <!-- Podaci vozila -->
        <div
          [ngClass]="
            !formService.isVehicleBoxValid() && (form.dirty || form.touched)
              ? 'flat-card mt-4 mb-5 invalid-card'
              : 'flat-card mt-4 mb-5'
          "
        >
          <div class="header">
            Podaci o vozilu
            <div class="header-desc font-normal">
              Podaci vezani za vozila koja obavljaju asistencije
            </div>
          </div>

          <div class="content flex flex-column gap-3">
            <atlas-inline-wrapper ctrlLabel="Dostupna vozila">
              <div class="flex gap-4 flex-wrap align-items-center">
                <ng-container
                  *ngFor="let vehicle of form.value.vehicles; let idx = index"
                >
                  <atlas-partner-vehicle-card
                    [vehicle]="vehicle"
                    [vehicleIdx]="idx"
                    [partnerId]="currentPartnerId"
                  >
                  </atlas-partner-vehicle-card>
                </ng-container>

                <atlas-button
                  btnIcon="pi pi-plus"
                  btnLabel="Dodaj vozilo"
                  btnClass="pl-0"
                  (btnClicked)="handleAddVehicle()"
                ></atlas-button>
              </div>
            </atlas-inline-wrapper>
          </div>
        </div>
      </p-scrollPanel>
    </div>

    <div class="flex pb-5 justify-content-end gap-2 mr-5">
      <atlas-button
        btnLabel="Odustani"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
        (btnClicked)="handleCancelForm()"
      ></atlas-button>
      <atlas-button
        btnLabel="{{ submitBtnLabel }}"
        [btnStyle]="ButtonStyle.BASIC"
        (btnClicked)="handleSubmitForm()"
      ></atlas-button>
    </div>
  </div>
</div>
