import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-assistance-box',
  standalone: true,
  imports: [NgClass,
    NgIf],
  templateUrl: './assistance-box.component.html',
  styleUrl: './assistance-box.component.scss'
})
export class AssistanceBoxComponent {
  @Input() cardIcon: string | undefined;
  @Input() cardTitle: string | undefined;
  @Input() cardNumber: string | undefined;
  @Input() infoPage: boolean | undefined;
  @Input() cardImageSrc: string | undefined;
}
