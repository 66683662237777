import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { NgForOf } from '@angular/common';
import { PageComponent } from '@shared/components/page/page.component';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { TabViewModule } from 'primeng/tabview';
import { TableComponent } from '@ui/table/table.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { ActionMode, TableConfig } from '@ui/table/table-common';
import {
  DeletePromoCodeCommand,
  GetPromoCodeTableQuery,
  IPaginationResponseOfPromoCodeTableResponse,
  PromoCodesClient,
} from '@shared/model/atlas.api';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { LocationService } from '@shared/services/location.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { AlertService } from '@shared/services/alert.service';
import { PromoCodePreviewComponent } from '@app/administrator/promo-codes/promo-code-preview/promo-code-preview.component';
import { PromoCodeService } from '@app/administrator/promo-codes/promo-code-service';

@Component({
  selector: 'atlas-promo-code-table',
  standalone: true,
  imports: [
    ButtonModule,
    NgForOf,
    PageComponent,
    PageInfoCardComponent,
    TabViewModule,
    TableComponent,
  ],
  templateUrl: './promo-code-table.component.html',
  styleUrl: './promo-code-table.component.scss',
})
export class PromoCodeTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  protected tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfPromoCodeTableResponse;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);

  protected active: boolean | undefined;
  public activeIndex: number | undefined;

  constructor(
    private _locationService: LocationService,
    private _dialogService: DialogService,
    private _promoClient: PromoCodesClient,
    private _confirmationService: ConfirmationService,
    private _alertService: AlertService,
    private _service: PromoCodeService,
  ) {
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
  }

  ngOnInit(): void {
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 0;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.loadPromoCodes();
      },
      columns: [
        {
          header: 'NAZIV',
          field: 'name',
          type: 'text',
        },
        {
          header: 'POPUST',
          field: 'discount',
          type: 'text',
        },
        {
          header: 'ISKORIŠCENO',
          field: 'usedCount',
          type: 'text',
        },
        {
          header: 'Aktivan na sajtu',
          field: 'availableOnWebsite',
          type: 'text',
        },
        {
          header: 'Aktivan na aplikaciji',
          field: 'availableOnWebsite',
          type: 'text',
        },
        {
          header: 'STATUS',
          field: 'activeBadge',
          type: 'badge',
          badgeClass: 'is-active-',
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Izmenite promo kod',
          icon: 'pi pi-pencil',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.editPromoCode(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Obrišite promo kod',
          icon: 'pi pi-trash',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.deletePromoCode(rowData);
          },
          shouldDisplayByCondition: () => true,
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pogledajte promo kod',
          icon: 'pi pi-eye',
          callback: (rowIdx: number) => {
            const rowData: any = this._getPaginatedItem(rowIdx);
            this.previewCode(rowData.id);
          },
          shouldDisplayByCondition: () => true,
        },
      ],
    });
  }

  loadPromoCodes() {
    this._loadPromoCodeTable().then((res) => {
      this.paginatedData = res.result;
    });
  }

  editPromoCode(rowId: any) {
    this._service.setSelectedPromoCodeId = rowId.toString();
    this._locationService.routeToEditPromoCode().then();
  }

  deletePromoCode(promoCode: any) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da obrišete promo kod?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._promoClient
            .deletePromoCode(
              new DeletePromoCodeCommand({
                id: promoCode.id,
              }),
            )
            .subscribe((res) => {
              this._alertService.addSuccessMsg(res.result);
              this.loadPromoCodes();
            }),
        );
      },
    });
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private async _loadPromoCodeTable() {
    return await firstValueFrom(
      this._promoClient.getTable(
        new GetPromoCodeTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
        }),
      ),
    );
  }

  createPromoCode() {
    this._locationService.routeToCreatePromoCode().then();
  }

  previewCode(rowId: any) {
    this._service.setSelectedPromoCodeId = rowId.toString();
    this.dialogConfig.header = 'Detalji promo koda';
    this.dialogConfig.customSubmitButton = {
      label: 'Izmeni',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Izbriši promo kod',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.closable = true;
    const dialogRef = this._dialogService.open(
      PromoCodePreviewComponent,
      this.dialogConfig,
    );
    this._subs.add(
      dialogRef.onClose.subscribe((shouldReload: boolean) => {
        if (shouldReload) {
          this.loadPromoCodes();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
