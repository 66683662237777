<div class="image-gallery" [ngClass]="imageClass">
  <ng-container *ngFor="let item of images; let i = index">
      <div class="image-wrapper" *ngIf="i < maxImages" (click)="openPreview(i)">
          <p-image 
              [src]="item.url" 
              [alt]="item.id" 
              width="52px" 
              height="52px"
              [preview]="false">
          </p-image>
          <div *ngIf="i === maxImages - 1 && images.length > maxImages" class="more-images-overlay">
              <span>+{{ images.length - maxImages }}</span>
          </div>
      </div>
  </ng-container>
</div>

<div 
  class="image-preview-overlay" 
  *ngIf="displayPreview" 
  (keydown)="handleKeydown($event)" 
  tabindex="0"
  #imagePreviewContainer 
  (click)="closePreview()"
>
  <div class="image-preview-container" (click)="$event.stopPropagation()">
      <p-button class="prev-button" (click)="prevImage()">&#10094;</p-button>
      <img [src]="selectedImage.url" class="image-preview" />
      <p-button class="next-button" (click)="nextImage()">&#10095;</p-button>
  </div>
  <p-button class="close-button" (click)="closePreview()">&#10005;</p-button>
</div>