import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BrandAutocompleteComponent } from '@app/features/vehicles/brand-autocomplete/brand-autocomplete.component';
import { ModelAutocompleteComponent } from '@app/features/vehicles/model-autocomplete/model-autocomplete.component';
import { VehicleTypeDropdownComponent } from '@app/features/vehicles/vehicle-type-dropdown/vehicle-type-dropdown.component';
import { NumOfPassengersDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/num-of-passengers-dropdown/num-of-passengers-dropdown.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { DividerModule } from 'primeng/divider';
import { LocationMapComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/location-map/location-map.component';
import {
  GoogleMap,
  MapDirectionsRenderer,
  MapMarker,
} from '@angular/google-maps';
import { firstValueFrom, Subscription } from 'rxjs';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import {
  AddPartnerVehicleCommand,
  PartnerVehicleDto,
  PartnersClient,
  IPartnerVehicleDto,
  UpdatePartnerVehicleCommand,
} from '@shared/model/atlas.api';
import { ControlsOf, markFormGroupTouched } from '@shared/forms/forms-common';
import { AlertService } from '@shared/services/alert.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { InputTextModule } from 'primeng/inputtext';
import { MaxLengthComponent } from '@ui/errors/max-length/max-length.component';
import {
  CreatePartnerForm,
  CreatePartnerVehicleForm,
  PartnerFormService,
} from '@app/administrator/partners/services/partner-form.service';
import { DialogResponse } from '@app/administrator/partners/services/vehilce-dialog.service';
import { NewVehicleService } from '@app/features/vehicles/services/new-vehicle-service';

@Component({
  selector: 'atlas-partner-vehicle-form',
  standalone: true,

  imports: [
    VehicleTypeDropdownComponent,
    ModelAutocompleteComponent,
    BrandAutocompleteComponent,
    InputTextComponent,
    InputGroupModule,
    InputAddonComponent,
    InputGroupAddonModule,
    NumOfPassengersDropdownComponent,
    DividerModule,
    LocationMapComponent,
    GoogleMap,
    MapDirectionsRenderer,
    ReactiveFormsModule,
    FormsModule,
    JsonPipe,
    NgClass,
    MapMarker,
    ErrorRequiredComponent,
    InputTextModule,
    MaxLengthComponent,
    NgIf,
  ],
  templateUrl: './partner-vehicle-form.component.html',
  styleUrl: './partner-vehicle-form.component.scss',
})
export class PartnerVehicleFormComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /** Subs */
  private _subs: Subscription = new Subscription();

  vehicleBrandId: string;
  fromInfoPage: boolean = false;
  fromEdit: boolean = false;
  currentPartnerId: string;

  /** Forms */
  vehicleForm: CreatePartnerVehicleForm;
  partnerForm: CreatePartnerForm;

  /** Location */
  display: any;
  @ViewChild('search') searchElementRef: any;

  markerPosition: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  geocoder: google.maps.Geocoder;

  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
    center: {
      // Belgrade
      lat: 44.80401,
      lng: 20.46513,
    },
    zoom: 10,
    styles: [
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  constructor(
    private _formService: PartnerFormService,
    private _dialogRef: DialogRef,
    private _dialogConfig: DialogConfig,
    private _alertService: AlertService,
    private _partnerClient: PartnersClient,
    private cdr: ChangeDetectorRef,
    private _client: PartnersClient,
    private _newVehicleService: NewVehicleService,
  ) {
    this.currentPartnerId = this._dialogConfig.data?.partnerId;
    this.fromInfoPage = this._dialogConfig.data?.fromInfoPage;

    if (this._dialogConfig.data?.vehicleId) {
      this.fromEdit = true;
      this._load().then((vehicle) => {
        this.vehicleForm = this._formService.initPartnerVehicleForm(
          vehicle.result.value,
        );
        this.markerPosition = {
          lat: vehicle.result.value.latitude,
          lng: vehicle.result.value.longitude,
        };
        this.mapOptions = {
          ...this.mapOptions,
          center: this.markerPosition,
        };
        this.cdr.detectChanges();
        this.getPlaceStartAutocomplete();
      });
    } else {
      this.vehicleForm = this._formService.initPartnerVehicleForm();
    }

    this.partnerForm = this._formService.partnerForm;
    this.geocoder = new google.maps.Geocoder();
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleAddVehicle();
      }),
    );
  }

  ngAfterViewInit() {
    if (this.searchElementRef) {
      this.getPlaceStartAutocomplete();
    }
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPosition = event.latLng.toJSON();
    this.vehicleForm.controls.latitude.patchValue(this.markerPosition.lat);
    this.vehicleForm.controls.longitude.patchValue(this.markerPosition.lng);
    this.display = {
      lat: this.markerPosition.lat,
      lng: this.markerPosition.lng,
    };
    this.geocoder.geocode(
      { location: this.markerPosition },
      (results, status) => {
        if (status === 'OK' && results[0]) {
          const address = results[0].formatted_address;
          this.vehicleForm.controls.place.patchValue(address);
          this.cdr.detectChanges();
        } else {
          this._alertService.addWarnMsg('Geocoder failed due to: ' + status);
        }
      },
    );
  }

  getPlaceStartAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      {
        types: ['geocode'],
      },
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        this.markerPosition = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.vehicleForm.controls.place.patchValue(place.formatted_address);
        this.vehicleForm.controls.latitude.patchValue(
          place.geometry.location.lat(),
        );
        this.vehicleForm.controls.longitude.patchValue(
          place.geometry.location.lng(),
        );
        this.display = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.mapOptions = {
          ...this.mapOptions,
          center: this.markerPosition,
        };
        this.cdr.detectChanges();
      }
    });
  }

  handleAddVehicle() {
    if (this.vehicleForm.invalid) {
      markFormGroupTouched(this.vehicleForm);
      this._newVehicleService.markAsTouched();
      this._alertService.addWarnMsg('Morate uneti sva polja');
      return;
    }

    // ToDo: refactor

    // Postoji i treca opcija, ako nije sa Edita i ako nije sa info strane partnera,
    // a to je sa forme kreiranja saradnika, i tada ne saljemo nista na BE vec samo
    // kreiramo objekat i dodajemo ga u formu.
    if (this.fromInfoPage) {
      if (this.fromEdit) {
        this._updateVehicleClientAction();
      } else {
        this._addVehicleClientAction();
      }
    } else if (this.fromEdit) {
      this._updateVehicleClientAction();
    } else {
      this._addVehicleToForm();
    }
  }

  private _addVehicleToForm() {
    this.partnerForm.controls.vehicles.push(
      this.vehicleForm as unknown as FormGroup<ControlsOf<PartnerVehicleDto>>,
    );
    this._dialogRef.close();
  }

  private _addVehicleClientAction() {
    this._subs.add(
      this._partnerClient
        .addPartnerVehicle(
          new AddPartnerVehicleCommand({
            partnerId: this.currentPartnerId,
            vehicle: new PartnerVehicleDto(
              this.vehicleForm.value as IPartnerVehicleDto,
            ),
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result.value);
          this._dialogRef.close();
        }),
    );
  }

  private _updateVehicleClientAction() {
    this._subs.add(
      this._partnerClient
        .updatePartnerVehicle(
          new UpdatePartnerVehicleCommand({
            vehicleId: this.vehicleForm.value.id,
            partnerId: this.currentPartnerId,
            vehicle: new PartnerVehicleDto({
              id: this.vehicleForm.value.id,
              brand: this.vehicleForm.value.brand,
              modelId: this.vehicleForm.value.modelId,
              typeId: this.vehicleForm.value.typeId,
              model: this.vehicleForm.value.model,
              licencePlate: this.vehicleForm.value.licencePlate,
              maxWeight: this.vehicleForm.value.maxWeight,
              seatsNumber: this.vehicleForm.value.seatsNumber,
              pricePerKm: this.vehicleForm.value.pricePerKm,
              longitude: this.vehicleForm.value.longitude,
              latitude: this.vehicleForm.value.latitude,
              place: this.vehicleForm.value.place,
            }),
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result.message);
          this._dialogRef.close({
            vehicleForm: this.vehicleForm.value,
            vehicleItem: res.result.value,
          } as DialogResponse);
        }),
    );
  }

  handleTypeChange() {
    this.vehicleForm.controls.brand.patchValue(undefined);
    this.vehicleForm.controls.modelId.patchValue(undefined);
    this.vehicleForm.controls.model.patchValue(undefined);
    this._newVehicleService.resetCtrlValues();
  }

  handleBrandChange(event: any) {
    this.vehicleBrandId = event.id;
    this.vehicleForm.controls.brand.patchValue(event.label);
    this.vehicleForm.controls.modelId.patchValue(undefined);
    this.vehicleForm.controls.model.patchValue(undefined);
    this._newVehicleService.resetModelCtrlValues();
  }

  handleModelChange(event: any) {
    this.vehicleForm.controls.modelId.patchValue(event.id);
    this.vehicleForm.controls.model.patchValue(event.label);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this._dialogRef.close();
  }

  private async _load() {
    return await firstValueFrom(
      this._client.getPartnerVehicle(this._dialogConfig.data.vehicleId),
    );
  }
}
