import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {ControlsOf} from "../../shared/forms/forms-common";
import {ILoginCommand} from "../../shared/model/atlas.api";
import { strictEmailValidator } from '@app/administrator/partners/services/emailValidator';

@Injectable({
    providedIn: 'root',
})
export class LoginFormService {
    private form: FormGroup<ControlsOf<ILoginCommand>> | null;

    constructor(private _fb: FormBuilder) {
        this.form = null;
    }

    getForm(): FormGroup<ControlsOf<ILoginCommand>> {
        if (!this.form) {
            this.init();
        }
        return this.form!;
    }

    private init() {
        this.form = this._fb.group<ControlsOf<ILoginCommand>>({
            email: new FormControl('', {
                nonNullable: true,
                validators: [Validators.email, Validators.required, strictEmailValidator()],
            }),
            password: new FormControl('', {
                nonNullable: true,
                validators: [Validators.required],
            }),
        });
    }
}
