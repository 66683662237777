import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ILoginCommand,
  LoginCommand,
  TokensClient,
} from '@shared/model/atlas.api';
import { ControlsOf } from '@shared/forms/forms-common';
import { FormGroup } from '@angular/forms';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { InputPasswordComponent } from '@ui/input-password/input-password.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { LocationService } from '@shared/services/location.service';
import { AuthService } from '@shared/services/auth.service';
import { catchError, finalize, Subscription } from 'rxjs';
import { LoaderService } from '@shared/components/loader/loader.service';
import { HttpClient } from '@angular/common/http';
import { LoginFormService } from './login-form.service';
import { WhiteLogoComponent } from '@ui/white-logo/white-logo.component';
import { ToastModule } from 'primeng/toast';
import { AlertService } from '@shared/services/alert.service';
import {UserService} from "@shared/services/user.service";
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';

@Component({
  selector: 'atlas-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [TokensClient, HttpClient],
  imports: [
    InputTextComponent,
    InputPasswordComponent,
    FormControlPipe,
    ButtonComponent,
    WhiteLogoComponent,
    ToastModule,
    InputMailComponent
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  form!: FormGroup<ControlsOf<ILoginCommand>>;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private _locationService: LocationService,
    private _authService: AuthService,
    private _loaderService: LoaderService,
    private _client: TokensClient,
    private _userService: UserService,
    _fs: LoginFormService,
    private _alertService: AlertService,
  ) {
    this.form = _fs.getForm();
  }

  ngOnInit(): void {}

  handleLogin() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this._loaderService.setLoadingText = 'Provera korisnika...';
    this._loaderService.setShowLoader = true;
    this._subs.add(
      this._client
        .getToken(
          new LoginCommand({
            email: this.form.value.email!,
            password: this.form.value.password!,
          }),
        )
        .pipe(
          catchError((err) => {
            this._alertService.addFailedMsg(err.exception);
            throw err;
          }),
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: (res) => {
            this._authService.setTokens(
                res.result.token!,
                res.result.refreshToken!,
            );
              this._authService.startSignalR();
              this._userService.isAdmin()
                ? this._locationService.routeToAdminHomePage()
                : this._userService.isSosAgent()
                    ? this._locationService.routeToSosHomePage()
                    : this._locationService.routeToHomePage();
          },
        }),
    );
  }

  handleForgotPassword() {
    this._locationService.routeToForgotPass();
  }

  handleRegister() {
    this._locationService.routeToRegister();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
