<div class="container-main ml-1">
    <div class="form-container">
        <div class="step-title">Korak 3</div>
        <div class="text-3xl font-semibold mt-1">Plaćanje</div>

        <div class="mt-6 title-text">Način isporuke</div>
        <div class="mt-3">
            <atlas-dropdown ctrlLabel="Način isporuke" optionLabel="Način isporuke" [formCtrl]="thirdStepControl" ctrlStyleClass="w-full lg:w-19rem" ctrlPlaceholder="Način isporuke"></atlas-dropdown>
        </div>
        <div class="mt-6 title-text">Način plaćanja</div>
        <div class="mt-5">
            <atlas-dropdown ctrlLabel="Način plaćanja" optionLabel="Način plaćanja" [formCtrl]="thirdStepControl" ctrlStyleClass="w-full lg:w-19rem" ctrlPlaceholder="Način placanja"></atlas-dropdown>
        </div>
        <div class="mt-4 title-text">Od kada želite da vam se aktivira paket?</div>
        <div class="mt-3" *ngFor="let type of partnerTypes">
            <p-radioButton
              [formControl]="thirdStepControl | formControl"
              [value]="type.id"
            ></p-radioButton>
            <label class="ml-3" [for]="type.name">{{ type.name }}</label>
          </div>
    </div>


    <div class="summary-box">
        <div class="package-text">Kupovina</div>
        <div class="item mt-5">
            <span class="sub-text">01. Nacionalni paket</span>
            <span class="sub-texte">{{ 3990 | currencyNumber}}</span>
        </div>
        <div class="item mt-3">
            <span class="sub-text">02. Guma paket</span>
            <span class="sub-texte">{{2990 | currencyNumber}}</span>
        </div>
        <div class="total mt-3">
            <span class="mt-3 package-text">Ukupno</span>
            <span class="mt-3 package-text">{{6980 | currencyNumber }}</span>
        </div>
    </div>
</div>
