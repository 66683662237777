<div>
  <div class="form">
    <!-- Naslov -->
    <div class="text-3xl font-semibold">{{ pageLabel }}</div>

    <p-divider></p-divider>

    <p-scrollPanel [style]="{ width: '100%', height: 'calc(112dvh - 290px)' }">
      <!-- Osnovni podaci paketa -->
      <div dodati validaciju za okvir sekcije [ngClass]="'flat-card mt-4'">
        <!-- Naslov -->
        <div class="header">
          Osnovno
          <div class="header-desc font-normal">Osnovni podaci paketa</div>
        </div>

        <div class="content flex flex-column gap-3">
          <!-- Naziv -->
          <atlas-inline-wrapper
            formCtrlId="name"
            ctrlLabel="Naziv"
            ctrlDesc="Naziv paketa"
          >
            <atlas-input-text
              [formCtrl]="form.controls.name"
              formCtrlId="name"
              formCtrlClass="w-full lg:w-30rem"
              ctrlPlaceholder="Naziv"
              showLabel="false"
            ></atlas-input-text>
          </atlas-inline-wrapper>

          <!-- Opis -->
          <atlas-inline-wrapper formCtrlId="description" ctrlLabel="Opis paketa">
            <atlas-input-textarea
              [formCtrl]="form.controls.description"
              ctrlPlaceholder="Opis"
            ></atlas-input-textarea>
          </atlas-inline-wrapper>

          <!-- Slika -->
          <atlas-inline-wrapper ctrlLabel="Slika">
            <div>
              <atlas-file-upload
                [imageUri]="imageUri"
                [isDisabled]="fileUploadDisabled"
                (onUpload)="handlePhotoUpload($event)"
              ></atlas-file-upload>
              <div *ngIf="fileUploadDisabled" class="mt-4">
                Dodavanje fotografije moguće je samo pri izmeni postojećeg
                paketa
              </div>
            </div>
          </atlas-inline-wrapper>
        </div>
      </div>

      <!-- Detalji paketa -->
      <div [ngClass]="'flat-card mt-4'">
        <!-- Naslov -->
        <div class="header">
          Detalji
          <div class="header-desc font-normal">
            Vozila i karakteristike paketa
          </div>
        </div>

        <div class="content flex flex-column gap-3">
          <!-- Obavezan broj sasije -->
          <atlas-inline-wrapper
            formCtrlId="isVehicleRequired"
            ctrlLabel="Broj šasije"
            ctrlDesc="Da li je broj šasije obavezan pri kupovini paketa?"
            class="mb-5"
          >
            <div class="flex-column">
              <div class="mb-3">
                <p-radioButton
                  [(ngModel)]="form.controls.isVehicleRequired.value"
                  [value]="true"
                ></p-radioButton>
                <label class="ml-3">Da</label>
              </div>

              <div>
                <p-radioButton
                  [(ngModel)]="form.controls.isVehicleRequired.value"
                  [value]="false"
                ></p-radioButton>
                <label class="ml-3">Ne</label>
              </div>
            </div>
            <div></div>
          </atlas-inline-wrapper>

          <!-- Obavezan JMBG -->
          <atlas-inline-wrapper
            formCtrlId="isClientIdRequired"
            ctrlLabel="JMBG"
            ctrlDesc="Da li je JMBG obavezan pri kupovini paketa?"
          >
            <div class="flex-column">
              <div class="mb-3">
                <p-radioButton
                  [(ngModel)]="form.controls.isClientIdRequired.value"
                  [value]="true"
                ></p-radioButton>
                <label class="ml-3">Da</label>
              </div>
              <div class="mb-3">
                <p-radioButton
                  [(ngModel)]="form.controls.isClientIdRequired.value"
                  [value]="false"
                ></p-radioButton>
                <label class="ml-3">Ne</label>
              </div>
            </div>
          </atlas-inline-wrapper>

          <!-- Tipovi vozila -->
          <atlas-inline-wrapper
            formCtrlId="vehicleTypes"
            ctrlLabel="Vozila"
            ctrlDesc="Vozila kojima će paket biti omogućen"
          >
            <div class="flex gap-4 box-padding">
              <div class="vehicle-box" *ngFor="let type of vehicleTypes">
                <atlas-vehicle-type-card
                  [type]="type"
                  [selectedTypes]="selectedVehicleTypes"
                  (typeId)="handleVehicleTypeAdd($event)"
                ></atlas-vehicle-type-card>
              </div>
            </div>
          </atlas-inline-wrapper>

          <!-- Glavne usluge -->
          <atlas-inline-wrapper
            formCtrlId="packageServices"
            ctrlLabel="Glavne usluge paketa"
            class="mt-4"
          >
            <div>
              <div class="mb-4" *ngFor="let service of services">
                <p-checkbox
                  [(ngModel)]="selectedPackageServices"
                  [label]="service.name"
                  [value]="service.id"
                  (onChange)="handlePackageServiceAdd($event)"
                ></p-checkbox>
                <div *ngIf="service.description" class="service-description">
                  {{service.description}}
                </div>
              </div>
            </div>
          </atlas-inline-wrapper>

          <!-- Dodatne usluge -->
          <atlas-inline-wrapper
            formCtrlId="packageDetails"
            ctrlLabel="Dodatne usluge"
            class="mt-4"
          >
            <atlas-package-detail-autocomplete
              (listOfServices)="servicesUpdated($event)"
            ></atlas-package-detail-autocomplete>
          </atlas-inline-wrapper>

          <atlas-inline-wrapper>
            <div class="services-container flex box-padding">
              <div *ngFor="let addService of additionalServices; let idx = index" class="service-badge-container">
                <div class="service-badge flex">
                  <div class="badge-txt">{{ addService.value ?? addService }}</div>
                  <button
                    (click)="handleRemoveBadge(idx)"
                    class="pi pi-times badge-btn"
                  ></button>
                </div>
              </div>
            </div>
          </atlas-inline-wrapper>
        </div>
      </div>

      <!-- Cene -->
      <div [ngClass]="'flat-card mt-4'">
        <!-- Naslov -->
        <div class="header">
          Cena
          <div class="header-desc font-normal">Formiranje cene paketa</div>
        </div>

        <div class="content flex flex-column gap-3">
          <!-- Stara cena -->
          <atlas-inline-wrapper
            formCtrlId="oldPrice"
            ctrlLabel="Stara cena"
            ctrlDesc="Stara cena paketa"
          >
            <atlas-input-addon
              ctrlPlaceholder="Cena"
              [formCtrl]="form.controls.oldPrice"
              addonText="RSD"
              formCtrlClass="w-full lg:w-26rem"
              [isPriceFormat]="true"
            ></atlas-input-addon>
          </atlas-inline-wrapper>
          <!-- Osnovna cena -->
          <atlas-inline-wrapper
            formCtrlId="price"
            ctrlLabel="Trenutna cena"
            ctrlDesc="Cena paketa na godisnjem nivou"
          >
            <atlas-input-addon
              ctrlPlaceholder="Cena"
              [formCtrl]="form.controls.price"
              addonText="RSD"
              formCtrlClass="w-full lg:w-26rem"
              [isPriceFormat]="true"
            ></atlas-input-addon>
          </atlas-inline-wrapper>

          <!-- Akcija -->
          <atlas-inline-wrapper ctrlLabel="Akcije i cene sa popustom">
            <div class="flex flex-column">
              <atlas-button
                btnIcon="pi pi-plus"
                btnLabel="Dodaj akciju"
                btnClass="pl-0"
                (btnClicked)="handleAddAction()"
              ></atlas-button>

              <div
                *ngFor="let price of form.value.packagePrices; let idx = index"
                class="mt-3"
              >
                <atlas-action-price-card
                  [priceInfo]="price"
                  [index]="idx"
                ></atlas-action-price-card>
              </div>
            </div>
          </atlas-inline-wrapper>

          <!-- Bonus na prodaju -->
          <atlas-inline-wrapper
            formCtrlId="packageFee"
            ctrlLabel="Bonus na prodaju"
            ctrlDesc="Bonus koji prodavac ostvaruje pr prodaju paketa"
          >
            <atlas-input-addon
              ctrlPlaceholder="Cena"
              [formCtrl]="form.controls.packageFee"
              showAddon="true"
              addonText="RSD"
              formCtrlClass="w-full lg:w-26rem"
              [isPriceFormat]="true"
            ></atlas-input-addon>
          </atlas-inline-wrapper>
        </div>
      </div>
    </p-scrollPanel>
  </div>

  <div class="flex pb-5 justify-content-end gap-2 mr-5">
    <atlas-button
      btnLabel="Odustani"
      [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
      (btnClicked)="handleCancelForm()"
    ></atlas-button>
    <atlas-button
      [btnLabel]= 'submitBtnLabel' 
      [btnStyle]="ButtonStyle.BASIC"
      (btnClicked)="handleSubmitForm()"
    ></atlas-button>
  </div>
</div>
