  <div class="h-20rem">
    <div class="flex gap-2">
      <!-- Unos Email-a -->
      <div class="flex flex-grow-1 gap-1">
        <atlas-input-mail
          [formCtrl]="form.controls.email | formControl"
          ctrlLabel="Imejl adresa"
          formCtrlId="imejl"
          ctrlPlaceholder="Unesite imejl"
          formCtrlType="email"
        ></atlas-input-mail>
        <!-- Rola dropdown -->
        <atlas-dropdown
          [options]="roles"
          optionLabel="roleName"
          [formCtrl]="form.controls.roleId"
          [ctrlPlaceholder]="roleDropdown ?? 'Izaberite poziciju'"
          (optionChanged)="handleRoleChange($event)"
          ctrlStyleClass="w-9rem"
          [style]="{ 'margin-top': '25px' }"
        ></atlas-dropdown>
      </div>
      <!-- Btn dodavanje -->
      <atlas-button
        class="add-employee-button"
        (btnClicked)="addNewEmployee()"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY"
        btnLabel="Dodaj"
        [isBtnDisabled]="!form.valid || !form.controls.roleId.value"
      ></atlas-button>
    </div>

    <div *ngFor="let employee of employees" class="flex align-items-center gap-2">
      <!-- Email -->
      <div class="p-2 flex-grow-1">
        <span>{{ employee.email }}</span>
      </div>
      <!-- Rola -->
      <div class="pt-2">
        <atlas-dropdown
          [options]="roles"
          optionLabel="roleName"
          [formCtrl]="getEmployeeRoleControl(employee)"
          [ctrlPlaceholder]="getRolePlaceholder(employee.roleId)"
          ctrlStyleClass="w-9rem"
          (optionChanged)="handleRoleChangeList($event, employee)"
        ></atlas-dropdown>
      </div>
      <!-- Dugme -->
      <atlas-button
        btnIcon="pi pi-fw pi-trash"
        (btnClicked)="removeEmployee(employee.email)"
      ></atlas-button>
    </div>
  </div>
