<p-blockUI
        *ngIf="showLoader"
        [target]="content"
        [blocked]="true"
        styleClass="blockUI"
>
    <div class="block-wrapper flex justify-content-center align-items-center">
        <div class="loading flex flex-column align-items-center" *ngIf="!showError">
            <div class="text-left loading-text">{{ loadingText }}</div>
            <div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <div *ngIf="showError" class="flex flex-column align-items-center">
            <div>
                <h3>Error</h3>
            </div>
            <div>{{ errorText }}</div>
        </div>
    </div>
</p-blockUI>
