import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IResetPasswordCommand } from '@shared/model/atlas.api';
import {
  confirmPasswordValidator,
  ControlsOf,
} from '@shared/forms/forms-common';
import { strictEmailValidator } from '@app/administrator/partners/services/emailValidator';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordFormService {
  private form: FormGroup<ControlsOf<IResetPasswordCommand>> | null;

  constructor(private _fb: FormBuilder) {
    this.form = null;
  }

  getForm(): FormGroup<ControlsOf<IResetPasswordCommand>> {
    if (!this.form) {
      this.init();
    }
    return this.form!;
  }

  private init() {
    this.form = this._fb.group<ControlsOf<IResetPasswordCommand>>({
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.email, Validators.required, strictEmailValidator()],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(6)],
      }),
      confirmedPassword: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          confirmPasswordValidator,
          Validators.minLength(6),
        ],
      }),
      verificationCode: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }
}
