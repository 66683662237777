import { NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { PageComponent } from '@shared/components/page/page.component';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableComponent } from '@ui/table/table.component';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { CampaignCardComponent } from '../campaign-card/campaign-card/campaign-card.component';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'atlas-campaign-table',
  standalone: true,
  imports: [PageComponent,
    TabViewModule,
    TableComponent,
    InputTextModule,
    ReactiveFormsModule,
    InputSearchComponent,
    ClientForAssistanceAutocompleteComponent,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    FormsModule,
    TableComponent,
    ButtonComponent,
    ContextMenuModule,
    PageComponent,
    TabViewModule,
    ConfirmDialogModule,
    InputTextModule,
    InputTextComponent,
    BadgeModule,
    NgIf,
    NgClass, NgFor, NgForOf,
    DataViewModule,
  CampaignCardComponent, DropdownModule],
  templateUrl: './campaign-table.component.html',
  styleUrl: './campaign-table.component.scss'
})
export class CampaignTableComponent implements OnInit {

  items = []
  otherItems = [];
  activeTabIndex: number = 0;
  campaignTable = new FormControl('');
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
  selectedRows: number = 5;
  rowsOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 }
  ];


  ngOnInit(): void {
    this.items = [
      { title: 'Naziv kampanje', status: 'Završena', description: 'Paragraphs play a fundamental role in organizing and presenting written content. They serve as building blocks that help convey ideas and arguments in a coherent...', flagColor: '#F04438', date: '15 May 2020', user: 'Nemanja S.', info: '90', totalUsers: '1450' },
      { title: 'Naziv kampanje', status: 'U toku', description: 'Bez opisa', date: '15 May 2020', flagColor: '#999999', user: 'Marko N.', info: '23', totalUsers: '1450' },
      { title: 'Naziv kampanje', status: 'Prekinuta', description: 'Paragraphs play a fundamental role in organizing and presenting written content.', flagColor: '#1E7EC3', date: '15 May 2020', user: 'Ivana R.', info: '45', totalUsers: '1450' },
      { title: 'Naziv kampanje', status: 'U toku', description: 'Bez opisa', date: '15 May 2020', flagColor: '#999999', user: 'Marko N', info: '60', totalUsers: '1450'}
    ];

    this.otherItems = [
      { title: 'Ugovori - pred istek', status: 'U toku', date: '15 May 2020', dealTime: '1 mesec', totalUsers: '412' },
      { title: 'Ugovori Jul 2024', status: 'U toku', date: '15 May 2020', dealTime: '01 Jul 2024 - 31 Jul 2024', totalUsers: '412' },
    ];
  }

  addCampaign() {
    alert('Dodavanje kampanje');
  }

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
  }

  load(){

  }

}
