import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActionMode,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@ui/table/table-common';
import { firstValueFrom, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { LazyLoadEvent, SelectItem, SharedModule } from 'primeng/api';
import {
  GetWebPurchasesTableQuery,
  IPaginationResponseOfWebPurchasesTableResponse,
  IWebPurchasesTableResponse,
  OrdersClient,
  PaginationResponseOfWebPurchasesTableResponse,
  PaymentStatus,
} from '@shared/model/atlas.api';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { AlertService } from '@shared/services/alert.service';
import { DialogService } from '@ui/dialog/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { TableComponent } from '@ui/table/table.component';
import {
  ChangePaymentStatusFormComponent,
  PaymentStatusForm,
} from '@app/finance-administrator/order-purchase-table/change-payment-status-form/change-payment-status-form.component';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';
import {PageInfoCardComponent} from "@ui/page-info-card/page-info-card.component";

@Component({
  selector: 'atlas-order-purchase-table',
  standalone: true,
  imports: [
    BadgeModule,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    PageComponent,
    SharedModule,
    TabViewModule,
    TableComponent,
    CurrencyNumberPipe,
    PageInfoCardComponent
  ],
  templateUrl: './order-purchase-table.component.html',
  styleUrl: './order-purchase-table.component.scss',
})
export class OrderPurchaseTableComponent
  implements OnInit, OnDestroy, OnTableInit
{
  private _subs: Subscription;
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  paymentStatusSearch: PaymentStatus[];
  totalNonPaid: number | undefined;
  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfWebPurchasesTableResponse;
  activeIndex: number | undefined;

  packageSum: number = 0;
  packageSumLabel: string = 'svih';

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_TINY);

  constructor(
    private client: OrdersClient,
    private _dialogService: DialogService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.totalNonPaid = 0;
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.paymentStatusSearch = [PaymentStatus.EMPTY];
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.NUMBER,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.NUMBER,
        value: SearchOptions.NUMBER,
      },
      {
        label: SearchOptions.CLIENT,
        value: SearchOptions.CLIENT,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
    ];
  }

  ngOnInit() {
    this.countNonPaid();

    this.alertService.requestCountChanged$.subscribe(() => {
      this.countNonPaid();
    });

    this.setTableConfig().then();

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { tab: 'sve' },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.activeIndex = 0;
        this.load();
      });
  }

  countNonPaid() {
    this._subs.add(
      this.client.getAllUnpaid().subscribe((res) => {
        this.totalNonPaid = res.result;
      }),
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 0;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Broj narudžbenice',
          field: 'number',
          type: 'text',
          styleClass: 'w-5rem',
          isFrozen: true
        },
        {
          header: 'Klijent',
          field: 'client',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold w-10rem',
          columns: [
            {
              header: '',
              field: 'clientPhone',
              type: 'text',
            },
          ],
        },
        // {
        //   header: 'Adresa',
        //   field: 'clientAddress',
        //   type: 'text',
        // },
        {
          header: 'Paketi',
          field: 'packages',
          type: 'text',
          styleClass: 'w-10rem'
        },
        {
          header: 'Vozilo',
          field: 'vehicle',
          type: 'text',
          styleClass: 'w-10rem'
        },
        {
          header: 'Cena',
          field: 'price',
          type: 'currency',
          columns: [
            {
              header: '',
              field: 'oldPrice',
              type: 'currency',
              styleClass:'line-through',
            },
          ]
        },
        {
          header: 'Datum kupovine',
          field: 'createdOn',
          type: 'text',
        },
        {
          header: 'Datum aktivacije',
          field: 'activatedOn',
          type: 'text',
        },
        {
          header: 'Napomena',
          field: 'note',
          type: 'text',
          styleClass: 'w-10rem',
          tooltipField: 'note'
        },
        {
          header: 'Kupon',
          field: 'promoCode',
          type: 'text',
        },
        {
          header: 'Tip uplate',
          field: 'paymentTypeBadge',
          type: 'badge',
          badgeClass: 'payment-type-',
          styleClass: 'w-7rem'
        },
        {
          header: 'Status uplate',
          field: 'paymentStatusBadge',
          type: 'badge',
          badgeClass: 'payment-status-',
          styleClass: 'w-7rem'
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Promeni status uplate',
          shouldDisplayByCondition: (rowIdx: number) => {
            return true;
            // const rowData: IWebPurchasesTableResponse =
            //   this._getPaginatedItem(rowIdx);
            // return rowData.paymentStatus !== PaymentStatus.WC_COMPLETED;
          },
          icon: 'pi pi-sync',
          callback: (rowIdx: number) => {
            const rowData: IWebPurchasesTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Promena statusa uplate';
            this.dialogConfig.headerDescription =
              'Odaberite novi status uplate';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi',
              icon: 'pi pi-check',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              orderId: rowData.id,
              paymentStatus: rowData.paymentStatus,
              paymentStatusStr: rowData.paymentStatusBadge.label,
            };
            this.dialogConfig.closable = true;

            this.openDialog(rowIdx);
          },
        },
      ],
    });
  }

  openDialog(rowIdx: number): void {
    const ref = this._dialogService.open(
      ChangePaymentStatusFormComponent,
      this.dialogConfig,
    );
    this._subs.add(
      ref.onClose.subscribe((statusRes: PaymentStatusForm) => {
        if (statusRes) {
          this.alertService.notifyRequestCountChanged();
          this.load();
        }
      }),
    );
  }

  private _updateItemStatus(rowIdx: number, statusRes: PaymentStatusForm) {
    const pagData = this.paginatedData.data;
    pagData.at(rowIdx).paymentStatus = statusRes.status;
    pagData.at(rowIdx).paymentStatusBadge.label = statusRes.label;

    console.log('pagData', pagData[rowIdx]);

    this.paginatedData = new PaginationResponseOfWebPurchasesTableResponse({
      data: pagData,
      ...this.paginatedData,
    });
  }

  handleTabChange(idx: any) {
    let selectedTab: string;

    switch (idx) {
      case 1:
        this.paymentStatusSearch = [PaymentStatus.WC_ON_HOLD, PaymentStatus.WC_PENDING, PaymentStatus.WC_PROCESSING];
        selectedTab = 'neplacene';
        this.packageSumLabel = 'neplaćenih';
        break;
      default:
        this.paymentStatusSearch = [PaymentStatus.EMPTY];
        this.packageSumLabel = 'svih';
        selectedTab = 'sve';
    }

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { tab: selectedTab },
        queryParamsHandling: 'merge',
      })
      .then();

    this.load();
  }

  load() {
    this._getList().then((res) => {
      this.paginatedData = res.result;
    });
    this._getSum().then((res) => {
      this.packageSum = res.result;
    })
  }

  private async _getList() {
    return await firstValueFrom(
      this.client.getTable(
        new GetWebPurchasesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          paymentStatuses: this.paymentStatusSearch,
          licencePlate:
            this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
              ? this.searchCtrl.value
              : undefined,
          websiteNumber:
              this.searchOptionCtrl.value === SearchOptions.NUMBER
                  ? this.searchCtrl.value
                  : undefined,
          client:
              this.searchOptionCtrl.value === SearchOptions.CLIENT
                  ? this.searchCtrl.value
                  : undefined,
        }),
      ),
    );
  }

  private async _getSum() {
    return await firstValueFrom(
      this.client.getWebPurchasesTableSum(
        new GetWebPurchasesTableQuery({
          paymentStatuses: this.paymentStatusSearch,
          licencePlate:
            this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
              ? this.searchCtrl.value
              : undefined,
          websiteNumber:
              this.searchOptionCtrl.value === SearchOptions.NUMBER
                  ? this.searchCtrl.value
                  : undefined,
          client:
              this.searchOptionCtrl.value === SearchOptions.CLIENT
                  ? this.searchCtrl.value
                  : undefined,
        })
      )
    )
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

enum SearchOptions {
  NUMBER = 'Broj narudžbenice',
  CLIENT = 'Klijent',
  LICENCE_PLATE = 'Tablice',
}
