import { CurrencyPipe, JsonPipe, NgClass, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServiceAvailabilityComponent } from '@app/sos-agent/packages/client-package-sidebar/service-availability/service-availability.component';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { ClientClient, PackageSidebarResponse } from '@shared/model/atlas.api';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'atlas-package-detail-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    SidebarModule,
    DividerModule,
    NgIf,
    NgOptimizedImage,
    ButtonComponent,
    ServiceAvailabilityComponent,
    JsonPipe,
    NgForOf,
    NgTemplateOutlet,
    NgClass,
    CurrencyPipe
  ],
  templateUrl: './package-detail-sidebar.component.html',
  styleUrl: './package-detail-sidebar.component.scss'
})
export class PackageDetailSidebarComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  showSidebar: boolean = false;
  packageData: PackageSidebarResponse;
  clientPackageId: any;

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  constructor(
    private service: ClientPackageTableService,
    private clientPackageClient: ClientClient
  ) {}
  
  ngOnInit(): void {
    this._subs.add(
      this.service.getShowPreviewSidebar.subscribe((show) => {
        if (show) {
          this.clientPackageId = this.service.getSidebarId;
          this.showSidebar = show;
          this._load();
        }
      }),
    );
  }

  async _load() {
    this._loadPackageData().then((res) => {
      this.packageData = res.result.value;
      debugger
    })
  }

  private async _loadPackageData() {
    return await firstValueFrom(
      this.clientPackageClient.getPackageSidebarInfo(this.clientPackageId)
    )
  }

  handleOpenProfile() {
    alert("Profil korisnika")
  }

  handleObnoviPaket() {
    alert("Obnovi paket")
  }

  handle() {
    alert("Cekamo detalje")
  }

  ngOnDestroy(): void {
    this.service.hidePreviewSidebar();
    this._subs.unsubscribe()
  }

}
