import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { AuthService } from '@shared/services/auth.service';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { provideAnimations } from '@angular/platform-browser/animations';
import { API_BASE_URL } from '@shared/model/atlas.api';
import { environment } from '../environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { errorInterceptor } from '@shared/interceptors/error.interceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { refreshTokenInterceptor } from '@shared/interceptors/refresh-token-interceptor.service';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import localeSrb from '@angular/common/locales/sr-Latn';
import localeSvExtra from '@angular/common/locales/extra/sv';

registerLocaleData(localeSrb, 'sr-Latn', localeSvExtra);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    AuthService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: API_BASE_URL, useValue: environment.apiBaseUrl },
    provideHttpClient(
      withInterceptors([errorInterceptor, refreshTokenInterceptor]),
    ),
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'RSD' },
    {
      provide: LOCALE_ID,
      useValue: 'sr-Latn',
    },
    CurrencyPipe,
    DecimalPipe,
    MessageService,
    DialogService,
    ConfirmationService,
  ],
};
