import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import {DialogConfig} from './dialog-config';
import {DialogComponent} from './dialog.component';
import {DialogRef} from './dialog-ref';
import {DialogInjector} from './dialog-injector';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    dialogComponentRefMap: Map<DialogRef, ComponentRef<DialogComponent>> =
        new Map();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
    }

    public open(componentType: Type<any>, config: DialogConfig) {
        const dialogRef = this.appendDialogComponentToBody(config);

        this.dialogComponentRefMap.get(dialogRef)!.instance.childComponentType =
            componentType;

        return dialogRef;
    }

    private appendDialogComponentToBody(config: DialogConfig) {
        const map = new WeakMap();
        map.set(DialogConfig, config);

        const dialogRef = new DialogRef();
        map.set(DialogRef, dialogRef);

        const sub = dialogRef.onClose.subscribe(() => {
            this.dialogComponentRefMap.get(dialogRef)!.instance.close();
        });

        const destroySub = dialogRef.onDestroy.subscribe(() => {
            this.removeDialogComponentFromBody(dialogRef);
            destroySub.unsubscribe();
            sub.unsubscribe();
        });

        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
        const componentRef = componentFactory.create(
            new DialogInjector(this.injector, map)
        );

        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        this.dialogComponentRefMap.set(dialogRef, componentRef);

        return dialogRef;
    }

    private removeDialogComponentFromBody(dialogRef: DialogRef) {
        if (!dialogRef || !this.dialogComponentRefMap.has(dialogRef)) {
            return;
        }

        const dialogComponentRef = this.dialogComponentRefMap.get(dialogRef);
        this.appRef.detachView(dialogComponentRef!.hostView);
        dialogComponentRef!.destroy();
        this.dialogComponentRefMap.delete(dialogRef);
    }
}
