import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientPackageTableService {
  showPreviewSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  showPreviewSidebar(itemId: string) {
    this.setSidebarId = itemId;
    this.showPreviewSidebar$.next(true);
  }

  hidePreviewSidebar() {
    this.showPreviewSidebar$.next(false);
  }

  get getShowPreviewSidebar() {
    return this.showPreviewSidebar$.asObservable();
  }

  private _sidebarItemId: string | undefined;
  set setSidebarId(id: string) {
    this._sidebarItemId = id;
  }

  get getSidebarId(): string {
    return this._sidebarItemId;
  }
}
