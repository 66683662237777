import { Injectable, signal } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import {
  ITokenResponse,
  RefreshTokenCommand,
  TokensClient,
} from '@shared/model/atlas.api';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  currentlyRefreshing: boolean;

  tokenRefreshed$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  get getTokenRefreshed() {
    return this.tokenRefreshed$.asObservable();
  }
  set setTokenRefreshed(value: string) {
    this.tokenRefreshed$.next(value);
  }

  startRefreshing() {
    this.currentlyRefreshing = true;
    this.setTokenRefreshed = undefined;
  }

  reset() {
    this.currentlyRefreshing = false;
    this.setTokenRefreshed = undefined;
  }

  constructor() {}
}
