import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { TabViewModule } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import {
  PackagesClient,
  VehicleTypeItem,
  VehiclesClient,
} from '@shared/model/atlas.api';
import { CommonModule } from '@angular/common';
import { PackageCardComponent } from '@ui/package-card/package-card.component';
import { LocationService } from '@shared/services/location.service';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { PackagePreviewComponent } from '../package-preview/package-preview/package-preview.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TableComponent } from '@ui/table/table.component';
import { TableConfig } from '@ui/table/table-common';
import { ButtonModule } from 'primeng/button';
import { PackageService } from '@app/administrator/services/package-service';

@Component({
  selector: 'atlas-package-table',
  standalone: true,
  imports: [
    PageComponent,
    PageInfoCardComponent,
    TabViewModule,
    CommonModule,
    PackageCardComponent,
    TableComponent,
    ButtonModule,
  ],
  templateUrl: './package-table.component.html',
  styleUrl: './package-table.component.scss',
})
export class PackageTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  allPackages: any[] = [];
  activePackagesCount: number | undefined;
  protected tableConfig: TableConfig;
  vehicleTypes: VehicleTypeItem[] = [];
  selectedVehicleType: string | undefined;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL_MEDIUM);

  protected active: boolean | undefined;
  public activeIndex: number | undefined;

  constructor(
    private _packageClient: PackagesClient,
    private _vehiclesClient: VehiclesClient,
    private _locationService: LocationService,
    private _dialogService: DialogService,
    private _packageService: PackageService,
  ) {
    this._subs = new Subscription();
    this.allPackages = [];
    this.tableConfig = new TableConfig({
      columns: [],
    });
  }

  ngOnInit(): void {
    this._loadPackages();
    this._loadVehicleTypes();
  }

  handleTabChange(tabIndex: number) {
    if (tabIndex === 0) {
      this.selectedVehicleType = undefined;
    } else {
      this.selectedVehicleType = this.vehicleTypes[tabIndex]?.typeId;
    }
    this._loadPackages();
  }

  handleAddPackage() {
    this._locationService.routeToPackageForm();
  }

  previewPackage(id: any) {
    this._packageService.setPackageId(id);
    this.dialogConfig.header = 'Detalji paketa';
    this.dialogConfig.customSubmitButton = {
      label: 'Izmeni',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Izbriši paket',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.closable = true;
    const dialogRef = this._dialogService.open(
      PackagePreviewComponent,
      this.dialogConfig,
    );

    dialogRef.onClose.subscribe((shouldReload: boolean) => {
      if (shouldReload) {
        this._loadVehicleTypes();
        this._loadPackages();
      }
    });
  }

  private _loadVehicleTypes() {
    this._subs.add(
      this._vehiclesClient.getVehicleTypes().subscribe((res) => {
        this.vehicleTypes = res.result.responseList!;
      }),
    );
  }

  private _loadPackages() {
    this._subs.add(
      this._packageClient
        .getAllPackages(this.selectedVehicleType)
        .subscribe((res) => {
          this.allPackages = res.result.responseList!;
          this.activePackagesCount = this.allPackages.filter(
            (x) => x.isActive,
          ).length;
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
