<atlas-page
  pageTitle="Parking"
  btnIcon="pi pi-plus"
  pageDescription="Uređivanje i dodavanje gradova i njihovih zona za plaćanje"
  actionLabel="Dodaj grad"
  (onHandleAction)="addCity()"
>
  <!-- parking overview cards -->
  <div class="flex flex-column md:flex-row gap-3">
    <atlas-page-info-card
      [cardTitle]="'Gradovi'"
      cardIcon="pi-flag"
      [cardNumber]="allCitiesCount"
      class="flex-1"
    ></atlas-page-info-card>
    <atlas-page-info-card
      [cardTitle]="'Zone'"
      [cardNumber]="allZonesCount"
      cardIcon="pi-image"
      class="flex-1"
    ></atlas-page-info-card>
  </div>
  <div class="text-2xl font-semibold mt-5">Gradovi</div>
  <div class="text-base text-gray-600 mt-2">
    Lista svih gradova i njihovih zona
  </div>
  <div class="main-div">
    <div class="main-city">
      <div class="text-l font-semibold city-title">Gradovi</div>

      <div class="items-height">
        <p-scrollPanel [style]="{ height: '100%' }">
          <div
            *ngFor="let city of allCities; let i = index"
            class="city-item"
            (click)="onCityClick(i)"
            [ngClass]="{ selected: i === selectedCityIndex }"
          >
            {{ city }}
            <i
              (click)="onEllipsisClick($event, overlayPanelCity)"
              [ngClass]="'pi pi-ellipsis-v'"
            ></i>
          </div>
        </p-scrollPanel>
      </div>
    </div>
    <div class="main-zones">
      <div class="text-l font-semibold zone-title">
        Zone
        <span  [ngClass]="{'lighter-background': !savedCityId}" class="add-zone" (click)="addZone()"> + Dodaj zonu </span>
      </div>
      <div class="items-height">
        <p-scrollPanel [style]="{ height: '100%' }">
          <div *ngFor="let zone of allZones; let i = index" class="zone-item">
            <div class="zone-color" [ngClass]="getColorClass(zone.color)">
              {{ zone.zoneCode }}
            </div>
            <div class="zone-info">
              <div class="zone-name">{{ zone.name }}</div>
              <div class="zone-description">{{ zone.description }}</div>
            </div>
            <div class="zone-pricing">{{ zone.type }}</div>
            <i
              (click)="onZoneEllipsisClick($event, zone, overlayPanelZone)"
              [ngClass]="'pi pi-ellipsis-v'"
            ></i>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</atlas-page>

<p-overlayPanel #overlayPanelCity>
  <div class="overlay-content">
    <div (click)="handleEditCity(this.savedCityId)">
      <i class="pi pi-pencil icon"></i> Izmeni
    </div>
    <div (click)="deleteCity(this.savedCityId)">
      <i class="pi pi-trash icon"></i> Obriši
    </div>
  </div>
</p-overlayPanel>
<p-overlayPanel #overlayPanelZone>
  <div class="overlay-content">
    <div (click)="handleEditZone(this.savedZoneId)">
      <i class="pi pi-pencil icon"></i> Izmeni
    </div>
    <div (click)="confirmDeleteZone(this.currentZone)">
      <i class="pi pi-trash icon"></i> Obriši
    </div>
  </div>
</p-overlayPanel>
