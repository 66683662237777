<div class="flex flex-column gap-2">
  <label [for]="ctrlLabel">{{ ctrlLabel }}</label>
  <p-password
    [formControl]="formCtrl"
    [id]="formCtrlId"
    [toggleMask]="true"
    inputStyleClass="w-full"
    styleClass="w-full"
    (keyup)="handleEnter($event)"
    [placeholder]="ctrlPlaceholder"
    [appendTo]="appendTo"
  ></p-password>

  <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
  <atlas-password-match [formCtrl]="formCtrl"></atlas-password-match>
  <atlas-min-length [formCtrl]="formCtrl"></atlas-min-length>
</div>
