import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import {
  AssistancesClient,
  ClientSearchParam,
  IClientForAssistanceAutocompleteResponse,
} from '@shared/model/atlas.api';
import { firstValueFrom, Subscription } from 'rxjs';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';

@Component({
  selector: 'atlas-client-for-assistance-autocomplete',
  standalone: true,
  imports: [InputSearchComponent, AutoCompleteModule, FormsModule],
  templateUrl: './client-for-assistance-autocomplete.component.html',
  styleUrl: './client-for-assistance-autocomplete.component.scss',
})
export class ClientForAssistanceAutocompleteComponent
  implements OnInit, OnDestroy
{
  clients: IClientForAssistanceAutocompleteResponse[] = [];
  searchParam: ClientSearchParam;
  private _subs: Subscription;

  constructor(
    private client: AssistancesClient,
    private service: AssistanceService,
    private formService: AssistanceFormService,
  ) {
    this.searchParam = this.service.clientSearchParamCtrl.value;
    this._subs = new Subscription();
  }

  ngOnInit() {
    this._subs.add(
      this.service.clientSearchParamCtrl.valueChanges.subscribe(
        (searchParam) => {
          this.searchParam = searchParam;
        },
      ),
    );
  }

  searchClient(searchValue: string) {
    this._getList(searchValue).then((res) => {
      this.clients = res.result;
    });
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }
    this._getClient(item.value.clientId).then((res) => {
      this.formService.setClient(res.result);
    });
  }

  private async _getList(keyword: string) {
    return await firstValueFrom(
      this.client.getClientForAssistanceAutocomplete(this.searchParam, keyword),
    );
  }

  private async _getClient(id: string) {
    return await firstValueFrom(this.client.getClientForAssistance(id));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
