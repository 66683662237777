<div class="flex flex-column gap-2 w-full">
    <input
      pInputText
      [id]="formCtrlId"
      [formControl]="formCtrl"
      [placeholder]="ctrlPlaceholder"
      [ngClass]="formCtrlClass"
      [disabled]="isDisabled"
      (blur)="handleBlur($event)"
    />
  

  <ng-container *ngIf="!hideErrors">
    <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
    <atlas-error-email [formCtrl]="formCtrl"></atlas-error-email>
    <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
  </ng-container>
</div>