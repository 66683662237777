import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loadingText$ = new BehaviorSubject<string>('Molimo pričekajte...');

  get getLoadingText() {
    return this.loadingText$.asObservable();
  }

  set setLoadingText(data: string) {
    this.loadingText$.next(data);
  }

  errorText$ = new BehaviorSubject<string>('');

  get getErrorText() {
    return this.errorText$.asObservable();
  }

  set setErrorText(data: string) {
    this.errorText$.next(data);
  }

  showLoader$ = new BehaviorSubject<boolean>(false);

  get getShowLoader() {
    return this.showLoader$.asObservable();
  }

  set setShowLoader(data: boolean) {
    this.showLoader$.next(data);
  }

  reset() {
    this.setErrorText = '';
    this.setLoadingText = 'Molimo pričekajte...';
    this.setShowLoader = false;
  }
}
