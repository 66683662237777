<atlas-page
  pageTitle="Klijenti"
  btnIcon="pi pi-user"
  actionLabel="Dodaj klijenta"
  (onHandleAction)="handleCreateClient()"
>
  <div class="search-bar">
    <atlas-inline-wrapper [showLabel]="false" formCtrlId="search">
      <div class="flex align-items-end">
        <atlas-dropdown
          [hideErrors]="true"
          [options]="searchOptions"
          [formCtrl]="searchOptionCtrl"
          ctrlStyleClass="input-next-to w-50rem"
        ></atlas-dropdown>
        <atlas-input-text
          [hideErrors]="true"
          formCtrlClass="dropdown-next-to w-10rem lg:w-20rem"
          ctrlPlaceholder="Pretraži"
          [formCtrl]="searchCtrl"
          (onEnterClicked)="load()"
        ></atlas-input-text>

        <div class="filters-button ml-3">
          <atlas-button
            btnIcon="pi pi-sliders-h "
            class="filters-button"
            [btnStyle]="ButtonStyle.BASIC"
            [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
            btnLabel="Filteri"
          ></atlas-button>
        </div>
      </div>
    </atlas-inline-wrapper>
  </div>
  <p-table
    dataKey="id"
    [value]="paginatedData.data"
    [tableStyle]="{ 'min-width': '60rem' }"
    [expandedRowKeys]="expandedRows"
    (onRowExpand)="onRowExpand($event)"
    [reorderableColumns]="true"
    [resizableColumns]="false"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Prikazano {first} - {last} od {totalRecords} entiteta"
    (onRowCollapse)="onRowCollapse($event)"
    [paginator]="true"
    [rows]="paginatedData.pageSize"
    [totalRecords]="paginatedData.totalCount"
    [lazy]="true"
    (onLazyLoad)="loadLazy($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem; padding: 16px">
          <i class="pi pi-chevron-right icon-color"></i>
        </th>
        <th style="width: 12rem">KLIJENT</th>
        <th style="width: 20rem">BROJ PAKETA</th>
        <th style="width: 10rem">PRODAVAC</th>
        <th style="width: 12rem; text-align: start">DODELJEN</th>
        <th style="width: 5rem; text-align: center"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-client let-expanded="expanded">
      <tr>
        <td>
          <i
            [ngClass]="
              expanded
                ? 'pi pi-chevron-down icon-color'
                : 'pi pi-chevron-right icon-color'
            "
            class="expand-icon"
            (click)="toggleRow(client)"
          >
          </i>
        </td>
        <td>
          <div
            style="display: flex; align-items: center"
            (mouseenter)="onHover(client)"
            (mouseleave)="onLeave(client)"
          >
            <ng-container *ngIf="!client.hovered; else showCheckbox">
              <img
                src="../../../../assets/images/client-icon.svg"
                class="agent-image"
                alt="Client Icon"
              />
            </ng-container>

            <ng-template #showCheckbox>
              <p-checkbox
                class="hover-td"
                [binary]="true"
                [ngModel]="client.selected"
                (onChange)="selectAllPackages(client)"
                [ngClass]="{ 'hovered-checkbox': expandedRows[client.id] }"
              ></p-checkbox>
            </ng-template>

            <!-- Client Info -->
            <div>
              <div class="client-name">{{ client.name }}</div>
              <small class="light-name">{{ client.phone }}</small>
            </div>
          </div>
        </td>
        <td class="padding-cell">{{ client.numOfPackages }} paketa</td>
        <td class="agent-cell">
          <img
            [src]="
              client.agentImage
                ? client.agentImage
                : 'assets/images/client-icon.svg'
            "
            [alt]="client.agent"
            class="agent-image"
          />
          {{ client.agent }}
        </td>
        <td class="padding-cell" style="text-align: start">
          {{ client.seller }}
        </td>
        <td class="comment-cell">
          <atlas-button [btnTooltip]="client.commentsNum + ' komentara'">
            <img
              src="../../../../assets/images/comment-Icon.svg"
              alt="Comments"
              (click)="showComments()"
            />
          </atlas-button>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-client>
      <tr>
        <td colspan="6">
          <div class="expanded-content">
            <div class="packages-list">
              <div *ngFor="let p of client.packages" class="package-row">
                <div class="empty-box"></div>

                <div class="package-column client-name">
                  <p-checkbox
                    [(ngModel)]="p.selected"
                    [label]="p.packageName"
                    [value]="p.packageName"
                    (onChange)="handleCheckboxChange($event, p)"
                    [binary]="true"
                  >
                  </p-checkbox>
                </div>

                <div class="vehicle-column">
                  <span class="client-name">{{ p.vehicle || "/" }}</span>
                  <span class="light-name">{{ p.licencePlate }}</span>
                </div>

                <div class="status-column">
                  <span
                    class="custom-badge"
                    [ngClass]="{
                      'badge-success': p.activeBadge.value,
                      'badge-danger': !p.activeBadge.value
                    }"
                  >
                    <span class="status-dot"></span>
                    {{ p.activeBadge.label }}
                  </span>
                </div>

                <div class="expiration-column light-name">
                  <i class="pi pi-calendar"></i> {{ p.created }}
                </div>

                <div class="renew-column">
                  <p-button
                    label="Obnovi paket"
                    icon="pi pi-refresh"
                    class="custom-renew-button"
                    (click)="handleRenewPackage(p)"
                  ></p-button>
                </div>

                <div class="more-options-column">
                  <i
                    (click)="openOptionsDialog(p.clientPackageId)"
                    class="pi pi-ellipsis-v"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <atlas-checkbox-action-dialog
    [selectedPackages]="selectedPackages"
    [distinctVehicleCount]="distinctVehicleCount"
  ></atlas-checkbox-action-dialog>
</atlas-page>
<atlas-package-detail-sidebar></atlas-package-detail-sidebar>
