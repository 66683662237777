import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { DialogRef } from '@ui/dialog/dialog-ref';
import {
  AdminService,
  InvatationForm,
} from '@app/administrator/services/admin-service';
import { Subscription, finalize } from 'rxjs';
import {
  EmployeeRequestItem,
  EmployeeRoleItem,
  EmployeesClient,
  InviteNewEmployeesCommand,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { LoaderService } from '@shared/components/loader/loader.service';
import { DialogConfig } from '@ui/dialog/dialog-config';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { strictEmailValidator } from '@app/administrator/partners/services/emailValidator';

@Component({
  selector: 'atlas-invite-employee',
  standalone: true,
  templateUrl: './invite-employee.component.html',
  styleUrl: './invite-employee.component.scss',
  imports: [
    ReactiveFormsModule,
    FormsModule,
    ButtonComponent,
    InputTextComponent,
    FormControlPipe,
    NgFor,
    DropdownComponent,
    NgIf,
    InputMailComponent
  ],
})
export class InviteEmployeeComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
  roleDropdown: string;

  employees: EmployeeRequestItem[] = [];
  roles: EmployeeRoleItem[] = [];
  form: InvatationForm;

  constructor(
    private _dialogRef: DialogRef,
    private _dialogConfig: DialogConfig,
    private _adminService: AdminService,
    private _employeeClient: EmployeesClient,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _formBuilder: FormBuilder,
  ) {
    this.form = this._adminService.getInviteEmployeeForm();
  }

  ngOnInit(): void {
    this._getRoles();
    this._initForm();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  private _initForm() {
    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email, strictEmailValidator()]],
      roleId: [''],
    });
  }

  addNewEmployee() {
    if (!this.form?.valid) {
      this._alertService.addWarnMsg('Potrebno je uneti sva polja!');
      return;
    }

    this.employees.push(
      new EmployeeRequestItem({
        email: this.form.value.email!,
        roleId: this.form.value.roleId,
      }),
    );

    this._initForm();
    this.roleDropdown = 'Izaberite poziciju';
  }

  getEmployeeRoleControl(employee: EmployeeRequestItem): FormControl {
    const index = this.employees.indexOf(employee);
    return this._formBuilder.control(employee.roleId, Validators.required);
  }

  removeEmployee(email: any) {
    const idx = this.employees.findIndex((x) => x.email == email);
    this.employees.splice(idx, 1);
  }

  private _handleSave() {
    if (this.employees.length === 0) {
      return this._alertService.addWarnMsg(
        'Mora se uneti barem jedan zaposleni',
      );
    }

    this._loaderService.setLoadingText = 'Poziv se šalje zaposlenima...';
    this._loaderService.setShowLoader = true;
    this._dialogConfig.styleClass = 'display: hidden';

    this._subs.add(
      this._employeeClient
        .inviteNewEmployees(
          new InviteNewEmployeesCommand({
            employeeRequestList: this.employees,
          }),
        )
        .subscribe((res) => {
          this._dialogRef.close();
          this._loaderService.reset();
          this._alertService.addSuccessMsg(res.result);
        }),
    );
  }

  private _getRoles() {
    this._subs.add(
      this._employeeClient.getEmployeeRoles().subscribe((x) => {
        this.roles = x.result.roles;
      }),
    );
  }
  getRolePlaceholder(roleId: string): string {
    const role = this.roles.find((r) => r.id === roleId);
    return role ? role.roleName : 'Uloga';
  }

  handleRoleChangeList(event: any, employee: EmployeeRequestItem) {
    employee.roleId = event.value.id;
    this.getEmployeeRoleControl(employee).setValue(event.value.id);
  }

  handleRoleChange(event: any) {
    this.form.controls.roleId.patchValue(event.value.id);
    this.roleDropdown = event.value.roleName;
  }

  exit() {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this.form?.reset();
    this._subs.unsubscribe();
  }
}
