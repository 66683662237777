import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChangeSellerDialogComponent } from "../../change-seller-dialog/change-seller-dialog/change-seller-dialog.component";
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { ClientAutocompleteComponent } from "../../client-autocomplete/client-autocomplete/client-autocomplete.component";
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { AssignPackagesToTempSellerCommand, OrdersClient } from '@shared/model/atlas.api';
import { DialogRef } from '@ui/dialog/dialog-ref';

@Component({
  selector: 'atlas-add-seller-dialog',
  standalone: true,
  imports: [ChangeSellerDialogComponent, ClientAutocompleteComponent],
  templateUrl: './add-seller-dialog.component.html',
  styleUrl: './add-seller-dialog.component.scss'
})
export class AddSellerDialogComponent implements OnInit,  OnDestroy {
  totalClients: any;
  totalPackages: any;
  agentImageUrl: string;
  agentName: string;
  packageIds: string[] = [];
  private _subs: Subscription = new Subscription();

  client: any[];
  selectedAgentId: string;

  constructor(private clientService: ClientPackageTableService,
    private _alertService: AlertService,
    private _client: OrdersClient,
    private _dialogRef: DialogRef
  ) { }

  ngOnInit(): void {
    this._loadClientData();
    this._setAgentInfo();
    this._listenForSubmit();
  }

  private _loadClientData(): void {
    this.packageIds = [];
  
    const clients = this.clientService.getSelectedClients();
  
    let selectedPackagesCount = 0;
    const uniqueAgents = new Set();
  
    clients.forEach(client => {
      const selectedPackages = client.packages.filter(pkg => pkg.selected === true);
  
      if (selectedPackages.length > 0) {
        uniqueAgents.add(client.name);
      }
  
      selectedPackages.forEach(pkg => {
        this.packageIds.push(pkg.clientPackageId);
      });
  
      selectedPackagesCount += selectedPackages.length;
    });
  
    this.totalClients = uniqueAgents.size;
    this.totalPackages = selectedPackagesCount;
  }

  private _setAgentInfo(): void {
    const clients = this.clientService.getSelectedClients();
    const lastClient = clients[clients.length - 1];

    this.agentName = lastClient.agent || 'Unknown Agent';
    this.agentImageUrl = lastClient.agentImage
      ? lastClient.agentImage
      : '../../../../assets/images/client-icon.svg';
  }

  private _listenForSubmit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  onAgentSelected(agentId: string) {
    this.selectedAgentId = agentId;
  }

  public _handleSave() {
    let isAgentAssigned = false;
    this._subs.add(
      this._client.assignPackagesToTempSeller(
        new AssignPackagesToTempSellerCommand
          ({
            clientPackageIds: this.packageIds,
            toTempSellerId: this.selectedAgentId
          })
      )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          isAgentAssigned = true;
          this.exit(isAgentAssigned);
        }),
    );
  }

  public exit(isAgentAssigned: boolean) {
    this._dialogRef.close(isAgentAssigned);
}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
