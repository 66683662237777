<!-- TITLE -->
<div class="step-title">Korak 2</div>
<div class="flex justify-content-between align-items-baseline">
  <div class="text-3xl font-semibold mt-1">Saradnik</div>
<!--    <atlas-button-->
<!--        [btnStyle]="ButtonStyle.TEXT"-->
<!--        (btnClicked)="showLegend = !showLegend"-->
<!--        [btnIcon]="showLegend ? 'pi pi-angle-down' : 'pi pi-angle-up'"-->
<!--        btnIconPosition="right"-->
<!--        btnLabel="Legenda"-->
<!--    ></atlas-button>-->
</div>

<!-- LEGEND-->
<atlas-map-legend *ngIf="showLegend"></atlas-map-legend>

<!-- PARTNERS MAP -->
<div class="mt-4 flex flex-column lg:flex-row gap-4">
  <div class="flex-70">
  <google-map #gmap [options]="mapOptions">
    @if (service.directionsResults$ | async; as directionsResults) {
      <map-directions-renderer
        [directions]="directionsResults"
        [options]="directionOptions"
      />
    }
  </google-map>
  </div>
  <div class="flex-30">
  <atlas-assistance-details></atlas-assistance-details>
  </div>
</div>

<!-- OVERLAY OF MAP MARKER-->
<p-overlayPanel #op>
  <ng-template pTemplate="content">
    <atlas-partner-preview-form [previewPartnerId]="previewPartnerIdx"></atlas-partner-preview-form>
  </ng-template></p-overlayPanel
>


