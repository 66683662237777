import { JsonPipe, NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '@shared/services/location.service';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { StepperModule } from 'primeng/stepper';
import { Steps, StepsModule } from 'primeng/steps';

@Component({
  selector: 'atlas-create-order-form',
  standalone: true,
  imports: [StepsModule,
    ButtonComponent,
    StepperModule,
    ScrollPanelModule,
    JsonPipe,
    ConfirmDialogModule,
    NgIf,
    NgClass,
    SkeletonModule,
    NgTemplateOutlet,],
  templateUrl: './create-order-form.component.html',
  styleUrl: './create-order-form.component.scss'
})
export class CreateOrderFormComponent implements OnInit {

  items: MenuItem[] | undefined;
  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;
  @ViewChild('steps') steps!: Steps;
  showLoader: boolean = false;
  shouldDisplayFinish: boolean = false;
  shouldDisplayBack: boolean = false;
  currentStep: number = 1

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private location: LocationService,
    private activatedRoute: ActivatedRoute
  ) {
   
  }
  ngOnInit(): void {
    this.items = [
      {
        label: 'Klijent',
        routerLink: 'step-1',
        icon: 'pi pi-check',
      },
      {
        label: 'Paketi i vozilo',
        routerLink: 'step-2',
      },
      {
        label: 'Placanje',
        routerLink: 'step-3',
      },
    ];
  }

  handleCancel() {
    this.confirmationService.confirm({
      header: 'Odustani od kreiranja klijenta?',
      message: 'Da li želiš da odustaneš od kreiranja klijenta?',
      acceptButtonStyleClass: ButtonSeverity.DANGER,
      acceptLabel: 'Odustani',
      rejectLabel: 'Ostani',
      icon: 'confirm-cancel.svg',
      accept: () => {
        this.location.routeToClientsTable();
      },
      reject: () => {},
    });
  }

  prevPage() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.router
        .navigate([`step-${this.currentStep}`], {
          relativeTo: this.activatedRoute,
        })
        .then(() => {
          this.shouldDisplayBack = this.currentStep > 1; 
          this.shouldDisplayFinish = this.currentStep === 3;
        });
    }
  }

  nextPage() {
    if (this.currentStep < 3) { 
      this.currentStep++;
      this.router
        .navigate([`step-${this.currentStep}`], {
          relativeTo: this.activatedRoute,
        })
        .then(() => {
          this.shouldDisplayBack = this.currentStep > 1;
          this.shouldDisplayFinish = this.currentStep === 3; 
        });
    }
  }



}
