import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/services/auth.guard';
import { AgentTableComponent } from '@app/cc-agent/agents/agent-table/agent-table.component';
import { RecommendationTableComponent } from '@app/cc-agent/recommendations/recommendation-table/recommendation-table.component';
import { ClientWithPackagesTableComponent } from '@app/cc-agent/clients/client-with-packages-table/client-with-packages-table.component';
import { CreateOrderFormComponent } from '@app/cc-agent/clients/create-order-form/create-order-form.component';
import { CreateOrderFormStep1Component } from '@app/cc-agent/clients/create-order-form/create-order-form-step-1/create-order-form-step-1.component';
import { CreateOrderFormStep2Component } from '@app/cc-agent/clients/create-order-form/create-order-form-step-2/create-order-form-step-2.component';
import { CreateOrderFormStep3Component } from '@app/cc-agent/clients/create-order-form/create-order-form-step-3/create-order-form-step-3.component';
import { CampaignTableComponent } from '@app/cc-agent/campaigns/campaign-table/campaign-table.component';

export const ccAgentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full',
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            component: ClientWithPackagesTableComponent,
          },
          {
            path: 'create',
            canActivate: [AuthGuard],
            component: CreateOrderFormComponent,
            children: [
              {
                path: 'step-1',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep1Component,
              },
              {
                path: 'step-2',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep2Component,
              },
              {
                path: 'step-3',
                canActivate: [AuthGuard],
                component: CreateOrderFormStep3Component,
              },
            ],
          },
        ],
      },
      {
        path: 'campaigns',
        canActivate: [AuthGuard],
        component: CampaignTableComponent,
        pathMatch: 'full',
      },
      {
        path: 'recommendations',
        canActivate: [AuthGuard],
        component: RecommendationTableComponent,
        pathMatch: 'full',
      },
      {
        path: 'agents',
        canActivate: [AuthGuard],
        component: AgentTableComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'assistances',
  },
];
