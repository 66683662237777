import { Component } from '@angular/core';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ButtonComponent, ButtonStyle } from '../../ui/button/button.component';
import { FormControlPipe } from '../../shared/pipes/form-control.pipe';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../../shared/services/location.service';
import { LoaderService } from '../../shared/components/loader/loader.service';
import {
  UsersClient,
  VerifyPasswordResetEmailCommand,
} from '../../shared/model/atlas.api';
import { finalize, Subscription } from 'rxjs';
import { WhiteLogoComponent } from '../../ui/white-logo/white-logo.component';
import { Router } from '@angular/router';
import { strictEmailValidator } from '@app/administrator/partners/services/emailValidator';

@Component({
  selector: 'atlas-forgot-password',
  standalone: true,
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
  providers: [UsersClient],
  imports: [
    InputTextComponent,
    ButtonComponent,
    FormControlPipe,
    WhiteLogoComponent,
  ],
})
export class ForgotPasswordComponent {
  constructor(
    private _locationService: LocationService,
    private _loaderService: LoaderService,
    private _client: UsersClient,
    private _router: Router,
  ) {}

  form = new FormGroup({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.email, strictEmailValidator()],
    }),
  });
  protected readonly ButtonStyle = ButtonStyle;
  private _subs: Subscription = new Subscription();

  handleResetPassword() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this._loaderService.setLoadingText = 'Provera tokena...';
    this._loaderService.setShowLoader = true;
    this._subs.add(
      this._client
        .verifyPasswordResetEmail(
          new VerifyPasswordResetEmailCommand({
            email: this.form.value.email!,
          }),
        )
        .pipe(
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: (res) => {
            this._router.navigateByUrl(
              '/authentication/forgot-password/reset-password',
              {
                state: {
                  email: this.form.value.email,
                },
              },
            );
          },
        }),
    );
  }

  handleBackToLogin() {
    this._locationService.routeToLogin();
  }
}
