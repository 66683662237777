<div class="campaign-card">
    <div class="campaign-header">
      <div class="title-section">
        <h3 class="title-header">{{ title }}</h3>
        <span class="status-badge ml-1" [ngClass]="getStatusBadgeClass(status)">
          <span class="status-dot"></span>
          {{ status }}
          
        </span>
      </div>
      <div class="top-right">
        <img *ngIf="showTopUserIcon" style="margin-top: -3px;"  src="assets/images/client-icon.svg" alt="User Icon" width="20" height="20" />
        <div *ngIf="showProgressBar" class="progress-container">
            <p-progressBar [value]="info" [style]="{'width':'100px', 'height':'8px'}" [ngClass]="getProgressBarClass(info)">
            </p-progressBar>
            <div class="progress-value">{{ info }}%</div>
          </div>
        <i class="pi pi-ellipsis-v"></i>
      </div>
    </div>
  
    <p class="description">{{ description }}</p>
    <div class="campaign-separator"></div>
    <div class="campaign-footer">
        <div class="campaign-info">
          <span *ngIf="showFlagIcon" class="pi pi-flag-fill" [ngStyle]="{'color': flagColor}"></span>
            <span class="separator"></span>
            <span class="pi pi-calendar"></span> {{ date }}
            <span class="separator"></span>
            <span *ngIf="showDealTime" class="pi pi-clock"></span> {{dealTime}}
            <span *ngIf="showDealTime" class="separator"></span>
            <img *ngIf="showFooterUserIcon" src="assets/images/client-icon.svg" alt="User Icon" width="20" height="20" /> {{ user }}
            <span *ngIf="showFooterUserIcon" class="separator"></span>
            <span  class="pi pi-users"></span> {{ totalUsers }}
          </div>
      <span (click)="moreDetails()" class="more-details">Vidi detalje ></span>
    </div>
  </div>
  