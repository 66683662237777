<div class="flex flex-column gap-2 w-full" *ngIf="!formCtrlIcon">
    <label [for]="formCtrlId" *ngIf="showLabel"
      >{{ ctrlLabel }}
    </label>
    <textarea
      pInputText
      pAutoFocus
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [rows]="rows"
      [placeholder]="ctrlPlaceholder"
      autocomplete="off"
      [ngClass]="
        formCtrlIcon ? 'pl-5 w-full text-base ' + formCtrlClass : formCtrlClass
      "
      [autofocus]="shouldFocus"
    ></textarea>
    <label style="color:#8e8e8e" *ngIf="showInfo">{{ctrlInfo}}</label>
    <ng-container *ngIf="!hideErrors">
      <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
      <atlas-error-email [formCtrl]="formCtrl"></atlas-error-email>
      <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
    </ng-container>
  </div>
  <div *ngIf="formCtrlIcon" class="flex flex-column gap-2 w-full">
    <label [for]="ctrlLabel" *ngIf="showLabel"
      >{{ ctrlLabel }}
    </label>
    <div class="p-input-icon-left" *ngIf="formCtrlIcon">
      <i [class]="formCtrlIcon"></i>
      <textarea
      pInputText
      pAutoFocus
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [rows]="rows"
      [placeholder]="ctrlPlaceholder"
      autocomplete="off"
      [ngClass]="
        formCtrlIcon ? 'pl-5 w-full' + formCtrlClass : formCtrlClass
      "
      [autofocus]="shouldFocus"
    ></textarea>
      <ng-container *ngIf="!hideErrors">
        <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
        <atlas-error-email [formCtrl]="formCtrl"></atlas-error-email>
        <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
      </ng-container>
    </div>
  </div>
  