<p-sidebar [(visible)]="showSidebar" position="right" styleClass="w-27rem">
  <ng-template pTemplate="header">
    <div class="text-2xl font-semibold">Detalji ugovora</div>
  </ng-template>
  <ng-container *ngIf="previewData">
    <div class="flex flex-column gap-3">
      <div class="flex justify-content-between align-items-center">
        <img
          ngSrc="assets/images/user.svg"
          width="48"
          height="48"
          alt="user-image"
        />
        <div class="flex flex-column gap-2">
          <div class="label-sm">Broj ugovora</div>
          <div class="label-sm-medium">{{ previewData.orderNum }}</div>
        </div>
      </div>
      <div class="text-3xl font-semibold">{{ previewData.client }}</div>
      <div class="label-md-medium">{{ previewData.address }}</div>
      <div class="flex justify-content-between">
        <div class="flex align-items-center gap-2">
          <img
            ngSrc="assets/images/phone.svg"
            width="16"
            height="16"
            alt="user-image"
          />
          <div class="label-sm-medium">{{ previewData.phone }}</div>
        </div>
        <div class="flex align-items-center gap-2">
          <img
            ngSrc="assets/images/email.svg"
            width="16"
            height="16"
            alt="user-image"
          />
          <div class="label-sm-medium">{{ previewData.email }}</div>
        </div>
      </div>
    </div>

    <p-divider></p-divider>

    <div class="flex flex-column gap-4">
      <div class="flex justify-content-between">
        <div class="text-2xl font-semibold">Paket</div>
        <img
          *ngIf="!previewData.isActive"
          ngSrc="/assets/images/package-inactive.svg"
          alt="status-image"
          width="83"
          height="24"
        />
        <img
          *ngIf="previewData.isActive"
          ngSrc="/assets/images/package-active.svg"
          alt="status-image"
          width="83"
          height="24"
        />
      </div>

      <div class="flex flex-column gap-3 package-box">
        <div class="flex gap-3 align-items-center">
          <img
            ngSrc="assets/images/package.svg"
            width="40"
            height="40"
            alt="user-image"
          />
          <div class="flex flex-column justify-content-between gap-1">
            <div class="label-sm">Vrsta paketa</div>
            <div class="label-md-medium">{{ previewData.package }}</div>
          </div>
        </div>

        <ng-container
          *ngTemplateOutlet="previewData.isActive ? packageButton : cashButton"
        ></ng-container>
      </div>

      <div class="label-sm">Dostupne asistencije</div>
      <div class="flex gap-4">
        <atlas-service-availability
          *ngFor="let service of previewData.availableServices"
          [serviceName]="service.name"
          [isAvailable]="service.isAvailable"
        ></atlas-service-availability>

        <atlas-service-availability
          *ngFor="let service of previewData.usedServices"
          [serviceName]="service.name"
          [isAvailable]="service.isAvailable"
        ></atlas-service-availability>
      </div>
      <div class="flex justify-content-between align-items-center">
        <div class="flex flex-column justify-content-between gap-2">
          <div class="label-sm">Početak ugovora</div>
          <div class="label-sm-medium">{{ previewData.start }}</div>
        </div>
        <div>
          <i class="pi pi-arrow-right"></i>
        </div>
        <div class="flex flex-column justify-content-between gap-2">
          <div class="label-sm">Kraj ugovora</div>
          <div class="label-sm-medium">{{ previewData.end }}</div>
        </div>
      </div>
    </div>

    <p-divider></p-divider>
    <div class="flex flex-column gap-3">
      <div class="text-2xl font-semibold">Vozilo</div>
      <div class="flex justify-content-between">
        <div class="label-sm">Model</div>
        <div class="label-sm-medium">{{ previewData.vehicleModel }}</div>
      </div>
      <div class="flex justify-content-between">
        <div class="label-sm">Tablice</div>
        <div class="label-sm-medium">{{ previewData.licencePlate }}</div>
      </div>
      <div class="flex justify-content-between">
        <div class="label-sm">Šasija</div>
        <div class="label-sm-medium">{{ previewData.chassis }}</div>
      </div>
    </div>
  </ng-container>
</p-sidebar>

<ng-template #packageButton>
  <atlas-button
    btnLabel="Započni asistenciju"
    btnClass="w-full"
    [btnSeverity]="ButtonSeverity.PRIMARY"
    [btnStyle]="ButtonStyle.BASIC"
    (btnClicked)="handleCreateForPackage()"
    [isBtnDisabled]="isReadonly"
    [btnTooltip]="isReadonly ? 'Nemate prava pristupa' : undefined"
  >
    <img
      ngSrc="assets/images/buttons/assistance.svg"
      width="20"
      height="20"
      alt="action-image"
    />
  </atlas-button>
</ng-template>

<ng-template #cashButton>
  <atlas-button
    btnLabel="Započni asistenciju za keš"
    btnClass="w-full"
    [btnSeverity]="ButtonSeverity.SECONDARY"
    [btnStyle]="ButtonStyle.BASIC"
    (btnClicked)="handleCreateForCash()"
    [isBtnDisabled]="isReadonly"
    [btnTooltip]="isReadonly ? 'Nemate prava pristupa' : undefined"
  >
    <img
      ngSrc="assets/images/buttons/assistance.svg"
      width="20"
      height="20"
      alt="action-image"
    />
  </atlas-button>
</ng-template>
