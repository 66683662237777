<div class="bottom-panel" *ngIf="hasSelectedPackages">
  <div class="panel-content">
    <p class="package-selection">
      Selektovano:
      <span class="highlight-number">{{ selectedPackages.length }}</span>
      {{ selectedPackages.length === 1 ? "paket" : "paketa" }}
    </p>

    <div class="action-buttons">
      <p-button
        (onClick)="handleAgentAssignment()"
        icon="pi pi-user-plus"
        label="Dodeli agenta"
        class="custom-btn"
      ></p-button>
      <p-button
        (onClick)="handleSellerChange()"
        icon="pi pi-user-edit"
        label="Promeni prodavca"
        class="custom-btn ml-2"
      ></p-button>
    </div>
  </div>

  <div class="divider"></div>
  <p-button
    icon="pi pi-refresh"
    label="Obnovi pakete"
    (onClick)="handleRenewPackages()"
    class="last-button custom-btn"
    pTooltip="Selektovani paketi sadrže {{ distinctVehicleCount }} {{
      distinctVehicleCount === 1 ? 'različito vozilo' : 'različita vozila'
    }} "
    tooltipPosition="right"
    [disabled]="distinctVehicleCount > 1"
  >
  </p-button>
</div>
