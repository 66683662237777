<div *ngIf="paginatedData">
  <div class="header-container">
    <div class="page-label">
      <!-- Naslov -->
      <div class="title-container">
        <span class="text-3xl font-semibold mt-1"
          >{{ pageLabel }} {{ paginatedData?.caseNumber }}</span
        >
        <img
          *ngIf="paginatedData.status === 4"
          ngSrc="/assets/images/badge1.svg"
          alt="status-image"
          width="83"
          height="23"
          class="ml-3 margin-badge"
        />
        <img
          *ngIf="paginatedData.status === 2"
          ngSrc="/assets/images/badge-canceled.svg"
          alt="status-image"
          width="83"
          height="23"
          class="ml-3 margin-badge"
        />
        <img
          *ngIf="paginatedData.status === 3"
          ngSrc="/assets/images/badge-finished.svg"
          alt="status-image"
          width="83"
          height="23"
          class="ml-3 margin-badge"
        />
        <div
          *ngIf="paginatedData.status === 1"
          class="custom-badge ml-3 margin-badge"
        >
          <span class="status-dot"></span>
          <span class="status-text">Nova</span>
        </div>
      </div>
    </div>

    <div class="actions">
      <span
        *ngIf="paginatedData.status === 1 || paginatedData.status === 4"
        (click)="editAssistance()"
        class="edit-assistance pointer"
      >
        <i class="pi pi-pencil"></i>Izmeni
      </span>
      <span
        *ngIf="paginatedData.status !== 2"
        (click)="cancelAssistance()"
        class="cancel-button pointer"
      >
        <i class="pi pi-ban"></i> Otkaži
      </span>
      <span style="margin-left: 1.5rem">
        <i class="pi pi-ellipsis-v"></i>
      </span>
    </div>
  </div>
  <p-divider></p-divider>
  <p-scrollPanel [style]="{ width: '100%', height: 'calc(112dvh - 290px)' }">
    <div class="wraper flex flex-row mt-4">
      <div class="left-side">
        <div class="text-lg font-semibold">Detalji</div>

        <div class="flex flex-column md:flex-row box-gap">
          <atlas-assistance-box
            [cardTitle]="'Paket'"
            [cardImageSrc]="'assets/images/package.svg'"
            [cardNumber]="paginatedData?.packageName"
            class="flex-1"
          ></atlas-assistance-box>
          <atlas-assistance-box
            cardTitle="Kreirana"
            [cardNumber]="paginatedData?.created"
            cardIcon="pi-calendar"
            class="flex-1"
          ></atlas-assistance-box>
          <atlas-assistance-box
            [cardTitle]="'Agent'"
            [cardNumber]="paginatedData?.agent"
            cardIcon="pi-user"
            class="flex-1"
          ></atlas-assistance-box>
        </div>

        <div class="flex flex-column md:flex-row box-gap mt-px">
          <div class="rounded-box flex-1">
            <div class="header-box">Klijent</div>
            <div class="content mt-3">
              <div class="field mt-4">
                <i class="pi pi-user"></i>
                <span class="box-title ml-1">Ime klijenta</span>
                <span class="box-desc">{{
                  paginatedData?.client || "--"
                }}</span>
              </div>
              <div class="field mt-4">
                <i class="pi pi-phone"></i>
                <span class="box-title ml-1">Kontakt telefon</span>
                <span class="box-desc">{{
                  paginatedData?.clientPhone || "--"
                }}</span>
              </div>
              <div class="field mt-4">
                <i class="pi pi-car"></i>
                <span class="box-title ml-1">Vozilo</span>
                <span class="box-desc">{{
                  paginatedData?.clientVehicle || "--"
                }}</span>
              </div>
              <div class="field mt-4">
                <img src="assets/images/plate-icon.svg" class="icon" />
                <span class="box-title ml-1">Reg. oznake</span>
                <span class="box-desc">{{
                  paginatedData?.clientLicencePlates || "--"
                }}</span>
              </div>
            </div>
          </div>
          <div class="rounded-box flex-1">
            <div class="header-box">Saradnik</div>
            <div class="content">
              <div class="field mt-4">
                <i class="pi pi-user"></i>
                <span class="box-title ml-1">Ime saradnika</span>
                <span class="box-desc">{{
                  paginatedData?.partner || "--"
                }}</span>
              </div>
              <div class="field mt-4">
                <i class="pi pi-phone"></i>
                <span class="box-title ml-1">Kontakt telefon</span>
                <span class="box-desc">{{
                  paginatedData?.partnerPhone || "--"
                }}</span>
              </div>
              <div class="field mt-4">
                <i class="pi pi-car"></i>
                <span class="box-title ml-1">Vozilo</span>
                <span class="box-desc">{{
                  paginatedData?.partnerVehicle || "--"
                }}</span>
              </div>
              <div class="field mt-4">
                <img
                  src="assets/images/plate-icon.svg"
                  alt="Licence Plate Icon"
                  class="icon"
                />
                <span class="box-title ml-1">Reg. oznake</span>
                <span class="box-desc">{{
                  paginatedData?.partnerLicencePlates || "--"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-lg font-semibold assistance-div">Asistencija</div>
        <div class="field mt-4">
          <span class="box-title ml-1">Vrsta kvara</span>
          <span class="box-desc">{{ paginatedData?.failureType || "--" }}</span>
        </div>
        <div class="field mt-4">
          <span class="box-title ml-1">Opis</span>
          <span class="box-desc">{{
            paginatedData?.failureDescription || "--"
          }}</span>
        </div>
        <div class="field mt-4">
          <span class="box-title ml-1">Broj putnika</span>
          <span class="box-desc">{{
            paginatedData?.numOfPassengers || "--"
          }}</span>
        </div>
      </div>
      <!-- DESNA STRANA -->
      <div class="right-side ml-px">
        <div class="contact-card border-round-xl p-4">
          <div class="contact-header mb-4">
            <div class="font-semibold text-lg line-height-4">Putanja</div>
          </div>
          <div class="contact-details">
            <div class="loction">
              <!-- PARTNERS MAP -->
              <div class="mb-4 flex flex-column lg:flex-row gap-4">
                <div>
                  <google-map #gmap [options]="mapOptions">
                    <ng-container
                      *ngIf="directionsResults$ | async as directionsResults"
                    >
                      <map-directions-renderer
                        [directions]="directionsResults"
                        [options]="directionOptions"
                      >
                      </map-directions-renderer>
                    </ng-container>
                  </google-map>
                </div>
              </div>
            </div>
            <div class="flex align-items-top gap-2 location-item">
              <span class="dot mr-2">
                <i class="pi pi-check" style="color: green"></i>
              </span>
              <div class="location">
                <div class="right-side-title">Saradnik</div>
                <span class="box-desc mt-2">
                  {{ paginatedData?.driverStartLocation?.place || "--" }}
                </span>
                <div class="mt-2 flex gap-1">
                  <i class="pi pi-clock"></i>
                  <span class="box-desc">{{
                    paginatedData?.assignedToDriverTime || "--:--"
                  }}</span>
                </div>
              </div>
            </div>
            <div class="flex align-items-top gap-2 location-item no-dot-border">
              <span class="dot mr-2">
                <i class="pi pi-check" style="color: green"></i>
              </span>
              <div class="location">
                <div class="right-side-title">Mesto kvara (A)</div>
                <span class="box-desc mt-2">
                  {{ paginatedData?.failureLocation?.place || "--" }}
                </span>
                <div class="mt-2 flex gap-1">
                  <i class="pi pi-clock"></i>
                  <span class="box-desc">{{
                    paginatedData?.driverFailureImagesTime || "--:--"
                  }}</span>
                </div>
                <atlas-image-preview
                  [images]="paginatedData?.failureImages"
                  imageClass="mt-2"
                ></atlas-image-preview>
                <div
                  (click)="showComment()"
                  *ngIf="paginatedData.failureComment"
                  class="comments pointer"
                >
                  <i class="pi pi-comment"></i> 1 komentar
                </div>
              </div>
            </div>
            <div
              class="flex align-items-top gap-bigger location-item no-dot-border"
            >
              <span *ngIf="paginatedData.status === 4" class="dot mr-2">
                <img [src]="'assets/images/circle.svg'" />
              </span>
              <div *ngIf="paginatedData.status === 4" class="location">
                <div
                  class="right-side-title font-semibold"
                  style="color: black"
                >
                  Odredište (B)
                </div>
                <span class="box-desc mt-2">
                  {{ paginatedData?.destinationLocation?.place || "--" }}
                </span>
                <div class="mt-2 flex gap-1">
                  <i class="pi pi-clock"></i>
                  <span class="box-desc">{{
                    paginatedData?.driverEndTime || "--:--"
                  }}</span>
                </div>
                <atlas-image-preview
                  [images]="paginatedData?.destinationImages"
                  imageClass="mt-2"
                ></atlas-image-preview>
              </div>
            </div>
            <div class="flex align-items-top gap-2 location-item no-dot-border">
              <span *ngIf="paginatedData.status !== 4" class="dot mr-2">
                <i class="pi pi-check" style="color: green"></i>
              </span>
              <div *ngIf="paginatedData.status !== 4" class="location">
                <div class="right-side-title">Odredište (B)</div>
                <span class="box-desc mt-2">
                  {{ paginatedData?.destinationLocation?.place }}
                </span>
                <div class="mt-2">
                  <i class="pi pi-clock"></i>
                  <span class="box-desc">{{
                    paginatedData?.driverEndTime || "--:--"
                  }}</span>
                </div>
                <atlas-image-preview
                  [images]="paginatedData?.destinationImages"
                  imageClass="mt-2"
                ></atlas-image-preview>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-scrollPanel>
</div>

<p-dialog
  header="Failure Comment"
  [(visible)]="displayComment"
  [modal]="true"
  [closable]="true"
  [dismissableMask]="true"
  [draggable]="false"
>
  <ng-template pTemplate="content">
    <div class="dialog-content">
      {{ paginatedData.failureComment }}
    </div>
  </ng-template>
</p-dialog>
