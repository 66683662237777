import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NewVehicleService {
  constructor(private _fb: FormBuilder) {}

  //#region Brand name

  public defaultBrandName: string = 'Marka vozila';
  private _brandNameCtrl: FormControl<string | undefined>;

  setBrandNameCtrl(brandName: string | undefined): void {
    if (!this._brandNameCtrl) {
      this._brandNameCtrl = this._fb.control<string | undefined>(brandName);
    } else {
      this._brandNameCtrl.patchValue(brandName);
    }
  }

  getBrandNameCtrl(): FormControl {
    return this._brandNameCtrl;
  }

  setBrandNameRequired() {
    this._brandNameCtrl.setValidators([Validators.required]);
    this._brandNameCtrl.updateValueAndValidity();
  }

  setBrandNameNonRequired() {
    this._brandNameCtrl.clearValidators();
    this._brandNameCtrl.updateValueAndValidity();
  }

  shouldResetBrandInput$: BehaviorSubject<boolean> =
      new BehaviorSubject<boolean>(false);

  resetBrandCtrlValues() {
    this._brandNameCtrl.patchValue(undefined);
    this._brandNameCtrl.markAsUntouched();
    this.shouldResetBrandInput$.next(true);
  }

  //#endregion Brand name

  //#region Model name

  public defaultModelName: string = 'Model vozila';
  private _modelNameCtrl: FormControl<string | undefined>;

  setModelNameCtrl(modelName: string | undefined): void {
    if (!this._modelNameCtrl) {
      this._modelNameCtrl = this._fb.control<string | undefined>(modelName);
    } else {
      this._modelNameCtrl.patchValue(modelName);
    }
  }

  getModelNameCtrl(): FormControl {
    return this._modelNameCtrl;
  }

  setModelNameRequired() {
    this._modelNameCtrl.setValidators([Validators.required]);
    this._modelNameCtrl.updateValueAndValidity();
  }

  setModelNameNonRequired() {
    this._modelNameCtrl.clearValidators();
    this._modelNameCtrl.updateValueAndValidity();
  }

  markModelNameCtrlAsTouched() {
    this._modelNameCtrl.markAsTouched();
  }

  shouldResetModelInput$: BehaviorSubject<boolean> =
      new BehaviorSubject<boolean>(false);

  resetModelCtrlValues() {
    this._modelNameCtrl.patchValue(undefined);
    this._modelNameCtrl.markAsUntouched();
    this.shouldResetModelInput$.next(true);
  }

  //#endregion Model name

  //#region Common

  markAsTouched() {
    this._brandNameCtrl.markAsTouched();
    this._modelNameCtrl.markAsTouched();
  }

  resetCtrl() {
    this._brandNameCtrl = undefined;
    this._modelNameCtrl = undefined;
  }


  resetCtrlValues() {
    this.resetBrandCtrlValues();
    this.resetModelCtrlValues();
  }

  //#endregion Common
}
