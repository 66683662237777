import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponent, ButtonStyle } from '../../button/button.component';
import { InputTextComponent } from '../../input-text/input-text.component';
import { NgForOf } from '@angular/common';
import { FormControl } from '@angular/forms';
import { TableAction, TableConfig } from '../table-common';
import { FormControlPipe } from '../../../shared/pipes/form-control.pipe';

@Component({
  selector: 'atlas-table-header',
  standalone: true,
  imports: [ButtonComponent, InputTextComponent, NgForOf, FormControlPipe],
  templateUrl: './table-header.component.html',
  styleUrl: './table-header.component.scss',
})
export class TableHeaderComponent implements OnInit {
  BASIC: ButtonStyle = ButtonStyle.BASIC;

  search: FormControl<string | null>;
  tableActions: TableAction[];

  @Input() searchLabel: string;
  @Input() tableConfig!: TableConfig;

  constructor() {
    this.searchLabel = 'Search items';
    this.search = new FormControl<string | null>(null);
    this.tableActions = [];
  }

  ngOnInit() {
    this._setTableActions();
  }

  private _setTableActions() {
    if (
      !this.tableConfig.headerActions ||
      this.tableConfig.headerActions.length === 0
    ) {
      return;
    }

    this.tableActions = this.tableConfig.headerActions
      ?.filter((a) => a.isVisible !== false)
      ?.map((a) => {
        // const hasAccess =
        //   !a.tipPristupa ||
        //   this._authService.hasClaim(TipPristupa[a.tipPristupa]);
        const hasAccess = true;

        return {
          mode: a.mode,
          isAvailable: hasAccess,
          icon: a.icon,
          label: a.label,
          actionClass: a.actionClass,
          tooltip: hasAccess ? a.hasAccessTooltip : a.noAccessTooltip,
          callback: (request?: any) =>
            hasAccess ? a.callback!(request) : null,
        };
      });
  }
}
