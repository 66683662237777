<div class="dialog-container">
    <p class="label-txt">Prodavac</p>
    <div class="agent-info">
        <img [src]="agentImageUrl" alt="Agent Image" class="agent-img" />
        <span class="agent-name">{{ agentName }}</span>
    </div>

     <div class="separator-container">
        <hr class="separator-line" />
        <div class="separator-circle">
            <i class="pi pi-chevron-down"></i>
        </div>
    </div>

    <p class="label-txt">Promeni</p>
    <atlas-client-autocomplete (agentSelected)="onAgentSelected($event)"></atlas-client-autocomplete>
</div>
