import {mapToCanMatch, Routes} from '@angular/router';
import { administratorRoutes } from '@app/administrator/administrator.routes';
import { sosAgentRoutes } from '@app/sos-agent/sos-agent.routes';
import { CanMatchAdmin } from '@app/can-match/can-match-admin';
import { CanMatchSosAgent } from '@app/can-match/can-match-sos-agent';
import { financeAdminRoutes } from '@app/finance-administrator/finance-admin.routes';
import { CanMatchFinanceAdmin } from '@app/can-match/can-match-finance-admin';
import {ccAgentRoutes} from "@app/cc-agent/cc-agent.routes";
import {CanMatchCcAgent} from "@app/can-match/can-match-cc-agent";

export const appLayoutRoutes: Routes = [
  {
    /** Administrator role */
    path: 'admin',
    canMatch: [CanMatchAdmin],
    children: [...administratorRoutes],
  },
  {
    /** SOS Agent role */
    path: 'sos',
    data: {
      breadcrumb: 'sos',
    },
    canMatch: [CanMatchSosAgent],
    children: [...sosAgentRoutes],
  },
  {
    /** Finance Admin role */
    path: 'finance',
    data: {
      breadcrumb: 'finance',
    },
    canMatch: [CanMatchFinanceAdmin],
    children: [...financeAdminRoutes],
  },
  {
    /** CC Agent role */
    path: 'cc',
    data: {
      breadcrumb: 'cc',
    },
    canMatch: [CanMatchCcAgent],
    children: [...ccAgentRoutes],
  },
];
