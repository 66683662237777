import { CommonModule } from '@angular/common';
import { Component, Input, input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PackagesClient } from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { InputSwitchComponent } from '@ui/input-switch/input-switch.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-package-card',
  standalone: true,
  imports: [CommonModule, InputSwitchComponent, InputSwitchModule, FormsModule],
  templateUrl: './package-card.component.html',
  styleUrl: './package-card.component.scss',
})
export class PackageCardComponent implements OnInit, OnDestroy {
  @Input() package!: any;
  private _subs: Subscription = new Subscription();

  constructor(
    private _packageClient: PackagesClient,
    private _alertService: AlertService,

  ) {}

  ngOnInit(): void {}

  changePackageStatus(){
    event.stopPropagation();
    this._subs.add(
      this._packageClient
        .enableDisablePackage(this.package.id)
        .subscribe((res) => {
          this._alertService.addSuccessMsg('Status paketa promenjen');
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
