import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BlockUIModule} from "primeng/blockui";
import {NgIf} from "@angular/common";
import {Subscription} from "rxjs";
import {LoaderService} from "./loader.service";

@Component({
    selector: 'atlas-loader',
    standalone: true,
    imports: [
        BlockUIModule,
        NgIf
    ],
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit, OnDestroy {
    @Input() content: any;
    @Input() showLoader: boolean;
    @Input() loadingText: string;
    @Input() showError: boolean;
    @Input() errorText: string;
    private _subs: Subscription = new Subscription();

    constructor(private _service: LoaderService) {
        this.content = '';
        this.showLoader = false;
        this.loadingText = '';
        this.showError = false;
        this.errorText = '';
    }

    ngOnChanges(changes: SimpleChanges): void {
        // @ts-ignore
        if (changes.showLoader.currentValue != changes.showLoader.previousValue) {
            // @ts-ignore
            this.showLoader = changes.showLoader.currentValue;
        }
    }

    ngOnInit(): void {
        this._service.getShowLoader.subscribe((showLoader) => {
            this.showLoader = showLoader;
        });

        if (!this.loadingText) {
            this._subs.add(
                this._service.getLoadingText.subscribe((text) => {
                    this.loadingText = text;
                })
            );
        }

        if (!this.errorText) {
            this._subs.add(
                this._service.getErrorText.subscribe((text) => {
                    this.errorText = text;
                })
            );
        }
    }

    ngOnDestroy(): void {
        this._subs.unsubscribe();
    }
}
