import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import { IPhoneItem, PhoneItem } from '@shared/model/atlas.api';
import { Guid } from 'guid-typescript';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(
    private _fb: FormBuilder
  ) {}

  private _phonesSubject = new BehaviorSubject<FormArray<FormGroup<ControlsOf<PhoneItem>>>>(
    new FormArray<FormGroup<ControlsOf<PhoneItem>>>([])
  );

  phones$ = this._phonesSubject.asObservable();

  getPhones(): FormArray<FormGroup<ControlsOf<PhoneItem>>> {
    return this._phonesSubject.value;
  }

  setPhones(phones: FormArray<FormGroup<ControlsOf<PhoneItem>>>) {
    this._phonesSubject.next(phones);
  }

  pushPhone(phone: PhoneItem) {
    this._phonesSubject.value.push(this.getPhoneForm(phone));
    this._phonesSubject.next(this._phonesSubject.value);
  }

  removePhone(idx: number) {
    this._phonesSubject.value.removeAt(idx);
    this._phonesSubject.next(this._phonesSubject.value);
  }

  resetPhones() {
    this._phonesSubject.next(new FormArray<FormGroup<ControlsOf<PhoneItem>>>([]));
  }

  getPhonesArray(model?: PhoneItem[]): FormArray<FormGroup<ControlsOf<PhoneItem>>> {
    const result = this._fb.array<FormGroup<ControlsOf<PhoneItem>>>(
      model?.length > 0 ? [] : [this.getPhoneForm()],
    );

    if (model) {
      model.forEach((s) => {
        result.push(this.getPhoneForm(s));
      });
    }

    return result;
  }

  handleNewInput(phone: PhoneItem) {
    const idx = this._phonesSubject.value.controls.findIndex((x) => x.value.id === phone.id);

    if (idx >= 0) {
      this._phonesSubject.value.controls[idx].patchValue(phone);
      this._phonesSubject.next(this._phonesSubject.value);
    } else {
      phone.id = Guid.create().toString();
      phone.label = phone.label?.trim();
      this.pushPhone(phone);
    }
  }

  getPhoneForm(model?: PhoneItem): FormGroup<ControlsOf<PhoneItem>> {
    return this._fb.group<ControlsOf<PhoneItem>>({
      label: this._fb.control(model?.label ?? '', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(64)],
      }),
      id: this._fb.control(model?.id ?? Guid.create().toString(), {
        nonNullable: true,
      }),
      init: undefined,
      toJSON: undefined
    });
  }

}

export declare type PhonesForm = FormGroup<ControlsOf<PhoneItem>>;