<div class="flex flex-column gap-2">
  <div>
    <atlas-input-password
      [formCtrl]="form.controls.password | formControl"
      ctrlLabel="Lozinka"
      formCtrlId="password"
    ></atlas-input-password>
  </div>
  <div>
    <atlas-input-password
      [formCtrl]="form.controls.confirmedPassword | formControl"
      ctrlLabel="Ponovite lozinku"
      formCtrlId="confirmedPassword"
      appendTo="body"
    ></atlas-input-password>
  </div>
</div>
