import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { routes } from '@app/app.routes';
import { ControlsOf } from '@shared/forms/forms-common';
import { ClientPackageCardItem, DeliveryType, IClientPackageCardItem, IRenewPackageCommand, PaymentType, RenewPackageCommand } from '@shared/model/atlas.api';
import { retry } from 'rxjs';
import { PackageCardListService } from './package-card-list.service';

@Injectable({
  providedIn: 'root'
})
export class OrderFormService {

  renewPackageForm: RenewPackageForm
  packageCardForm: IClientPackageCardItem

  constructor(
    private _fb: FormBuilder,
    private _packageCardService: PackageCardListService
  ) { }

  get getRenewPackageForm(): RenewPackageForm {
    if (!this.renewPackageForm) {
      this.initRenewPackageForm();
    }
    return this.renewPackageForm;
  }

  initRenewPackageForm(model?: IRenewPackageCommand) {
    this.renewPackageForm = this._fb.group<ControlsOf<IRenewPackageCommand>>({
      clientPackageId: this._fb.control(model?.clientPackageId, {
        nonNullable: false,
        validators: [Validators.required]
      }),
      package: this._packageCardService.initPackageCardForm(model?.package),
      deliveryType: this._fb.control(DeliveryType.E_CONTRACT, {
        nonNullable: false,
        validators: [Validators.required]
      }),
      paymentType: this._fb.control(PaymentType.CASH, {
        nonNullable: false,
        validators: [Validators.required]
      }),
      isUrgent: this._fb.control(model?.isUrgent, {
        nonNullable: false,
        validators: [Validators.required]
      })
    });
    return this.renewPackageForm;
  }


}

export declare type RenewPackageForm = FormGroup<ControlsOf<IRenewPackageCommand>>