<div class="flex wrapper" [class.selected]="isSelected" (click)="onSelect()">
    <div class="icon-wrap" [class.icon-selected]="isSelected">
      <i [ngClass]="[iconClass, 'icon']"></i>
    </div>
    <div class="container-checkbox">
      <div class="label ml-3">{{ name }}</div>
    </div>
    <div class="checkbox-div">
      <div class="checkbox ml-3">
        <p-checkbox [binary]="true" [ngModel]="isSelected"></p-checkbox>
      </div>
    </div>
  </div>
  