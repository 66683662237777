import {Component} from '@angular/core';

@Component({
    selector: 'atlas-sos-settings',
    standalone: true,
    imports: [],
    templateUrl: './sos-settings.component.html',
    styleUrl: './sos-settings.component.scss',
})
export class SosSettingsComponent {
}
