<div class="flex flex-column gap-3">
  <div class="flex flex-column gap-2">
    <label>Status uplate</label>
    <atlas-payment-status-dropdown
      [statusCtrl]="statusCtrl | formControl"
    ></atlas-payment-status-dropdown>
  </div>

  <atlas-calendar
    ctrlLabel="Datum aktivacije"
    [formCtrl]="activationDateCtrl"
  ></atlas-calendar>
</div>
