export  const activePartnerSvg = '<svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<rect width="32" height="32" rx="8" fill="#1E7EC3"/>\n' +
    '<path d="M17.333 12.6665H18.8912C19.0542 12.6665 19.1358 12.6665 19.2125 12.6849C19.2805 12.7013 19.3455 12.7282 19.4052 12.7647C19.4725 12.806 19.5301 12.8636 19.6454 12.9789L22.3539 15.6874C22.4692 15.8027 22.5269 15.8604 22.5681 15.9276C22.6047 15.9873 22.6316 16.0523 22.6479 16.1203C22.6663 16.1971 22.6663 16.2786 22.6663 16.4417V18.3332C22.6663 18.6438 22.6663 18.7991 22.6156 18.9216C22.5479 19.085 22.4181 19.2148 22.2548 19.2824C22.1323 19.3332 21.977 19.3332 21.6663 19.3332M18.333 19.3332H17.333M17.333 19.3332V12.7998C17.333 12.0531 17.333 11.6797 17.1877 11.3945C17.0599 11.1436 16.8559 10.9397 16.605 10.8118C16.3198 10.6665 15.9464 10.6665 15.1997 10.6665H11.4663C10.7196 10.6665 10.3462 10.6665 10.061 10.8118C9.81014 10.9397 9.60616 11.1436 9.47833 11.3945C9.33301 11.6797 9.33301 12.0531 9.33301 12.7998V17.9998C9.33301 18.7362 9.92996 19.3332 10.6663 19.3332M17.333 19.3332H14.6663M14.6663 19.3332C14.6663 20.4377 13.7709 21.3332 12.6663 21.3332C11.5618 21.3332 10.6663 20.4377 10.6663 19.3332M14.6663 19.3332C14.6663 18.2286 13.7709 17.3332 12.6663 17.3332C11.5618 17.3332 10.6663 18.2286 10.6663 19.3332M21.6663 19.6665C21.6663 20.587 20.9201 21.3332 19.9997 21.3332C19.0792 21.3332 18.333 20.587 18.333 19.6665C18.333 18.746 19.0792 17.9998 19.9997 17.9998C20.9201 17.9998 21.6663 18.746 21.6663 19.6665Z" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M17.2002 10.7998H9.6002L9.2002 11.5998V17.9998C9.2002 18.3998 10.0002 19.1998 10.8002 19.1998C10.8002 18.3998 11.2002 17.1998 12.8002 17.1998C14.0802 17.1998 14.6669 18.5331 14.8002 19.1998H17.2002V10.7998Z" fill="white"/>\n' +
    '<path d="M17.6002 16.3998H22.4002V19.1998H21.6002C21.4669 18.7998 20.9602 17.9998 20.0002 17.9998C19.0402 17.9998 18.5335 18.7998 18.4002 19.1998H17.6002V16.3998Z" fill="white"/>\n' +
    '<path d="M16 36L21.1962 31.5H10.8038L16 36Z" fill="#1E7EC3"/>\n' +
    '</svg>\n';

export const potentialPartnerSvg = '<svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<rect width="32" height="32" rx="8" fill="#808080"/>\n' +
    '<path d="M17.333 12.6665H18.8912C19.0542 12.6665 19.1358 12.6665 19.2125 12.6849C19.2805 12.7013 19.3455 12.7282 19.4052 12.7647C19.4725 12.806 19.5301 12.8636 19.6454 12.9789L22.3539 15.6874C22.4692 15.8027 22.5269 15.8604 22.5681 15.9276C22.6047 15.9873 22.6316 16.0523 22.6479 16.1203C22.6663 16.1971 22.6663 16.2786 22.6663 16.4417V18.3332C22.6663 18.6438 22.6663 18.7991 22.6156 18.9216C22.5479 19.085 22.4181 19.2148 22.2548 19.2824C22.1323 19.3332 21.977 19.3332 21.6663 19.3332M18.333 19.3332H17.333M17.333 19.3332V12.7998C17.333 12.0531 17.333 11.6797 17.1877 11.3945C17.0599 11.1436 16.8559 10.9397 16.605 10.8118C16.3198 10.6665 15.9464 10.6665 15.1997 10.6665H11.4663C10.7196 10.6665 10.3462 10.6665 10.061 10.8118C9.81014 10.9397 9.60616 11.1436 9.47833 11.3945C9.33301 11.6797 9.33301 12.0531 9.33301 12.7998V17.9998C9.33301 18.7362 9.92996 19.3332 10.6663 19.3332M17.333 19.3332H14.6663M14.6663 19.3332C14.6663 20.4377 13.7709 21.3332 12.6663 21.3332C11.5618 21.3332 10.6663 20.4377 10.6663 19.3332M14.6663 19.3332C14.6663 18.2286 13.7709 17.3332 12.6663 17.3332C11.5618 17.3332 10.6663 18.2286 10.6663 19.3332M21.6663 19.6665C21.6663 20.587 20.9201 21.3332 19.9997 21.3332C19.0792 21.3332 18.333 20.587 18.333 19.6665C18.333 18.746 19.0792 17.9998 19.9997 17.9998C20.9201 17.9998 21.6663 18.746 21.6663 19.6665Z" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M17.2002 10.7998H9.6002L9.2002 11.5998V17.9998C9.2002 18.3998 10.0002 19.1998 10.8002 19.1998C10.8002 18.3998 11.2002 17.1998 12.8002 17.1998C14.0802 17.1998 14.6669 18.5331 14.8002 19.1998H17.2002V10.7998Z" fill="white"/>\n' +
    '<path d="M17.6002 16.3998H22.4002V19.1998H21.6002C21.4669 18.7998 20.9602 17.9998 20.0002 17.9998C19.0402 17.9998 18.5335 18.7998 18.4002 19.1998H17.6002V16.3998Z" fill="white"/>\n' +
    '<path d="M16 36L21.1962 31.5H10.8038L16 36Z" fill="#808080"/>\n' +
    '</svg>\n';

export const boschPartenerSvg = '<svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<rect width="32" height="32" rx="8" fill="#1EB9C3"/>\n' +
    '<path d="M17.333 12.6665H18.8912C19.0542 12.6665 19.1358 12.6665 19.2125 12.6849C19.2805 12.7013 19.3455 12.7282 19.4052 12.7647C19.4725 12.806 19.5301 12.8636 19.6454 12.9789L22.3539 15.6874C22.4692 15.8027 22.5269 15.8604 22.5681 15.9276C22.6047 15.9873 22.6316 16.0523 22.6479 16.1203C22.6663 16.1971 22.6663 16.2786 22.6663 16.4417V18.3332C22.6663 18.6438 22.6663 18.7991 22.6156 18.9216C22.5479 19.085 22.4181 19.2148 22.2548 19.2824C22.1323 19.3332 21.977 19.3332 21.6663 19.3332M18.333 19.3332H17.333M17.333 19.3332V12.7998C17.333 12.0531 17.333 11.6797 17.1877 11.3945C17.0599 11.1436 16.8559 10.9397 16.605 10.8118C16.3198 10.6665 15.9464 10.6665 15.1997 10.6665H11.4663C10.7196 10.6665 10.3462 10.6665 10.061 10.8118C9.81014 10.9397 9.60616 11.1436 9.47833 11.3945C9.33301 11.6797 9.33301 12.0531 9.33301 12.7998V17.9998C9.33301 18.7362 9.92996 19.3332 10.6663 19.3332M17.333 19.3332H14.6663M14.6663 19.3332C14.6663 20.4377 13.7709 21.3332 12.6663 21.3332C11.5618 21.3332 10.6663 20.4377 10.6663 19.3332M14.6663 19.3332C14.6663 18.2286 13.7709 17.3332 12.6663 17.3332C11.5618 17.3332 10.6663 18.2286 10.6663 19.3332M21.6663 19.6665C21.6663 20.587 20.9201 21.3332 19.9997 21.3332C19.0792 21.3332 18.333 20.587 18.333 19.6665C18.333 18.746 19.0792 17.9998 19.9997 17.9998C20.9201 17.9998 21.6663 18.746 21.6663 19.6665Z" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M17.2002 10.7998H9.6002L9.2002 11.5998V17.9998C9.2002 18.3998 10.0002 19.1998 10.8002 19.1998C10.8002 18.3998 11.2002 17.1998 12.8002 17.1998C14.0802 17.1998 14.6669 18.5331 14.8002 19.1998H17.2002V10.7998Z" fill="white"/>\n' +
    '<path d="M17.6002 16.3998H22.4002V19.1998H21.6002C21.4669 18.7998 20.9602 17.9998 20.0002 17.9998C19.0402 17.9998 18.5335 18.7998 18.4002 19.1998H17.6002V16.3998Z" fill="white"/>\n' +
    '<path d="M16 36L21.1962 31.5H10.8038L16 36Z" fill="#1EB9C3"/>\n' +
    '</svg>\n';


export const authPartnerSvg = '<svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<rect width="32" height="32" rx="8" fill="#EB9229"/>\n' +
    '<path d="M17.333 12.6665H18.8912C19.0542 12.6665 19.1358 12.6665 19.2125 12.6849C19.2805 12.7013 19.3455 12.7282 19.4052 12.7647C19.4725 12.806 19.5301 12.8636 19.6454 12.9789L22.3539 15.6874C22.4692 15.8027 22.5269 15.8604 22.5681 15.9276C22.6047 15.9873 22.6316 16.0523 22.6479 16.1203C22.6663 16.1971 22.6663 16.2786 22.6663 16.4417V18.3332C22.6663 18.6438 22.6663 18.7991 22.6156 18.9216C22.5479 19.085 22.4181 19.2148 22.2548 19.2824C22.1323 19.3332 21.977 19.3332 21.6663 19.3332M18.333 19.3332H17.333M17.333 19.3332V12.7998C17.333 12.0531 17.333 11.6797 17.1877 11.3945C17.0599 11.1436 16.8559 10.9397 16.605 10.8118C16.3198 10.6665 15.9464 10.6665 15.1997 10.6665H11.4663C10.7196 10.6665 10.3462 10.6665 10.061 10.8118C9.81014 10.9397 9.60616 11.1436 9.47833 11.3945C9.33301 11.6797 9.33301 12.0531 9.33301 12.7998V17.9998C9.33301 18.7362 9.92996 19.3332 10.6663 19.3332M17.333 19.3332H14.6663M14.6663 19.3332C14.6663 20.4377 13.7709 21.3332 12.6663 21.3332C11.5618 21.3332 10.6663 20.4377 10.6663 19.3332M14.6663 19.3332C14.6663 18.2286 13.7709 17.3332 12.6663 17.3332C11.5618 17.3332 10.6663 18.2286 10.6663 19.3332M21.6663 19.6665C21.6663 20.587 20.9201 21.3332 19.9997 21.3332C19.0792 21.3332 18.333 20.587 18.333 19.6665C18.333 18.746 19.0792 17.9998 19.9997 17.9998C20.9201 17.9998 21.6663 18.746 21.6663 19.6665Z" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M17.2002 10.7998H9.6002L9.2002 11.5998V17.9998C9.2002 18.3998 10.0002 19.1998 10.8002 19.1998C10.8002 18.3998 11.2002 17.1998 12.8002 17.1998C14.0802 17.1998 14.6669 18.5331 14.8002 19.1998H17.2002V10.7998Z" fill="white"/>\n' +
    '<path d="M17.6002 16.3998H22.4002V19.1998H21.6002C21.4669 18.7998 20.9602 17.9998 20.0002 17.9998C19.0402 17.9998 18.5335 18.7998 18.4002 19.1998H17.6002V16.3998Z" fill="white"/>\n' +
    '<path d="M16 36L21.1962 31.5H10.8038L16 36Z" fill="#EB9229"/>\n' +
    '</svg>\n';