<div class="ml-1" style="max-width: 700px">
  <div class="step-title">Korak 2</div>
  <div class="text-3xl font-semibold mt-1">Paket i vozilo</div>
  <div class="mt-6 title-text">Vrsta paketa</div>

  <div class="flex gap-4 box-padding mt-5">
    <atlas-icon-checkbox
      name="Paket za vozilo"
      [isSelected]="selectedOption === 'Vozilo'"
      [iconClass]="'pi pi-car'"
      (select)="selectVozilo()"
    ></atlas-icon-checkbox>
    <atlas-icon-checkbox
      name="Lični paket"
      [isSelected]="selectedOption === 'Licni'"
      [iconClass]="'pi pi-user'"
      (select)="selectLicni()"
    ></atlas-icon-checkbox>
  </div>

  <div *ngIf="selectedOption === 'Vozilo'">
    <div class="mt-6 title-text">Izaberi paket za</div>
    <div class="vehicle-type-container mt-5">
      <div
        class="flex wrapper"
        *ngFor="let vehicle of vehicleTypes"
        [class.selected]="selectedVehicle === vehicle?.name"
        (click)="onSelectVehicle(vehicle)"
      >
        <div class="container-checkbox">
          <div class="label ml-3">{{ vehicle?.name }}</div>
        </div>

        <div class="checkbox-div">
          <div class="checkbox ml-3">
            <p-checkbox
              [binary]="true"
              [ngModel]="selectedVehicle === vehicle.name"
              (onChange)="onSelectVehicle(vehicle)"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <atlas-package-card-list></atlas-package-card-list>

    <!-- <div class="package-container mt-5">
      <div class="header-container mt-2">
        <span class="title-text">Paket 1</span>
        <i
          *ngIf="selectedPackage"
          (click)="clearSelectedPackage($event)"
          class="pi pi-trash pointer"
        ></i>
      </div>

      <div
        class="package-header mt-3"
        [ngClass]="{ disabled: !selectedVehicle }"
        (click)="selectedVehicle ? togglePackageDropdown() : null"
      >
        <div class="flex align-items-center">
          <img
            ngSrc="assets/images/package.svg"
            width="40"
            height="40"
            alt="user-image"
          />
          <span class="package-text">{{
            selectedPackage?.name || "Izaberi paket"
          }}</span>
          <i
            [ngClass]="
              isPackageDropdownOpen ? 'pi pi-angle-up' : 'pi pi-angle-down'
            "
            class="ml-1"
          ></i>
        </div>

        <div class="package-price" *ngIf="selectedPackage">
          {{ decimalPipe.transform(selectedPackage.price, "1.2-2") }} rsd
        </div>
      </div>

      <div *ngIf="isPackageDropdownOpen" class="package-dropdown mt-2">
        <div
          *ngFor="let pkg of allPackages"
          (click)="selectPackage(pkg)"
          class="package-item"
        >
          {{ pkg?.name }} - {{ decimalPipe.transform(pkg?.price, "1.2-2") }} rsd
        </div>
      </div>

      <div class="mt-3" *ngIf="selectedPackage">
        <atlas-input-text
          [formCtrl]="secondStepControl"
          ctrlLabel="Broj šasije"
          formCtrlId="broj"
          [tooltip]="'Broj šasije vozila nedostaje za ovaj paket'"
          ctrlPlaceholder="Broj šasije"
          [formCtrlIconRight]="'pi pi-info-circle'"
        ></atlas-input-text>
      </div>

      <div class="mt-3" *ngIf="selectedPackage">
        <atlas-input-text
          [formCtrl]="''"
          ctrlLabel="Cena"
          ctrlPlaceholder="Cena"
          hideErrors="true"
          [isPriceFormat]="true"
          formCtrlClass="w-full lg:w-32rem"
        ></atlas-input-text>
      </div>
    </div> -->

    <div class="mt-6 title-text">Detalji vozila</div>

    <div class="Mail-Num mt-3">
      <atlas-input-text
        [formCtrl]="secondStepControl"
        ctrlLabel="Marka vozila" 
        ctrlPlaceholder="Marka vozila"
        hideErrors="true"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>
      <atlas-input-text
        [formCtrl]="secondStepControl"
        ctrlLabel="Model vozila"
        ctrlPlaceholder="Model vozila"
        hideErrors="true"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>
    </div>

    <div class="Mail-Num mt-3">
      <atlas-input-text
        [formCtrl]="secondStepControl"
        ctrlLabel="Registracione oznake"
        ctrlPlaceholder="Registracione oznake"
        hideErrors="true"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>
      <atlas-dropdown
        ctrlLabel="Godište (opciono)"
        optionLabel="Godište (opciono)"
        [formCtrl]="secondStepControl"
        ctrlStyleClass="w-full lg:w-19rem"
        ctrlPlaceholder="Godište"
      >
      </atlas-dropdown>
    </div>
    <div class="Mail-Num mt-3">
      <atlas-dropdown
        ctrlLabel="Boja vozila (opciona)"
        optionLabel="Boja vozila (opciona)"
        [formCtrl]="secondStepControl"
        ctrlStyleClass="w-full lg:w-19rem"
        ctrlPlaceholder="Boja vozila"
      >
      </atlas-dropdown>
    </div>
    <div class="mt-5 title-text">Šlepanje</div>
    <div class="mt-1 support-text">
      Adresa gde se ovo vozilo šlepa kada je u pitanju usluga asistencije
    </div>
    <div class="mt-2 mb-1">
      <atlas-input-text
        [formCtrl]="secondStepControl"
        ctrlPlaceholder="Adresa šlepanja"
        hideErrors="true"
        showLabel="false"
        formCtrlClass="w-full lg:w-19rem"
      ></atlas-input-text>
    </div>
  </div>
  <div *ngIf="selectedOption === 'Licni'">
    <div class="mt-6 title-text">Detalji paketa</div>

    <div class="package-container mt-3">
      <div class="package-header mt-3">
        <div class="flex align-items-center">
          <img
            ngSrc="assets/images/package.svg"
            width="40"
            height="40"
            alt="user-image"
          />
          <span class="package-text">Moj paket</span>
        </div>
        <div class="package-price" *ngIf="selectedPackage">
          {{ selectedPackage.price }} rsd
        </div>
      </div>

      <!-- Chassis Number Input -->
      <div class="mt-3">
        <atlas-input-text
          [formCtrl]="secondStepControl"
          ctrlLabel="JMBG"
          formCtrlId="broj"
          hideErrors="true"
          [tooltip]="'JMBG je potreban za kupovinu licnog paketa'"
          ctrlPlaceholder="JMBG"
          [formCtrlIconRight]="'pi pi-info-circle'"
        ></atlas-input-text>
      </div>

      <div class="mt-3">
        <atlas-input-text
          [formCtrl]="''"
          ctrlLabel="Cena"
          ctrlPlaceholder="Cena"
          hideErrors="true"
          [isPriceFormat]="true"
          formCtrlClass="w-full lg:w-32rem"
        ></atlas-input-text>
      </div>
    </div>
    <div class="mt-6 title-text mb-2">Adresa šlepanja</div>
    <atlas-input-text
      ctrlPlaceholder="Adresa"
      [formCtrl]="secondStepControl"
      hideErrors="true"
      formCtrlClass="w-full lg:w-19rem"
    ></atlas-input-text>
  </div>
</div>
