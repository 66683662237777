import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, ContentChildren, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { UniqueComponentId } from 'primeng/utils';
import { trigger, state, style, transition, animate } from '@angular/animations';
function StepperHeader_ng_container_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = (a0, a1, a2, a7) => ({
  index: a0,
  active: a1,
  highlighted: a2,
  class: "p-stepper-action",
  headerClass: "p-stepper-action",
  numberClass: "p-stepper-number",
  titleClass: "p-stepper-title",
  onClick: a7
});
function StepperHeader_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, StepperHeader_ng_container_0_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template)("ngTemplateOutletContext", i0.ɵɵpureFunction4(2, _c0, ctx_r0.index, ctx_r0.active, ctx_r0.highlighted, ctx_r0.onClick));
  }
}
function StepperHeader_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 3);
    i0.ɵɵlistener("click", function StepperHeader_ng_template_1_Template_p_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onClick.emit($event, ctx_r4.index));
    });
    i0.ɵɵelementStart(1, "span", 4);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 5);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.id)("tabindex", ctx_r1.disabled ? -1 : undefined)("aria-controls", ctx_r1.ariaControls);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.index + 1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.getStepProp);
  }
}
function StepperSeparator_ng_container_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c1 = (a0, a1, a2, a3) => ({
  index: a0,
  active: a1,
  highlighted: a2,
  class: a3
});
function StepperSeparator_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, StepperSeparator_ng_container_0_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template)("ngTemplateOutletContext", i0.ɵɵpureFunction4(2, _c1, ctx_r0.index, ctx_r0.active, ctx_r0.highlighted, ctx_r0.separatorClass));
  }
}
function StepperSeparator_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 3);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.separatorClass);
  }
}
function StepperContent_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c2 = (a0, a1, a2, a3, a4, a5) => ({
  index: a0,
  active: a1,
  highlighted: a2,
  onClick: a3,
  prevCallback: a4,
  nextCallback: a5
});
function StepperContent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, StepperContent_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template)("ngTemplateOutletContext", i0.ɵɵpureFunction6(2, _c2, ctx_r0.index, ctx_r0.active, ctx_r0.highlighted, ctx_r0.onClick, ctx_r0.prevCallback, ctx_r0.nextCallback));
  }
}
function StepperContent_2_ng_template_0_ng_container_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function StepperContent_2_ng_template_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, StepperContent_2_ng_template_0_ng_container_0_ng_container_1_Template, 1, 0, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.stepperPanel);
  }
}
function StepperContent_2_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, StepperContent_2_ng_template_0_ng_container_0_Template, 2, 1, "ng-container", 1);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r3.stepperPanel);
  }
}
function StepperContent_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, StepperContent_2_ng_template_0_Template, 1, 1, "ng-template");
  }
}
const _c3 = ["*"];
function Stepper_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Stepper_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Stepper_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.startTemplate);
  }
}
function Stepper_ng_container_2_ng_template_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "p-stepperSeparator", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext();
    const step_r8 = ctx_r11.$implicit;
    const index_r9 = ctx_r11.index;
    const ctx_r10 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("template", step_r8.separatorTemplate)("separatorClass", "p-stepper-separator")("stepperPanel", step_r8)("index", index_r9)("active", ctx_r10.isStepActive(index_r9))("highlighted", index_r9 < ctx_r10.activeStep);
  }
}
const _c4 = (a0, a1) => ({
  "p-highlight": a0,
  "p-disabled": a1
});
function Stepper_ng_container_2_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 8)(1, "p-stepperHeader", 9);
    i0.ɵɵlistener("onClick", function Stepper_ng_container_2_ng_template_2_Template_p_stepperHeader_onClick_1_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r13);
      const index_r9 = restoredCtx.index;
      const ctx_r12 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r12.onItemClick($event, index_r9));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, Stepper_ng_container_2_ng_template_2_ng_container_2_Template, 2, 6, "ng-container", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const step_r8 = ctx.$implicit;
    const index_r9 = ctx.index;
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("key", ctx_r6.getStepKey(step_r8, index_r9))("ngClass", i0.ɵɵpureFunction2(20, _c4, ctx_r6.isStepActive(index_r9), ctx_r6.isItemDisabled(index_r9)))("data-pc-name", ctx_r6.stepperPanel)("data-p-highlight", ctx_r6.isStepActive(index_r9))("data-p-disabled", ctx_r6.isItemDisabled(index_r9))("data-pc-index", index_r9)("data-p-active", ctx_r6.isStepActive(index_r9));
    i0.ɵɵattribute("aria-current", ctx_r6.isStepActive(index_r9) ? "step" : undefined);
    i0.ɵɵadvance();
    i0.ɵɵclassMap("p-stepper-action");
    i0.ɵɵproperty("id", ctx_r6.getStepHeaderActionId(index_r9))("template", step_r8.headerTemplate)("stepperPanel", step_r8)("getStepProp", ctx_r6.getStepProp(step_r8, "header"))("index", index_r9)("disabled", ctx_r6.isItemDisabled(index_r9))("active", ctx_r6.isStepActive(index_r9))("highlighted", index_r9 < ctx_r6.activeStep)("aria-controls", ctx_r6.getStepContentId(index_r9));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", index_r9 !== ctx_r6.stepperPanels.length - 1);
  }
}
function Stepper_ng_container_2_ng_template_4_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "p-stepperContent", 11);
    i0.ɵɵlistener("onClick", function Stepper_ng_container_2_ng_template_4_ng_container_0_Template_p_stepperContent_onClick_1_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const index_r15 = i0.ɵɵnextContext().index;
      const ctx_r17 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r17.onItemClick($event, index_r15));
    })("nextCallback", function Stepper_ng_container_2_ng_template_4_ng_container_0_Template_p_stepperContent_nextCallback_1_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const index_r15 = i0.ɵɵnextContext().index;
      const ctx_r20 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r20.nextCallback($event, index_r15));
    })("prevCallback", function Stepper_ng_container_2_ng_template_4_ng_container_0_Template_p_stepperContent_prevCallback_1_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const index_r15 = i0.ɵɵnextContext().index;
      const ctx_r22 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r22.prevCallback($event, index_r15));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r24 = i0.ɵɵnextContext();
    const index_r15 = ctx_r24.index;
    const step_r14 = ctx_r24.$implicit;
    const ctx_r16 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("id", ctx_r16.getStepContentId(index_r15))("template", step_r14.contentTemplate)("orientation", ctx_r16.orientation)("stepperPanel", step_r14)("index", index_r15)("active", ctx_r16.isStepActive(index_r15))("highlighted", index_r15 < ctx_r16.activeStep)("ariaLabelledby", ctx_r16.getStepHeaderActionId(index_r15));
  }
}
function Stepper_ng_container_2_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Stepper_ng_container_2_ng_template_4_ng_container_0_Template, 2, 8, "ng-container", 1);
  }
  if (rf & 2) {
    const index_r15 = ctx.index;
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r7.isStepActive(index_r15));
  }
}
function Stepper_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "ul", 5);
    i0.ɵɵtemplate(2, Stepper_ng_container_2_ng_template_2_Template, 3, 23, "ng-template", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 7);
    i0.ɵɵtemplate(4, Stepper_ng_container_2_ng_template_4_Template, 1, 1, "ng-template", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.panels)("ngForTrackBy", ctx_r1.trackByFn);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.panels)("ngForTrackBy", ctx_r1.trackByFn);
  }
}
function Stepper_ng_template_3_ng_template_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "p-stepperSeparator", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r29 = i0.ɵɵnextContext();
    const step_r26 = ctx_r29.$implicit;
    const index_r27 = ctx_r29.index;
    const ctx_r28 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("template", step_r26.separatorTemplate)("separatorClass", "p-stepper-separator")("stepperPanel", step_r26)("index", index_r27)("active", ctx_r28.isStepActive(index_r27))("highlighted", index_r27 < ctx_r28.activeStep);
  }
}
const _c5 = a0 => ({
  "p-stepper-panel-active": a0
});
const _c6 = a0 => ({
  transitionParams: a0
});
const _c7 = a1 => ({
  value: "visible",
  params: a1
});
const _c8 = a1 => ({
  value: "hidden",
  params: a1
});
function Stepper_ng_template_3_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r31 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12)(1, "div", 13)(2, "p-stepperHeader", 9);
    i0.ɵɵlistener("onClick", function Stepper_ng_template_3_ng_template_0_Template_p_stepperHeader_onClick_2_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r31);
      const index_r27 = restoredCtx.index;
      const ctx_r30 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r30.onItemClick($event, index_r27));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(3, "div", 14);
    i0.ɵɵtemplate(4, Stepper_ng_template_3_ng_template_0_ng_container_4_Template, 2, 6, "ng-container", 1);
    i0.ɵɵelementStart(5, "p-stepperContent", 11);
    i0.ɵɵlistener("onClick", function Stepper_ng_template_3_ng_template_0_Template_p_stepperContent_onClick_5_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r31);
      const index_r27 = restoredCtx.index;
      const ctx_r32 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r32.onItemClick($event, index_r27));
    })("nextCallback", function Stepper_ng_template_3_ng_template_0_Template_p_stepperContent_nextCallback_5_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r31);
      const index_r27 = restoredCtx.index;
      const ctx_r33 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r33.nextCallback($event, index_r27));
    })("prevCallback", function Stepper_ng_template_3_ng_template_0_Template_p_stepperContent_prevCallback_5_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r31);
      const index_r27 = restoredCtx.index;
      const ctx_r34 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r34.prevCallback($event, index_r27));
    });
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const step_r26 = ctx.$implicit;
    const index_r27 = ctx.index;
    const ctx_r25 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("key", ctx_r25.getStepKey(step_r26, index_r27))("ngClass", i0.ɵɵpureFunction1(30, _c5, ctx_r25.orientation === "vertical" && ctx_r25.isStepActive(index_r27)))("data-pc-name", "stepperpanel")("data-p-highlight", ctx_r25.isStepActive(index_r27))("data-p-disabled", ctx_r25.isItemDisabled(index_r27))("data-pc-index", index_r27)("data-p-active", ctx_r25.isStepActive(index_r27));
    i0.ɵɵattribute("aria-current", ctx_r25.isStepActive(index_r27) ? "step" : undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(32, _c4, ctx_r25.isStepActive(index_r27), ctx_r25.isItemDisabled(index_r27)));
    i0.ɵɵadvance();
    i0.ɵɵclassMap("p-stepper-action");
    i0.ɵɵproperty("id", ctx_r25.getStepHeaderActionId(index_r27))("template", step_r26.headerTemplate)("stepperPanel", step_r26)("getStepProp", ctx_r25.getStepProp(step_r26, "header"))("index", index_r27)("disabled", ctx_r25.isItemDisabled(index_r27))("active", ctx_r25.isStepActive(index_r27))("highlighted", index_r27 < ctx_r25.activeStep)("aria-controls", ctx_r25.getStepContentId(index_r27));
    i0.ɵɵadvance();
    i0.ɵɵproperty("@tabContent", ctx_r25.isStepActive(index_r27) ? i0.ɵɵpureFunction1(37, _c7, i0.ɵɵpureFunction1(35, _c6, ctx_r25.transitionOptions)) : i0.ɵɵpureFunction1(41, _c8, i0.ɵɵpureFunction1(39, _c6, ctx_r25.transitionOptions)));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", index_r27 !== ctx_r25.stepperPanels.length - 1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("id", ctx_r25.getStepContentId(index_r27))("template", step_r26.contentTemplate)("orientation", ctx_r25.orientation)("stepperPanel", step_r26)("index", index_r27)("active", ctx_r25.isStepActive(index_r27))("highlighted", index_r27 < ctx_r25.activeStep)("ariaLabelledby", ctx_r25.getStepHeaderActionId(index_r27));
  }
}
function Stepper_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Stepper_ng_template_3_ng_template_0_Template, 6, 43, "ng-template", 6);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngForOf", ctx_r2.panels)("ngForTrackBy", ctx_r2.trackByFn);
  }
}
function Stepper_ng_container_5_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Stepper_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Stepper_ng_container_5_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.endTemplate);
  }
}
class StepperHeader {
  id;
  template;
  stepperPanel;
  index;
  disabled;
  active;
  highlighted;
  getStepProp;
  ariaControls;
  onClick = new EventEmitter();
  static ɵfac = function StepperHeader_Factory(t) {
    return new (t || StepperHeader)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: StepperHeader,
    selectors: [["p-stepperHeader"]],
    hostAttrs: [1, "p-element"],
    inputs: {
      id: "id",
      template: "template",
      stepperPanel: "stepperPanel",
      index: "index",
      disabled: "disabled",
      active: "active",
      highlighted: "highlighted",
      getStepProp: "getStepProp",
      ariaControls: "ariaControls"
    },
    outputs: {
      onClick: "onClick"
    },
    decls: 3,
    vars: 2,
    consts: [[4, "ngIf", "ngIfElse"], ["buttonRef", ""], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["role", "tab", 1, "p-stepper-action", 3, "id", "tabindex", "aria-controls", "click"], [1, "p-stepper-number"], [1, "p-stepper-title"]],
    template: function StepperHeader_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, StepperHeader_ng_container_0_Template, 2, 7, "ng-container", 0)(1, StepperHeader_ng_template_1_Template, 5, 5, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      }
      if (rf & 2) {
        const _r2 = i0.ɵɵreference(2);
        i0.ɵɵproperty("ngIf", ctx.template)("ngIfElse", _r2);
      }
    },
    dependencies: [i1.NgIf, i1.NgTemplateOutlet],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperHeader, [{
    type: Component,
    args: [{
      selector: 'p-stepperHeader',
      template: `
        <ng-container *ngIf="template; else buttonRef">
            <ng-container
                *ngTemplateOutlet="
                    template;
                    context: {
                        index: index,
                        active: active,
                        highlighted: highlighted,
                        class: 'p-stepper-action',
                        headerClass: 'p-stepper-action',
                        numberClass: 'p-stepper-number',
                        titleClass: 'p-stepper-title',
                        onClick: onClick
                    }
                "
            ></ng-container>
        </ng-container>
        <ng-template #buttonRef>
            <p-button [id]="id" class="p-stepper-action" role="tab" [tabindex]="disabled ? -1 : undefined" [aria-controls]="ariaControls" (click)="onClick.emit($event, index)">
                <span class="p-stepper-number">{{ index + 1 }}</span>
                <span class="p-stepper-title">{{ getStepProp }}</span>
            </p-button>
        </ng-template>
    `,
      host: {
        class: 'p-element'
      }
    }]
  }], null, {
    id: [{
      type: Input
    }],
    template: [{
      type: Input
    }],
    stepperPanel: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    active: [{
      type: Input
    }],
    highlighted: [{
      type: Input
    }],
    getStepProp: [{
      type: Input
    }],
    ariaControls: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }]
  });
})();
class StepperSeparator {
  template;
  separatorClass;
  stepperPanel;
  index;
  active;
  highlighted;
  static ɵfac = function StepperSeparator_Factory(t) {
    return new (t || StepperSeparator)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: StepperSeparator,
    selectors: [["p-stepperSeparator"]],
    hostAttrs: [1, "p-stepper-separator"],
    inputs: {
      template: "template",
      separatorClass: "separatorClass",
      stepperPanel: "stepperPanel",
      index: "index",
      active: "active",
      highlighted: "highlighted"
    },
    decls: 3,
    vars: 2,
    consts: [[4, "ngIf", "ngIfElse"], ["span", ""], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["aria-hidden", "true"]],
    template: function StepperSeparator_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, StepperSeparator_ng_container_0_Template, 2, 7, "ng-container", 0)(1, StepperSeparator_ng_template_1_Template, 1, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      }
      if (rf & 2) {
        const _r2 = i0.ɵɵreference(2);
        i0.ɵɵproperty("ngIf", ctx.template)("ngIfElse", _r2);
      }
    },
    dependencies: [i1.NgIf, i1.NgTemplateOutlet],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperSeparator, [{
    type: Component,
    args: [{
      selector: 'p-stepperSeparator',
      template: `
        <ng-container *ngIf="template; else span">
            <ng-container *ngTemplateOutlet="template; context: { index: index, active: active, highlighted: highlighted, class: separatorClass }"></ng-container>
        </ng-container>
        <ng-template #span>
            <span [class]="separatorClass" aria-hidden="true"></span>
        </ng-template>
    `,
      host: {
        class: 'p-stepper-separator'
      }
    }]
  }], null, {
    template: [{
      type: Input
    }],
    separatorClass: [{
      type: Input
    }],
    stepperPanel: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    active: [{
      type: Input
    }],
    highlighted: [{
      type: Input
    }]
  });
})();
class StepperContent {
  id;
  orientation;
  template;
  ariaLabelledby;
  stepperPanel;
  index;
  active;
  highlighted;
  onClick = new EventEmitter();
  prevCallback = new EventEmitter();
  nextCallback = new EventEmitter();
  static ɵfac = function StepperContent_Factory(t) {
    return new (t || StepperContent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: StepperContent,
    selectors: [["p-stepperContent"]],
    hostVars: 6,
    hostBindings: function StepperContent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("p-stepper-content", true)("p-element", true)("p-toggleable-content", ctx.orientation === "vertical");
      }
    },
    inputs: {
      id: "id",
      orientation: "orientation",
      template: "template",
      ariaLabelledby: "ariaLabelledby",
      stepperPanel: "stepperPanel",
      index: "index",
      active: "active",
      highlighted: "highlighted"
    },
    outputs: {
      onClick: "onClick",
      prevCallback: "prevCallback",
      nextCallback: "nextCallback"
    },
    decls: 3,
    vars: 6,
    consts: [["role", "tabpanel", "data-pc-name", "stepperpanel", 3, "id"], [4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "ngTemplateOutlet"]],
    template: function StepperContent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, StepperContent_ng_container_1_Template, 2, 9, "ng-container", 1)(2, StepperContent_2_Template, 1, 0, null, 1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("id", ctx.id);
        i0.ɵɵattribute("data-pc-index", ctx.index)("data-p-active", ctx.active)("aria-labelledby", ctx.ariaLabelledby);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.template);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.template);
      }
    },
    dependencies: [i1.NgIf, i1.NgTemplateOutlet],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperContent, [{
    type: Component,
    args: [{
      selector: 'p-stepperContent',
      template: ` <div [id]="id" role="tabpanel" data-pc-name="stepperpanel" [attr.data-pc-index]="index" [attr.data-p-active]="active" [attr.aria-labelledby]="ariaLabelledby">
        <ng-container *ngIf="template">
            <ng-container *ngTemplateOutlet="template; context: { index: index, active: active, highlighted: highlighted, onClick: onClick, prevCallback: prevCallback, nextCallback: nextCallback }"></ng-container>
        </ng-container>
        <ng-template *ngIf="!template">
            <ng-container *ngIf="stepperPanel">
                <ng-container *ngTemplateOutlet="stepperPanel"></ng-container>
            </ng-container>
        </ng-template>
    </div>`,
      host: {
        '[class.p-stepper-content]': 'true',
        '[class.p-element]': 'true',
        '[class.p-toggleable-content]': "orientation === 'vertical'"
      }
    }]
  }], null, {
    id: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    template: [{
      type: Input
    }],
    ariaLabelledby: [{
      type: Input
    }],
    stepperPanel: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    active: [{
      type: Input
    }],
    highlighted: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    prevCallback: [{
      type: Output
    }],
    nextCallback: [{
      type: Output
    }]
  });
})();
/**
 * StepperPanel is a helper component for Stepper component.
 * @group Components
 */
class StepperPanel {
  /**
   * Orientation of tab headers.
   * @group Props
   */
  header;
  templates;
  headerTemplate;
  startTemplate;
  contentTemplate;
  separatorTemplate;
  endTemplate;
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'content':
          this.contentTemplate = item.template;
          break;
        case 'separator':
          this.separatorTemplate = item.template;
          break;
      }
    });
  }
  static ɵfac = function StepperPanel_Factory(t) {
    return new (t || StepperPanel)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: StepperPanel,
    selectors: [["p-stepperPanel"]],
    contentQueries: function StepperPanel_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      header: "header"
    },
    ngContentSelectors: _c3,
    decls: 1,
    vars: 0,
    template: function StepperPanel_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperPanel, [{
    type: Component,
    args: [{
      selector: 'p-stepperPanel',
      template: ` <ng-content></ng-content> `,
      host: {
        class: 'p-element'
      }
    }]
  }], null, {
    header: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
/**
 * The Stepper component displays a wizard-like workflow by guiding users through the multi-step progression.
 * @group Components
 */
class Stepper {
  /**
   * Active step index of stepper.
   * @group Props
   */
  activeStep = 0;
  /**
   * Orientation of the stepper.
   * @group Props
   */
  orientation = 'horizontal';
  /**
   * Whether the steps are clickable or not.
   * @group Props
   */
  linear = false;
  /**
   * Transition options of the animation.
   * @group Props
   */
  transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';
  stepperPanels;
  templates;
  onClick = new EventEmitter();
  /**
   * Emitted when the value changes.
   * @param {ActiveStepChangeEvent} event - custom change event.
   * @group Emits
   */
  activeStepChange = new EventEmitter();
  headerTemplate;
  startTemplate;
  separatorTemplate;
  endTemplate;
  id = UniqueComponentId();
  panels;
  isStepActive(index) {
    return this.activeStep === index;
  }
  getStepProp(step) {
    if (step?.header) {
      return step.header;
    }
    if (step?.content) {
      return step.content;
    }
    return undefined;
  }
  getStepKey(step, index) {
    return this.getStepProp(step) || index;
  }
  getStepHeaderActionId(index) {
    return `${this.id}_${index}_header_action`;
  }
  getStepContentId(index) {
    return `${this.id}_${index}_content`;
  }
  updateActiveStep(event, index) {
    this.activeStep = index;
    this.activeStepChange.emit(this.activeStep);
  }
  onItemClick(event, index) {
    if (this.linear) {
      event.preventDefault();
      return;
    }
    if (index !== this.activeStep) {
      this.updateActiveStep(event, index);
    }
  }
  isItemDisabled(index) {
    return this.linear && !this.isStepActive(index);
  }
  prevCallback(event, index) {
    if (index !== 0) {
      this.updateActiveStep(event, index - 1);
    }
  }
  nextCallback(event, index) {
    if (index !== this.stepperPanels.length - 1) {
      this.updateActiveStep(event, index + 1);
    }
  }
  trackByFn(index) {
    return index;
  }
  ngAfterContentInit() {
    this.panels = this.stepperPanels.toArray();
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'start':
          this.startTemplate = item.template;
          break;
        case 'end':
          this.endTemplate = item.template;
          break;
        default:
          break;
      }
    });
  }
  static ɵfac = function Stepper_Factory(t) {
    return new (t || Stepper)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Stepper,
    selectors: [["p-stepper"]],
    contentQueries: function Stepper_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, StepperPanel, 4);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.stepperPanels = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostVars: 6,
    hostBindings: function Stepper_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("p-stepper", true)("p-component", true)("p-stepper-vertical", ctx.orientation === "vertical");
      }
    },
    inputs: {
      activeStep: "activeStep",
      orientation: "orientation",
      linear: "linear",
      transitionOptions: "transitionOptions"
    },
    outputs: {
      onClick: "onClick",
      activeStepChange: "activeStepChange"
    },
    decls: 6,
    vars: 4,
    consts: [["role", "tablist"], [4, "ngIf"], [4, "ngIf", "ngIfElse"], ["vertical", ""], [4, "ngTemplateOutlet"], [1, "p-stepper-nav"], ["ngFor", "", 3, "ngForOf", "ngForTrackBy"], [1, "p-stepper-panels"], ["role", "presentation", 1, "p-stepper-header", 3, "key", "ngClass", "data-pc-name", "data-p-highlight", "data-p-disabled", "data-pc-index", "data-p-active"], [3, "id", "template", "stepperPanel", "getStepProp", "index", "disabled", "active", "highlighted", "aria-controls", "onClick"], [3, "template", "separatorClass", "stepperPanel", "index", "active", "highlighted"], [3, "id", "template", "orientation", "stepperPanel", "index", "active", "highlighted", "ariaLabelledby", "onClick", "nextCallback", "prevCallback"], [1, "p-stepper-panel", 3, "key", "ngClass", "data-pc-name", "data-p-highlight", "data-p-disabled", "data-pc-index", "data-p-active"], [1, "p-stepper-header", 3, "ngClass"], [1, "p-stepper-toggleable-content"]],
    template: function Stepper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, Stepper_ng_container_1_Template, 2, 1, "ng-container", 1)(2, Stepper_ng_container_2_Template, 5, 4, "ng-container", 2)(3, Stepper_ng_template_3_Template, 1, 2, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor)(5, Stepper_ng_container_5_Template, 2, 1, "ng-container", 1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        const _r3 = i0.ɵɵreference(4);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.startTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.orientation === "horizontal")("ngIfElse", _r3);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.endTemplate);
      }
    },
    dependencies: [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, StepperContent, StepperHeader, StepperSeparator],
    styles: ["@layer primeng{.p-stepper-vertical .p-stepper-panel>.p-stepper-toggleable-content{overflow:hidden}.p-stepper-vertical .p-stepper-panel-active>.p-stepper-toggleable-content:not(.ng-animating){overflow:inherit}}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('tabContent', [state('hidden', style({
        height: '0',
        visibility: 'hidden'
      })), state('visible', style({
        height: '*',
        visibility: 'visible'
      })), transition('visible <=> hidden', [animate('250ms cubic-bezier(0.86, 0, 0.07, 1)')]), transition('void => *', animate(0))])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Stepper, [{
    type: Component,
    args: [{
      selector: 'p-stepper',
      template: `
        <div role="tablist">
            <ng-container *ngIf="startTemplate">
                <ng-container *ngTemplateOutlet="startTemplate"></ng-container>
            </ng-container>
            <ng-container *ngIf="orientation === 'horizontal'; else vertical">
                <ul class="p-stepper-nav">
                    <ng-template ngFor let-step [ngForOf]="panels" let-index="index" [ngForTrackBy]="trackByFn">
                        <li
                            [key]="getStepKey(step, index)"
                            class="p-stepper-header"
                            [ngClass]="{
                                'p-highlight': isStepActive(index),
                                'p-disabled': isItemDisabled(index)
                            }"
                            [attr.aria-current]="isStepActive(index) ? 'step' : undefined"
                            role="presentation"
                            [data-pc-name]="stepperPanel"
                            [data-p-highlight]="isStepActive(index)"
                            [data-p-disabled]="isItemDisabled(index)"
                            [data-pc-index]="index"
                            [data-p-active]="isStepActive(index)"
                        >
                            <p-stepperHeader
                                [id]="getStepHeaderActionId(index)"
                                [template]="step.headerTemplate"
                                [stepperPanel]="step"
                                [getStepProp]="getStepProp(step, 'header')"
                                [index]="index"
                                [disabled]="isItemDisabled(index)"
                                [active]="isStepActive(index)"
                                [highlighted]="index < activeStep"
                                [class]="'p-stepper-action'"
                                [aria-controls]="getStepContentId(index)"
                                (onClick)="onItemClick($event, index)"
                            ></p-stepperHeader>

                            <ng-container *ngIf="index !== stepperPanels.length - 1">
                                <p-stepperSeparator [template]="step.separatorTemplate" [separatorClass]="'p-stepper-separator'" [stepperPanel]="step" [index]="index" [active]="isStepActive(index)" [highlighted]="index < activeStep" />
                            </ng-container>
                        </li>
                    </ng-template>
                </ul>
                <div class="p-stepper-panels">
                    <ng-template ngFor let-step [ngForOf]="panels" let-index="index" [ngForTrackBy]="trackByFn">
                        <ng-container *ngIf="isStepActive(index)">
                            <p-stepperContent
                                [id]="getStepContentId(index)"
                                [template]="step.contentTemplate"
                                [orientation]="orientation"
                                [stepperPanel]="step"
                                [index]="index"
                                [active]="isStepActive(index)"
                                [highlighted]="index < activeStep"
                                [ariaLabelledby]="getStepHeaderActionId(index)"
                                (onClick)="onItemClick($event, index)"
                                (nextCallback)="nextCallback($event, index)"
                                (prevCallback)="prevCallback($event, index)"
                            />
                        </ng-container>
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #vertical>
                <ng-template ngFor let-step [ngForOf]="panels" let-index="index" [ngForTrackBy]="trackByFn">
                    <div
                        [key]="getStepKey(step, index)"
                        class="p-stepper-panel"
                        [ngClass]="{
                            'p-stepper-panel-active': orientation === 'vertical' && isStepActive(index)
                        }"
                        [attr.aria-current]="isStepActive(index) ? 'step' : undefined"
                        [data-pc-name]="'stepperpanel'"
                        [data-p-highlight]="isStepActive(index)"
                        [data-p-disabled]="isItemDisabled(index)"
                        [data-pc-index]="index"
                        [data-p-active]="isStepActive(index)"
                    >
                        <div
                            class="p-stepper-header "
                            [ngClass]="{
                                'p-highlight': isStepActive(index),
                                'p-disabled': isItemDisabled(index)
                            }"
                        >
                            <p-stepperHeader
                                [id]="getStepHeaderActionId(index)"
                                [template]="step.headerTemplate"
                                [stepperPanel]="step"
                                [getStepProp]="getStepProp(step, 'header')"
                                [index]="index"
                                [disabled]="isItemDisabled(index)"
                                [active]="isStepActive(index)"
                                [highlighted]="index < activeStep"
                                [class]="'p-stepper-action'"
                                [aria-controls]="getStepContentId(index)"
                                (onClick)="onItemClick($event, index)"
                            ></p-stepperHeader>
                        </div>

                        <div class="p-stepper-toggleable-content" [@tabContent]="isStepActive(index) ? { value: 'visible', params: { transitionParams: transitionOptions } } : { value: 'hidden', params: { transitionParams: transitionOptions } }">
                            <ng-container *ngIf="index !== stepperPanels.length - 1">
                                <p-stepperSeparator [template]="step.separatorTemplate" [separatorClass]="'p-stepper-separator'" [stepperPanel]="step" [index]="index" [active]="isStepActive(index)" [highlighted]="index < activeStep" />
                            </ng-container>
                            <p-stepperContent
                                [id]="getStepContentId(index)"
                                [template]="step.contentTemplate"
                                [orientation]="orientation"
                                [stepperPanel]="step"
                                [index]="index"
                                [active]="isStepActive(index)"
                                [highlighted]="index < activeStep"
                                [ariaLabelledby]="getStepHeaderActionId(index)"
                                (onClick)="onItemClick($event, index)"
                                (nextCallback)="nextCallback($event, index)"
                                (prevCallback)="prevCallback($event, index)"
                            />
                        </div>
                    </div>
                </ng-template>
            </ng-template>
            <ng-container *ngIf="endTemplate">
                <ng-container *ngTemplateOutlet="endTemplate"></ng-container>
            </ng-container>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        '[class.p-stepper]': 'true',
        '[class.p-component]': 'true',
        '[class.p-stepper-vertical]': "orientation === 'vertical'"
      },
      animations: [trigger('tabContent', [state('hidden', style({
        height: '0',
        visibility: 'hidden'
      })), state('visible', style({
        height: '*',
        visibility: 'visible'
      })), transition('visible <=> hidden', [animate('250ms cubic-bezier(0.86, 0, 0.07, 1)')]), transition('void => *', animate(0))])],
      styles: ["@layer primeng{.p-stepper-vertical .p-stepper-panel>.p-stepper-toggleable-content{overflow:hidden}.p-stepper-vertical .p-stepper-panel-active>.p-stepper-toggleable-content:not(.ng-animating){overflow:inherit}}\n"]
    }]
  }], null, {
    activeStep: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    linear: [{
      type: Input
    }],
    transitionOptions: [{
      type: Input
    }],
    stepperPanels: [{
      type: ContentChildren,
      args: [StepperPanel]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    onClick: [{
      type: Output
    }],
    activeStepChange: [{
      type: Output
    }]
  });
})();
class StepperModule {
  static ɵfac = function StepperModule_Factory(t) {
    return new (t || StepperModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: StepperModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedModule],
      exports: [Stepper, StepperPanel, StepperContent, StepperHeader, StepperSeparator, SharedModule],
      declarations: [Stepper, StepperPanel, StepperPanel, StepperContent, StepperHeader, StepperSeparator]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Stepper, StepperContent, StepperHeader, StepperModule, StepperPanel, StepperSeparator };
