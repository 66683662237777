import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ChangeSellerDialogComponent } from '../../change-seller-dialog/change-seller-dialog/change-seller-dialog.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { AddSellerDialogComponent } from '../../add-seller-dialog/add-seller-dialog/add-seller-dialog.component';

@Component({
  selector: 'atlas-checkbox-action-dialog',
  standalone: true,
  imports: [NgIf,
    ButtonModule,
    TooltipModule
  ],
  templateUrl: './checkbox-action-dialog.component.html',
  styleUrl: './checkbox-action-dialog.component.scss'
})
export class CheckboxActionDialogComponent {
  @Input() selectedPackages: any[] = [];
  selectedClient: any;
  @Input() distinctVehicleCount: number = 0;
  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(private _dialogService: DialogService,) { }

  get hasSelectedPackages(): boolean {
    return this.selectedPackages.length > 0;
  }

  onClientSelected(client: any) {
    this.selectedClient = client;
  }

  handleAgentAssignment() {
    this.dialogConfig.header = 'Dodeli agentu';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      AddSellerDialogComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe((isAgentAssigned) => {
      if (isAgentAssigned) {
        window.location.reload();
      }
    });
  }

  handleSellerChange() {
    this.dialogConfig.header = 'Promeni prodavca';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      ChangeSellerDialogComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe((isSellerChanged) => {
      if (isSellerChanged) {
        window.location.reload();
      }
    })
  }

  handleRenewPackages() {
    alert('Obnova paketa')
  }
}
