import { JsonPipe, NgIf, NgClass, NgTemplateOutlet, NgForOf } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { PackageDetailSidebarComponent } from '@app/cc-agent/clients/package-detail-sidebar/package-detail-sidebar.component';
import { PackageCardListService } from '@app/cc-agent/services/package-card-list.service';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { PageComponent } from '@shared/components/page/page.component';
import { ClientClient, ClientWithPackagesItem, ClientWithPackagesTableResponse, GetClientWithPackagesTableQuery, IPaginationResponseOfClientWithPackagesTableResponse } from '@shared/model/atlas.api';
import { LocationService } from '@shared/services/location.service';
import { ButtonComponent, ButtonSeverity, ButtonStyle } from '@ui/button/button.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { TableConfig } from '@ui/table/table-common';
import { TableComponent } from '@ui/table/table.component';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule, TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { CheckboxActionDialogComponent } from '../checkbox-action-dialog/checkbox-action-dialog/checkbox-action-dialog.component';

@Component({
  selector: 'atlas-client-with-packages-table',
  standalone: true,
  imports: [PageComponent,
    TableComponent,
    TableModule,
    ButtonComponent,
    ScrollPanelModule,
    JsonPipe,
    NgIf,
    NgClass,
    SkeletonModule,
    NgTemplateOutlet,
    ButtonModule,
    NgForOf,
    BadgeModule,
    ButtonModule,
    InlineWrapperComponent,
    DropdownComponent,
    InputAddonComponent,
    InputTextComponent,
    PackageDetailSidebarComponent,
    CheckboxModule,
    FormsModule,
    CheckboxActionDialogComponent,
    NgTemplateOutlet
  ],
  templateUrl: './client-with-packages-table.component.html',
  styleUrl: './client-with-packages-table.component.scss'
})
export class ClientWithPackagesTableComponent implements OnInit, OnDestroy {

  paginatedData: IPaginationResponseOfClientWithPackagesTableResponse;
  private _subs: Subscription = new Subscription();
  protected tableConfig: TableConfig;
  searchOptions: SelectItem[];
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  selectedPackages: any[] = [];
  selectedVehicles: Set<string> = new Set();
  @Output() clientSelected = new EventEmitter<any>();
  selectedAgent: string;
  selectedCount: number = 0;
  expandedRows: { [key: string]: boolean } = {};
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(
    private client: ClientClient,
    private _locationService: LocationService,
    private service: ClientPackageTableService,
    private _packageCardSerice: PackageCardListService
  ) {
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT_NAME,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.CLIENT_NAME,
        value: SearchOptions.CLIENT_NAME,
      },
      {
        label: SearchOptions.CLIENT_PHONE,
        value: SearchOptions.CLIENT_PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
      {
        label: SearchOptions.IS_ACTIVE,
        value: SearchOptions.IS_ACTIVE,
      },
    ];
  }
  ngOnInit(): void {
    this.load()
  }

  handleCreateClient() {
    this._locationService.routeToCreateClientForm();
  }

  onRowExpand(event: TableRowExpandEvent) {
    this.expandedRows[event.data.id] = true;
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    this.expandedRows[event.data.id] = false;
  }

  toggleRow(client: any): void {
    // Toggle row expansion
    if (this.expandedRows[client.id]) {
      delete this.expandedRows[client.id];
      this.service.removeClient(client);
    } else {
      this.expandedRows[client.id] = true;
      this.service.addClient(client);
    }
  }

  openOptionsDialog(clientId: string) {
    this.service.showPreviewSidebar(clientId);
  }

  handleCheckboxChange(event: any, packageData: any) {
    if (event.checked) {
      this.selectedPackages.push(packageData);
      if (packageData.vehicle) {
        this.selectedVehicles.add(packageData.vehicle);
      }
    } else {
      const index = this.selectedPackages.indexOf(packageData);
      if (index > -1) {
        this.selectedPackages.splice(index, 1);
        if (packageData.vehicle) {
          this.selectedVehicles.delete(packageData.vehicle);
        }
      }
    }
    this.selectedCount = this.selectedPackages.length;
  }

  showComments() {
    alert("Prikaz komentara");
  }

  onHover(client: any): void {
    if (this.expandedRows[client.id]) {
      client.hovered = true; 
    }
  }
  
  onLeave(client: any): void {
    client.hovered = false; 
  }

  selectAllPackages(client: any) {
    const newSelectedState = !client.selected;

    client.selected = newSelectedState;

    if (!newSelectedState) {
        client.packages.forEach(pkg => {
            pkg.selected = false;
        });

        this.selectedPackages = this.selectedPackages.filter(pkg => pkg.clientPackageId !== client.id);
        this.selectedPackages = [];

        client.packages.forEach(pkg => {
            if (pkg.vehicle) {
                this.selectedVehicles.delete(pkg.vehicle); 
            }
        });
    } else {
        client.packages.forEach(pkg => {
            pkg.selected = true; 

            if (!this.selectedPackages.includes(pkg)) {
                this.selectedPackages.push(pkg);
            }
            
            if (pkg.vehicle) {
                this.selectedVehicles.add(pkg.vehicle); 
            }
        });
    }

    this.selectedCount = this.selectedPackages.length;
}
  
  get distinctVehicleCount(): number {
    return this.selectedVehicles.size;
  }

  loadLazy(event: LazyLoadEvent) {
    if (event.first === 0) {
      this.paginatedData.currentPage = 0;
    } else {
      this.paginatedData.currentPage = event.first! / event.rows! + 1;
    }
    this.paginatedData.pageSize = event.rows!;
    this.load();
  }

  getPackageSeverity(status: string): string {
    switch (status?.toLowerCase()) {
      case 'istekao':
        return 'danger';
      case 'aktivan':
        return 'success';
      default:
        return 'info';
    }
  }

  async load() {
    this._loadTable().then((res) => {
      this.paginatedData = res.result;
    });
  }

  private async _loadTable() {
    return await firstValueFrom(
      this.client.getClientWithPackagesTable(
        new GetClientWithPackagesTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: 5,
          clientName: this.searchOptionCtrl.value === SearchOptions.CLIENT_NAME
            ? this.searchCtrl.value
            : undefined,
          clientPhone: this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
            ? this.searchCtrl.value
            : undefined,
          licencePlate: this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
            ? this.searchCtrl.value
            : undefined,
          isActive: this.searchOptionCtrl.value === SearchOptions.IS_ACTIVE
            ? this.searchCtrl.value
            : undefined,
        }),
      ),

    );
  }

  handleRenewPackage(packageInfo: ClientWithPackagesItem) {
    this._packageCardSerice.setSelectedClientPackageId(packageInfo.clientPackageId); 
    this._packageCardSerice.setSelectedPackageId(packageInfo.packageId);
    this._locationService.routeToRenewPackageForm();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }


}

enum SearchOptions {
  CLIENT_NAME = 'Klijent',
  CLIENT_PHONE = 'Telefon',
  LICENCE_PLATE = 'Tablice',
  IS_ACTIVE = 'Aktivnost',
}