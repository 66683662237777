<div class="flex justify-content-between wrapper mb-4 sizing">
  <div class="flex flex-column card-info">
    <div class="price-desc">{{ priceInfo.description }}</div>
    <div class="price-value">{{ priceInfo.price | currency}}</div>
  </div>
  <div class="flex card-action">
    <p-inputSwitch></p-inputSwitch>
    <div (click)="handleRemoveCard()" class="pi pi-trash ml-5 icon mt-1"></div>
  </div>
</div>
