<div class="flex wraper">
  <div class="icon-wrap" *ngIf="type.icon">
    <i class="{{ type.icon }} icon"></i>
  </div>
  <!-- Promeniti ikonicu motora! -->
  <img
    *ngIf="!type.icon"
    [src]="'/assets/images/moto.svg'"
    alt="card icon"
    class="icon-svg"
  />
  <div class="container-checkbox">
  <div class="label ml-3">{{ type.name }}</div>
</div>
<div class="checkbox-div">
  <div class="checkbox ml-3">
    <p-checkbox
      [(ngModel)]="selectedTypes"
      [value]="type.typeId"
      (onChange)="handleCheckboxAdd($event)"
    ></p-checkbox>
  </div>
</div>
</div>
