import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssistanceVehicleDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/assistance-vehicle/assistance-vehicle-dropdown/assistance-vehicle-dropdown.component';
import { BrandAutocompleteComponent } from '@app/features/vehicles/brand-autocomplete/brand-autocomplete.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ModelAutocompleteComponent } from '@app/features/vehicles/model-autocomplete/model-autocomplete.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { VehicleTypeDropdownComponent } from '@app/features/vehicles/vehicle-type-dropdown/vehicle-type-dropdown.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JsonPipe, NgClass } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { Subscription } from 'rxjs';
import { IServiceForAssistanceDropdown } from '@shared/model/atlas.api';
import { MessagesModule } from 'primeng/messages';
import { SelectItem, SharedModule } from 'primeng/api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';

@Component({
  selector: 'atlas-package-service-dropdown',
  standalone: true,
  imports: [
    AssistanceVehicleDropdownComponent,
    BrandAutocompleteComponent,
    InputTextComponent,
    ModelAutocompleteComponent,
    RadioButtonModule,
    VehicleTypeDropdownComponent,
    NgClass,
    ReactiveFormsModule,
    DividerModule,
    MessagesModule,
    SharedModule,
    JsonPipe,
    DropdownComponent,
  ],
  templateUrl: './package-service-dropdown.component.html',
  styleUrl: './package-service-dropdown.component.scss',
})
export class PackageServiceDropdownComponent implements OnInit, OnDestroy {
  searchOptions: SelectItem[] = [];

  private _subs: Subscription;

  constructor(
    public formService: AssistanceFormService,
    private service: AssistanceService,
  ) {
    this._subs = new Subscription();
    if (this.service.packageServices.length > 0) {
      this._setOptions(this.service.packageServices.value);
    }
  }

  ngOnInit() {
    this._subs.add(
      this.service.packageServices.valueChanges.subscribe((services) => {
        this._setOptions(services);
        this.formService.getForm.controls.serviceId.patchValue(null);
      }),
    );
  }

  private _setOptions(services: IServiceForAssistanceDropdown[]) {
    this.searchOptions = services.map((x) => ({
      label: x.label,
      value: x.serviceId,
      disabled: !x.isAvailable,
    }));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
