<div class="form-container" style="display: grid;">
    <div class="gap-3">
      <!-- Unos grada -->
      <div class="city-input">
        <atlas-input-text
          [formCtrl]="form.controls?.cityName!"
          ctrlLabel="Naziv grada"
          formCtrlId="grad"
          ctrlPlaceholder="Naziv grada"
        ></atlas-input-text>      
      </div>
    </div>
  </div>
  