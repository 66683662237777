import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/forms/forms-common';
import { ClientPackageCardItem, IClientPackageCardItem, PackageItem, VehicleTypeItem } from '@shared/model/atlas.api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PackageCardListService {

  constructor(
    private _fb: FormBuilder
  ) {}

  // #region BS

  selectedVehicleType = new BehaviorSubject<VehicleTypeItem>(null);

  setSelectedVehicleType(vehicleType: VehicleTypeItem) {
    this.selectedVehicleType.next(vehicleType);
  }

  getSelectedVehicleType() {
    return this.selectedVehicleType.asObservable();
  }

  vehicleTypeId = new BehaviorSubject<string>(null);

  setVehicleTypeId(vehicleTypeId: string) {
    this.vehicleTypeId.next(vehicleTypeId);
  }

  getVehicleTypeId() {
    return this.vehicleTypeId.asObservable();
  }

  selectedPackageId = new BehaviorSubject<string>(null);

  setSelectedPackageId(packageId: string) {
    this.selectedPackageId.next(packageId);
    this._packageSubject.value.controls.packgeId.patchValue(packageId);
  }

  getSelectedPackageId() {
    return this.selectedPackageId.asObservable();
  }

  selectedPackageName = new BehaviorSubject<string>(null);

  setSelectedPackageName(selected: string) {
    this.selectedPackageName.next(selected);
  }

  getSelectedPackageName() {
    return this.selectedPackageName.asObservable();
  }
  
  selectedPackagePrice = new BehaviorSubject<number>(null);

  setSelectedPackagePrice(selected: number) {
    this.selectedPackagePrice.next(selected);
    this._packageSubject.value.controls.packagePrice.patchValue(selected);
  }

  getSelectedPackagePrice() {
    return this.selectedPackagePrice.asObservable();
  }

  selectedPacakgePriceId = new BehaviorSubject<string>(null);

  setSelectedPacakgePriceId(selected: string) {
    this.selectedPacakgePriceId.next(selected);
    this._packageSubject.value.controls.packagePriceId.patchValue(selected);
  }

  getSelectedPacakgePriceId() {
    return this.selectedPacakgePriceId.asObservable();
  }

  selectedChassisNum = new BehaviorSubject<string>(null);

  setSelectedChassisNum(selected: string) {
    this.selectedChassisNum.next(selected);
    this._packageSubject.value.controls.chassisNum.patchValue(selected);
  }

  getSelectedChassisNum() {
    return this.selectedChassisNum.asObservable();
  }

  selectedClientPackageId = new BehaviorSubject<string>(null);

  setSelectedClientPackageId(selected: string) {
    this.selectedClientPackageId.next(selected);
  }

  getSelectedClientPackageId() {
    return this.selectedClientPackageId.value;
  }

  // #endregion BS

 private _packageSubject = new BehaviorSubject<FormGroup<ControlsOf<ClientPackageCardItem>>>(this.initPackageCardForm());

  getPackage(): FormGroup<ControlsOf<ClientPackageCardItem>> {
  return this._packageSubject.value;
}

  setPackage(packageItem: FormGroup<ControlsOf<ClientPackageCardItem>>) {
    this._packageSubject.next(packageItem);
  }

  initPackageCardForm(model?: ClientPackageCardItem) {
    return this._fb.group<ControlsOf<ClientPackageCardItem>>({
      packgeId: this._fb.control(model?.packgeId, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      chassisNum: this._fb.control(model?.chassisNum),
      packagePriceId: this._fb.control(model?.packagePriceId, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      packagePrice: this._fb.control(model?.packagePrice),
      init: undefined,
      toJSON: undefined
    })
  }
}

export declare type PackageCardForm = FormGroup<ControlsOf<IClientPackageCardItem>>

