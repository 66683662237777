import { Component } from '@angular/core';

@Component({
  selector: 'atlas-recommendation-table',
  standalone: true,
  imports: [],
  templateUrl: './recommendation-table.component.html',
  styleUrl: './recommendation-table.component.scss'
})
export class RecommendationTableComponent {

}
