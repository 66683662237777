import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { InputPasswordComponent } from '@ui/input-password/input-password.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  confirmPasswordValidator,
  ControlsOf,
  markFormGroupTouched,
} from '@shared/forms/forms-common';
import {
  IResetPasswordOnFirstLoginCommand,
  ResetPasswordOnFirstLoginCommand,
  UsersClient,
} from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';

@Component({
  selector: 'atlas-reset-password-first-login-form',
  standalone: true,
  imports: [FormControlPipe, InputPasswordComponent, InputTextComponent],
  templateUrl: './reset-password-first-login-form.component.html',
  styleUrl: './reset-password-first-login-form.component.scss',
})
export class ResetPasswordFirstLoginFormComponent implements OnInit, OnDestroy {
  form: FormGroup<ControlsOf<IResetPasswordOnFirstLoginCommand>> | undefined;
  private _subs: Subscription = new Subscription();

  constructor(
    private _dialogRef: DialogRef,
    private _fb: FormBuilder,
    private _alertService: AlertService,
    private _client: UsersClient,
  ) {}

  ngOnInit() {
    this.form = this._fb.group<ControlsOf<IResetPasswordOnFirstLoginCommand>>({
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(6)],
      }),
      confirmedPassword: new FormControl('', {
        nonNullable: true,
        validators: [
          Validators.required,
          confirmPasswordValidator,
          Validators.minLength(6),
        ],
      }),
    });

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSubmit();
      }),
    );
  }

  private _handleSubmit() {
    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }
    this._subs.add(
      this._client
        .resetPasswordOnFirstLogin(
          new ResetPasswordOnFirstLoginCommand({
            password: this.form.value.password,
            confirmedPassword: this.form.value.confirmedPassword,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close();
        }),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
