<span class="max-w-full p-input-icon-left block">
  <i class="pi pi-search search-icon"></i>
  <p-autoComplete
    pAutoFocus
    (completeMethod)="handleSearch($event)"
    (onKeyUp)="handleEnterClick($event)"
    (onSelect)="onSelectChange($event)"
    [(ngModel)]="model"
    [optionLabel]="fieldName"
    [placeholder]="ctrlPlaceholder"
    [suggestions]="suggestions"
    [inputStyleClass]="'w-full pl-5 text-sm ' + formCtrlClass"
    [styleClass]="'w-full'"
    [disabled]="isDisabled"
    [autofocus]="shouldFocus"
  >
  </p-autoComplete>
</span>
