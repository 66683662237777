import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AddCityForm,
  AdminService,
} from '@app/administrator/services/admin-service';
import {
  CreateParkingCityCommand,
  ParkingCityItem,
  ParkingsClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-add-city',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, InputTextComponent],
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss'],
})
export class AddCityComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  cities: ParkingCityItem[] = [];
  form: AddCityForm;
  currentCityId: string;
  allCities: any[] = [];

  constructor(
    private _dialogRef: DialogRef,
    private _adminService: AdminService,
    private _alertService: AlertService,
    private _parkingClient: ParkingsClient,
  ) {
    this.form = this._adminService.getAddCityForm();
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleSave();
      }),
    );
  }

  _handleSave() {
    if (!this.form?.valid) {
      this.form.markAllAsTouched();
      this._alertService.addWarnMsg('Potrebno je uneti sva polja!');
      return;
    }
    const cityName = this.form.value.cityName;
    this._subs.add(
      this._parkingClient
        .createParkingCity(
          new CreateParkingCityCommand({
            name: cityName,
          }),
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this.exit();
        }),
    );
  }

  exit() {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this.form?.reset();
    this._subs.unsubscribe();
  }
}
