import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import {
  ClientSearchParam,
  IPartnerVehicleAssistanceItem,
  IServiceForAssistanceDropdown,
} from '@shared/model/atlas.api';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MapDirectionsService } from '@angular/google-maps';
import {
  AssistanceForm,
  AssistanceFormService,
} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AssistanceService {

  private assistanceId: string | null = null;

  
  get getClientDetails(): ClientDetails {
    return {
      from: this.getForm.value.from.place,
      to: this.getForm.value.to.place,
      vehicle: this.getForm.value.vehicle.licencePlate,
      clientPackageId: this.getForm.value.clientPackageId,
      numOfPassengers: this.getForm.value.numOfPassengers,
      name: this.getForm.value.client.clientName,
      phones: this.formService.getClientPhones,
    };
  }
  constructor(
    private formService: AssistanceFormService,
    private mapDirectionsService: MapDirectionsService,
    private router: Router,
  ) {}

  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;

  clientSearchParamCtrl: FormControl = new FormControl<ClientSearchParam>(
    ClientSearchParam.LICENCE_PLATE,
  );

  vehicleOptionCtrl: FormControl = new FormControl<VehicleOption>(
    VehicleOption.EXISTING,
  );

  isUpdate: boolean;

  setIsUpdate() {
    this.isUpdate = true;
  }

  packageServices: FormArray<FormControl<IServiceForAssistanceDropdown>> =
    new FormArray<FormControl<IServiceForAssistanceDropdown>>([]);

  updateServices(services: IServiceForAssistanceDropdown[]) {
    this.packageServices.clear();
    services.forEach((service) => {
      this.packageServices.push(new FormControl(service));
    });
  }

  setAssistanceId(id: string) {
    this.assistanceId = id;
  }

  getAssistanceId(): string | null {
    return this.assistanceId;
  }

  setDirection() {
    if (!this.getForm.value.from || !this.getForm.value.to) {
      return;
    }

    const request: google.maps.DirectionsRequest = {
      destination: new google.maps.LatLng(
        this.getForm.value.to.latitude,
        this.getForm.value.to.longitude,
      ),
      origin: new google.maps.LatLng(
        this.getForm.value.from.latitude,
        this.getForm.value.from.longitude,
      ),
      travelMode: google.maps.TravelMode.DRIVING,
    };
    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(
      map((response) => {
        if (response.status === 'ZERO_RESULTS') {
          return null;
        }
        this.getForm.controls.durationText
          .patchValue(`${response.result.routes.at(0).legs.at(0).distance.text}
          (${response.result.routes.at(0).legs.at(0).duration.text})`);
        this.formService.getForm.controls.distanceMeters.patchValue(
          response.result.routes.at(0).legs.at(0).distance.value,
        );
        this.formService.getForm.controls.durationSeconds.patchValue(
          response.result.routes.at(0).legs.at(0).duration.value,
        );
        return response.result;
      }),
    );
  }

  get getForm(): AssistanceForm {
    return this.formService.getForm;
  }

  selectedPartner: IPartnerVehicleAssistanceItem | undefined;
  selectedDriver: string | undefined;

  partners: IPartnerVehicleAssistanceItem[] = [];

  set setPartners(partners: IPartnerVehicleAssistanceItem[]) {
    this.partners = [...partners];
  }

  get getPartners() {
    return this.partners;
  }

  set setSelectedPartner(
    partnerVehicle: IPartnerVehicleAssistanceItem | undefined,
  ) {
    this.selectedPartner = partnerVehicle;
    this.formService.setPartnerVehicle = partnerVehicle?.id;
  }

  set setDriver(value: string) {
    this.selectedDriver = value;
  }

  resetPartner() {
    this.setSelectedPartner = undefined;
  }

  currentStep = 0;

  get isFirstStep(): boolean {
    return this.currentStep === 0;
  }

  reset() {
    this.formService.resetForm();
    this.partners = [];
    this.isUpdate = false;
    this.selectedPartner = undefined;
    this.vehicleOptionCtrl.patchValue(VehicleOption.EXISTING);
  }
}

export enum VehicleOption {
  EXISTING = 'existing',
  NEW = 'new',
}

export interface ClientDetails {
  name: string;
  phones: string;
  from: string;
  to: string;
  vehicle: string;
  clientPackageId: string;
  numOfPassengers: number;
}

export declare type ASSISTANCE_TABS =
  | 'sve'
  | 'u_toku'
  | 'zavrsene'
  | 'otkazane'
  | 'zatrazene'
  | 'nove';
