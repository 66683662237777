<p-button
  [id]="btnId"
  [type]="btnType"
  [icon]="btnIcon"
  [iconPos]="btnIconPosition"
  [pTooltip]="btnTooltip"
  [tooltipPosition]="btnTooltipPosition"
  [styleClass]="
    btnStyle + ' ' + btnSeverity + ' atlas-btn outline-none ' + btnClass
  "
  (click)="handleBtnClick($event)"
  [disabled]="isBtnDisabled"
  [label]="btnLabel"
>
  <ng-content></ng-content>
</p-button>
