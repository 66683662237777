import { CurrencyPipe, DecimalPipe, NgClass, NgFor, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrandAutocompleteComponent } from '@app/features/vehicles/brand-autocomplete/brand-autocomplete.component';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { PackageCardListService } from '../services/package-card-list.service';
import { GetPackagesPublicResponse, PackageCardItem, PackageItem, PackagePriceDropdownItem, PackagesClient, PaymentType, VehicleTypeItem } from '@shared/model/atlas.api';
import { DropdownModule } from 'primeng/dropdown';
import { CurrencyNumberPipe } from '@shared/pipes/currency-number.pipe';

@Component({
  selector: 'atlas-package-card-list',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    InputAddonComponent,
    DropdownComponent,
    DropdownModule,
    IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    NgOptimizedImage,
    PackageCardListComponent,
    CurrencyPipe,
    CurrencyNumberPipe
  ],
  templateUrl: './package-card-list.component.html',
  styleUrl: './package-card-list.component.scss'
})
export class PackageCardListComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: any;
  selectedVehicle: string | null;
  selectedPackage: PackageItem;
  isPackageDropdownOpen: boolean = false;
  allPackages: PackageCardItem[] = [];
  secondStepControl = new FormControl('');

  actionPricesDropdown: PackagePriceDropdownItem[] = []
  selectedActionPrice: string = null;
  selectedPackageName: string = null;
  selectedPackageId: string = null;

  priceInput: any = null;
  showPriceInput: boolean = false;
  selectedVehicleType: VehicleTypeItem | null;
  vehicleType: string;

  constructor(
    private _packagesService: PackageCardListService,
    private _packagesClent: PackagesClient,
    private decimalPipe: DecimalPipe,
  ) {}

  ngOnInit(): void {
    this.form = this._packagesService.initPackageCardForm();

    this._subs.add(
      this._packagesService.getSelectedVehicleType().subscribe((x) => {
        if (x) {
          this.selectedVehicleType = x
          this.selectedVehicle = x.name;
          this._loadPackages(x.typeId);
        }
      })
    );

    this._subs.add(
      this._packagesService.getVehicleTypeId().subscribe((x) => {
        this.vehicleType = x;
        this._loadPackages(x);
      })
    )

    this._subs.add(
      this._packagesService.getSelectedPackageId().subscribe((x) => {
        if (x) {
          this.selectedPackageId = x
          this._loadPackage(x)
        }
      })
    )
  }
 
  private async _loadPackage(packageId: string) {
    await this._loadPackages(this.vehicleType);

    var selectedPackage = this.allPackages.find(x => x.id === packageId);
  
    this._setPackageActionPrices(selectedPackage.id);
    this.selectPackage(selectedPackage);
  }

  togglePackageDropdown() {
    this.isPackageDropdownOpen = !this.isPackageDropdownOpen;
  }

  clearSelectedPackage(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPackage = null;
  }

  private _loadPackages(vehicleTypeId?: string): Promise<void> {
    return new Promise((resolve) => {
      this._subs.add(
        this._packagesClent
          .getAllForPackageCard(vehicleTypeId)
          .subscribe((x) =>{
            this.allPackages = x.result.cardPackages || []
            resolve();
          })
      )
    })
  }

  private _setPackageActionPrices(packageId: string) {
    this.allPackages.find(x => x.id === packageId).actionPrices.forEach(x => {
      const action = new PackagePriceDropdownItem({
        id: x.id,
        label: x.label,
        price: x.price
      })
      this.actionPricesDropdown.push(action)
    })

    const firstAction = new PackagePriceDropdownItem({
        id: '0',
        label: 'Upisi cenu'
      })

      this.actionPricesDropdown.unshift(firstAction)
  }

  selectPackage(pkg: PackageItem) {
    this.selectedPackage = pkg;
    this.isPackageDropdownOpen = false;
    this._setPackageActionPrices(pkg.id);
    this._packagesService.setSelectedPackageName(pkg.name)
    this._packagesService.setSelectedPackagePrice(pkg.price)
  }

  handleActionPriceChange(event: any) {
    if (event.value.id === '0') {
      this.showPriceInput = true
    }
    this.selectedActionPrice = event.value.price;
    this._packagesService.setSelectedPackagePrice(event.value.price);
    this._packagesService.setSelectedPacakgePriceId(event.value.id);
  }

  handlePriceInput(event: any) {
    const currentValue = event.target.value.replace(/[.,]/g, '');
    this.priceInput = parseFloat(currentValue);
    this._packagesService.setSelectedPackagePrice(this.priceInput);
}

  handleChassisInputChange(event: any) {
    this._packagesService.setSelectedChassisNum(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe()
    this._packagesService.setSelectedPackagePrice(null)
  }

}
