<p-confirmDialog #cd appendTo="body" [transitionOptions]="'70ms'" >
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div *ngIf="message.icon" class="border-circle inline-flex justify-content-center align-items-center h-5rem w-5rem -mt-8 header-circle">
                <i *ngIf="!message.icon.includes('svg')" [ngClass]="'pi text-4xl m-0 text-color ' + message.icon"></i>
                <img *ngIf="message.icon.includes('svg')" [ngSrc]="'assets/images/confirmation-dialog/' + message.icon" width="40" height="40" [alt]="message.icon">
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0 text-gray-600">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4 w-full">
                <atlas-button class="flex-1" btnClass="w-full" [btnLabel]="message.rejectLabel ?? 'Odustani'" [btnStyle]="ButtonStyle.OUTLINED" [btnSeverity]="ButtonSeverity.SECONDARY_GREY" (btnClicked)="cd.reject()"></atlas-button>
                <atlas-button class="flex-1" btnClass="w-full" [btnLabel]="message.acceptLabel ?? 'Potvrdi'" [btnStyle]="message.acceptButtonStyleClass ?? ButtonStyle.DIALOG" (btnClicked)="cd.accept()"></atlas-button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>