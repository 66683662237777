<div class="flex flex-column gap-2 w-full" *ngIf="!formCtrlIcon">
  <label [for]="formCtrlId" *ngIf="showLabel">{{ ctrlLabel }} </label>
  <p-inputGroup>
    <input
      *ngIf="!isPriceFormat"
      pInputText
      pAutoFocus
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [placeholder]="ctrlPlaceholder"
      autocomplete="off"
      [ngClass]="formCtrlClass"
      [autofocus]="shouldFocus"
      [pKeyFilter]="formCtrlFilter"
    />
    <p-inputNumber
      *ngIf="isPriceFormat"
      pAutoFocus
      (blur)="handleBlur($event)"
      (keyup)="handleKeyUp($event)"
      [formControl]="formCtrl"
      [id]="formCtrlId"
      [placeholder]="ctrlPlaceholder"
      [ngClass]="formCtrlClass"
      [autofocus]="shouldFocus"
      autocomplete="off"
      mode="decimal"
  [locale]="'de-DE'"
  [minFractionDigits]="2"
  [maxFractionDigits]="2"
    />

    <p-inputGroupAddon
      [ngClass]="
        formCtrl.errors?.['required'] && (formCtrl.dirty || formCtrl.touched)
          ? 'error-info'
          : ''
      "
    >
      <span class="uppercase">{{ addonText }}</span>
    </p-inputGroupAddon>
  </p-inputGroup>
  <ng-container *ngIf="!hideErrors">
    <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
    <atlas-error-email [formCtrl]="formCtrl"></atlas-error-email>
    <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
  </ng-container>
</div>

<div *ngIf="formCtrlIcon" class="flex flex-column gap-2 w-full">
  <label [for]="ctrlLabel" *ngIf="showLabel">{{ ctrlLabel }} </label>
  <div class="p-input-icon-left">
    <i [class]="formCtrlIcon"></i>
    <p-inputGroup>
      <input
        *ngIf="!isPriceFormat"
        pInputText
        pAutoFocus
        (blur)="handleBlur($event)"
        (keyup)="handleKeyUp($event)"
        [formControl]="formCtrl"
        [id]="formCtrlId"
        [pKeyFilter]="formCtrlFilter"
        [placeholder]="ctrlPlaceholder"
        autocomplete="off"
        [ngClass]="'pl-5 w-full' + formCtrlClass"
        [autofocus]="shouldFocus"
      />
      <p-inputNumber
        *ngIf="isPriceFormat"
        pAutoFocus
        (blur)="handleBlur($event)"
        (keyup)="handleKeyUp($event)"
        [formControl]="formCtrl"
        [id]="formCtrlId"
        [placeholder]="ctrlPlaceholder"
        [ngClass]="formCtrlClass"
        [autofocus]="shouldFocus"
        autocomplete="off"
        mode="decimal"
  [locale]="'de-DE'"
  [minFractionDigits]="2"
  [maxFractionDigits]="2"
      />
      <p-inputGroupAddon
        [ngClass]="
          formCtrl.errors?.['required'] && (formCtrl.dirty || formCtrl.touched)
            ? 'error-info'
            : ''
        "
      >
        <i [ngClass]="formCtrlIcon"></i>
      </p-inputGroupAddon>
    </p-inputGroup>
    <ng-container *ngIf="!hideErrors">
      <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
      <atlas-error-email [formCtrl]="formCtrl"></atlas-error-email>
      <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
    </ng-container>
  </div>
</div>
