<div class="flex flex-row container overflow-hidden">
  <div class="menu-size">
    <atlas-menu></atlas-menu>
  </div>
  <div class="flex flex-grow-1 layout-container px-5 py-3">
    <div class="w-full">
      <div>
        <p-breadcrumb
          *ngIf="false"
          [home]="home"
          [model]="menuItems!"
        ></p-breadcrumb>
      </div>

      <div class="pt-3">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<atlas-assistance-toast *ngIf="shouldShowAssistancesToast"></atlas-assistance-toast>
<atlas-confirm-dialog></atlas-confirm-dialog>
