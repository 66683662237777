import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { InputPasswordComponent } from '../../../ui/input-password/input-password.component';
import {
  ButtonComponent,
  ButtonStyle,
} from '../../../ui/button/button.component';
import { FormControlPipe } from '../../../shared/pipes/form-control.pipe';
import { finalize, Subscription } from 'rxjs';
import {
  IResetPasswordCommand,
  ResetPasswordCommand,
  UsersClient,
} from '../../../shared/model/atlas.api';
import { LocationService } from '../../../shared/services/location.service';
import { FormGroup } from '@angular/forms';
import { ControlsOf } from '../../../shared/forms/forms-common';
import { ResetPasswordFormService } from './reset-password-form.service';
import { LoaderService } from '../../../shared/components/loader/loader.service';
import { Router } from '@angular/router';
import { WhiteLogoComponent } from '../../../ui/white-logo/white-logo.component';

@Component({
  selector: 'atlas-reset-password',
  standalone: true,
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
  providers: [UsersClient],
  imports: [
    InputTextComponent,
    InputPasswordComponent,
    ButtonComponent,
    FormControlPipe,
    WhiteLogoComponent,
  ],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form!: FormGroup<ControlsOf<IResetPasswordCommand>>;
  protected readonly ButtonStyle = ButtonStyle;
  email: string = '';

  constructor(
    _fs: ResetPasswordFormService,
    private _locationService: LocationService,
    private _loaderService: LoaderService,
    private _client: UsersClient,
    private _router: Router,
  ) {
    this.form = _fs.getForm();
    const navigation = this._router.getCurrentNavigation();
    if (navigation != null) {
      const state = navigation!.extras.state as {
        email: string;
      };
      this.email = state.email;
    }
  }

  ngOnInit(): void {
    this.form.patchValue({
      email: this.email,
    });
  }

  handleResetPassword() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this._loaderService.setLoadingText = 'Resetovanje lozinke...';
    this._loaderService.setShowLoader = true;
    this._subs.add(
      this._client
        .resetPassword(
          new ResetPasswordCommand({
            email: this.form.value.email!,
            password: this.form.value.password!,
            confirmedPassword: this.form.value.confirmedPassword!,
            verificationCode: this.form.value.verificationCode!,
          }),
        )
        .pipe(
          finalize(() => {
            this._loaderService.reset();
          }),
        )
        .subscribe({
          next: (res) => {
            this._locationService.routeToLogin('');
          },
        }),
    );
  }

  handleBackToLogin() {
    this._locationService.routeToLogin('');
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
