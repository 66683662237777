import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({
  name: 'currencyNumber',
  standalone: true,
})
export class CurrencyNumberPipe implements PipeTransform {
  constructor(private _currencyPipe: CurrencyPipe) {}

  transform(value: number | string, ...args: any[]): any {
    if (args[0] === 'EUR') {
      args[0] = '\u20AC';
    }
    return this._currencyPipe.transform(
      value,
      '',
      args[0] ? args[0] + ' ' : args[0] === null ? '' : 'symbol',
      args[1] ? '.' + args[1] : '.2',
      args[0] && args[0] != 'RSD' ? 'en-US' : undefined,
    );
  }
}
