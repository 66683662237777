<div class="h-13rem">
  <div class="text-sm-medium mb-2">Razlog</div>
  <textarea
    id="cancel-reason"
    rows="7"
    cols="30"
    pInputTextarea
    [(ngModel)]="inputValue"
    class="w-full"
    placeholder="Unesite razlog..."
    [required]="true"
  ></textarea>
</div>
