import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { TabViewModule } from 'primeng/tabview';
import { TableComponent } from '@ui/table/table.component';
import {
  ActionMode,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@ui/table/table-common';
import {
  AssistancesClient,
  AssistanceStatus,
  GetAssistanceTableQuery,
  IAssistanceTableResponse,
  IPaginationResponseOfAssistanceTableResponse,
  PaginationResponseOfAssistanceTableResponse,
  SetStatusInProgressCommand,
  SetStatusResponse,
} from '@shared/model/atlas.api';
import { firstValueFrom, Subscription } from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { LocationService } from '@shared/services/location.service';
import { ConfirmationService, LazyLoadEvent, SelectItem } from 'primeng/api';
import { ButtonComponent, ButtonSeverity } from '@ui/button/button.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { AssistanceFormService } from '../assistance-form/services/assistance-form.service';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { CancelAssistanceDialogComponent } from '../cancel-assistance.dialog/cancel-assistance.dialog.component';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from '@ui/dialog/dialog.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { BadgeModule } from 'primeng/badge';
import { ActivatedRoute, Router } from '@angular/router';
import { ASSISTANCE_TABS } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { GuaranteeFormComponent } from '@app/sos-agent/assistances/guarantee-form/guarantee-form.component';

@Component({
  selector: 'atlas-assistance-table',
  standalone: true,
  imports: [
    PageComponent,
    TabViewModule,
    TableComponent,
    InputTextModule,
    ReactiveFormsModule,
    InputSearchComponent,
    ClientForAssistanceAutocompleteComponent,
    DropdownComponent,
    InlineWrapperComponent,
    InputTextComponent,
    FormsModule,
    TableComponent,
    ButtonComponent,
    ContextMenuModule,
    PageComponent,
    TabViewModule,
    ConfirmDialogModule,
    InputTextModule,
    InputTextComponent,
    BadgeModule,
  ],
  templateUrl: './assistance-table.component.html',
  styleUrl: './assistance-table.component.scss',
})
export class AssistanceTableComponent
  implements OnInit, OnDestroy, OnTableInit
{
  private _subs: Subscription;
  searchOptionCtrl: FormControl;
  searchCtrl: FormControl;
  searchOptions: SelectItem[];
  statusSearch: AssistanceStatus;
  totalRequested: number | undefined;
  tableConfig: TableConfig;
  paginatedData: IPaginationResponseOfAssistanceTableResponse;
  activeIndex: number | undefined;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(
    private client: AssistancesClient,
    private alert: AlertService,
    private locationService: LocationService,
    private confirmationService: ConfirmationService,
    private _assistanceFormService: AssistanceFormService,
    private _dialogService: DialogService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.totalRequested = 0;
    this._subs = new Subscription();
    this.tableConfig = new TableConfig({
      columns: [],
    });
    this.paginatedData = {
      data: [],
      currentPage: 0,
    };
    this.statusSearch = AssistanceStatus.EMPTY;
    this.searchOptionCtrl = new FormControl<SearchOptions>(
      SearchOptions.CLIENT,
    );
    this.searchCtrl = new FormControl<string>('');
    this.searchOptions = [
      {
        label: SearchOptions.CLIENT,
        value: SearchOptions.CLIENT,
      },
      {
        label: SearchOptions.CLIENT_PHONE,
        value: SearchOptions.CLIENT_PHONE,
      },
      {
        label: SearchOptions.LICENCE_PLATE,
        value: SearchOptions.LICENCE_PLATE,
      },
      {
        label: SearchOptions.PARTNER,
        value: SearchOptions.PARTNER,
      },
    ];
  }

  ngOnInit() {
    this.countRequested();

    this.alertService.requestCountChanged$.subscribe(() => {
      this.countRequested();
    });

    this.setTableConfig().then();

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab']?.toLowerCase();
      const tabLabels = [
        'sve',
        'u_toku',
        'zavrsene',
        'otkazane',
        'zatrazene',
        'nove',
      ];
      if (tab && tabLabels.includes(tab)) {
        this.activeIndex = tabLabels.indexOf(tab);
      } else {
        this.activeIndex = 0;
        this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: { tab: 'sve' },
            queryParamsHandling: 'merge',
          })
          .then();
      }
    });
  }

  countRequested() {
    this._subs.add(
      this.client.getRequestedCount().subscribe((res) => {
        this.totalRequested = res.result;
      }),
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (event.first === 0) {
          this.paginatedData.currentPage = 0;
        } else {
          this.paginatedData.currentPage = event.first! / event.rows! + 1;
        }
        this.paginatedData.pageSize = event.rows!;
        this.load();
      },
      selectMode: SelectMode.MULTI,
      columns: [
        {
          header: 'Broj slučaja',
          field: 'caseNumber',
          type: 'text',
        },
        {
          header: 'Klijent',
          field: 'client',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'clientPhone',
              type: 'text',
            },
          ],
        },
        {
          header: 'Status',
          field: 'statusBadge',
          type: 'badge',
          styleClass: 'font-bold',
          badgeClass: 'assistance-'
        },
        {
          header: 'Vozilo',
          field: 'vehicle',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'licencePlate',
              type: 'text',
            },
          ],
        },
        {
          header: 'Relacija',
          field: 'from',
          type: 'text',
          columns: [
            {
              header: '',
              field: 'to',
              type: 'text',
            },
          ],
        },
        {
          header: 'Saradnik',
          field: 'partner',
          type: 'text',
          styleClass: 'text-black-alpha-90 font-semibold',
          columns: [
            {
              header: '',
              field: 'partnerPhone',
              type: 'text',
            },
          ],
        },
        {
          header: 'Vozač',
          field: 'driver',
          type: 'text',
          columns: [
            {
              header: '',
              field: 'driverPhone',
              type: 'text',
            },
          ],
        },
        {
          header: 'Agent',
          field: 'agent',
          type: 'text',
        },
        {
          header: 'Datum',
          field: 'createdDate',
          styleClass: 'text-black-alpha-90 font-semibold',
          type: 'text',
          columns: [
            {
              header: '',
              field: 'createdTime',
              type: 'text',
            },
          ],
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          title: 'Izmeni asistenciju',
          icon: 'pi pi-pencil',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return (
              rowData.status == AssistanceStatus.NEW ||
              rowData.status == AssistanceStatus.IN_PROGRTESS ||
              rowData.status == AssistanceStatus.DRAFT
            );
          },

          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            this._assistanceFormService.setStatus(rowData.status);
            this.locationService.routeToEditAssitanceForm(rowData.id);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pregled asistencije',
          icon: 'pi pi-eye',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return (
              rowData.status == AssistanceStatus.NEW ||
              rowData.status == AssistanceStatus.IN_PROGRTESS ||
              rowData.status == AssistanceStatus.CANCELED ||
              rowData.status == AssistanceStatus.FINISHED
            );
          },

          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            this.locationService.routeToPreviewAssistence(rowData.id);
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Obradi zahtev',
          icon: 'pi pi-verified',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.REQUEST;
          },

          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            this._assistanceFormService.setStatus(rowData.status);
            this.locationService.routeToEditAssitanceForm(rowData.id);
          },
        },
        // {
        //   mode: ActionMode.SINGLE,
        //   label: 'Pregledaj asistenciju',
        //   icon: 'pi pi-eye',
        //   shouldDisplayByCondition: (rowIdx: number) => {
        //     const rowData: IAssistanceTableResponse =
        //       this._getPaginatedItem(rowIdx);
        //     return rowData.status != AssistanceStatus.DRAFT;
        //   },
        //   callback: (rowIdx: number) => {
        //     const rowData: IAssistanceTableResponse =
        //       this._getPaginatedItem(rowIdx);
        //     this._getAssistance(rowData.id).then((res) => {
        //       this.locationService.routeToEditAssitanceForm(res.result.value);
        //     });
        //   },
        // },
        {
          mode: ActionMode.MULTI,
          label: 'Započni asistenciju',
          icon: 'pi pi-play',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.NEW;
          },
          callback: (rowIdx: number) => {
            this.confirmationService.confirm({
              header: 'Izmena statusa asistencije',
              message: 'Da li želiš da započneš asistenciju?',
              acceptButtonStyleClass: ButtonSeverity.DANGER,
              rejectLabel: 'Odustani',
              acceptLabel: 'Započni',
              icon: 'pi pi-play',
              accept: () => {
                const rowData: IAssistanceTableResponse =
                  this._getPaginatedItem(rowIdx);
                this._setInProgress(rowData.id).then((res) => {
                  this.alert.addSuccessMsg(res.result.message);
                  this._updateTableItems(
                    rowIdx,
                    res.result.value.statusBadge,
                    AssistanceStatus.IN_PROGRTESS,
                  );
                });
              },
              reject: () => {},
            });
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Završi asistenciju',
          icon: 'pi pi-stop',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.IN_PROGRTESS;
          },
          callback: (rowIdx: number) => {
            this.confirmationService.confirm({
              header: 'Izmena statusa asistencije',
              message: 'Da li želiš da završiš asistenciju?',
              acceptButtonStyleClass: ButtonSeverity.DANGER,
              rejectLabel: 'Odustani',
              acceptLabel: 'Završi',
              icon: 'pi pi-stop',
              accept: () => {
                const rowData: IAssistanceTableResponse =
                  this._getPaginatedItem(rowIdx);
                this._setFinished(rowData.id).then((res) => {
                  this.alert.addSuccessMsg(res.result.message);
                  this._updateTableItems(
                    rowIdx,
                    res.result.value.statusBadge,
                    AssistanceStatus.FINISHED,
                  );
                });
              },
              reject: () => {},
            });
          },
        },
        {
          mode: ActionMode.SINGLE,
          title: 'Pošalji garanciju',
          icon: 'pi pi-envelope',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return rowData.status == AssistanceStatus.FINISHED;
          },
          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Slanje garancije';
            //this.dialogConfig.headerDescription = 'Potvrdite slanje';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi slanje',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              id: rowData.id,
            };
            this.dialogConfig.closable = true;

            this.openGuaranteeDialog(rowIdx);
          },
        },
        {
          mode: ActionMode.MULTI,
          label: 'Otkaži asistenciju',
          shouldDisplayByCondition: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);
            return (
              rowData.status == AssistanceStatus.NEW ||
              rowData.status == AssistanceStatus.IN_PROGRTESS ||
              rowData.status == AssistanceStatus.REQUEST
            );
          },
          icon: 'pi pi-times',
          callback: (rowIdx: number) => {
            const rowData: IAssistanceTableResponse =
              this._getPaginatedItem(rowIdx);

            this.dialogConfig.header = 'Otkazivanje';
            this.dialogConfig.headerDescription =
              'Potvrdite otkazivanje asistencije';
            this.dialogConfig.customSubmitButton = {
              label: 'Potvrdi otkazivanje',
              icon: 'pi pi-times',
              style: 'max-width: 100px',
            };
            this.dialogConfig.customCancelButton = {
              label: 'Otkaži',
              icon: '',
              style: 'max-width: 100px',
            };
            this.dialogConfig.data = {
              id: rowData.id,
            };
            this.dialogConfig.closable = true;

            this.openCancelDialog(rowIdx);
          },
        },
      ],
    });
  }

  openCancelDialog(rowIdx: number): void {
    const ref = this._dialogService.open(
      CancelAssistanceDialogComponent,
      this.dialogConfig,
    );
    this._subs.add(
      ref.onClose.subscribe((res: SetStatusResponse) => {
        if (res) {
          if (
            this.paginatedData.data.at(rowIdx).status ===
            AssistanceStatus.REQUEST
          ) {
            this.alertService.notifyRequestCountChanged();
          }
          if (this.statusSearch === AssistanceStatus.EMPTY) {
            this._updateTableItems(
              rowIdx,
              res.statusBadge,
              AssistanceStatus.CANCELED,
            );
          } else {
            this.load();
          }
        }
      }),
    );
  }

  openGuaranteeDialog(rowIdx: number): void {
    const ref = this._dialogService.open(
      GuaranteeFormComponent,
      this.dialogConfig,
    );
  }

  handleTabChange(idx: any) {
    let selectedTab: string;

    switch (idx) {
      case 1:
        this.statusSearch = AssistanceStatus.IN_PROGRTESS;
        selectedTab = 'u_toku';
        break;
      case 2:
        this.statusSearch = AssistanceStatus.FINISHED;
        selectedTab = 'zavrsene';
        break;
      case 3:
        this.statusSearch = AssistanceStatus.CANCELED;
        selectedTab = 'otkazane';
        break;
      case 4:
        this.statusSearch = AssistanceStatus.REQUEST;
        selectedTab = 'zatrazene';
        break;
      case 5:
        this.statusSearch = AssistanceStatus.NEW;
        selectedTab = 'nove';
        break;
      default:
        this.statusSearch = AssistanceStatus.EMPTY;
        selectedTab = 'sve';
    }

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { tab: selectedTab },
        queryParamsHandling: 'merge',
      })
      .then();

    this.load();
  }

  load() {
    this._getList().then((res) => {
      this.paginatedData = res.result;
    });
  }

  private async _getList() {
    return await firstValueFrom(
      this.client.getTable(
        new GetAssistanceTableQuery({
          pageNumber: this.paginatedData.currentPage,
          pageSize: this.paginatedData.pageSize,
          status: this.statusSearch,
          clientName:
            this.searchOptionCtrl.value === SearchOptions.CLIENT
              ? this.searchCtrl.value
              : undefined,
          clientPhone:
            this.searchOptionCtrl.value === SearchOptions.CLIENT_PHONE
              ? this.searchCtrl.value
              : undefined,
          licencePlate:
            this.searchOptionCtrl.value === SearchOptions.LICENCE_PLATE
              ? this.searchCtrl.value
              : undefined,
          partnerName:
            this.searchOptionCtrl.value === SearchOptions.PARTNER
              ? this.searchCtrl.value
              : undefined,
        }),
      ),
    );
  }

  private async _setInProgress(id: string) {
    return await firstValueFrom(
      this.client.setStatusInProgress(
        new SetStatusInProgressCommand({
          id: id,
        }),
      ),
    );
  }

  private async _setFinished(id: string) {
    return await firstValueFrom(
      this.client.setStatusFinished(
        new SetStatusInProgressCommand({
          id: id,
        }),
      ),
    );
  }

  private _getPaginatedItem(index: number) {
    return this.paginatedData.data[this._getPaginatedIndex(index)];
  }

  private _getPaginatedIndex(index: number): number {
    if (this.paginatedData.currentPage > 1) {
      const idx =
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize;
      return (
        (index - this.paginatedData.pageSize) % this.paginatedData.pageSize
      );
    }
    return index;
  }

  private _updateTableItems(
    rowIdx: number,
    statusBadge: any,
    status: AssistanceStatus,
  ) {
    const pagData = this.paginatedData.data;
    pagData.at(rowIdx).statusBadge = statusBadge;
    pagData.at(rowIdx).status = status;
    this.paginatedData = new PaginationResponseOfAssistanceTableResponse({
      data: pagData,
      ...this.paginatedData,
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

enum SearchOptions {
  CLIENT = 'Klijent',
  CLIENT_PHONE = 'Telefon',
  LICENCE_PLATE = 'Tablice',
  PARTNER = 'Saradnik',
}
