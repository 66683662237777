import { Component, Input } from '@angular/core';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';

@Component({
  selector: 'atlas-input-switch',
  standalone: true,
  imports: [InputSwitchModule, FormsModule],
  templateUrl: './input-switch.component.html',
  styleUrl: './input-switch.component.scss',
})
export class InputSwitchComponent {
  /** I/O */
  @Input() formCtrl!: FormControl;
  @Input() formGroup!: FormGroup;
  @Input() isDisabled!: boolean;
  @Input() ctrlName!: string;
}
