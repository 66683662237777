<div class="ml-1" style="max-width: 700px">
  <div class="step-title">Korak 1</div>
  <div class="text-3xl font-semibold mt-1">Klijent</div>
  <div class="mt-6 title-text">Vrsta klijenta</div>

  <div class="flex gap-4 box-padding mt-5">
    <atlas-icon-checkbox
      name="Fizičko lice"
      [iconClass]="'pi pi-user'"
      [isSelected]="selectedOption === 'Fizicko'"
      (select)="selectFizicko()"
    ></atlas-icon-checkbox>
    <atlas-icon-checkbox
      name="Pravno lice"
      [isSelected]="selectedOption === 'Pravno'"
      [iconClass]="'pi pi-briefcase'"
      (select)="selectPravno()"
    ></atlas-icon-checkbox>
  </div>
    <div class="mt-5 title-text">Detalji klijenta</div>
    <div class="mt-3">
      <div class="Mail-Num">
        <atlas-input-text
          [formCtrl]="firstStepControl"
          ctrlLabel="Ime"
          ctrlPlaceholder="Ime"
          hideErrors="true"
          formCtrlClass="w-full lg:w-19rem"
        ></atlas-input-text>
        <atlas-input-text
          [formCtrl]="firstStepControl"
          ctrlLabel="Prezime"
          ctrlPlaceholder="Prezime"
          hideErrors="true"
          formCtrlClass="w-full lg:w-19rem"
        ></atlas-input-text>
      </div>
    </div>
    <div class="mt-3">
      <div class="Mail-Num">
        <atlas-input-phone
          [formCtrl]="firstStepControl"
          ctrlLabel="Broj telefona"
          ctrlPlaceholder="Broj telefona"
          hideErrors="true"
          formCtrlClass="w-full lg:w-19rem"
        ></atlas-input-phone>
        <atlas-input-mail
          [formCtrl]="firstStepControl"
          ctrlLabel="Imejl adresa"
          ctrlPlaceholder="Imejl adresa"
          hideErrors="true"
          formCtrlClass="w-full lg:w-19rem"
        ></atlas-input-mail>
      </div>
    </div>
    <div *ngIf="selectedOption === 'Fizicko'" class="mt-6">
      <atlas-dropdown
        [options]="roles"
        ctrlLabel="Medij"
        [formCtrl]="firstStepControl"
        ctrlPlaceholder="Izaberi medij"
        (optionChanged)="handleRoleChange($event)"
        ctrlStyleClass="w-full lg:w-19rem"
      ></atlas-dropdown>
    </div>
    <div *ngIf="selectedOption === 'Pravno'" class="mt-6">
      <div class="mt-3 mb-3 title-text">Detalji firme</div>
        <div class="Mail-Num">
            <atlas-input-text
              [formCtrl]="firstStepControl"
              ctrlLabel="Naziv firme"
              ctrlPlaceholder="Naziv firme"
              hideErrors="true"
              formCtrlClass="w-full lg:w-19rem"
            ></atlas-input-text>
            <atlas-input-text
              [formCtrl]="firstStepControl"
              ctrlLabel="Adresa firme"
              ctrlPlaceholder="Adresa firme"
              hideErrors="true"
              formCtrlClass="w-full lg:w-19rem"
            ></atlas-input-text>
          </div>
          <div class="Mail-Num mt-3">
          <atlas-input-text
          [formCtrl]="firstStepControl"
          ctrlLabel="PIB"
          ctrlPlaceholder="PIB"
          hideErrors="true"
          formCtrlClass="w-full lg:w-19rem"
        ></atlas-input-text>
    </div>
    <div class="Mail-Num mt-3">
        <atlas-dropdown
        [options]="roles"
        ctrlLabel="Medij"
        [formCtrl]="firstStepControl"
        ctrlPlaceholder="Izaberi medij"
        (optionChanged)="handleRoleChange($event)"
        ctrlStyleClass="w-full lg:w-19rem"
      ></atlas-dropdown>
    </div>
    </div>
</div>
