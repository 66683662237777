import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { IAssistanceVehicle } from '@shared/model/atlas.api';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { AssistanceFormService } from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';

@Component({
  selector: 'atlas-assistance-vehicle-dropdown',
  standalone: true,
  imports: [DropdownComponent, JsonPipe],
  templateUrl: './assistance-vehicle-dropdown.component.html',
  styleUrl: './assistance-vehicle-dropdown.component.scss',
})
export class AssistanceVehicleDropdownComponent implements OnInit, OnDestroy {
  searchOptions: any[] = [];

  private _subs: Subscription;
  selectedVehicleCtrl: FormControl<string | null>;
  vehicles: IAssistanceVehicle[] = [];

  constructor(
    private formService: AssistanceFormService,
    private service: AssistanceService,
  ) {
    this._subs = new Subscription();
    this.selectedVehicleCtrl = new FormControl(null);
    if (this.formService.getForm.value.vehicle.vehicleId) {
      this._updateItems(this.formService.getClientForm.value.vehicles as IAssistanceVehicle[]);
      this.selectedVehicleCtrl.patchValue(
        this.formService.getForm.value.vehicle.vehicleId,
      );
    }
  }

  ngOnInit() {
    this._subs.add(
      this.formService.getClientVehiclesArray.valueChanges.subscribe(
        (vehicles: IAssistanceVehicle[]) => {
          this._updateItems(vehicles);
          this._resetCtrl();
        },
      ),
    );

    this._subs.add(
      this.selectedVehicleCtrl.valueChanges.subscribe((vehicleId) => {
        const selectedVehicle = this.vehicles.find(
          (x) => x.vehicleId === vehicleId,
        );
        if (selectedVehicle) {
          this.formService.setVehicle(selectedVehicle);
        } else {
          this.formService.setVehicle();
        }
      }),
    );
    this._subs.add(
      this.service.vehicleOptionCtrl.valueChanges.subscribe((vehicleId) => {
        this._resetCtrl();
      }),
    );
  }

  private _resetCtrl() {
    this.selectedVehicleCtrl.patchValue(null);
  }

  private _updateItems(vehicles?: IAssistanceVehicle[]) {
    this.vehicles = [...vehicles];
    this.searchOptions = [
      ...this.vehicles
        .filter((x) => x.vehicleId)
        .map((v) => ({
          label: v.fullName,
          sublabel: v.licencePlate,
          value: v.vehicleId,
        })),
    ];
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
