<div class="flex flex-column gap-2">
  <label for="send-in-english">Pošalji na</label>

  <p-selectButton
    [options]="stateOptions"
    [formControl]="form.controls.sendInEnglish | formControl"
    optionLabel="label"
    optionValue="value"
    id="send-in-english"
  />
  <p-divider></p-divider>

  <div
    [ngClass]="
      form.value.sendInEnglish
        ? 'flex flex-column'
        : 'flex flex-column disable-wrapper'
    "
  >
    <atlas-input-text
      ctrlPlaceholder="Enter towing text..."
      ctrlLabel="Towing"
      formCtrlId="towing"
      [formCtrl]="form.controls.towing"
      [isDisabled]="!form.value.sendInEnglish"
      class="w-full"
    ></atlas-input-text>

    <atlas-input-text
      ctrlPlaceholder="Enter taxi text..."
      ctrlLabel="Taxi"
      formCtrlId="taxi"
      [formCtrl]="form.controls.taxi"
      class="w-full"
      [isDisabled]="true"
    ></atlas-input-text>

    <atlas-input-text
      ctrlPlaceholder="Enter rent a car text..."
      ctrlLabel="Rent a car"
      formCtrlId="rent-a-car"
      [formCtrl]="form.controls.rentACar"
      class="w-full"
    ></atlas-input-text>

    <atlas-input-text
      ctrlPlaceholder="Enter repair on spot text..."
      ctrlLabel="Repair on spot"
      formCtrlId="repair-on-spot"
      [formCtrl]="form.controls.repairOnSpot"
      class="w-full"
    ></atlas-input-text>

    <atlas-input-text
      ctrlPlaceholder="Enter repair in service text..."
      ctrlLabel="Repair in service"
      formCtrlId="repair-in-service"
      [formCtrl]="form.controls.repairInService"
      class="w-full"
    ></atlas-input-text>

    <atlas-input-text
      ctrlPlaceholder="Enter hotel accomodation text..."
      ctrlLabel="Hotel accomodation"
      formCtrlId="hotel-acc"
      [formCtrl]="form.controls.hotelAccommodation"
      class="w-full"
    ></atlas-input-text>

    <atlas-input-text
      ctrlPlaceholder="Enter storage fee text..."
      ctrlLabel="Storage fee"
      formCtrlId="storage-fee"
      [formCtrl]="form.controls.storageFee"
      class="w-full"
    ></atlas-input-text>
  </div>
</div>
