<div>
  <div>
    <div>
      <!-- Naslov -->
      <div class="text-3xl font-semibold mt-1 pb-5 page-title">
        Obnova Paketa
      </div>

      <p-scrollPanel
        [style]="{ width: '100%', height: 'calc(112dvh - 290px)' }"
      >
        <div class="flex justify-content-between mt-5 mb-5">
          <!-- Package Card -->
          <div class="package-card">
            <atlas-package-card-list></atlas-package-card-list>
          </div>

          <!-- Package Price / Desna strana -->
          <div>
            <div class="price-card p-4 border-round-xl">
              <div class="font-semibold text-lg mb-4">Kupovina</div>
              <div class="flex pb-3 justify-content-between name-card">
                <div class="font-medium text-sm package-name">
                  {{ packageName }}
                </div>
                <div class="font-medium text-sm pacakge-price">
                  {{ packagePrice | currencyNumber }}
                </div>
              </div>

              <div class="flex pt-3 justify-content-between align-items-center">
                <div class="font-semibold text-lg">Ukupno</div>
                <div class="font-semibold text-lg">
                  {{ packagePrice | currencyNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <p-divider></p-divider>

        <div class="mt-5 ml-1">
          <div class="mb-4">
            <div class="mb-5 font-semibold text-base">Način isporuke</div>
            <atlas-dropdown
              [ctrlLabel]="'Način isporuke'"
              [options]="deliveryType"
              [formCtrl]="renewForm.controls.deliveryType"
              ctrlStyleClass="w-17rem"
            ></atlas-dropdown>
          </div>

          <p-divider></p-divider>

          <div class="mt-4 mb-4">
            <div class="mb-5 font-semibold text-base">Način plaćanja</div>
            <atlas-dropdown
              [ctrlLabel]="'Način plaćanja'"
              [options]="paymentType"
              [formCtrl]="renewForm.controls.paymentType"
              ctrlStyleClass="w-17rem"
            ></atlas-dropdown>
          </div>
        </div>

        <p-divider></p-divider>

        <div class="mt-4 mb-4 title-text">
          Od kada želite da Vam se aktivira paket?
        </div>

        <div class="mb-3" *ngFor="let type of activationType">
          <p-radioButton
            [formControl]="renewForm.controls.isUrgent | formControl"
            [value]="type.value"
          ></p-radioButton>
          <label class="ml-3" [for]="type.label">{{ type.label }}</label>
        </div>

        <p-divider></p-divider>

        <div class="flex justify-content-between mt-8">
          <div>
            <atlas-button
              btnLabel="Nazad"
              btnIcon="pi pi-arrow-left"
              [btnStyle]="ButtonStyle.OUTLINED"
              [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
              (btnClicked)="handleCancelForm()"
            ></atlas-button>
          </div>
          <div>
            <atlas-button
              btnLabel="Potvrdi obnovu"
              btnIcon="pi pi-box"
              [btnStyle]="ButtonStyle.OUTLINED"
              [btnSeverity]="ButtonSeverity.SECONDARY"
              (btnClicked)="handleSubmitForm()"
            ></atlas-button>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</div>
