import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'atlas-max-length',
  standalone: true,
  imports: [NgIf],
  templateUrl: './max-length.component.html',
  styleUrl: './max-length.component.scss',
})
export class MaxLengthComponent implements OnInit {
  /** I/O */
  @Input() formCtrl!: FormControl<any>;
  errorMsg: string = '';
  errorValue: string = '';

  ngOnInit() {
    this.updateErrorMsg(); 
    this.formCtrl.valueChanges.subscribe(() => {
      this.updateErrorMsg(); 
    });
  }
  
  private updateErrorMsg() {
    const maxlengthError = this.formCtrl.errors?.['maxlength'];
    const maxlengthValue = this.formCtrl.errors?.['max'];
    this.errorMsg = maxlengthError ? `Maksimalan broj karaktera je ${maxlengthError.requiredLength}` : '';
    this.errorValue = maxlengthValue ? `Maksimalan broj je ${maxlengthValue.max}` : '';
  }
}
