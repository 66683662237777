import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { ClientPackageTableService } from '@app/sos-agent/packages/services/client-package-table.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import {JsonPipe, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet} from '@angular/common';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { ServiceAvailabilityComponent } from '@app/sos-agent/packages/client-package-sidebar/service-availability/service-availability.component';
import {
  ClientClient,
  IClientPackagePreviewResponse,
} from '@shared/model/atlas.api';
import {LocationService} from "@shared/services/location.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'atlas-client-package-sidebar',
  standalone: true,
    imports: [
        SidebarModule,
        DividerModule,
        NgIf,
        NgOptimizedImage,
        ButtonComponent,
        ServiceAvailabilityComponent,
        JsonPipe,
        NgForOf,
        NgTemplateOutlet,
    ],
  templateUrl: './client-package-sidebar.component.html',
  styleUrl: './client-package-sidebar.component.scss',
})
export class ClientPackageSidebarComponent implements OnInit, OnDestroy {
  private _subs: Subscription;
  showSidebar: boolean;
  clientPackageId: string;
  previewData: IClientPackagePreviewResponse;
  isReadonly: boolean = false;

  constructor(
    private service: ClientPackageTableService,
    private client: ClientClient,
    private locationService: LocationService,
    private route: ActivatedRoute,
  ) {
    this._subs = new Subscription();
    this.showSidebar = false;
    this.clientPackageId = '';
    this.previewData = {};
    this.isReadonly = route.snapshot.data['readonly'];
  }

  ngOnInit() {
    this._subs.add(
      this.service.getShowPreviewSidebar.subscribe((show) => {
        if (show) {
          this.clientPackageId = this.service.getSidebarId;
          this.showSidebar = show;
          this._load();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.service.hidePreviewSidebar();
    this._subs.unsubscribe();
  }

  protected readonly ButtonSeverity = ButtonSeverity;
  protected readonly ButtonStyle = ButtonStyle;

  handleCreateForPackage() {
    this.locationService.routeToCreateAssitanceForm(this.previewData.id);
  }

  handleCreateForCash() {
    this.locationService.routeToCreateAssitanceForm(this.previewData.id);
  }

  private _load() {
    this._getPreviewData().then((res) => {
      this.previewData = res.result.value;
      console.log(this.previewData);
    });
  }

  private async _getPreviewData() {
    return await firstValueFrom(
      this.client.getClientPackagePreview(this.clientPackageId),
    );
  }
}
