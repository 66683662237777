<ng-container *ngIf="totalRequested > 0 && !userService.isAdmin()">
  <div class="custom-toast" [ngClass]="{'hide-toast': hideToast}">
    <div class="toast-item">
      <div class="toast-color">
        <img ngSrc="assets/images/toast-icon.svg"
             width="45"
             height="45"
             alt="user-image" />
      </div>
      <div class="zone-info">
        <div class="zone-name">Zatražene asistencije</div>
        <div class="zone-description label-sm">
          Imate <span class="numberTotal">{{ totalRequested }}</span> {{ assistanceLabel }} na koje treba odgovoriti
        </div>
      </div>
    </div>
    <a (click)="navigateToAssistances()" class="toast-link pointer">
      Vidi zatrazene asistencije &rarr;
    </a>
  </div>
  <p-button *ngIf="showToggleButton" class="toggle-button" (click)="toggleToast()">
    <i class="pi" [ngClass]="hideToast ? 'pi-eye' : 'pi-eye-slash'"></i>
  </p-button>
</ng-container>
