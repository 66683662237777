import { Component } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { JsonPipe, NgClass, NgIf, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'atlas-confirm-dialog',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    ButtonComponent,
    NgIf,
    NgOptimizedImage,
    NgClass,
    JsonPipe,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;
}
