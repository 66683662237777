<div class="package-container mt-5">
  <!-- Package Header -->
  <div class="header-container mt-2">
    <span class="title-text">Paket 1</span>
    <i
      *ngIf="selectedPackage"
      (click)="clearSelectedPackage($event)"
      class="pi pi-trash pointer"
    ></i>
  </div>

  <div
    class="package-header mt-3"
    [ngClass]="{ disabled: !selectedVehicle }"
    (click)="selectedVehicle ? togglePackageDropdown() : null"
  >
    <div class="flex align-items-center">
      <img
        ngSrc="assets/images/package.svg"
        width="40"
        height="40"
        alt="user-image"
      />
      <span class="package-text">{{
        selectedPackage?.name || "Izaberi paket"
      }}</span>
      <i
        [ngClass]="
          isPackageDropdownOpen ? 'pi pi-angle-up' : 'pi pi-angle-down'
        "
        class="ml-1"
      ></i>
    </div>

    <div class="package-price" *ngIf="selectedPackage && !selectedActionPrice">
      {{ selectedPackage.price | currencyNumber }}
    </div>

    <span *ngIf="selectedActionPrice" class="package-price">
      {{ selectedActionPrice | currencyNumber }}
    </span>
  </div>

  <div *ngIf="isPackageDropdownOpen" class="package-dropdown mt-2">
    <div
      *ngFor="let pkg of allPackages"
      (click)="selectPackage(pkg)"
      class="package-item"
    >
      {{ pkg?.name }} - {{ decimalPipe.transform(pkg?.price, "1.2-2") }} RSD
    </div>
  </div>

  <div class="mt-3" *ngIf="selectedPackage">
    <atlas-input-text
      [formCtrl]="form.controls.chassisNum"
      ctrlLabel="Broj šasije"
      formCtrlId="broj"
      [tooltip]="'Broj šasije vozila nedostaje za ovaj paket'"
      ctrlPlaceholder="Broj šasije"
      [formCtrlIconRight]="'pi pi-info-circle'"
      (inputLeft)="handleChassisInputChange($event)"
    ></atlas-input-text>
  </div>

  <div class="mt-3" *ngIf="selectedPackage && showPriceInput">
    <atlas-input-addon
      [formCtrl]="form.controls.packagePrice"
      addonText="RSD"
      ctrlPlaceholder="Cena"
      [isPriceFormat]="true"
      (keyReleased)="handlePriceInput($event)"
    ></atlas-input-addon>
  </div>

  <!-- ActioinPriceDropdown -->
  <div class="dropdown-div mt-3" *ngIf="selectedPackage">
    <p-dropdown
      [options]="actionPricesDropdown"
      [optionLabel]="actionPricesDropdown.label"
      [formControl]="form.controls.packagePriceId"
      [placeholder]="'Izaberite akcijsku cenu'"
      (onChange)="handleActionPriceChange($event)"
    >
      <ng-template let-selected pTemplate="selectedItem">
        <div class="flex align-items-center gap-2">
          <span class="font-bold text-sm">{{ selected.label }}</span>
          <span *ngIf="!showPriceInput" class="dot">•</span>
          <span class="font-normal text-sm">{{
            decimalPipe.transform(selected.price, "1.2-2")
          }}</span>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div class="flex align-items-center gap-2">
          <span class="font-bold text-sm">{{ item.label }}</span>
          <span *ngIf="!showPriceInput" class="dot">•</span>
          <span class="font-normal text-sm">{{
            decimalPipe.transform(item.price, "1.2-2")
          }}</span>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
