<atlas-page
  pageTitle="Asistencije"
  pageDescription="Prikaz liste i detalja svih asistencija"
>
  <div class="flex flex-column gap-3 table-filter flex-wrap">
    <div class="flex align-items-center gap-3 flex-wrap">
      <p-tabView
        class="tabView flex-1"
        [(activeIndex)]="activeIndex!"
        (activeIndexChange)="handleTabChange($event)"
      >
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">
                Sve asistencije
              </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="U toku">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> U toku </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Završene">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Završene </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Otkazane">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Otkazane </span>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Zatražene">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Zatražene </span>
              <p-badge [value]="totalRequested" />
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Nove">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0"> Nove </span>
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
      <div class="search-bar">
        <atlas-inline-wrapper [showLabel]="false" formCtrlId="search">
          <div class="flex align-items-end">
            <atlas-dropdown
              [hideErrors]="true"
              [options]="searchOptions"
              [formCtrl]="searchOptionCtrl"
              ctrlStyleClass="input-next-to w-50rem"
            ></atlas-dropdown>
            <atlas-input-text
              [hideErrors]="true"
              formCtrlClass="dropdown-next-to w-10rem lg:w-20rem"
              ctrlPlaceholder="Pretraži"
              [formCtrl]="searchCtrl"
              (onEnterClicked)="load()"
            ></atlas-input-text>
          </div>
        </atlas-inline-wrapper>
      </div>
    </div>
  </div>
  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
    [resetPagination]="resetPagination"
  ></atlas-table>
</atlas-page>
