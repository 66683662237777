import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ISendGuaranteeCommand,
} from '@shared/model/atlas.api';
import { ControlsOf } from '@shared/forms/forms-common';

@Injectable({
  providedIn: 'root',
})
export class GuaranteeFormService {

  constructor(private _fb: FormBuilder) {}

  private _form: GuaranteeForm | undefined;

  getForm(assistanceId: string): GuaranteeForm {
    if (!this._form) {
      this.setForm({
        assistanceId: assistanceId,
      });
    }
    return this._form;
  }

  setForm(model: ISendGuaranteeCommand) {
    if (!this._form) {
      this._form = this._fb.group<ControlsOf<ISendGuaranteeCommand>>({
        assistanceId: this._fb.control(model.assistanceId),
        sendInEnglish: this._fb.control(model.sendInEnglish ?? false),
        towing: this._fb.control(model.towing, {
          validators: [Validators.maxLength(128)]
        }),
        taxi: this._fb.control(model.taxi, {
          validators: [Validators.maxLength(128)]
        }),
        rentACar: this._fb.control(model.rentACar, {
          validators: [Validators.maxLength(128)]
        }),
        repairOnSpot: this._fb.control(model.repairOnSpot, {
          validators: [Validators.maxLength(128)]
        }),
        hotelAccommodation: this._fb.control(model.hotelAccommodation, {
          validators: [Validators.maxLength(128)]
        }),
        repairInService: this._fb.control(model.repairInService, {
          validators: [Validators.maxLength(128)]
        }),
        storageFee: this._fb.control(model.storageFee, {
          validators: [Validators.maxLength(128)]
        }),
      })
    } else {
      this._form.patchValue(model)
    }
  }

  resetForm() {
    this._form = undefined;
  }
}

export declare type GuaranteeForm = FormGroup<ControlsOf<ISendGuaranteeCommand>>;
