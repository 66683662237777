import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorEmailComponent } from '@ui/errors/error-email/error-email.component';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'atlas-inline-wrapper-zone',
  standalone: true,
  imports: [ErrorRequiredComponent,
    ErrorEmailComponent,
    InputTextModule,
    NgIf,
    NgClass,
    ReactiveFormsModule,],
  templateUrl: './inline-wrapper-zone.component.html',
  styleUrl: './inline-wrapper-zone.component.scss'
})
export class InlineWrapperZoneComponent {
  @Input() ctrlLabel: string = 'Label';
  @Input() showLabel: boolean = true;
  @Input() ctrlDesc: string = '';
  @Input() formCtrlId: string = 'text-input';
}
