import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { ContactPhoneInputComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/contact-phone-list/contact-phone-input/contact-phone-input.component';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import {
  AssistanceFormService,
} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { PhoneItem } from '@shared/model/atlas.api';
import { ControlsOf } from '@shared/forms/forms-common';
import { AssistanceClientForm } from '../../services/assistance-client-form.service';
import { Guid } from 'guid-typescript';
import { ContactFormService } from '@app/administrator/partners/services/contact-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-contact-phone-list',
  standalone: true,
  imports: [
    FormControlPipe,
    ReactiveFormsModule,
    InputTextComponent,
    NgForOf,
    ContactPhoneInputComponent,
    ButtonComponent,
    NgIf,
    JsonPipe,
  ],
  templateUrl: './contact-phone-list.component.html',
  styleUrl: './contact-phone-list.component.scss',
})
export class ContactPhoneListComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  phoneItems: FormArray<FormGroup<ControlsOf<PhoneItem>>>;
  @Output() phoneItemChanged = new EventEmitter<FormArray<FormGroup<ControlsOf<PhoneItem>>>>();

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(
    private _phonesService: ContactFormService
  ) {}

  ngOnInit(): void {

    this._subs.add(
      this._phonesService.phones$.subscribe((phones) => {
        this.phoneItems = phones;
      })
    )
  }

  handleAddContact() {
    const newEmptyPhone = this._phonesService.getPhoneForm();

    this._phonesService.pushPhone(newEmptyPhone.value as PhoneItem)
    this.phoneItems = this._phonesService.getPhones();
  }

  handleRemoveContact(idx: number) {
    this._phonesService.removePhone(idx);
    this.phoneItems = this._phonesService.getPhones();
  }

  ngOnDestroy(): void {
    this._phonesService.resetPhones();
    this._subs.unsubscribe()
  }

}
