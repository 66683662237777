@for (phoneItem of phoneItems?.value; let idx = $index; track phoneItem.id) {
    <div class="flex gap-1 w-30rem">
      <atlas-contact-phone-input
        [phone]="phoneItem.label"
        [id]="phoneItem.id"
        class="w-full"
      ></atlas-contact-phone-input>
      <atlas-button
        *ngIf="idx > 0"
        btnIcon="pi pi-trash"
        [btnStyle]="ButtonStyle.TEXT"
        [btnSeverity]="ButtonSeverity.DANGER"
        btnClass="pl-0"
        (btnClicked)="handleRemoveContact(idx)"
      ></atlas-button>
    </div>
}

<!--@empty {
  <div class="w-30rem">
  <atlas-contact-phone-input [isRequired]="true" [phone]="''"></atlas-contact-phone-input>
  </div>
}-->

<!--<small-->
<!--  *ngIf="formService.getForm.controls.client.controls.phones.invalid &&-->
<!--  (formService.getForm.controls.client.controls.phones.dirty || formService.getForm.controls.client.controls.phones.touched)"-->
<!--  class="error-info text-red-500"-->
<!--&gt;Morate odabrati postojeće ili uneti novo vozilo</small-->
<!--&gt;-->

<atlas-button
  btnIcon="pi pi-plus"
  btnLabel="Dodatni kontakt"
  btnClass="pl-0"
  (btnClicked)="handleAddContact()"
></atlas-button>