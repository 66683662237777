<div
  class="flat-card mt-4 flex flex-column lg:flex-row justify-content-between px-3 gap-3"
>
  <div class="flex align-items-center gap-2">
    <img
      [ngSrc]="'assets/images/map-legend/location-from.svg'"
      width="32"
      height="32"
      alt="distance-image"
    />
    Mesto kvara
  </div>
  <div class="flex align-items-center gap-2">
    <img
      [ngSrc]="'assets/images/map-legend/location-to.svg'"
      width="32"
      height="32"
      alt="distance-image"
    />
      <div>
    Odredište
      </div>
  </div>
  <div class="flex align-items-center gap-2">
    <img
      [ngSrc]="'assets/images/map-legend/active-partner.svg'"
      width="32"
      height="32"
      alt="distance-image"
    />
    Aktivan saradnik
  </div>
  <div class="flex align-items-center gap-2">
    <img
      [ngSrc]="'assets/images/map-legend/potentional-partner.svg'"
      width="32"
      height="32"
      alt="distance-image"
    />
    Potencijalni saradnik
  </div>
  <div class="flex align-items-center gap-2">
    <img
      [ngSrc]="'assets/images/map-legend/bosch-partner.svg'"
      width="32"
      height="32"
      alt="distance-image"
    />
    Bosch car saradnik
  </div>
  <div class="flex align-items-center gap-2">
    <img
      [ngSrc]="'assets/images/map-legend/auth-partner.svg'"
      width="32"
      height="32"
      alt="distance-image"
    />
    Ovlašćeni servis
  </div>
</div>
