import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';

@Component({
  selector: 'atlas-file-upload',
  standalone: true,
  imports: [FileUploadModule, NgIf],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent {
  @Input() isDisabled: boolean;
  @Input() imageUri: string;
  @Output() onUpload = new EventEmitter<File>();

  handleUpload(event: any) {
    this.onUpload.emit(event);
  }
}
