import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmployeeRoleItem, EmployeesClient } from '@shared/model/atlas.api';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'atlas-employee-role-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './employee-role-dropdown.component.html',
  styleUrl: './employee-role-dropdown.component.scss',
})
export class EmployeeRoleDropdownComponent implements OnInit {
  roleOptions: EmployeeRoleItem[] = [];

  @Input() roleCtrl: FormControl<string>;

  constructor(private _client: EmployeesClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._getRoles().then((res) => {
      this.roleOptions = res.result.roles;
    });
  }

  private async _getRoles() {
    return await firstValueFrom(this._client.getEmployeeRoles());
  }
}
