import { filter, pairwise } from 'rxjs/operators';
import { NavigationExtras, Router, RoutesRecognized } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import {
  ASSISTANCE_TABS,
  AssistanceService,
} from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public previousUrl = '';
  private directionsService = new google.maps.DirectionsService();

  constructor(
    private _router: Router,
    private _location: Location,
    // @Vukasin ToDo: ne zelimo dependency na neki servis
    private assistanceService: AssistanceService,
    private http: HttpClient,
  ) {
    _router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
      });
  }

  private _routeToUrl(url: string, navigationData?: NavigationExtras) {
    this._router.navigate([url], navigationData).then();
  }

  routeBack() {
    this._location.back();
  }

  route(
    request: google.maps.DirectionsRequest,
  ): Observable<google.maps.DirectionsResult> {
    return new Observable((observer) => {
      this.directionsService.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          observer.next(result);
        } else {
          observer.error(status);
        }
        observer.complete();
      });
    });
  }

  //#region Authentication routes

  routeToLogin(returnUrl?: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/authentication', 'login'], {
        queryParams: { returnUrl: returnUrl },
      }),
    );
    this._routeToUrl(url);
  }

  routeToRegister() {
    this._routeToUrl('/authentication/register');
  }

  routeToForgotPass() {
    this._routeToUrl('/authentication/forgot-password');
  }

  //#endregion Authentication routes

  //#region Shared

  routeToAdminHomePage() {
    this._routeToUrl('/admin');
  }

  routeToSosHomePage() {
    this._routeToUrl('/sos');
  }

  routeToHomePage() {
    this._routeToUrl('');
  }

  //#endregion Shared

  //#region Sos Agent routes
  routeToCreateAssitanceForm(clientPackageId?: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        'sos',
        'assistances',
        'create',
        'step-1',
      ]),
    );
    return this._routeToUrl(url, {
      state: {
        clientPackageId: clientPackageId,
      },
    });
  }

  routeToCreateClientForm() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        'cc',
        'clients',
        'create',
        'step-1',
      ]),
    );

    return this._router.navigateByUrl(url);
  }
  
  routeToPreviewAssistence(id: string) {
    // @Vukasin ToDo: Pomeriti setovanje id asistencije u parent componentu
    this.assistanceService.setAssistanceId(id);
    this._router
      .navigate(['/', 'sos', 'assistances', 'assistance-preview'], {
        state: {
          id: id,
        },
      })
      .then();
  }

  routeToEditAssitanceForm(id: string) {
    this._router
      .navigate(['/', 'sos', 'assistances', 'edit', 'step-1'], {
        state: {
          id: id,
        },
      })
      .then();
  }

  routeToAssitancesTable(tabName: ASSISTANCE_TABS = 'sve') {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'sos', 'assistances']),
    );
    return this._routeToUrl(url, {
      queryParams: { tab: tabName },
      queryParamsHandling: 'merge',
    });
  }

  routeToClientsTable() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'sos', 'clients']),
    );
    return this._routeToUrl(url);
  }

  //#endregion Sos Agent routes

  //#region Partner routes

  routeToAddPartnerForm(navigationData?: NavigationExtras) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'partners', 'add-partner']),
    );
    return this._routeToUrl(url, navigationData);
  }

  routeToEditPartnerForm(navigationData?: NavigationExtras) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'partners', 'edit-partner']),
    );
    return this._routeToUrl(url, navigationData);
  }

  routeToPartnerInfoPage() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'partners', 'partner-info']),
    );
    return this._routeToUrl(url);
  }

  routeToPartnerTable() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'partners']),
    );
    return this._routeToUrl(url);
  }

  //#endregion Partner routes

  //#region Package routes

  routeToPackageForm() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'packages', 'create-package']),
    );
    return this._routeToUrl(url);
  }

  routeToEditPackageForm() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'packages', 'edit-package']),
    );
    return this._routeToUrl(url);
  }

  routeToPackageTable() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'packages']),
    );
    return this._routeToUrl(url);
  }

  routeToSettings() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'settings']),
    );
    return this._routeToUrl(url);
  }

  //#endregion Package routes

  //#region Promo code routes

  routeToPromoCodes() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/', 'admin', 'promo-codes']),
    );

    return this._router.navigateByUrl(url);
  }

  routeToCreatePromoCode() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        'admin',
        'promo-codes',
        'create-promo-code',
      ]),
    );

    return this._router.navigateByUrl(url);
  }

  routeToEditPromoCode(promoCodeData?: any) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        '/',
        'admin',
        'promo-codes',
        'edit-promo-code',
      ]),
    );

    if (promoCodeData) {
      return this._router.navigateByUrl(url, { state: promoCodeData });
    } else {
      return this._router.navigateByUrl(url);
    }
  }
  //#endregion Promo code routes
}
