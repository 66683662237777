<atlas-page
  pageTitle="Promo kodovi"
  btnIcon="pi pi-plus"
  pageDescription="Uređivanje i dodavanje promo kodova"
  actionLabel="Dodaj promo kod"
  (onHandleAction)="createPromoCode()"
>
  <atlas-table
    [paginatedData]="paginatedData"
    [tableConfig]="tableConfig"
  ></atlas-table>
</atlas-page>
