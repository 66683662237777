import { Component, Input } from '@angular/core';
import {
  InitClient,
  IPaymentStatusDropdownItem,
  PaymentStatus,
} from '@shared/model/atlas.api';
import { FormControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';

@Component({
  selector: 'atlas-payment-status-dropdown',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './payment-status-dropdown.component.html',
  styleUrl: './payment-status-dropdown.component.scss',
})
export class PaymentStatusDropdownComponent {
  statusOptions: IPaymentStatusDropdownItem[] = [];

  @Input() statusCtrl: FormControl<string>;

  constructor(private _client: InitClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._getStatuses().then((res) => {
      this.statusOptions = res.result.items;
    });
  }

  private async _getStatuses() {
    return await firstValueFrom(this._client.getPaymentStatusDropdown());
  }
}
