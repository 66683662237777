import { Component, OnInit, ViewChild } from '@angular/core';
import { InlineWrapperComponent } from '@ui/inline-wrapper/inline-wrapper.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { AssistanceStatus, ClientSearchParam } from '@shared/model/atlas.api';
import { JsonPipe, NgClass } from '@angular/common';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { DividerModule } from 'primeng/divider';
import { InputTextareaComponent } from '@ui/input-textarea/input-textarea.component';
import { ClientForAssistanceAutocompleteComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/client-for-assistance-autocomplete/client-for-assistance-autocomplete.component';
import { AssistanceVehicleComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/assistance-vehicle/assistance-vehicle.component';
import { FailureTypeDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/failure-type-dropdown/failure-type-dropdown.component';
import { LocationMapComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/location-map/location-map.component';
import { NumOfPassengersDropdownComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/num-of-passengers-dropdown/num-of-passengers-dropdown.component';
import { PackageForAssistanceComponent } from '@app/sos-agent/assistances/assistance-form/assistance-form-step-1/package-for-assistance/package-for-assistance.component';
import {
  AssistanceForm,
  AssistanceFormService,
} from '@app/sos-agent/assistances/assistance-form/services/assistance-form.service';
import { AssistanceService } from '@app/sos-agent/assistances/assistance-form/services/assistance.service';
import {
  ContactPhoneListComponent
} from "@app/sos-agent/assistances/assistance-form/assistance-form-step-1/contact-phone-list/contact-phone-list.component";
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';

@Component({
  selector: 'atlas-assistance-form-step-1',
  standalone: true,
  imports: [
    InlineWrapperComponent,
    InputTextComponent,
    DropdownComponent,
    ClientForAssistanceAutocompleteComponent,
    JsonPipe,
    FormControlPipe,
    ContactPhoneListComponent,
    AssistanceVehicleComponent,
    DividerModule,
    FailureTypeDropdownComponent,
    InputTextareaComponent,
    LocationMapComponent,
    NumOfPassengersDropdownComponent,
    ReactiveFormsModule,
    PackageForAssistanceComponent,
    NgClass,
    InputMailComponent
  ],
  templateUrl: './assistance-form-step-1.component.html',
  styleUrl: './assistance-form-step-1.component.scss',
})
export class AssistanceFormStep1Component implements OnInit {
  searchOptionCtrl: FormControl;
  form: AssistanceForm;
  status: number | null = null;
  isDisabled: boolean = false;

  searchOptions: any = [
    { label: 'Telefon', value: ClientSearchParam.PHONE },
    { label: 'Klijent', value: ClientSearchParam.NAME },
    { label: 'Tablice', value: ClientSearchParam.LICENCE_PLATE },
  ];

  constructor(
    private service: AssistanceService,
    private formService: AssistanceFormService,
  ) {
    this.searchOptionCtrl = this.service.clientSearchParamCtrl;
    this.form = formService.getForm;
    this.service.currentStep = 0;
  }

  ngOnInit() {
    this.checkStatus();
  }

  updatePhoneItems(event: any) {
    this.form.controls.client.controls.phones.patchValue(event);
  }

  checkStatus() {
    this.isDisabled = this.formService.getStatus() === AssistanceStatus.REQUEST;
  }
}
