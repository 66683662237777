import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/services/auth.guard';
import { OrderPurchaseTableComponent } from '@app/finance-administrator/order-purchase-table/order-purchase-table.component';

export const financeAdminRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'purchases',
        pathMatch: 'full',
      },
      {
        path: 'purchases',
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            component: OrderPurchaseTableComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'purchases',
  },
];
