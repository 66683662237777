<div class="flex flex-column gap-2 w-full">
  <textarea
    rows="5"
    class="w-30rem"
    pInputTextarea
    [formControl]="formCtrl"
    [autoResize]="true"
    [placeholder]="ctrlPlaceholder"
  ></textarea>

  <atlas-error-required [formCtrl]="formCtrl"></atlas-error-required>
  <atlas-max-length [formCtrl]="formCtrl"></atlas-max-length>
</div>
<!--<small-->
<!--  class="error-info"-->
<!--&gt;{{ formCtrl.errors['maxlength']['actualLength'] }} / {{ formCtrl.errors['maxlength']['requiredLength'] }}  </small-->
<!--&gt;-->
