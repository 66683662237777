<div>
  <div>
    <p class="font-bold mb-0">Naziv akcije</p>
    <atlas-input-text
      ctrlPlaceholder="Naziv akcije"
      [formCtrl]="packageActionForm.controls.description"
      formCtrlClass="w-full"
    ></atlas-input-text>
  </div>
  <div>
    <atlas-input-addon
      [formCtrl]="packageActionForm.controls.price"
      ctrlPlaceholder="00,00"
      addonText="RSD"
      [isPriceFormat]="true"
    ></atlas-input-addon>
  </div>
</div>
