import { Component, EventEmitter, Output } from '@angular/core';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NgForOf, NgIf } from '@angular/common';
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user.service';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { ButtonModule } from 'primeng/button';
import { ButtonComponent, ButtonStyle } from '@ui/button/button.component';
import { LocationService } from '@shared/services/location.service';

enum MenuSection {
  UPRAVLJANJE = 0,
  NAVIGACIJA = 1,
  OSTALO = 2,
}

@Component({
  selector: 'atlas-menu',
  standalone: true,
  imports: [
    ScrollPanelModule,
    NgIf,
    NgForOf,
    MenuItemComponent,
    ButtonModule,
    ButtonComponent,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  /** Menu items */
  allMenuItems: IMenuItem[] = [];
  menuItems: IMenuItem[] = [];
  isMenuVisible = true;

  @Output() menuVisibilityChange = new EventEmitter<boolean>();

  /** Finite props */
  hasOstalo: boolean = false;
  ostaloSectionValue: MenuSection = MenuSection.OSTALO;

  /** Props */
  userName: string;
  userImage: string;
  userRoles: string;

  constructor(
    private _authService: AuthService,
    public userService: UserService,
    private _locationService: LocationService,
  ) {
    this.userName = this.userService.getUserData.fullName;
    this.userImage = this.userService.getUserData.imageUrl;
    const roles = userService.getRole.split(',');
    if (roles.length > 1) {
      this.userRoles = roles[0].concat(
        ' (+',
        (roles.length - 1).toString(),
        ')',
      );
    } else {
      this.userRoles = roles[0];
    }
    this._setMenuItems();
  }

  toggleMenu() {
    this.menuVisibilityChange.emit(false);
  }

  private _setMenuItems() {
    this.allMenuItems = [
      ...this.adminMenuItems,
      ...this.sosAgentMenuItems,
      ...this.financeAdminMenuItems,
      ...this.ccAgentMenuItems,
      ...this.guestMenuItems
    ];
    this.menuItems = this.allMenuItems.filter((x) => x.isVisible);
    this.hasOstalo = this.menuItems.some(
      (x) => x.section === MenuSection.OSTALO,
    );
  }

  handleLogOut() {
    this._locationService.routeToSettings();
    // this._authService.logout();
  }

  handleCreateAssitance() {
    this._locationService.routeToCreateAssitanceForm();
  }

  protected readonly ButtonStyle = ButtonStyle;

  private adminMenuItems: IMenuItem[] = [
    {
      label: 'Zaposleni',
      routerLink: '/admin/employees',
      icon: 'employees-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Saradnici',
      routerLink: '/admin/partners',
      icon: 'partners-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Paketi',
      routerLink: '/admin/packages',
      icon: 'packages-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Promo kodovi',
      routerLink: '/admin/promo-codes',
      icon: 'promo-code-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    {
      label: 'Parking',
      routerLink: '/admin/parking',
      icon: 'parkings-icon',
      isVisible: this.userService.isAdmin(),
      section: MenuSection.UPRAVLJANJE,
    },
    // {
    //   label: 'Podešavanja',
    //   routerLink: '/admin/settings',
    //   icon: 'settings-icon',
    //   isVisible: this.userService.isAdmin(),
    //   section: MenuSection.OSTALO,
    // },
  ];

  private sosAgentMenuItems: IMenuItem[] = [
    {
      label: 'Ugovori',
      routerLink: '/sos/contracts',
      icon: 'contracts-icon',
      isVisible: this.userService.isSosAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Asistencije',
      routerLink: '/sos/assistances',
      icon: 'assistances-icon',
      isVisible: this.userService.isSosAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    // {
    //   label: 'Podešavanja',
    //   routerLink: '/sos/settings',
    //   icon: 'settings-icon',
    //   isVisible: this.userService.isSosAgent(),
    //   section: MenuSection.OSTALO,
    // },
  ];

  private financeAdminMenuItems: IMenuItem[] = [
    {
      label: 'Website kupovine',
      routerLink: '/finance/purchases',
      icon: 'purchase-icon',
      isVisible: this.userService.isFinanceAdmin(),
      section: MenuSection.NAVIGACIJA,
    },
  ];

  private ccAgentMenuItems: IMenuItem[] = [
    {
      label: 'Klijenti',
      routerLink: '/cc/clients',
      icon: 'clients-icon',
      isVisible: this.userService.isCcAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Kampanje',
      routerLink: '/cc/campaigns',
      icon: 'campaigns-icon',
      isVisible: this.userService.isCcAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Preporuke',
      routerLink: '/cc/recommendations',
      icon: 'recommendations-icon',
      isVisible: this.userService.isCcAgent(),
      section: MenuSection.NAVIGACIJA,
    },
    {
      label: 'Agenti',
      routerLink: '/cc/agents',
      icon: 'agents-icon',
      isVisible: this.userService.isCcAgent(),
      section: MenuSection.NAVIGACIJA,
    },
  ];

  private guestMenuItems: IMenuItem[] = [
    {
      label: 'Ugovori',
      routerLink: '/guest/contracts',
      icon: 'contracts-icon',
      isVisible: this.userService.isGuest(),
      section: MenuSection.NAVIGACIJA,
    },
  ];
  protected readonly MenuSection = MenuSection;
}

interface IMenuItem {
  label: string;
  routerLink: string;
  icon: string;
  isVisible: boolean;
  section: MenuSection;
}
