import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PackagesClient } from '@shared/model/atlas.api';
import {
  ButtonComponent,
  ButtonSeverity,
  ButtonStyle,
} from '@ui/button/button.component';
import { InputSearchComponent } from '@ui/input-search/input-search.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-package-detail-autocomplete',
  standalone: true,
  imports: [InputSearchComponent, ButtonComponent],
  templateUrl: './package-detail-autocomplete.component.html',
  styleUrl: './package-detail-autocomplete.component.scss',
})
export class PackageDetailAutocompleteComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  packages: string[] = [];

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonSeverity = ButtonSeverity;

  additionalServiceItem: string;
  additionalServices: string[] = [];
  resetInput: boolean = false;

  @Output() listOfServices = new EventEmitter<string[]>();

  @ViewChild('inputSearch', { static: false })
  inputSearch: InputSearchComponent;

  constructor(private _packageClient: PackagesClient) {}

  ngOnInit() {}

  private loadServices(searchValue?: string) {
    this._subs.add(
      this._packageClient.getPackageDetails(searchValue).subscribe((res) => {
        this.packages = res.result.packageDetails;
      }),
    );
  }

  handleSearch(item: any) {
    this.loadServices(item);
  }

  onSelectChange(item: any) {
    if (!item || !item.value) {
      return;
    }

    this.additionalServiceItem = item;
  }

  handleAddService() {
    if (!this.additionalServiceItem) {
      return; 
    }
  
    this.additionalServices = [
      ...this.additionalServices,
      this.additionalServiceItem,
    ];
  
    this.additionalServiceItem = null;
  
    this.listOfServices.emit(this.additionalServices);
  
    this.resetInput = true;
  
    setTimeout(() => {
      this.resetInput = false;
    }, 0);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
