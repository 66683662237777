import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '@shared/components/page/page.component';
import { PageInfoCardComponent } from '@ui/page-info-card/page-info-card.component';
import { TableComponent } from '@ui/table/table.component';
import {
  DeleteParkingCityCommand,
  ParkingsClient,
  ZoneColor,
} from '@shared/model/atlas.api';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {
  DialogConfig,
  DialogSize,
  OnDialogTypedInit,
} from '@ui/dialog/dialog-config';
import { AddCityComponent } from './add-city/add-city/add-city.component';
import { AddZoneComponent } from './add-zone/add-zone/add-zone.component';
import { DialogService } from '@ui/dialog/dialog.service';
import { ConfirmationService } from 'primeng/api';
import { AlertService } from '@shared/services/alert.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { EditCityComponent } from './edit-city/edit-city/edit-city.component';
import { EditZoneComponent } from './edit-zone/edit-zone/edit-zone.component';
import { AdminService } from '@app/administrator/services/admin-service';

@Component({
  selector: 'atlas-parking-table',
  standalone: true,
  imports: [
    PageComponent,
    PageInfoCardComponent,
    TableComponent,
    CommonModule,
    ScrollPanelModule,
    OverlayPanelModule,
  ],
  templateUrl: './parking-table.component.html',
  styleUrl: './parking-table.component.scss',
})
export class ParkingTableComponent
  implements
    OnInit,
    OnDestroy,
    AfterViewInit,
    OnDialogTypedInit<AddCityComponent>,
    OnDialogTypedInit<AddZoneComponent>
{
  allCities: any[] = [];
  allZones: any[] = [];
  private _subs: Subscription = new Subscription();
  private cityIds: any[] = [];
  selectedCityIndex: number;
  savedCityId: any;
  currentZone: any;
  savedZoneId: any;
  allCitiesCount: string = '';
  allZonesCount: string = '';

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(
    private _parkingClient: ParkingsClient,
    private _dialogService: DialogService,
    private _adminService: AdminService,
    private _confirmationService: ConfirmationService,
    private _alertService: AlertService,
  ) {
    this.allCities = [];
    this.allZones = [];
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._loadCities().then();
    this._getCitiesZonesCount();
  }

  addCity() {
    this.dialogConfig.header = 'Dodavanje grada';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;

    const dialogRef = this._dialogService.open(
      AddCityComponent,
      this.dialogConfig,
    );

    dialogRef.onClose.subscribe(() => {
      this._loadCities();
      this._getCitiesZonesCount();
    });
  }

  addZone() {
    this.dialogConfig.header = 'Dodavanje zone';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;
    const dialogRef = this._dialogService.open(
      AddZoneComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe(() => {
      this.loadZones();
      this._getCitiesZonesCount();
    });
  }

  openDialog(formType: any): void {
    this._dialogService.open(formType, this.dialogConfig);
  }

  onEllipsisClick(event: Event, overlayPanel: any) {
    overlayPanel.toggle(event);
  }

  onZoneEllipsisClick(event: Event, zone: any, overlayPanel: any) {
    this.currentZone = zone;
    this.savedZoneId = this.currentZone.id;
    this._adminService.setZoneId(this.savedZoneId);
    overlayPanel.toggle(event);
  }

  handleEditCity(city: any) {
    this.dialogConfig.header = 'Izmena grada';
    this.dialogConfig.customSubmitButton = {
      label: 'Izmeni',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.closable = true;
    const dialogRef = this._dialogService.open(
      EditCityComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe(() => {
      this._loadCities();
    });
  }

  handleEditZone(zone: any) {
    this.dialogConfig.header = 'Izmena zone';
    this.dialogConfig.customSubmitButton = {
      label: 'Izmeni',
      icon: '',
    };
    this.dialogConfig.customCancelButton = {
      label: 'Otkaži',
      icon: '',
      style: 'max-width: 100px',
    };
    this.dialogConfig.closable = true;
    const dialogRef = this._dialogService.open(
      EditZoneComponent,
      this.dialogConfig,
    );
    dialogRef.onClose.subscribe(() => {
      this.loadZones();
    });
  }

  private async _loadCities() {
    this._subs.add(
      this._parkingClient.getParkingCities().subscribe((res) => {
        const cityNames = res.result.responseList!.map((city) => city.cityName);
        this.cityIds = res.result.responseList!.map((id) => id.cityId);
        this.allCities = cityNames;
      }),
    );
  }

  loadZones() {
    this._subs.add(
      this._parkingClient
        .getAllParkingZones(this.savedCityId)
        .subscribe((res) => {
          this.allZones = res.result.responseList.map((zone) => ({
            id: zone.id,
            name: zone.name,
            description: zone.description,
            zoneCode: zone.phoneNumber,
            color: zone.color,
            type: this.getTypeOfParking(zone.type),
          }));
        }),
    );
  }

  private _getCitiesZonesCount() {
    this._subs.add(
      this._parkingClient.getCitiesAndZonesCount().subscribe((res) => {
        this.allCitiesCount = res.result.value.citiesCount.toString();
        this.allZonesCount = res.result.value.zonesCount.toString();
      }),
    );
  }

  deleteCity(city: any) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da obrišete grad?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._parkingClient
            .deleteParkingCity(
              new DeleteParkingCityCommand({
                parkingCityId: this.savedCityId,
              }),
            )
            .subscribe((x) => {
              this._alertService.addSuccessMsg(x.result);
              this._loadCities().then();
              this._getCitiesZonesCount();
              const previousIndex = this.selectedCityIndex - 1;
              const firstIndex = 0;
              this.onCityClick(previousIndex > 0 ? previousIndex : firstIndex);
            }),
        );
      },
    });
  }

  confirmDeleteZone(zone: any) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da obrišete zonu?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Potvrdite',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.deleteZone(zone.id);
      },
    });
  }

  deleteZone(zoneId: string) {
    this._subs.add(
      this._parkingClient.deleteParkingZone(zoneId).subscribe((x) => {
        this._alertService.addSuccessMsg(x.result);
        this.loadZones();
        this._getCitiesZonesCount();
      }),
    );
  }

  onCityClick(index: number) {
    this.selectedCityIndex = index;
    this.savedCityId = this.cityIds[index];
    this._adminService.setCityId(this.savedCityId);
    this._subs.add(
      this._parkingClient
        .getAllParkingZones(this.savedCityId)
        .subscribe((res) => {
          this.allZones = res.result.responseList.map((zone) => ({
            id: zone.id,
            name: zone.name,
            description: zone.description,
            zoneCode: zone.phoneNumber,
            color: zone.color,
            type: this.getTypeOfParking(zone.type),
          }));
        }),
    );
  }

  getTypeOfParking(type: number): string {
    switch (type) {
      case 1:
        return 'Po satu';
      case 2:
        return 'Dnevna';
      default:
        return 'Nepoznato';
    }
  }

  getColorClass(color: number): string {
    switch (color) {
      case ZoneColor.PLAVA:
        return 'blue-bg';
      case ZoneColor.ZELENA:
        return 'green-bg';
      case ZoneColor.ZUTA:
        return 'yellow-bg';
      case ZoneColor.LJUBICASTA:
        return 'purple-bg';
      case ZoneColor.SIVA:
        return 'gray-bg';
      case ZoneColor.CRVENA:
        return 'red-bg';
      case ZoneColor.ZLATNA:
        return 'gold-bg';
      case ZoneColor.BELA:
        return 'white-bg';
      default:
        return 'gray-bg';
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
