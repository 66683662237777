import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  PackageForm,
  PackageService,
} from '@app/administrator/services/package-service';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'atlas-action-price-card',
  standalone: true,
  imports: [InputSwitchModule, CurrencyPipe],
  templateUrl: './action-price-card.component.html',
  styleUrl: './action-price-card.component.scss',
})
export class ActionPriceCardComponent implements OnInit {
  @Input() priceInfo: any;
  @Input() index: any;

  constructor(private packageService: PackageService) {}

  packageForm: PackageForm;

  ngOnInit(): void {
    this.packageForm = this.packageService.packageForm;
  }

  handleRemoveCard() {
    var packagePrices = this.packageForm.get('packagePrices') as FormArray;
    if (packagePrices && packagePrices.length > this.index) {
      packagePrices.removeAt(this.index);
    }
  }
}
