<li
  class="h-full menu-item"
  (mouseenter)="isHover = true"
  (mouseleave)="isHover = false"
>
  <a
    pRipple
    class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center menu-item-text font-normal cursor-pointer transition-colors transition-duration-150 no-underline"
    [routerLink]="itemRouterLink"
    routerLinkActive="active-route"
    [ngClass]="{
      'selected-item': isItemSelected(),
      'non-selected-item': !isItemSelected()
    }"
  >
    <img
      *ngIf="!isItemSelected() && !isHover"
      [ngSrc]="'assets/images/menu-items/' + itemIcon + '.svg'"
      width="20"
      height="20"
      alt="icon-image"
    />
    <img
      *ngIf="isItemSelected() || isHover"
      [ngSrc]="'assets/images/menu-items/' + itemIcon + '-selected.svg'"
      width="20"
      height="20"
      alt="icon-image"
    />
    <h4 *ngIf="itemLabel" class="item-label">{{ itemLabel }}</h4>
  </a>
</li>
