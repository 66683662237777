import {Component} from '@angular/core';

@Component({
    selector: 'atlas-new-password',
    standalone: true,
    imports: [],
    templateUrl: './new-password.component.html',
    styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent {

}
