import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  AdminService,
  EditCityForm,
} from '@app/administrator/services/admin-service';
import {
  EditParkingCityCommand,
  ParkingsClient,
} from '@shared/model/atlas.api';
import { AlertService } from '@shared/services/alert.service';
import { DialogRef } from '@ui/dialog/dialog-ref';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-edit-city',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, InputTextComponent, NgIf],
  templateUrl: './edit-city.component.html',
  styleUrl: './edit-city.component.scss',
})
export class EditCityComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  form: EditCityForm;
  currentCityId: string;

  constructor(
    private _dialogRef: DialogRef,
    private _adminService: AdminService,
    private _alertService: AlertService,
    private _parkingClient: ParkingsClient,
  ) {
    this._adminService.cityId$.subscribe((cityId) => {
      this.currentCityId = cityId;
    });
    this.loadCityInfo(this.currentCityId);
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSubmit();
      }),
    );
  }

  private loadCityInfo(id: string) {
    this._subs.add(
      this._parkingClient.getCityById(id).subscribe((res) => {
        this.form = this._adminService.getEditCityForm(res.result.value);
      }),
    );
  }

  handleSubmit() {
    this._subs.add(
      this._parkingClient
        .editParkingCity(this.form.value as EditParkingCityCommand)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.result);
          this._dialogRef.close();
        }),
    );
  }

  ngOnDestroy(): void {
    this._adminService.reset();
    this._subs.unsubscribe();
  }
}
