import { NgFor, NgIf, NgClass, NgForOf, JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrandAutocompleteComponent } from '@app/features/vehicles/brand-autocomplete/brand-autocomplete.component';
import { IconCheckboxComponent } from '@shared/components/icon-checkbox/icon-checkbox/icon-checkbox.component';
import { InputMailComponent } from '@shared/components/input-mail/input-mail/input-mail.component';
import { InputPhoneComponent } from '@shared/components/input-phone/input-phone.component';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { CheckboxComponent } from '@ui/checkbox/checkbox.component';
import { DropdownComponent } from '@ui/dropdown/dropdown.component';
import { InputAddonComponent } from '@ui/input-addon/input-addon/input-addon.component';
import { InputTextComponent } from '@ui/input-text/input-text.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'atlas-create-order-form-step-3',
  standalone: true,
  imports: [NgFor,
    NgIf,
    NgClass,
    NgForOf,
    CheckboxModule,
    InputTextComponent,
    InputPhoneComponent,
    InputMailComponent,
    InputAddonComponent,
    DropdownComponent, IconCheckboxComponent,
    BrandAutocompleteComponent,
    FormsModule,
    FormControlPipe,
    JsonPipe,
    RadioButtonModule,
  CheckboxComponent,
  ReactiveFormsModule,
],
  templateUrl: './create-order-form-step-3.component.html',
  styleUrl: './create-order-form-step-3.component.scss'
})
export class CreateOrderFormStep3Component implements OnInit {

  thirdStepControl = new FormControl('');
  partnerTypes: any[];
  
  constructor() {
    this._initCategories();
  }
  ngOnInit(): void {
    this._initCategories();
  }

  private _initCategories() {
    this.partnerTypes = [
      { name: 'Aktivacija odmah (paket se aktivira odmah nakon evidentiranja uplate)', id: 1 },
      { name: 'Standardna aktivacija (7 dana od evidentiranja uplate)', id: 2 },
    ];
  }

}
