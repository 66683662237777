import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CheckboxModule} from "primeng/checkbox";

@Component({
    selector: 'atlas-checkbox',
    standalone: true,
    imports: [
        CheckboxModule
    ],
    templateUrl: './checkbox.component.html',
    styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
    /** I/O */
    @Input() formCtrl!: FormControl;
    @Input() ctrlPlaceholder: string = '';
    @Input() ctrlLabel: string = 'Label'
    @Input() formCtrlId: string = 'text-input';
    @Input() formCtrlType: string = 'text';
    @Input() formCtrlClass: string = '';
    @Input() isDisabled: boolean;
    @Output() keyReleased = new EventEmitter<boolean>();
    @Output() inputLeft: EventEmitter<string | number> | null;

    constructor() {
        this.isDisabled = false;
        this.inputLeft = null;
    }
}
