import {Component} from '@angular/core';

@Component({
    selector: 'atlas-email-check',
    standalone: true,
    imports: [],
    templateUrl: './email-check.component.html',
    styleUrl: './email-check.component.scss'
})
export class EmailCheckComponent {

}
