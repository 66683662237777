<div class="border-solid border-1 border-round-xl card">
  <div class="types-container flex">
    <div *ngFor="let type of package.vehicleTypes" class="type-badge mr-3 mb-3">
      {{ type }}
    </div>
  </div>

  <div class="flex align-items-center">
    <div *ngIf="package.imageUri">
      <img src="{{ package.imageUri }}" alt="package-img" class="image mr-2" />
    </div>
    <div *ngIf="!package.imageUri">
      <i class="pi pi-image icon"></i>
    </div>
    <div class="flex-grow-1">
      <div class="font-medium ">{{ package.name }}</div>
      <div class="font-medium text-sm price">{{ package.price }} rsd.</div>
    </div>
    <div (click)="changePackageStatus()">
      <p-inputSwitch [(ngModel)]="package.isActive"></p-inputSwitch>
    </div>
  </div>
</div>
