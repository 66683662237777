import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  input,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PackageService } from '@app/administrator/services/package-service';
import { VehicleTypeItem } from '@shared/model/atlas.api';
import { CheckboxComponent } from '@ui/checkbox/checkbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-vehicle-type-card',
  standalone: true,
  imports: [
    CheckboxComponent,
    CheckboxModule,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
  ],
  templateUrl: './vehicle-type-card.component.html',
  styleUrl: './vehicle-type-card.component.scss',
})
export class VehicleTypeCardComponent {
  private _subs: Subscription = new Subscription();

  @Input() type: VehicleTypeItem;
  @Output() typeId = new EventEmitter<string>();
  @Input() selectedTypes: string[] = [];

  handleCheckboxAdd(event: any) {
    this.typeId.emit(event.checked[0]);
  }
}
