import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { ToastModule } from 'primeng/toast';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, LoaderComponent, ToastModule],
})
export class AppComponent {
  title = 'angular';

  constructor(private config: PrimeNGConfig) {}

  ngOnInit() {
    this.config.setTranslation({
        passwordPrompt: 'Unesi lozinku',
        emptyFilterMessage: 'Nema rezultata',
        emptySearchMessage: 'Nema rezultata',
        emptyMessage: 'Nema opcija',
        accept: 'Prihvati',
        addRule: 'Dodaj pravilo',
        am: 'AM',
        apply: 'Primeni',
        cancel: 'Otkaži',
        choose: 'Izaberi',
        chooseDate: 'Izaberi datum',
        chooseMonth: 'Izaberi mesec',
        chooseYear: 'Izaberi godinu',
        clear: 'Očisti',
        // completed: 'Završeno',
        contains: 'Sadrži',
        // custom: 'Prilagođeno',
        dateAfter: 'Datum je posle',
        dateBefore: 'Datum je pre',
        dateFormat: 'dd.mm.gggg',
        dateIs: 'Datum je',
        dateIsNot: 'Datum nije',
        dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
        dayNamesMin: ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
        dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
        emptySelectionMessage: 'Nema izabrane stavke',
        endsWith: 'Završava sa',
        equals: 'Jednako',
        fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        // filter: 'Filtriraj',
        firstDayOfWeek: 0,
        gt: 'Veće od',
        gte: 'Veće ili jednako',
        lt: 'Manje od',
        lte: 'Manje ili jednako',
        matchAll: 'Podudaraju se sa svim',
        matchAny: 'Podudara se bilo koji',
        medium: 'Srednje',
        monthNames: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
        nextDecade: 'Naredna decenija',
        nextHour: 'Naredni sat',
        nextMinute: 'Naredni minut',
        nextMonth: 'Naredni mesec',
        nextSecond: 'Naredna sekunda',
        nextYear: 'Naredna godina',
        noFilter: 'Bez filtera',
        notContains: 'Ne sadrži',
        notEquals: 'Nije jednako',
        // now: 'Sada',
        pending: 'Na čekanju',
        pm: 'PM',
        prevDecade: 'Prethodna decenija',
        prevHour: 'Prethodni sat',
        prevMinute: 'Prethodni minut',
        prevMonth: 'Prethodni mesec',
        prevSecond: 'Prethodna sekunda',
        prevYear: 'Prethodna godina',
        reject: 'Odbaci',
        removeRule: 'Ukloni pravilo',
        searchMessage: 'Dostupno rezultata: {0}',
        selectionMessage: 'Odabrano stavki: {0}',
        // showMonthAfterYear: false,
        startsWith: 'Počinje sa',
        strong: 'Jako',
        today: 'Danas',
        upload: 'Postavi',
        weak: 'Slabo',
        weekHeader: "Sed",
        aria: {
            cancelEdit: 'Otkaži izmenu',
            close: 'Zatvori',
            // collapseLabel: 'Umanji',
            collapseRow: 'Sklopi red',
            editRow: 'Uredi red',
            // expandLabel: 'Proširi',
            expandRow: 'Proširi red',
            falseLabel: 'Nije tačno',
            filterConstraint: 'Ograničenje filtera',
            filterOperator: 'Operator filtera',
            firstPageLabel: 'Prva strana',
            gridView: 'Prikaz u tabeli',
            hideFilterMenu: 'Sakri meni filtera',
            jumpToPageDropdownLabel: 'Skoči na padajuću listu stranice',
            jumpToPageInputLabel: 'Skoči na unos stranice',
            lastPageLabel: 'Poslednja strana',
            listView: 'Prikaz u listi',
            moveAllToSource: 'Pomeri sve na izvor',
            moveAllToTarget: 'Pomeri sve na cilj',
            moveBottom: 'Pomeri na dno',
            moveDown: 'Pomeri dole',
            moveToSource: 'Pomeri na izvor',
            moveToTarget: 'Pomeri na cilj',
            moveTop: 'Pomeri na vrh',
            moveUp: 'Pomeri gore',
            navigation: 'Navigacija',
            next: 'Sledeće',
            nextPageLabel: 'Sledeća stranica',
            nullLabel: 'Nije izabrano',
            // otpLabel: 'Unesite jednokratnu lozinku {0}',
            pageLabel: 'Strana {page}',
            // passwordHide: 'Sakrij lozinku',
            // passwordShow: 'Prikaži lozinku',
            previous: 'Prethodno',
            previousPageLabel: 'Prethodna strana',
            rotateLeft: 'Rotiraj levo',
            rotateRight: 'Rotiraj desno',
            rowsPerPageLabel: 'Redova po strani',
            saveEdit: 'Sačuvaj izmene',
            scrollTop: 'Idi na vrh',
            selectAll: 'Sve stavke su odabrane',
            // selectLabel: 'Izaberi',
            selectRow: 'Red odabran',
            showFilterMenu: 'Prikaži meni filtera',
            slide: 'Sklizni',
            slideNumber: 'Broj slajda {slideNumber}',
            star: '1 zvezda',
            stars: 'Zvezda: {start}',
            trueLabel: 'Tačno',
            unselectAll: 'Svi predmeti su deselektovani',
            // unselectLabel: 'Poništi izbor',
            unselectRow: 'Red deselektovan',
            zoomImage: 'Uvećaj sliku',
            zoomIn: 'Uvećaj',
            zoomOut: 'Umanji'
        }
    });
}
}
