import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { ButtonComponent } from '@ui/button/button.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgIf } from '@angular/common';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MessagesModule } from 'primeng/messages';
import { AssistancesClient, UsersClient } from '@shared/model/atlas.api';
import { DialogConfig, DialogSize } from '@ui/dialog/dialog-config';
import { DialogService } from '@ui/dialog/dialog.service';
import { ResetPasswordFirstLoginFormComponent } from '@infrastructure/auth/reset-password-first-login-form/reset-password-first-login-form.component';
import { AssistanceToastComponent } from '@app/sos-agent/assistances/assistance-toast/assistance-toast/assistance-toast.component';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'atlas-app-layout',
  standalone: true,
  providers: [ConfirmationService],
  imports: [
    InputTextModule,
    BadgeModule,
    RippleModule,
    StyleClassModule,
    SelectButtonModule,
    ButtonModule,
    RouterOutlet,
    MenuComponent,
    BreadcrumbModule,
    ButtonComponent,
    OverlayPanelModule,
    NgIf,
    ConfirmDialogComponent,
    ScrollPanelModule,
    MessagesModule,
    AssistanceToastComponent,
  ],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  shouldShowAssistancesToast: boolean;

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = { icon: 'pi pi-home', routerLink: 'home' };
  menuItems: MenuItem[] | undefined;

  dialogConfig: DialogConfig = new DialogConfig(DialogSize.SMALL);

  constructor(
    private router: Router,
    private client: UsersClient,
    private dialogService: DialogService,
    private userService: UserService,
  ) {
    this.menuItems = [
      {
        label: '#ToDo',
      },
    ];

    if (this.userService.isSosAgent()) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((res: NavigationEnd) => {
          this.shouldShowAssistancesToast =
            !res.url.includes('/assistances/create') &&
            !res.url.includes('/assistances/edit');
        });
    }
  }

  ngOnInit(): void {
    // this._initBreadcrumb();
    // this._subs.add(
    //   this.router.events.subscribe(
    //     () =>
    //       (this.menuItems = this._createBreadcrumbsWrapper(
    //         this.activatedRoute,
    //       )),
    //   ),
    // );
    this._getShouldResetPassword().then((x) => {
      if (x.result) {
        this._showResetPassword();
      }
    });
  }

  private _initBreadcrumb() {
    this.menuItems = this._createBreadcrumbsWrapper(
      this.router.routerState.root,
    );
    return this.menuItems?.shift();
  }

  get isSosAgent(): boolean {
    return this.userService.isSosAgent();
  }

  private _createBreadcrumbsWrapper(route: ActivatedRoute): any {
    if (route.children.length > 0) {
      return this._createBreadcrumbs(route.children[0], '', []);
    }

    return [];
  }

  private _createBreadcrumbs(
    route: ActivatedRoute,
    routerLink: string = '',
    breadcrumbs: MenuItem[] = [],
  ): any {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((x) => x.path).join('/');
      if (routeURL !== '') {
        routerLink += `/${routeURL}`;
      }

      const label =
        child.snapshot.data[AppLayoutComponent.ROUTE_DATA_BREADCRUMB];

      if (label) {
        breadcrumbs.push({ label, routerLink });
      }

      return this._createBreadcrumbs(child, routerLink, breadcrumbs);
    }
  }

  private async _getShouldResetPassword() {
    return await firstValueFrom(this.client.shouldResetPassword());
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  openDialog(formType: any): void {
    this.dialogService.open(formType, this.dialogConfig);
  }

  private _showResetPassword() {
    this.dialogConfig.header = 'Postavite lozinku';
    this.dialogConfig.headerDescription =
      'Molimo Vas da da unesete novu lozinku';
    this.dialogConfig.customSubmitButton = {
      label: 'Potvrdi',
      icon: 'pi pi-key',
    };
    this.dialogConfig.hideCancel = true;
    this.dialogConfig.closable = false;
    this.dialogConfig.maximisable = false;

    this.openDialog(ResetPasswordFirstLoginFormComponent);
  }
}
