<atlas-page
  pageTitle="Kampanje"
  btnIcon="pi pi-plus"
  actionLabel="Dodaj kampanju"
  (onHandleAction)="addCampaign()"
>
  <div class="header-container flex align-items-center justify-content-between w-full gap-3">
    <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
      <p-tabPanel header="Leadovi">
        <ng-template pTemplate="header">
          <div class="flex align-items-center justify-content-between w-full">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">Leadovi</span>
              <p-badge [value]="16"></p-badge>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>

      <p-tabPanel header="Ugovori">
        <ng-template pTemplate="header">
          <div class="flex align-items-center justify-content-between w-full">
            <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0">Ugovori</span>
              <p-badge [value]="8"></p-badge>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>

    <div>
      <div class="search-bar gap-3"></div>
        <atlas-input-text
          ctrlPlaceholder="Pretraži"
          [hideErrors]="true"
          [formCtrl]="campaignTable"
          formCtrlIcon="pi pi-search"
          (onEnterClicked)="load()"
        ></atlas-input-text>
      </div>
      <div class="filters-button">
      <atlas-button
      btnIcon="pi pi-sliders-h"
        class="filters-button"
        [btnStyle]="ButtonStyle.BASIC"
        [btnSeverity]="ButtonSeverity.SECONDARY_GREY"
        btnLabel="Filteri"
      ></atlas-button>
    </div>
      <div class="flex align-items-center gap-2">
        <p-dropdown
          [options]="rowsOptions"
          [(ngModel)]="selectedRows"
          placeholder="Select rows"
          class="dropdown-number"
        ></p-dropdown>
      </div>
    </div>

  <div *ngIf="activeTabIndex === 0">
    <p-dataView [value]="items" paginator="true" [rows]="selectedRows">
      <ng-template let-items pTemplate="list">
        <div *ngFor="let item of items;" class="col-12 p-md-4">
          <atlas-campaign-card
            [flagColor]="item.flagColor"
            [title]="item.title"
            [showTopUserIcon]="true"
            [showProgressBar]="true"
            [showFlagIcon]="true"
            [showFooterUserIcon]="true"
            [status]="item.status"
            [description]="item.description"
            [date]="item.date"
            [user]="item.user"
            [info]="item.info"
            [totalUsers]="item.totalUsers"
          ></atlas-campaign-card>
        </div>
      </ng-template>
    </p-dataView>
  </div>

  <div *ngIf="activeTabIndex === 1">
    <p-dataView  [value]="otherItems" paginator="true" [rows]="selectedRows">
      <ng-template let-otherItems pTemplate="list">
        <div *ngFor="let item of otherItems;" class="col-12 p-md-4">
          <atlas-campaign-card
            [title]="item.title"
            [status]="item.status"
            [description]="item.description"
            [date]="item.date"
            [showDealTime]="true"
            [dealTime]="item.dealTime"
            [user]="item.user"
            [totalUsers]="item.totalUsers"
          ></atlas-campaign-card>
        </div>
      </ng-template>
    </p-dataView>
  </div>

</atlas-page>
