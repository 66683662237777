import { Component } from '@angular/core';

@Component({
  selector: 'atlas-agent-table',
  standalone: true,
  imports: [],
  templateUrl: './agent-table.component.html',
  styleUrl: './agent-table.component.scss'
})
export class AgentTableComponent {

}
