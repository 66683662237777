import {Component} from '@angular/core';

@Component({
    selector: 'atlas-white-logo',
    standalone: true,
    imports: [],
    templateUrl: './white-logo.component.html',
    styleUrl: './white-logo.component.scss'
})
export class WhiteLogoComponent {

}
