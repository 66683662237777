import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';

@Component({
  selector: 'atlas-dropdown',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    NgIf,
    ErrorRequiredComponent,
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  /** I/O */
  @Input() formCtrl!: FormControl;
  @Input() optionLabel!: string;
  @Input() options: any[] = [];
  @Input() ctrlDataKey: string | undefined = undefined;
  @Input() formCtrlId!: string;
  @Input() ctrlTooltip!: string;
  @Input() ctrlLabel: string = '';
  @Input() showLabel: boolean = true;
  @Input() ctrlPlaceholder!: string;
  @Input() ctrlTooltipPosition!: string; // Dodati u .html
  @Input() canFilter!: boolean;
  @Input() ctrlFilterPlaceholder!: string;
  @Input() ctrlFilterBy!: string;
  @Input() ctrlStyleClass!: string;
  @Input() isGrouped!: boolean;
  @Input() canClear!: boolean;
  @Input() ctrlDisabled!: boolean;
  @Input() hideErrors: boolean = false;
  @Input() ctrlAppendTo: string = 'body';

  @Output() optionChanged = new EventEmitter();

  /**
   * Pass a template for the autocomplete in this component
   *
   * @usage
   * Can be passed inside the body of this component as:
   *  <app-my-wrapper ...>
   *      <ng-template ... #item let-obj>...</ng-template>
   *  </app-my-wrapper>
   */
  @ContentChild('item') item!: TemplateRef<ElementRef>;
  @ContentChild('selectedItem') selectedItem!: TemplateRef<ElementRef>;

  constructor() {}

  handleOnItemChange(event: any) {
    this.optionChanged.emit(event);
  }
}

export interface DropdownOptions {
  label: string;
  value: any;
}
