<div class="flex gap-4 align-items-baseline">
    <div class="flex-grow-1">
        <atlas-input-text
                [ctrlLabel]="searchLabel"
                [formCtrl]="search | formControl"
                formCtrlId="table-search"
                formCtrlIcon="pi pi-search"
        >
        </atlas-input-text>
    </div>
    <span *ngFor="let action of tableActions">
  <atlas-button
          [btnIcon]="action.icon!"
          [btnLabel]="action.label!"
          [btnStyle]="BASIC"
          (btnClicked)="action.callback!(this.search.value)"
  >
  </atlas-button>
  </span>
</div>
