import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ErrorRequiredComponent } from '@ui/errors/error-required/error-required.component';
import { JsonPipe, NgIf } from '@angular/common';
import { MaxLengthComponent } from '@ui/errors/max-length/max-length.component';

@Component({
  selector: 'atlas-input-textarea',
  standalone: true,
  imports: [
    InputTextareaModule,
    ReactiveFormsModule,
    ErrorRequiredComponent,
    NgIf,
    JsonPipe,
    MaxLengthComponent,
  ],
  templateUrl: './input-textarea.component.html',
  styleUrl: './input-textarea.component.scss',
})
export class InputTextareaComponent {
  /** I/O */
  @Input() formCtrl!: FormControl;
  @Input() ctrlPlaceholder: string = '';
  @Input() ctrlLabel: string = 'Label';
  @Input() formCtrlId: string = 'text-input';
  @Input() formCtrlType: string = 'text';
  @Input() formCtrlClass: string = '';
  @Input() formCtrlIcon?: string;
  @Input() isDisabled: boolean;
  @Input() showLabel: boolean = true;
  @Input() shouldFocus: boolean = false;
  @Output() keyReleased = new EventEmitter<boolean>();
  @Output() inputLeft: EventEmitter<string | number> | null = new EventEmitter<
    string | number
  >();
  @Output() onEnterClicked = new EventEmitter<string>();

  constructor() {
    this.isDisabled = false;
  }

  handleKeyUp(value: any) {
    this.keyReleased.emit(value);
    if (value.keyCode === 13) {
      this.onEnterClicked.emit();
    }
  }

  handleBlur(event: any) {
    if (this.inputLeft) {
      this.inputLeft.emit(event.target.value);
    }
  }
}
