<div class="flex flex-column gap-5">
  <div>
    <div class="text-3xl font-semibold">{{ pageTitle }}</div>
    <div class="flex justify-content-between align-items-center">
      <div class="text-base text-gray-600">{{ pageDescription }}</div>
      <atlas-button
        *ngIf="actionLabel"
        (btnClicked)="handleAction()"
        [btnStyle]="ButtonStyle.BASIC"
        btnClass="w-full"
        [btnIcon]="btnIcon"
        [btnLabel]="actionLabel"
      >
      </atlas-button>
    </div>
  </div>
  <div>
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100dvh - 200px)'}">
      <ng-content></ng-content>
    </p-scrollPanel>
  </div>
</div>
 